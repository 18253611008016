import backup from "./backup";



export default [
  {
    path: "/" + "backup",
    component: backup,

    name: "backup_show",
  },
];
