<template>
  <div class="v-list_add">
    <!--   -->
    <div class="v-main_table" v-if="headers.length > 0">
      <!--  plugine-->
      <table-headers
        :title="title"
        :controller_root="controller_root"
        :slot_show="slot_show"
        :action_traitement="action_traitement"
      ></table-headers>
      <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->
      <v-data-table
        :loading="loading"
        class="elevation-1"
        no-results-text="Aucun élément trouvé"
        no-data-text="Aucun élément trouvé"
        :show-expand="expanded.length > 0"
        :single-expand="true"
        :headers="headers"
        :items="items_show_style"
        :show-select="true"
        :value="selected"
        @input="select($event)"
        @click:row="clickrow"
        :item-key="item_key"
        :options.sync="optionsDataTable"
        :server-items-length="TotalItemCount"
      >
        <!--  :search="search" -->
        <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->

        <template v-slot:expanded-item="{ headers, item }">
          <table-expand
            :controller_root="controller_root"
            :item="item"
            :numbretd="headers.length"
          ></table-expand>
        </template>

        <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->
        <template v-slot:item.action="{ item }">
          <table-action
            :item="item"
            :controller_root="controller_root"
            :slot_show="slot_show"
            :action_traitement="action_traitement"
          ></table-action>
        </template>

        <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->
        <template v-slot:item.id="{ item }">
          <v-chip color="orange" dark
            ><strong>{{ item.id }}</strong></v-chip
          >
        </template>

        <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->
        <template v-slot:item.date="{ item }">
          <v-chip v-show="item.date">
            <v-icon color="orange" small class="pr-2"
              >mdi-calendar-clock-outline</v-icon
            >
            {{ convertDate(item.date) }}</v-chip
          >
        </template>
        <template v-slot:item.createdAt="{ item }">
          <v-chip v-show="item.createdAt">
            <v-icon color="orange" small class="pr-2"
              >mdi-calendar-clock-outline</v-icon
            >
            {{ convertDate(item.createdAt) }}</v-chip
          >
        </template>
        <template v-slot:item.updatedAt="{ item }">
          <v-chip v-show="item.updatedAt">
            <v-icon color="orange" small class="pr-2"
              >mdi-calendar-clock-outline</v-icon
            >
            {{ convertDate(item.updatedAt) }}</v-chip
          >
        </template>
        <template v-slot:item.dateUpload="{ item }">
          <v-chip v-show="item.dateUpload">
            <v-icon color="orange" small class="pr-2"
              >mdi-calendar-clock-outline</v-icon
            >
            {{ convertDate(item.dateUpload) }}</v-chip
          >
        </template>

        <template v-slot:item.dateCommande="{ item }">
          <v-chip v-show="item.dateCommande">
            <v-icon color="orange" small class="pr-2"
              >mdi-calendar-clock-outline</v-icon
            >
            {{ convertDate(item.dateCommande) }}</v-chip
          >
        </template>
        <template v-slot:item.dateLivraison="{ item }">
          <v-chip v-show="item.dateLivraison">
            <v-icon color="orange" small class="pr-2"
              >mdi-calendar-clock-outline</v-icon
            >
            {{ convertDate(item.dateLivraison) }}</v-chip
          >
        </template>

        <template v-slot:item.dateoperation="{ item }">
          <v-chip v-show="item.dateoperation">
            <v-icon color="orange" small class="pr-2"
              >mdi-calendar-clock-outline</v-icon
            >
            {{ convertDate(item.dateoperation) }}</v-chip
          >

          }</template
        >

        <template v-slot:item.lastdateachat="{ item }">
          <v-chip v-show="item.lastdateachat">
            <v-icon color="orange" small class="pr-2"
              >mdi-calendar-clock-outline</v-icon
            >
            {{ convertDate(item.lastdateachat) }}</v-chip
          >
        </template>

        <template v-slot:item.icon="{ item }">
          <v-icon> {{ item.icon }} </v-icon>
        </template>

        <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->
        <template v-slot:item.files="{ item }">
          <!-- <table-setfiles v-if="!item.files || item.files==''" name="files" :controller_root="controller_root" :id="item.id"  ></table-setfiles>
            -->
          <table-avatar
            :controller_root="controller_root"
            name="files"
            :id="item.id"
            size="30px"
            :refgroup="item.files"
            :hasdowlaod="true"
          ></table-avatar>
        </template>
        <template v-slot:item.file="{ item }">
          <!-- <table-setfiles v-if="!item.file || item.file==''" name="file" :controller_root="controller_root" :id="item.id"  ></table-setfiles>
        -->
          <table-avatar
            :controller_root="controller_root"
            name="file"
            :id="item.id"
            size="30px"
            :refgroup="item.file"
            :hasdowlaod="true"
          ></table-avatar>
        </template>
        <template v-slot:item.logo="{ item }">
          <!-- <table-setfiles v-if="!item.logo || item.logo==''" name="logo" :controller_root="controller_root" :id="item.id"  ></table-setfiles>
        -->
          <table-avatar
            :controller_root="controller_root"
            name="logo"
            :id="item.id"
            size="30px"
            :refgroup="item.logo"
            :hasdowlaod="true"
          ></table-avatar>
        </template>

        <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->
        <template v-slot:item.actionTraiter="{ item }">
          <table-actiontraiter
            :action="item.actionTraiter"
          ></table-actiontraiter>
        </template>

        <!-- foreign_key wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->

        <template v-slot:item.user="{ item }">
          <table-foreignkey
            :itemObject="item.user"
            :itemShowString="['identifiant']"
          ></table-foreignkey>
        </template>

        <template v-slot:item.client="{ item }">
          <table-foreignkey
            :itemObject="item.client"
            :itemShowString="['identifiant']"
          ></table-foreignkey>
        </template>
        <template v-slot:item.validateur="{ item }">
          <table-foreignkey
            :itemObject="item.validateur"
            :itemShowString="['identifiant']"
          ></table-foreignkey>
        </template>

        <template v-slot:item.serveur="{ item }">
          <table-foreignkey
            :itemObject="item.serveur"
            :itemShowString="['identifiant']"
          ></table-foreignkey>
        </template>
        <template v-slot:item.mastercheff="{ item }">
          <table-foreignkey
            :itemObject="item.mastercheff"
            :itemShowString="['identifiant']"
          ></table-foreignkey>
        </template>

        <template v-slot:item.fournisseur="{ item }">
          <table-foreignkey
            :itemObject="item.fournisseur"
            :itemShowString="['fournisseur']"
          ></table-foreignkey>
        </template>

        <template v-slot:item.modepaiement="{ item }">
          <table-foreignkey
            :itemObject="item.modepaiement"
            :itemShowString="['modepaiement']"
          ></table-foreignkey>
        </template>

        <template v-slot:item.categories="{ item }">
          <table-foreignkey
            :itemObject="item.categories"
            :itemShowString="['categorie']"
          ></table-foreignkey>
        </template>

        <template v-slot:item.categorieplat="{ item }">
          <table-foreignkey
            :itemObject="item.categorieplat"
            :itemShowString="['categorie']"
          ></table-foreignkey>
        </template>

        <template v-slot:item.inventaire="{ item }">
          <table-foreignkey
            :itemObject="item.inventaire"
            :itemShowString="['createdAt']"
          ></table-foreignkey>
        </template>

        <template v-slot:item.recette="{ item }">
          <table-foreignkey
            :itemObject="item.recette"
            :itemShowString="['name']"
          ></table-foreignkey>
        </template>

        <template v-slot:item.recetteracine="{ item }">
          <table-foreignkey
            :itemObject="item.recetteracine"
            :itemShowString="['name']"
          ></table-foreignkey>
        </template>

        <template v-slot:item.commandes="{ item }">
          <table-foreignkey
            :itemObject="item.commandes"
            :itemShowString="['code']"
          ></table-foreignkey>
        </template>
        <template v-slot:item.plat="{ item }">
          <table-foreignkey
            :itemObject="item.plat"
            :itemShowString="['name']"
          ></table-foreignkey>
        </template>

        <template v-slot:item.produits="{ item }">
          <table-foreignkey
            :itemObject="item.produits"
            :itemShowString="['designation']"
          ></table-foreignkey>
        </template>

        <template v-slot:item.bonsachats="{ item }">
          <table-foreignkey
            :itemObject="item.bonsachats"
            :itemShowString="['reffournisseur']"
          ></table-foreignkey>
        </template>
        <template v-slot:item.perte="{ item }">
          <table-foreignkey
            :itemObject="item.perte"
            :itemShowString="['titre']"
          ></table-foreignkey>
        </template>

        <template v-slot:item.color="{ item }">
          <v-card
            style="min-width: 64px; height: 34px"
            :color="item.color"
            class="align-center justify-center"
          >
            <v-card-text>{{ item.color }}</v-card-text>
          </v-card>
        </template>

        <template v-slot:item.stockalerte="{ item }">
          <v-chip>
            <v-icon color="error" small class="pr-2">mdi-bell-ring</v-icon>
            {{ item.stockalerte }}</v-chip
          >
        </template>

        <template v-slot:item.unite="{ item }">
          <v-chip>
            <v-icon color="orange" small class="pr-2">mdi-scale</v-icon>
            {{ item.unite }}</v-chip
          >
        </template>

         <template v-slot:item.stock="{ item }">
          <v-chip>
            <v-icon color="orange" small class="pr-2">mdi-database-eye-outline</v-icon>
            {{ item.stock }}</v-chip
          >
        </template>

        <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->
        <template
          v-slot:body.append="{ headers, items, isSelected, isMobile }"
          v-if="calculemontantitem.length > 0 && items.length > 0"
        >
          <tr v-if="!isMobile">
            <td :colspan="headers.length" v-if="selected.length == 0">
              <v-alert
                border="left"
                color="green"
                dense
                icon="mdi-cash-multiple"
                colored-border
                type="success"
              >
                les élements afficher:{{ items.length }}<br />
                <span
                  v-for="(montant, indexmontant) in calculemontantitem"
                  :key="indexmontant"
                >
                  {{ $t("TOTAL ") + montant }} :
                  {{ calculemontant(items, montant) }} DH<br />
                </span>
              </v-alert>
            </td>

            <td :colspan="headers.length" v-else>
              <v-alert
                border="left"
                colored-border
                color="orange"
                dense
                icon="mdi-cash-multiple"
                type="success"
              >
                les élements selecter :{{ selected.length }}<br />
                <span
                  v-for="(montant, indexmontantt) in calculemontantitem"
                  :key="indexmontantt"
                >
                  {{ $t("TOTAL ") + montant }} :
                  {{ calculemontant(selected, montant) }}
                  DH<br />
                </span>
              </v-alert>
            </td>
          </tr>
        </template>
      </v-data-table>

      <table-dialog :controller_root="controller_root" v-if="slot_show">
        <template v-slot:show>
          <slot name="show"></slot>
        </template>

        <template v-slot:add>
          <slot name="add"></slot>
        </template>

        <template v-slot:update>
          <slot name="update"></slot>
        </template>
      </table-dialog>
    </div>
  </div>
</template>

<script>
import util from "@/util/index";
import i18n from "@/plugins/i18n";

export default {
  props: {
    controller_root: {},

    slot_show: { default: false },
    action_traitement: { default: true },

    calculemontantitem: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      isMobile: false,

      optionsDataTable: {
        groupBy: [],
        groupDesc: [],

        multiSort: false,
        mustSort: false,

        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 10,
      },
    };
  },
  watch: {
    optionsDataTable(options) {
      console.log(options);
      this.setitemsPerPage(options.itemsPerPage);
      this.setPage(options.page);

      this.get_date_http();
    },
    search(search) {
      this.get_date_http();
    },
  },

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    this.get_date_http();
  },
  computed: {
    title() {
      return this.$store.getters["show_data/g_title"]({
        controller_root: this.controller_root,
      });
    },

    notaffiche() {
      return this.$store.getters["show_data/g_not_affiche"]({
        controller_root: this.controller_root,
      });
    },

    search() {
      return this.$store.getters["show_data/g_search"]({
        controller_root: this.controller_root,
      });
    },
    page() {
      return this.$store.getters["show_data/g_page"]({
        controller_root: this.controller_root,
      });
    },
    itemsPerPage() {
      return this.$store.getters["show_data/g_itemsPerPage"]({
        controller_root: this.controller_root,
      });
    },
    TotalItemCount() {
      return this.$store.getters["show_data/g_TotalItemCount"]({
        controller_root: this.controller_root,
      });
    },

    items() {
      return this.$store.getters["show_data/g_items"]({
        controller_root: this.controller_root,
      });
    },

    items_show_style() {
      let items = this.items;

      items = this.show_style_not_string(items);

      return items;
    },

    selected() {
      return this.$store.getters["show_data/g_selected"]({
        controller_root: this.controller_root,
      });
    },
    item_key() {
      return this.$store.getters["show_data/g_item_key"]({
        controller_root: this.controller_root,
      });
    },
    expanded() {
      return this.$store.getters["show_data/g_expanded"]({
        controller_root: this.controller_root,
      });
    },
    namesexpanded() {
      let expanded = this.expanded;
      let namesexpanded = [];
      for (const key in expanded) {
        namesexpanded.push(expanded[key]["controller_attach"]);
      }
      return namesexpanded;
    },
    headers() {
      //************************************************************ */
      let name_input_files = [];
      /*    let shema_inputs = this.$store.getters["g_SCHEMA_GlobalHTML"][this.controller_root];
    

      if (shema_inputs != undefined) {
        for (const shema_input of shema_inputs["html"]) {
          if (shema_input.type == "file" || shema_input.type == "files" || shema_input.type == "logo") {
            name_input_files.push(shema_input.name);
          }
        }
      } */

      //*********************************************************************** */

      let headers = this.$store.getters["show_data/g_headers"]({
        controller_root: this.controller_root,
        namesexpanded: this.namesexpanded,
        notaffiche: this.notaffiche,
        name_input_files,
      });
      let textaction = "";
      if (this.isMobile) {
        textaction = "";
      } else {
        textaction = "Les Actions des traitement ";
      }

      if (this.action_traitement) {
        headers.push({
          text: textaction,
          value: "action",
          sortable: false,
          align: "end",
        });
      }

      return headers;
    },

    loading() {
      return this.$store.state["show_data"].loading;
    },
  },
  methods: {
    customsearch: util.customsearchTable,
    setitemsPerPage(val) {
      this.$store.commit(
        "show_data/set_itemsPerPage",

        { data: val, controller_root: this.controller_root }
      );
    },
    setPage(val) {
      let page = parseInt(val, 10);

      this.$store.commit("show_data/set_page", {
        data: page,
        controller_root: this.controller_root,
      });
    },

    show_style_not_string(items) {
      items.forEach((item, index) => {
        let newItem = {};
        for (const key in item) {
          const value = item[key];
          newItem[key] = value;

          if (typeof value == "boolean") {
            if (value) {
              newItem[key] = "X";
            } else {
              newItem[key] = ".";
            }
            items[index] = newItem;
          }

          if (typeof value == "object") {
            // return item;
          }
        }

        // newItems.push(newItem);
      });
      return items;
    },

    get_date_http() {
      setTimeout(() => {
        //  optionsDataTable: JSON.stringify(this.optionsDataTable),
        //    searchDataTable: JSON.stringify(this.search),
        //   AttributesTableHide: JSON.stringify(this.notaffiche),

        //     console.log(this.optionsDataTable);
        console.log(this.search);
        //    console.log(this.notaffiche);

        let itemsPerPage = this.itemsPerPage;
        if (itemsPerPage == -1) {
          itemsPerPage = this.TotalItemCount;
        }

        // dateTimeUpload=asc
        let op = {
          page: this.page,
          itemsPerPage: itemsPerPage,
        };

        const sortBys = this.optionsDataTable["sortBy"];
        for (let index = 0; index < sortBys.length; index++) {
          const sortBy = sortBys[index];
          const sortDesc = this.optionsDataTable["sortDesc"][index];

          if (sortDesc == true) {
            op["order[" + sortBy + "]"] = "desc";
          } else {
            op["order[" + sortBy + "]"] = "asc";
          }
        }

        const params = {
          [this.controller_root]: op,
        };

        this.$store.dispatch("run_show", {
          names_controllers: undefined,
          params,
        });
      }, 300);
    },

    calculemontant(items, pour) {
      let a = 0;
      for (let index = 0; index < items.length; index++) {
        const element = items[index];
        a = a + element[pour] * 1;
      }
      return a.toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },
    calculemontantSelected(items, pour, isSelected) {
      let a = 0;
      for (let index = 0; index < items.length; index++) {
        const element = items[index];
        if (isSelected(element)) {
          a = a + element[pour] * 1;
        }
      }
      return a.toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },

    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
     convertDate(inputFormat) {
      if (
        inputFormat == "" ||
        inputFormat == null ||
        inputFormat == undefined
      ) {
        return "";
      }
      function pad(s) {
        return s < 10 ? "0" + s : s;
      }
      var d = new Date(inputFormat);
      return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(
        "/"
      )+        " " +
        [pad(d.getHours()), pad(d.getMinutes())].join(":");
      
    },
    page_count(val) {
      this.$store.commit(
        "show_data/set_pageCount",

        {
          data: val,
          controller_root: this.controller_root,
        }
      );
    },

    clickrow(a, b) {},
    select(selected) {
      let item_key = this.item_key;

      let items = this.items;

      let keys = [];
      selected.forEach((select) => {
        keys.push(select[item_key]);
      });

      let selected_items = [];

      items.forEach((item) => {
        let id = item[item_key];
        if (keys.includes(id)) {
          selected_items.push(item);
        }
      });

      this.$store.commit(
        "show_data/set_selected",

        {
          // data: selected,
          data: selected_items,
          controller_root: this.controller_root,
        }
      );
    },

    isGranted(attribute) {
      return this.$store.getters["security/isGranted"](
        attribute,
        this.controller_root
      );
    },
  },
};
</script>
