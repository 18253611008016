<template>
  <card-card
    :close="close"
    @close="closeevent"
    icon="mdi-package-down"
    color="orange"
    :title="$t('listStock')"
    class="px-5 py-3"
  >
    <v-sheet
      class="v-sheet--offset mx-auto"
      color="cyan"
      elevation="12"
      max-width="calc(100% - 32px)"
    >
      <v-data-table
        dense
        :headers="titles"
        :items="dataSet"
        :show-expand="true"
        :single-expand="true"
        class="elevation-1"
        no-data-text
        item-key="titre"
      >
        <template v-slot:expanded-item="{ item }">
          <td :colspan="titles.length + 1">
            <v-data-table
              :headers="headers"
              :items="item['produitsStockalerts']"
              hide-default-footer
              dense
            >
              <template v-slot:item.stock="{ item }">
                <v-chip color="red" dark>{{ item.stock }}</v-chip>
              </template>
              <template v-slot:item.Stockcommande="{ item }">
                <v-chip color="orange" dark>{{ item.Stockcommande }}</v-chip>
              </template>
            </v-data-table>
          </td>
        </template>

        <!-- -->
      </v-data-table>
    </v-sheet>

    <v-card-text class="pt-0">
      <div class="title font-weight-light mb-2 red--text">
        Produits en alerte stock
      </div>
      <div class="subheading font-weight-light grey--text">
        Stock inférieur au stock d'alerte
      </div>
      <v-divider class="my-2"></v-divider>
      <v-icon class="mr-2" small>mdi-clock</v-icon>
      <span class="caption grey--text font-weight-light">{{
        new Date().toLocaleString()
      }}</span>
    </v-card-text>
  </card-card>
</template>
<script>
import i18n from "@/plugins/i18n";
import axios from "axios";

import widgetsMixin from "./../widgetsMixin";
export default {
  mixins: [widgetsMixin],
  data: () => ({
    headers: [
      { text: i18n.t("ref"), value: "ref" },
      { text: i18n.t("designation"), value: "designation" },
      { text: i18n.t("stock"), value: "stock" },
      { text: i18n.t("stockalerte"), value: "stockalerte" },
      { text: i18n.t("SVP commander "), value: "Stockcommande" },
    ],
    stockalerte: {},
  }),

  methods: {},
  computed: {
    Base_url_web() {
      return this.$store.getters["get_Base_url"];
    },
    JWTToken() {
      return this.$store.getters["get_JWTToken"];
    },
    titles() {
      return [{ text: i18n.t("titre"), value: "titre" }];
    },
    dataSet() {
      let dataSet = [];

      for (const key in this.stockalerte) {
        dataSet.push({
          titre: key,
          produitsStockalerts: this.stockalerte[key],
        });
      }

      return dataSet;
    },
  },

  mounted() {
    let path = "/stockalert/statistique";
    let JWTToken = this.JWTToken;

    axios
      .get(this.Base_url_web + path, {
        params: {},
        headers: {
          Authorization: JWTToken,
        },
      })
      .then((response) => {
        this.stockalerte = response.data;
      })
      .catch((error) => {
        console.error("path");
      });
  },
};
</script>
<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>