'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})
exports['default'] = void 0

var _axios = _interopRequireDefault(require('axios'))

var _router = _interopRequireDefault(require('@/router'))

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

//let colorconsole="color: red"
var colorconsole = 'color: green'
var colorconsoleyellow = 'color: magenta' //let colorconsole="color: blue"
//let colorconsole="color: magenta"
//let colorconsole="color: cyan"
//let colorconsole="color: gray"
//let colorconsole="color: grey"

var _default = {
  //ok
  ajax_initialize_struct_Multiselect: function ajax_initialize_struct_Multiselect(
    _ref,
  ) {
    var commit = _ref.commit,
      dispatch = _ref.dispatch,
      getters = _ref.getters,
      rootGetters = _ref.rootGetters,
      rootState = _ref.rootState,
      state = _ref.state
    console.groupCollapsed(
      '%caction => structure/%cajax_initialize_struct_Multiselect',
      colorconsole,
      colorconsoleyellow,
    )
    console.log('commit exec => set_headers ') //console.log("event data root event name + value", event)

    state['namescontroller_multiselect'].forEach(function (
      controller_multiselect,
    ) {
      var where_attach = state['where_attach'][controller_multiselect]
      var jointure = state['jointure'][controller_multiselect]
      var controller_root_attach =
        state['controller_root_attach'][controller_multiselect]
      var where_attach_get_value =
        state['where_attach_get_value'][controller_multiselect]
      var value_name

      if (where_attach_get_value == undefined) {
        value_name = where_attach
      } else {
        value_name = where_attach_get_value
      } // value root atache

      var value = rootGetters['structure/get_input_binds']({
        controller_root: controller_root_attach,
        name: value_name,
      })
      var urlPage = rootGetters['get_path'](controller_multiselect)

      _axios['default']
        .get(urlPage, {
          params: {
            where: where_attach,
            value: value,
            jointure: jointure,
            controller_root_attach: controller_root_attach,
          },
        })
        .then(function (response) {
          // let schema = response.data.schema;
          var titles = response.data['titles']
          var dataSet = response.data['dataSet']
          commit('set_headers', {
            controller_multiselect: controller_multiselect,
            data: titles,
          })
          commit('set_items', {
            controller_multiselect: controller_multiselect,
            data: dataSet,
          })
        })
        ['catch'](function (error) {
          console.error('error get info', error)
        })
    })
    console.log(state)
    console.groupEnd()
  },
  //ok
  ajax_custom_Multiselect_ByDataSave: function ajax_custom_Multiselect_ByDataSave(
    _ref2,
    _ref3,
  ) {
    var commit = _ref2.commit,
      dispatch = _ref2.dispatch,
      getters = _ref2.getters,
      rootGetters = _ref2.rootGetters,
      rootState = _ref2.rootState,
      state = _ref2.state
    var datasave = _ref3.datasave
    console.groupCollapsed(
      '%caction => structure/%cajax_custom_Multiselect_ByDataSave',
      colorconsole,
      colorconsoleyellow,
    )
    console.log('commit exec => set_jointure ')
    console.log('dispatch exec => ajax_initialize_struct_Multiselect')
    console.log('commit exec => set_selected')
    state.namescontroller_multiselect.forEach(function (
      controller_multiselect,
    ) {
      commit('set_jointure', {
        controller_multiselect: controller_multiselect,
        data: 'FULL',
      })
      commit('set_selected', {
        controller_multiselect: controller_multiselect,
        data: datasave[controller_multiselect].dataSet,
      })
    })
    dispatch('ajax_initialize_struct_Multiselect')
    console.log(state)
    console.groupEnd()
  },
  /*   remove({ commit, dispatch, getters, rootGetters, rootState, state }) {
      for (const controller_multiselect in state.headers) {
        commit("remove_all", { controller_multiselect: controller_multiselect });
      }
    }, */
}
exports['default'] = _default
