<template>
  <v-card-title>
    <v-row class="justify-center" >
     
        <v-col :cols="12" :lg="5" class="d-none d-lg-block">
          {{ $t("Total élements ") }} {{ TotalItemCount }}
          <div class="flex-grow-1"></div>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-col>

        <v-col :cols="12" :lg="5">
          <!--     vvvvvvvvvvvvvvvvvvvvv -->
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="pink"
                class="mx-2"
                outlined
                dark
                @click="alertshow()"
                v-show="
                  selected.length > 0 &&
                  isGranted('delete') &&
                  action_traitement
                "
              >
                <v-badge color="red">
                  <span slot="badge">{{ selected.length }}</span>
                  <v-icon>mdi-delete</v-icon>
                </v-badge>
              </v-btn>
            </template>
            <span>{{ $t("Supprimer") }}</span>
          </v-tooltip>

          <!--     vvvvvvvvvvvvvvvvvvvvv -->
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="primary"
                class="mx-2"
                outlined
                dark
                v-show="selected.length > 0"
                :to="{ name: 'pdf', params: { controller: controller_root } }"
              >
                <v-badge color="primary">
                  <span slot="badge">{{ selected.length }}</span>
                  <v-icon>mdi-download</v-icon>
                </v-badge>
              </v-btn>
            </template>
            <span>{{ $t("DownloadData") }}</span>
          </v-tooltip>

          <!--     vvvvvvvvvvvvvvvvvvvvv -->
          <v-tooltip top v-if="slot_show">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="primary"
                class="mx-2"
                outlined
                dark
                @click="form_show_dialog()"
                v-show="isGranted('add') && action_traitement"
              >
                <v-icon>mdi-playlist-plus</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("add") }}</span>
          </v-tooltip>
          <!--     vvvvvvvvvvvvvvvvvvvvv -->
          <v-tooltip top v-else>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                v-show="isGranted('add') && action_traitement"
                color="primary"
                class="mx-2"
                outlined
                dark
                :to="{ name: controller_root + '_add' }"
              >
                <v-icon>mdi-playlist-plus</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("add") }}</span>
          </v-tooltip>

          <!--     vvvvvvvvvvvvvvvvvvvvv -->
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="primary"
                class="mx-2"
                outlined
                dark
                v-show="importexcel && isadmin"
                :to="{
                  name: 'importdata',
                  params: { controller: controller_root },
                }"
              >
                <v-icon>mdi-table-large-plus</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("importdata") }}</span>
          </v-tooltip>
          <!--     vvvvvvvvvvvvvvvvvvvvv -->
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="orange"
                class="mx-2"
                outlined
                dark
                v-show="fusionner && isadmin && selected.length > 1"
                @click="alertmerge()"
              >
                <v-icon>mdi-merge</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("mergeData") }}</span>
          </v-tooltip>
        </v-col>
     
         <v-col :cols="12" :lg="8">
        <div class="flex-grow-1"></div>

        <!--     vvvvvvvvvvvvvvvvvvvvv -->
       


          <table-find
            :controller_root="controller_root"
       
           
          ></table-find>


      </v-col>
    
        <v-col :cols="12" :lg="12">
          <v-snackbar
            v-model="alert"
            centered
            vertical
            color="error"
            :top="true"
          >
            <v-icon>mdi-home-alert</v-icon
            >{{ $t("Êtes-vous sûr de vouloir supprimer des lignes") }}
            <br />
            <strong>{{ text }}</strong>
            <div style="display-2">
              <v-btn dark text @click="alert = false">{{ $t("Fermer") }}</v-btn>
              <v-btn dark text @click="delete_group()">{{ $t("oui") }}</v-btn>
            </div>
          </v-snackbar>

          <div class="text-center">
            <v-dialog v-model="alertDialogMerge">
              <v-toolbar dark color="primary">
                <v-toolbar-title>{{ $t("merge_title") }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn icon dark @click="alertmergeclose()">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>

              <component
                v-if="alertDialogMerge"
                :is="'steppers-merge'"
                :controller_root="controller_root"
              ></component>
            </v-dialog>
          </div>
        </v-col>
      </v-row>

  </v-card-title>
</template>


<script>
import { mapGetters } from "vuex";

export default {
  props: {
    title: {},
    controller_root: {},
    action_traitement: { default: true },
    slot_show: {},
    
    // pdf:{ default: false },
  },
  data() {
    return {
      dialog: false,
      alertDialogMerge: false,
      alert: false,
      delete: false,
      text: "",
    };
  },
  methods: {
    form_show_dialog() {
      this.$store.dispatch("initialize_struct_from", {
        names_controllers: [this.controller_root],
      });

      this.$store.commit("dialog/set_dialog", {
        data: [],
        flag: true,
        type: "add",
        controller: this.controller_root,
      });
    },

    delete_group() {
      this.alert = false;
      this.$store.dispatch("send/delete_group", this.controller_root);
    },
    alertshow() {
      this.alert = true;
      this.text = " ";
      this.selected.forEach((element) => {
        this.text = this.text + "    ,     " + element["id"];
      });
    },
    alertmerge() {
      this.alertDialogMerge = true;
    },
    alertmergeclose() {
      this.alertDialogMerge = false;
      this.$store.commit("show_data/set_selected", {
        data: [],
        controller_root: this.controller_root,
      });
    },
    isGranted(attribute) {
      return this.$store.getters["security/isGranted"](
        attribute,
        this.controller_root
      );
    },
  },

  computed: {
    TotalItemCount() {
      return this.$store.getters["show_data/g_TotalItemCount"]({
        controller_root: this.controller_root,
      });
    },
    importexcel() {
      
      return this.$store.getters["get_importexcel"](this.controller_root);
    },
    fusionner() {
      return this.$store.getters["get_fusionner"](this.controller_root);
    },
    isadmin() {
      return this.$store.getters["security/isAdmin"];
    },

    selected() {
      return this.$store.getters["show_data/g_selected"]({
        controller_root: this.controller_root,
      });
    },
    items() {
      return this.$store.getters["show_data/g_items"]({
        controller_root: this.controller_root,
      });
    },
  },
  watch: {
    selected(val) {
      if (val.length == 0) {
        this.alertDialogMerge = false;
      }
    },
  },
};
</script>

