<template>
  <div>
    <v-row>
      <v-col :cols="12">
        <v-btn elevation="3"  @click="Open_dialog"
          >
            <v-icon dark> mdi-file-find </v-icon></v-btn
        >
          {{ $t("recherche") + "  :" + search }}
    
      </v-col>
     
    </v-row>

    <v-row>
      <v-col
        :cols="12"
        :lg="4"
        v-for="(inputname, index) in selects"
        :key="index"
      >
        <table-selectbyfilde
          :label="$t(inputname)"
          @change="modelselectfild"
          :name="inputname"
          :value="filds_find[inputname]"
          :controller_root="controller_root"
      /></v-col>
    </v-row>

    <!-- dilaog -->

    <v-row justify="center">
      <v-dialog v-model="dialog" scrollable max-width="350px">
        <v-card>
          <v-card-title> {{ $t("recherche par ") }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 300px">
            <v-checkbox
              v-for="(fild, indexA) in list_fild"
              :key="indexA"
              v-model="selects"
              :label="$t(fild)"
              :value="fild"
            ></v-checkbox>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="Close_dialog">
              {{ $t("Close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    controller_root: {},
  },
  computed: {
    title() {
      return this.$store.getters["show_data/g_title"]({
        controller_root: this.controller_root,
      });
    },

    schemas() {
      return this.$store.getters["show_data/g_schemas"]({
        controller_root: this.controller_root,
      });
    },
    notaffiche() {
      return this.$store.getters["show_data/g_not_affiche"]({
        controller_root: this.controller_root,
      });
    },

    list_fild() {
      let list_fild = [];

      let notaffiche = this.notaffiche;

      this.schemas.forEach((element) => {
        if (
          notaffiche.indexOf(element["name"]) == -1 &&
          element["name"] != "mastercheff" &&
          element["typeHtml"] != "file" &&
          element["typeHtml"] != "files" &&
          element["typeHtml"] != "logo"
        ) {
          list_fild.push(element["name"]);
        }
      });

      return list_fild;
    },
    search: {
      get() {
        return this.$store.getters["show_data/g_search"]({
          controller_root: this.controller_root,
        });
      },
      set(val) {
        //  console.log(val);

        this.$store.commit("show_data/set_search", {
          controller_root: this.controller_root,
          data: val,
        });
      },
    },
  },

  data() {
    return {
      filds_find: {},

      search_: "",

      dialog: false,
      selects: [],

      items_: [],
    };
  },

  methods: {
    clear() {
      this.$store.commit("show_data/set_search", {
        controller_root: this.controller_root,
        data: "",
      });
    },
    modelselectfild({ value, from }) {
      console.log(value, from);
      this.filds_find[from] = value;
      this.find_by();
    },

    find_by() {
      let find_val = "";

      for (const key in this.filds_find) {
        const findtext = this.filds_find[key];
        if (findtext === null || findtext === "null" || findtext === "") {
        } else {
          if (key == "_betwen") {
            find_val = find_val + "&" + findtext;
          } else {

            //api/product?name=awesome&strategy[name]=exact


            const find = key + "=" + findtext;

            find_val = find_val + "&" + find;
          }
        }
      }

      this.$store.commit("show_data/set_search", {
        controller_root: this.controller_root,
        data: find_val,
      });
    },
    Close_dialog() {
      this.dialog = false;
      //this.clear_filde_find();
    },
    Open_dialog() {
      this.dialog = true;
      // this.clear_filde_find();
    },
    clear_filde_find() {
      for (const key in this.filds_find) {
        this.filds_find[key] = null;
      }

      this.$store.commit("show_data/set_search", {
        controller_root: this.controller_root,
        data: "",
      });
    },
  },
  watch: {
    selects(list_key) {
      console.log(list_key);
      for (const key in this.filds_find) {
        if (!list_key.includes(key)) {
          this.filds_find[key] = null;
          console.log(key);
        }
      }

      this.find_by();
    },
    notaffiche() {
      this.selects = [];
      this.filds_find = {};

      this.search_ = "";
    },
  },
};
</script>
