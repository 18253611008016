import i18n from '@/plugins/i18n'
export default {
  g_nametables: (state) => () => {
    return state.nametables
  },
  g_title: (state) => ({ controller_root }) => {
    return state.titles[controller_root]
  },
  g_schemas: (state) => ({ controller_root }) => {
    return state.schemas[controller_root]
  },
  g_not_affiche: (state) => ({ controller_root }) => {
    return state.not_affiches[controller_root]

    //  return state.not_affiches[controller_root];
  },

  g_obligatoir: (state) => ({ controller_root }) => {
    let schema = state.schemas[controller_root]
    let item_obligatoir = []
    schema.forEach((item) => {
      if (
        !item['nullable'] &&
        item['typeColumn'] != 'ManyToMany' &&
        item['typeColumn'] != 'OneToMany' &&
        item['name'] != 'id'
      ) {
        item_obligatoir.push(item['name'])
      }
    })
    return item_obligatoir

    //  return state.not_affiches[controller_root];
  },

  g_expanded: (state) => ({ controller_root }) => {
    return state.expandeds[controller_root]
  },
  /////////////////////////////
  g_search: (state) => ({ controller_root }) => {
    return state.search[controller_root]
  },

  g_headers: (state) => ({
    controller_root,
    namesexpanded,
    notaffiche,
    name_input_files,
    obligatoir,
  }) => {
    let headers = []
    let item_obligatoir = []
    if (obligatoir != undefined) {
      item_obligatoir = obligatoir
    }

    let titles = state.headers[controller_root]

    for (const key in titles) {
      const obj_title = titles[key]

      if (
        (notaffiche.indexOf(obj_title.value) == -1 &&
          namesexpanded.indexOf(obj_title.value) == -1 &&
          name_input_files.indexOf(obj_title.value) == -1) ||
        item_obligatoir.indexOf(obj_title.value) > -1
      ) {
        /// is not array namesexpanded
        if (obj_title.value != undefined) {
          obj_title.text = i18n.t(obj_title.value)
          headers.push(obj_title)
        }
      }
    }

    return headers
  },

  g_items: (state) => ({ controller_root }) => {
    let data = state.items[controller_root]

    return data
  },
  g_options: (state) => ({ controller_root }) => {
    let data = state.options[controller_root]

    return data
  },

  g_CurrentPageNumber: (state) => ({ controller_root }) => {
    let data = state.CurrentPageNumber[controller_root]

    return data
  },

  g_ItemNumberPerPage: (state) => ({ controller_root }) => {
    let data = state.ItemNumberPerPage[controller_root]

    return data
  },

  g_TotalItemCount: (state) => ({ controller_root }) => {
    let data = state.TotalItemCount[controller_root]

    return data
  },

  g_selected: (state) => ({ controller_root }) => {
    return state.selected[controller_root]
  },
  g_item_key: (state) => ({ controller_root }) => {
    return state.item_key[controller_root]
  },
  g_page: (state) => ({ controller_root }) => {
    return state.page[controller_root]
  },
  g_pageCount: (state) => ({ controller_root }) => {
    return state.pageCount[controller_root]
  },
  g_itemsPerPage: (state) => ({ controller_root }) => {
    return state.itemsPerPage[controller_root]
  },
}
