import api_config from '@/#_CONFIG/Entity'

export default {
  get_schemas: (state) => ({ controller_root }) => {
    // return state.inputes_schemas[controller_root];

    return api_config[controller_root].schema
  },

  get_schema: (state) => ({ controller_root, name }) => {
    let schema = {}
    api_config[controller_root].schema.forEach((element) => {
      if (element['name'] == name) {
        schema = element
        return
      }
    })
    return schema
  },
  get_data_Select_load: (state) => ({ controller_root, nameManyToOne }) => {
    return state.data_Select_load[controller_root][nameManyToOne]
  },
  get_inputes_binds: (state) => ({ controller_root }) => {
    return state.inputes_binds[controller_root]
  },
  get_input_binds: (state) => ({ controller_root, name }) => {
    return state.inputes_binds[controller_root][name]
  },

  get_actionTraiter: (state) => () => {
    return state.actionTraiter
  },

  ////////////////////////////////////////////////////////////////////

  //????????????????????
  get_nameinput_root: (state) => ({ controller_root }) => {
    return state.nameinput_root[controller_root]
  },

  get_NameAndValue_input_root: (state) => ({ controller_root }) => {
    const name = state.nameinput_root[controller_root]
    const value = state.inputes_binds[controller_root][name]

    return { name, value }
  },
}
