import router from '@/router'
import axios from 'axios'
import api_config from '@/#_CONFIG/Entity'

const param = {
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
  },
  onUploadProgress: function (progressEvent) {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    )
    //console.log("onUploadProgress => " + percentCompleted);
  },
}

function normalisation(controller_root, input_name, value) {
  let api_config_ = api_config[controller_root]
  let schemainputs = api_config_.schema
  let v = value
  schemainputs.forEach((schema) => {
    if (schema.name == input_name) {
      if (schema.typeHtml == 'checkbox') {
        if (
          value == '' ||
          value == null ||
          value == false ||
          value == undefined
        ) {
          v = false
        } else {
          v = true
        }
      }
    }
  })
  return v
}

function data_binds(controller_root, rootGetters) {
  // const data_binds = rootGetters["structure/get_inputes_binds"]({
  //   controller_root,
  // });
  // return data_binds;

  let data = {}
  const data_binds = rootGetters['structure/get_inputes_binds']({
    controller_root,
  })

  for (const key in data_binds) {
    if (Object.hasOwnProperty.call(data_binds, key)) {
      const element = data_binds[key]
      if (typeof element != 'object') {
        data[key] = normalisation(controller_root, key, element)
      }
    }
  }

  return data
}

function data_binds_listsAdd(controller_root, rootGetters, data_binds) {
  const namescontroller_listsAdd = rootGetters[
    'structure/addlist/namescontroller_listsAdd'
  ]({ controller_root })
  namescontroller_listsAdd.forEach((controller_listsAdd) => {
    const value_addlist = rootGetters['structure/addlist/inputes_binds']({
      controller_listsAdd,
    })
    const data_compresser = value_addlist
    data_binds[controller_listsAdd] = data_compresser
  })
  return data_binds
}
function data_binds_multiselect(controller_root, rootGetters, data_binds) {
  const namescontroller_multiselect = rootGetters[
    'structure/multiselect/namescontroller_multiselect'
  ]({ controller_root })
  namescontroller_multiselect.forEach((controller_multiselect) => {
    const value_multiselect = rootGetters[
      'structure/multiselect/inputes_binds'
    ]({ controller_multiselect })
    const data_compresser = JSON.stringify(value_multiselect)
    data_binds[controller_multiselect] = data_compresser
  })
  return data_binds
}

export default {
  /**
   *
   * 3tini data data binds o nsiftha machrlich fik
   *
   *
   */
  send_data(
    { commit, dispatch, getters, rootGetters, rootState, state },
    { controller_root },
  ) {
    let data = data_binds(controller_root, rootGetters)
    data = data_binds_listsAdd(controller_root, rootGetters, data)
    data = data_binds_multiselect(controller_root, rootGetters, data)

    let urlPage = ''

    if (!(data['id'] * 1 > 0)) {
      delete data.id
      const formdata = JSON.stringify(data)
      urlPage = rootGetters['get_add'](controller_root)
      commit(
        'flash/set_message',
        "C'est bon!   Un élément a été  enregistrée",
        { root: true },
      )
      commit('flash/set_color', 'success', { root: true })

      let Base_url = rootState.Base_url;
      let JWTToken = rootState.JWTToken
      param['headers']['Authorization']=JWTToken
      axios
        .post(Base_url+urlPage, formdata, param)
        .then((response) => {
          commit('dialog/set_dialog_btn_send', { flag: false }, { root: true })

          commit('flash/set_show', true, { root: true })

          dispatch(
            'run_show',
            { names_controllers: [controller_root] },
            { root: true },
          )

          dispatch(
            'initialize_struct_from',
            { names_controllers: [controller_root] },
            { root: true },
          )

          //   dispatch("theme/count_notification", {}, { root: true });

          let id = response.data['id']
          router.push({
            name: controller_root + '_show_item',
            params: { id: id },
          })

          if ('restaurant' == controller_root) {
            window.location.href = '/'
          }
        })
        .catch((error) => {
          
          commit('dialog/set_dialog_btn_send', { flag: false }, { root: true })
          
          commit(
            'flash/set_message',
            "accès refusé  ,vérifier relation d'enregistrement message erreur /n" + error.response
          
            .data['hydra:description']
            ,
            { root: true },
          )
          commit('flash/set_color', 'error', { root: true })
          commit('flash/set_show', true, { root: true })






        })
    } else {
      const formdata = JSON.stringify(data)
      urlPage = rootGetters['get_update'](controller_root)
      commit('flash/set_message', "C'est bon!   Un élément a été modifié", {
        root: true,
      })
      commit('flash/set_color', 'info', { root: true })
      let _id = data['id']

      let Base_url = rootState.Base_url;
      let JWTToken = rootState.JWTToken
      param['headers']['Authorization']=JWTToken

      axios
        .put(Base_url+urlPage + '/' + _id, formdata, param)
        .then((response) => {
          commit('dialog/set_dialog_btn_send', { flag: false }, { root: true })

          commit('flash/set_show', true, { root: true })

          dispatch(
            'run_show',
            { names_controllers: [controller_root] },
            { root: true },
          )
          dispatch(
            'initialize_struct_from',
            { names_controllers: [controller_root] },
            { root: true },
          )
          // dispatch("theme/count_notification", {}, { root: true });

          let id = response.data['id']
          router.push({
            name: controller_root + '_show_item',
            params: { id: id },
          })

          if ('restaurant' == controller_root) {
            window.location.href = '/'
          }
        })
        .catch((error) => {
          commit('dialog/set_dialog_btn_send', { flag: false }, { root: true })
          commit(
            'flash/set_message',
            "accès refusé  ,vérifier relation d'enregistrement message erreur /n" + error.response
          
            .data['hydra:description']
            ,
            { root: true },
          )
          commit('flash/set_color', 'error', { root: true })
          commit('flash/set_show', true, { root: true })
          // router.push('/login')
        })
    }
  },

  /**
   *
   * 3tini data data binds o nsiftha machrlich fik
   *
   *
   */
  send_data_ajax(
    { commit, dispatch, getters, rootGetters, rootState, state },
    { controller_root, controller_main },
  ) {
    let data = data_binds(controller_root, rootGetters)
    data = data_binds_listsAdd(controller_root, rootGetters, data)
    data = data_binds_multiselect(controller_root, rootGetters, data)
    let urlPage = ''
    delete data.id
    const formdata = JSON.stringify(data)
    urlPage = rootGetters['get_add'](controller_root)

    if (!(data['id'] * 1 > 0)) {
      urlPage = rootGetters['get_add'](controller_root)
      commit(
        'flash/set_message',
        "C'est bon!   Un élément a été  enregistrée",
        { root: true },
      )
      commit('flash/set_color', 'success', { root: true })
    } else {
      urlPage = rootGetters['get_update'](controller_root)
      commit('flash/set_message', "C'est bon!   Un élément a été modifié", {
        root: true,
      })
      commit('flash/set_color', 'info', { root: true })
    }

    let Base_url = rootState.Base_url;
    let JWTToken = rootState.JWTToken
    param['headers']['Authorization']=JWTToken


    axios
      .post(Base_url+urlPage, formdata, param)
      .then((response) => {
        commit('dialog/set_dialog_btn_send', { flag: false }, { root: true })

        commit('flash/set_show', true, { root: true })

        dispatch('structure/ajax_charge_all_DataSelect', {}, { root: true })
        dispatch(
          'structure/addlist/ajax_charge_all_DataSelect',
          {},
          { root: true },
        )
        let id = response.data['@id']

        commit(
          'structure/set_input_binds',
          {
            name: controller_root,
            value: id,
            controller_root: controller_main,
          },
          { root: true },
        )

        if ('restaurant' == controller_root) {
          window.location.href = '/'
        }
      })
      .catch((error) => {

        commit(
          'flash/set_message',
          "accès refusé  ,vérifier relation d'enregistrement message erreur /n" + error.response
        
          .data['hydra:description']
          ,
          { root: true },
        )
        commit('flash/set_color', 'error', { root: true })
        commit('flash/set_show', true, { root: true })
      })
  },

  /**
   *
   * 3tini data data binds o nsiftha machrlich fik
   *
   *
   */
  send_refGroup_files(
    { commit, dispatch, getters, rootGetters, rootState, state },
    { controller_root, controller_id, name, value },
  ) {
    let data = {
      id: controller_id,
    }
    data[name] = value

    const formdata = JSON.stringify(data)
    let urlPage = ''

    if (data['id'] == '') {
      urlPage = rootGetters['get_add'](controller_root)
      commit(
        'flash/set_message',
        "C'est bon!   Un élément a été  enregistrée",
        { root: true },
      )
      commit('flash/set_color', 'success', { root: true })

      let Base_url = rootState.Base_url;
      let JWTToken = rootState.JWTToken
      param['headers']['Authorization']=JWTToken

      axios
        .post(Base_url+urlPage, formdata, param)
        .then((response) => {
          commit('dialog/set_dialog_btn_send', { flag: false }, { root: true })

          commit('flash/set_show', true, { root: true })

          // dispatch("theme/count_notification", {}, { root: true });
        })
        .catch((error) => {
          commit('dialog/set_dialog_btn_send', { flag: false }, { root: true })
          commit(
            'flash/set_message',
            "accès refusé  ,vérifier relation d'enregistrement message erreur /n" + error.response
          
            .data['hydra:description']
            ,
            { root: true },
          )
          commit('flash/set_color', 'error', { root: true })
          commit('flash/set_show', true, { root: true })
        })
    } else {
      urlPage = rootGetters['get_update'](controller_root)
      commit('flash/set_message', "C'est bon!   Un élément a été modifié", {
        root: true,
      })
      commit('flash/set_color', 'info', { root: true })
      let Base_url = rootState.Base_url;
      let JWTToken = rootState.JWTToken
      param['headers']['Authorization']=JWTToken

      axios
        .put(Base_url+urlPage + '/' + controller_id, formdata, param)
        .then((response) => {
          commit('dialog/set_dialog_btn_send', { flag: false }, { root: true })

          commit('flash/set_show', true, { root: true })

          //dispatch("theme/count_notification", {}, { root: true });
        })
        .catch((error) => {
          commit('dialog/set_dialog_btn_send', { flag: false }, { root: true })
          commit(
            'flash/set_message',
            "accès refusé  ,vérifier relation d'enregistrement message erreur /n" + error.response
          
            .data['hydra:description']
            ,
            { root: true },
          )
          commit('flash/set_color', 'error', { root: true })
          commit('flash/set_show', true, { root: true })
        })
    }
  },

  /**
   * 3tini item nakhod id o nsift machrlich fik
   *
   *
   */

  delete_data(
    { commit, dispatch, getters, rootGetters, rootState, state },
    { controller_root, item },
  ) {
    //************************************************************ */
    const urlPage = rootGetters['get_delete'](controller_root)
    const id = item.id

    let Base_url = rootState.Base_url;
    let JWTToken = rootState.JWTToken
   

    axios
      .delete(Base_url+urlPage + '/' + id, { params: {
       

      }, headers:{
        Authorization: JWTToken
      } })
      .then(function (response) {
        commit('flash/set_message', "C'est bon!   Un élément a été supprimé", {
          root: true,
        })
        commit('flash/set_color', 'warning', { root: true })
        commit('flash/set_show', true, { root: true })

        dispatch(
          'run_show',
          { names_controllers: [controller_root] },
          { root: true },
        )
        // dispatch("theme/count_notification", {}, { root: true });
      })
      .catch(function (error) {
        commit(
          'flash/set_message',
          "accès refusé  ,vérifier relation d'enregistrement avant supreme",
          { root: true },
        )
        commit('flash/set_color', 'error', { root: true })
        commit('flash/set_show', true, { root: true })

        // router.push('/login')
      })

    commit(
      'show_data/set_selected',
      { data: [], controller_root },
      { root: true },
    )
  },

  /**
   * nchad hdchi li salictitih {selected} ou najbad id o dispatch
   *
   *
   */

  delete_group(
    { commit, dispatch, getters, rootGetters, rootState, state },
    controller_root,
  ) {
    const selected = rootGetters['show_data/g_selected']({ controller_root })

    for (const key in selected) {
      const item = selected[key]
      dispatch('delete_data', { controller_root, item })
    }
  },
}
