import api_config from '@/#_CONFIG/Entity'

export default {
  set_init(state, param_tables) {
    let namescontrollers = []
    let namesmodules = {}
    let nameinput_root = {}

    let inputes_schemas = {}
    let data_Select_load = {}
    let inputes_binds = {}

    let id = {}

    for (const index in param_tables) {
      const controller_root = param_tables[index]['controller_root']

      namescontrollers.push(controller_root)
      namesmodules[controller_root] = param_tables[index]['namemodule']
      nameinput_root[controller_root] = param_tables[index]['input_root']

      id[controller_root] = param_tables[index]['id'] || undefined // si update or show item

      inputes_schemas[controller_root] = [] ///  // by file config
      data_Select_load[controller_root] = {} /// par ajax
      inputes_binds[controller_root] = [] ///  // by file config

      // by file config

      let schemas = api_config[controller_root].schema
      let inputes_binds_ = {}
      for (const key in schemas) {
        const schema = schemas[key]
        inputes_binds_[schema['name']] = schema['defaultValue']
      }

      inputes_schemas[controller_root] = schemas
      inputes_binds[controller_root] = inputes_binds_
    }

    state['namescontrollers'] = namescontrollers
    state['namesmodules'] = namesmodules
    state['nameinput_root'] = nameinput_root

    state['inputes_schemas'] = inputes_schemas
    state['data_Select_load'] = data_Select_load

    state['inputes_binds'] = inputes_binds
    state['id'] = id
  },
  set_schemas(state, { schemas, controller_root }) {
    let inputes_binds = {}
    for (const key in schemas) {
      const schema = schemas[key]
      inputes_binds[schema['name']] = schema['defaultValue']
    }

    state['inputes_binds'][controller_root] = inputes_binds
  },

  set_data_Select_load(state, { controller_root, dataselect_load }) {
    state['data_Select_load'][controller_root] = dataselect_load
  },

  //////////////////////inputes_binds//////////////////////////////

  set_inputes_binds(state, { controller_root, datasave }) {
    state['inputes_binds'][controller_root] = datasave
  },

  set_input_binds(state, { controller_root, name, value }) {
    state['inputes_binds'][controller_root][name] = value
  },

  clear_input_binds(state, { controller_root }) {
    let binds = state['inputes_binds'][controller_root]
    for (const key in binds) {
      if (binds.hasOwnProperty(key)) {
        binds[key] = ''
      }
    }
  },

  //////////////////////inputes_binds//////////////////////////////

  set_actionTraiter(state, { actionTraiter }) {
    state['actionTraiter'] = actionTraiter
  },
}
