<template>
  <v-col :cols="grid" v-show="typeHtml != 'hidden'">
    <component
      :is="typeVuetify"
      :controller_root="controller_root"
      :schema="schema"
    ></component>
  </v-col>
</template>
<script>
export default {
  props: {
    controller_root: String,
    typeHtml: String,
    schema: Object,
    grade: String,
  },

  computed: {
    typeVuetify() {
      switch (this.typeHtml) {
        case "Textarea":
          return "show-item-textarea";
          break;
        case "select":
          return "show-item-select";
          break;
        case "selectajax":
          return "show-item-select";
          break;

        case "file":
          return "show-item-file";
          break;
        case "files":
          return "show-item-files";
          break;
        case "logo":
          return "show-item-logo";
          break;

        case "groupcheckbox":
          return "show-item-groupcheckbox";
          break;

        case "checkbox":
          return "show-item-checkbox";
          break;
        case "radio":
          return "show-item-radio";
          break;
        case "decimal":
          return "show-item-decimal";
          break;
        case "number":
          return "show-item-number";
          break;
        case "money":
          return "show-item-money";
          break;
        case "hidden":
          return "show-item-hidden";
          break;
        case "date":
          return "show-item-date";
          break;
        case "tel":
          return "show-item-tel";
          break;
        case "fax":
          return "show-item-fax";
          break;
        case "email":
          return "show-item-email";
          break;

        case "url":
          return "show-item-url";
          break;

        case "addlist":
          return "show-item-empty";
          break;
        case "multiselect":
          return "show-item-empty";
          break;
        case "color":
          return "show-item-color";
          break;
        case "rating":
          return "show-item-rating";
          break;

        default:
          return "show-item-text";
          break;
      }
    },
    grid() {
      if (this.grade == "master") {
        return "8 ";
      }
      if (this.grade == "slave") {
        return "4 ";
      }
      return "12";
    },
  },
};
</script>
