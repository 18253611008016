<template>
  <td v-if="rows" :colspan="numbretd">
    <div v-for="(row, indexrows) in rows" :key="indexrows">
      <v-data-table
        :headers="headers[indexrows]"
        :items="dataSet[indexrows]"
        :items-per-page="items_per_page[indexrows]"
        hide-default-footer
        dense
      >
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr>
              <th
                :colspan="headers.length"
                class="blue lighten-1 center text-center black--text title"
              >{{ $t(titles[indexrows])    }}</th>
            </tr>
          </thead>
        </template>
      </v-data-table>
    </div>
  </td>
  <td v-else :colspan="numbretd">..........</td>
</template>


<script>
import { mapGetters } from "vuex";
export default {
  props: {
    nametableroot: {},
    item: {},
    numbretd: {}
  },
  computed: {
    headers() {
      let Headers = [];
     
        const name_expanded = this.vuex_expanded["controller_expanded"];
        let notaffiche = this.vuex_expanded["not_affiche"];

        let titles = this.item[name_expanded].titles;

        let headers = [];

        for (const key in titles) {
          const obj_title = titles[key];
           var regex = /FOREIGN_KEY_/ig;
           var found = obj_title.value.match(regex);

          if (notaffiche.indexOf(obj_title.value) == -1  && found==null) {
            obj_title.text = obj_title.value
              .replace(new RegExp("[$]", "g"), " ")
              .toLocaleUpperCase();

            headers.push(obj_title);
          }
        }

        Headers.push(headers);
      
      return Headers;
    },
    dataSet() {
      let dataSet = [];
     
        const name_expanded = this.vuex_expanded["controller_expanded"];

        dataSet.push(this.item[name_expanded].dataSet);
      
      return dataSet;
    },
    items_per_page() {
      let items_per_page = [];
     
        const name_expanded = this.vuex_expanded["controller_expanded"];

        items_per_page.push(this.item[name_expanded].dataSet.length);
      
      return items_per_page;
    },
    rows() {
      let rows = [];
     
        const name_expanded = this.vuex_expanded["controller_expanded"];

        rows.push(this.item[name_expanded]);
      
      return rows;
    },
    titles() {
      let titles = [];
     
        titles.push(this.vuex_expanded["title"]);
     
      return titles;
    },
    vuex_expanded() {
      return this.$store.getters["structure/multiselect/g_expanded"]({
        controller_multiselect: this.nametableroot
      });
    }
  }
};
</script>

