module.exports = {
  id: { typeHtml: "hidden" },
  categorie: { typeHtml: "text", unique: true, nullable: false },
  note: { typeHtml: "text" },
  logo: { typeHtml: "logo" },
  updatedAt: { typeHtml: "hidden" },
  createdAt: { typeHtml: "hidden" },

  user: { typeHtml: "hidden" , to_string: "identifiant"},
};
