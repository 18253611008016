import router from '@/router'
import axios from 'axios'
import api_config from '@/#_CONFIG/Entity'

function chargeDataSelect(
  commit,
  getters,
  rootGetters,
  controller_listsAdd,
  schemas,
  fin_exc,
) {
  let Base_url = rootGetters['get_Base_url']
  let JWTToken = rootGetters['get_JWTToken']

  /// charge ManyToOne select
  let namesManyToOne = []
  let URLs = []

  let field_attach = getters['field_attach_to'][controller_listsAdd]
  let filter = getters['filter'][controller_listsAdd]

  for (const key in schemas) {
    const schema = schemas[key]

    if (schema['typeHtml'] == 'select' || schema['typeHtml'] == 'selectajax') {
      const nameInput = schema['from']

      namesManyToOne.push(nameInput)
      let url

      if (field_attach[nameInput] != undefined) {
        let key = field_attach[nameInput].controller_attach // ex "produits_prixachats",
        let where_attach = field_attach[nameInput].where_attach // ex "fournisseur"
        let controller_root_attach =
          field_attach[nameInput].controller_root_attach //ex  "commande"

        if (Array.isArray(where_attach)) {
          let where_params = {}

          where_attach.forEach((where) => {
            where_params[where] = rootGetters['structure/get_input_binds']({
              controller_root: controller_root_attach,
              name: where,
            })
          })

          url = axios.get(Base_url + rootGetters['get_attach'](key), {
            params: Object.assign({
              where_params,

              where: 'array',
            },filter),
            headers: { Authorization: JWTToken },
          })
        } else {
          /// where_attach is string
          // value root atacha
          let value = rootGetters['structure/get_input_binds']({
            controller_root: controller_root_attach,
            name: where_attach,
          })

          url = axios.get(Base_url + rootGetters['get_attach'](key), {
            params: Object.assign({ where: where_attach, value: value },filter),
            headers: { Authorization: JWTToken },
          })
        }
      } else {
        url = axios.get(Base_url + rootGetters['get_dataselect'](nameInput), {
          headers: {
            Accept: 'application/ld+json',
            Authorization: JWTToken,
          },
          params: Object.assign({ itemsPerPage: 1000 },filter),
        })
      }

      URLs.push(url)
    }
  }
  axios
    .all(URLs)
    .then(function (responses) {
      let data_Select_load = {}
      for (let index = 0; index < namesManyToOne.length; index++) {
        const nameManyToOne = namesManyToOne[index]
        data_Select_load[nameManyToOne] =
          responses[index]['data']['hydra:member']
      }

      commit('set_data_Select_load', { controller_listsAdd, data_Select_load })
    })

    .finally(fin_exc)
}

export default {
  //ok
  ajax_initialize_struct_ListAdd({
    commit,
    dispatch,
    getters,
    rootGetters,
    rootState,
    state,
  }) {
    state.namescontroller_listsAdd.forEach((controller_listsAdd) => {
      ///////////////////////////////////////////////////////////

      let controller_listsLots = getters['controller_listsLots'](
        controller_listsAdd,
      )

      if (controller_listsLots != undefined && controller_listsLots != '') {
        commit('lotproduits/set_init', {
          controller_listsLots,
        })

        dispatch('lotproduits/ajax_initialize_struct_ListLot', {
          controller_listsLots,
        })
      }

      ////////////////////////////////////////////

      let api_config_ = api_config[controller_listsAdd]
      let schema = api_config_.schema
      let titles = api_config_.titles

      commit('set_schema', { controller_listsAdd, schema })
      commit('set_titles', { controller_listsAdd, titles })
      chargeDataSelect(
        commit,
        getters,
        rootGetters,
        controller_listsAdd,
        schema,
        () => {},
      )
    })
  },

  ajax_charge_all_DataSelect(
    { commit, dispatch, getters, rootGetters, rootState, state },
    {},
  ) {
    state.namescontroller_listsAdd.forEach((controller_listsAdd) => {
      let api_config_ = api_config[controller_listsAdd]
      let schema = api_config_.schema

      chargeDataSelect(
        commit,
        getters,
        rootGetters,
        controller_listsAdd,
        schema,
        () => {},
      )
    })
  },

  //ok
  ajax_custom_ListAdd_ByDataSave(
    { commit, dispatch, getters, rootGetters, rootState, state },
    { datasave },
  ) {
    state.namescontroller_listsAdd.forEach((controller_listsAdd) => {
      let controller_listsLots = getters['controller_listsLots'](
        controller_listsAdd,
      )

      if (controller_listsLots != undefined && controller_listsLots != '') {
        commit('lotproduits/set_init', {
          controller_listsLots,
        })

        dispatch('lotproduits/ajax_initialize_struct_ListLot', {
          controller_listsLots,
        })
      }

      let api_config_ = api_config[controller_listsAdd]
      let schema = api_config_.schema
      let titles = api_config_.titles

      commit('set_schema', { controller_listsAdd, schema })
      commit('set_titles', { controller_listsAdd, titles })
      commit('remove_rows', { controller_listsAdd })
      chargeDataSelect(
        commit,
        getters,
        rootGetters,
        controller_listsAdd,
        schema,
        () => {
          let schemehtml = getters.schemehtml[controller_listsAdd]
          let data = datasave[controller_listsAdd]
          

          if (schemehtml.length > 0) {
            for (const indexrows in data) {
              let row = JSON.parse(JSON.stringify(schemehtml))
              for (const indexrow in row) {
                const input = row[indexrow]

                const value = data[indexrows][input.name]

                if (typeof value === 'object' && value !== null) {
                  row[indexrow].value = value['@id']
                  //row[indexrow].value = [];
                } else {
                  row[indexrow].value = value
                }
              }

              commit('add_row', { controller_listsAdd, row })
            }
          }
        },
      )
    })
  },

  //ok
  input_change(
    { commit, dispatch, getters, rootGetters, rootState, state },
    { controller_listsAdd, input_schema, value, indexrows, indexrow },
  ) {
    /// event to select value
    if (
      input_schema.typeHtml == 'select' ||
      input_schema.typeColumn == 'ManyToOne' ||
      input_schema['typeHtml'] == 'selectajax'
    ) {
      commit('event_to_select', {
        controller_listsAdd,
        name: input_schema.name,
        value,
        indexrows,
      })
    }

    commit('change_data_row', {
      controller_listsAdd,
      indexrows,
      indexrow,
      value,
    })

    commit('calcule_row', {
      controller_listsAdd,
      indexrows,
    })
  },

  remove({ commit, dispatch, getters, rootGetters, rootState, state }) {
    commit('remove_all_data_Select_load')
    commit('remove_all_rows')
  },

  set_data(
    { commit, dispatch, getters, rootGetters, rootState, state },
    { controller_listsAdd, data },
  ) {
    let schemehtml = getters.schemehtml[controller_listsAdd]

    if (schemehtml.length > 0) {
      for (const indexrows in data) {
        let row = JSON.parse(JSON.stringify(schemehtml))

        for (const indexrow in row) {
          const input = row[indexrow]

          let value = ''
          if (
            input.type == 'select' &&
            data[indexrows]['FOREIGN_KEY_' + input.name] != undefined
          ) {
            value = data[indexrows]['FOREIGN_KEY_' + input.name]
          } else if (
            input.name == 'id_produits' &&
            data[indexrows]['FOREIGN_KEY_produits'] != undefined
          ) {
            value = data[indexrows]['FOREIGN_KEY_produits']
          } else {
            value = data[indexrows][input.name]
          }
          row[indexrow].default = value

          //   commit("change_data_row", { controller_listsAdd,indexrows, indexrow, value: value });
        }
        commit('add_row', { controller_listsAdd, row })
      }
    }
  },
  ////////////////////////////////////////////////////////
  set_data_par_multiselect(
    { commit, dispatch, getters, rootGetters, rootState, state },
    { controller_listsAdd, data, pieceID },
  ) {
    let schemehtml = getters.schemehtml[controller_listsAdd]
    let controller_listsLots = getters.controller_listsLots(controller_listsAdd)

    if (schemehtml.length > 0) {
      for (const indexrows in data) {
        let row = JSON.parse(JSON.stringify(schemehtml))

        for (const indexrow in row) {
          const input = row[indexrow]

          let value = ''

          if (
            input.typeHtml == 'select' ||
            input.typeColumn == 'ManyToOne' ||
            input['typeHtml'] == 'selectajax'
          ) {
            value = data[indexrows][input.name + '_id']
          } else if (input.name == 'piece') {
            let code = data[indexrows]['code']
            if (code) {
              value = pieceID + code
            } else {
              value = pieceID
            }
          } else if (
            input.name == controller_listsLots ||
            input.from == controller_listsLots
          ) {
            value = []
          } else {
            value = data[indexrows][input.name]
          }

          row[indexrow].value = value
        }

        //  row[controller_listsLots].value = [];

        commit('add_row', { controller_listsAdd, row })
      }
    }
  },

  ///////////////////////////////////////////////////////////
}
