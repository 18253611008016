<template>
<div>
    <card-block icon="mdi-printer" id="grouproot1" color="light-blue" :title="$t('box_title_printer_1')" :text="$t('box_text_printer_1')">
        <v-row>
            <v-col cols="12" lg="4" md="4">

                <v-card>

                    <v-card-text>
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12">
                                    <v-checkbox v-model="entite" :label="$t(`entête/header`)" false-value="0" true-value="1"></v-checkbox>
                                </v-col>

                                <v-col cols="12">
                                    <v-radio-group v-model="orientation" :label="$t('orientation')">
                                        <v-radio :label="$t(`Portrait`)" value="P"></v-radio>
                                        <v-radio :label="$t(`Landscape`)" value="L"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                 <v-col cols="12">
                                    <v-radio-group v-model="pageformat" :label="$t('page format')">
                                        <v-radio :label="`A3`" value="A3"></v-radio>
                                        <v-radio :label="`A4`" value="A4"></v-radio>
                                        <v-radio :label="`A5`" value="A5"></v-radio>
                                         
                                    </v-radio-group>
                                </v-col>

                                <v-col cols="12">
                                    <v-subheader>{{$t('marge supérieure')}}</v-subheader>
                                    <v-slider v-model="backtop" thumb-label :max="255" class="align-center" prepend-icon="mdi-border-top">
                                        <template v-slot:append>
                                            <v-text-field v-model="backtop" class="mt-0 pt-0" type="number" style="width: 60px" label="mm"></v-text-field>
                                        </template>
                                    </v-slider>
                                </v-col>
                                <v-col cols="12">
                                    <v-subheader>{{$t('marge inférieure')}}</v-subheader>
                                    <v-slider v-model="backbottom" thumb-label :max="255" class="align-center" prepend-icon="mdi-border-bottom">
                                        <template v-slot:append>
                                            <v-text-field v-model="backbottom" class="mt-0 pt-0" type="number" style="width: 60px" label="mm"></v-text-field>
                                        </template>
                                    </v-slider>
                                </v-col>

                                <v-col cols="12">
                                    <v-subheader>{{$t('marge gauche')}}</v-subheader>
                                    <v-slider v-model="backleft" thumb-label :max="255" class="align-center" prepend-icon="mdi-dock-left">
                                        <template v-slot:append>
                                            <v-text-field v-model="backleft" class="mt-0 pt-0" type="number" style="width: 60px" label="mm"></v-text-field>
                                        </template>
                                    </v-slider>
                                </v-col>

                                <v-col cols="12">
                                    <v-subheader>{{$t('marge à droite')}}</v-subheader>
                                    <v-slider v-model="backright" thumb-label :max="255" class="align-center" prepend-icon="mdi-dock-right">
                                        <template v-slot:append>
                                            <v-text-field v-model="backright" class="mt-0 pt-0" type="number" style="width: 60px" label="mm"></v-text-field>
                                        </template>
                                    </v-slider>
                                </v-col>

                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>

            </v-col>
            <v-col cols="12" lg="8" md="8">
                <v-card>
                    <iframe width="100%" height="900px" :src="urlPagepdf"  />
                  
                </v-card>
            </v-col>
        </v-row>
    </card-block>
</div>
</template>

<script>
import axios from "axios";
//import router from "@/router";
import {
    forIn
} from "lodash/object";

export default {
    computed: {
        urlPagepdf() {
            let urlPage = this.$store.getters["get_pdf"](this.controller);
            let id = this.id;

            return urlPage + "?id=" + id +
            "&entite=" + this.entite +
            "&orientation=" + this.orientation +
                "&pageformat=" + this.pageformat +
                "&backtop=" + this.backtop +
                "&backbottom=" + this.backbottom +
                "&backleft=" + this.backleft +
                "&backright=" + this.backright;
        },
        id() {
            return this.$route.params.id;
        },
        controller() {
            return this.$route.params.controller;
        },
    },
    data: () => ({
       
        orientation:"P",
        pageformat:"A4",
        entite: "0",
        backtop: 38,
        backbottom: 44,
        backleft: 12,
        backright: 12,

    }),

    watch: {

    },

    methods: {

    },
    mounted() {
        this.entite = "1";
    },
};
</script>

<style>
</style>
