<template>
  <div>
    <div style="width: max-content; float: right" v-if="action_traitement">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            v-show="isGranted('add') && action_traitement"
            color="primary"
            class="mx-2"
            fab
            dark
            large
            :to="{ name: controller_root + '_add' }"
          >
            <v-icon>mdi-playlist-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("add") }}</span>
      </v-tooltip>

     <!--  <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            dark
            small
            color="primary"
            @click="showItem(item)"
            v-show="isGranted('view', item)"
            v-on="on"
          >
            <v-icon>mdi-view-carousel</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Consulter") }}</span>
      </v-tooltip> -->

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            dark
            small
            color="#9575CD"
            @click="pdf(item)"
            v-if="urlPagepdf"
            v-on="on"
          >
            <v-icon>mdi-pdf-box</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Consulter PDF") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            dark
            small
            color="#E040FB"
            @click="email(item)"
            v-if="urlPageemail"
            v-on="on"
          >
            <v-icon>mdi-email-send</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("send email") }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            dark
            small
            color="green"
            @click="editItem(item)"
            v-show="isGranted('edit', item)"
            v-on="on"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Modifier") }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            dark
            small
            color="pink"
            @click="alertshow()"
            v-show="isGranted('delete', item)"
            v-on="on"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Supprimer") }}</span>
      </v-tooltip>

      <v-snackbar
        v-model="alert"
        elevation="24"
        :top="true"
        centered
        vertical
        color="error"
      >
        <v-icon>mdi-home-alert</v-icon
        >{{ $t("Êtes-vous sûr de vouloir supprimer cette ligne") }}
        <br />
        <strong>{{ text }}</strong>

        <div style="display-2">
          <v-btn dark text @click="alert = false">{{ $t("Fermer") }}</v-btn>
          <v-btn dark text @click="deleteItem()">{{ $t("oui") }}</v-btn>
        </div>
      </v-snackbar>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return { alert: false, delete: false, text: "", fab: false };
  },
  props: {
    item: {},
    controller_root: {},
    action_traitement: { default: true },
    slot_show: {},
  },

  computed: {
    urlPagepdf() {
      let names_controllers = this.controller_root;
      return this.$store.getters["get_pdf"](names_controllers);
    },
    urlPageemail() {
      let names_controllers = this.controller_root;
      return this.$store.getters["get_email"](names_controllers);
    },
  },

  methods: {
    isGranted(attribute, item) {
      return this.$store.getters["security/isGranted"](
        attribute,
        this.controller_root,
        item
      );
    },

    showItem() {
      let item = this.item;
      if (this.slot_show) {
        this.$store.dispatch("custom_fromById", {
          names_controllers: [this.controller_root],
          id: item.id,
        });

        this.$store.commit("dialog/set_dialog", {
          data: item,
          flag: true,
          type: "show",
          controller: this.controller_root,
        });
      } else {
        this.$router.push({
          name: this.controller_root + "_show_item",
          params: { id: item.id },
        });
      }
    },

    pdf() {
      let item = this.item;

      this.$router.push({
        name: "print",
        params: { id: item.id, controller: this.controller_root },
      });

      /*   let id = this.item.id;
      let names_controllers = this.controller_root;
      let urlPage = this.urlPagepdf;
      console.log(urlPage);
      

      window.open(
        urlPage + "?id=" + id,
        "_blank" // <- This is what makes it open in a new window.
      ); */
    },
    email() {
      let item = this.item;

      this.$router.push({
        name: "email",
        params: { id: item.id, controller: this.controller_root },
      });
    },

    editItem() {
      let item = this.item;
      if (this.slot_show) {
        this.$store.dispatch("custom_fromById", {
          names_controllers: [this.controller_root],
          id: item.id,
        });

        this.$store.commit("dialog/set_dialog", {
          data: item,
          flag: true,
          type: "update",
          controller: this.controller_root,
        });
      } else {
        this.$router.push({
          name: this.controller_root + "_update",
          params: { id: item.id },
        });
      }
    },
    avoirItem() {
      let item = this.item;
      if (this.slot_show) {
        this.$store.dispatch("custom_fromById", {
          names_controllers: [this.controller_root],
          id: item.id,
        });

        this.$store.commit("dialog/set_dialog", {
          data: item,
          flag: true,
          type: "update",
          controller: this.controller_root,
        });
      } else {
        this.$router.push({
          name: this.controller_root + "_avoir",
          params: { id: item.id },
        });
      }
    },

    alertshow() {
      this.alert = true;

      this.text =
        "(  ID = " +
        this.item["id"] +
        /*   "   /      " +
        this.item["toString"] + */
        "  ) ";
    },

    deleteItem() {
      this.alert = false;

      this.$store.dispatch("send/delete_data", {
        controller_root: this.controller_root,
        item: this.item,
      });


      this.$router.push({
          name: this.controller_root + "_show",
        
        });
    },
  },
};
</script>
<style>
/* This is for documentation purposes and will not be needed in your application */
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
</style>