<template>
  <div class="text-center">
    
    <v-btn @click="savedata" :disabled="!valid || senddata " color="success" >
     {{$t('Enregistrer')}} <v-icon>mdi-content-save</v-icon>
    </v-btn>
    <v-btn @click="cleardata" :disabled=" senddata " color="warning">
   {{$t('Vide')}}   <v-icon>mdi-close-circle</v-icon>
    </v-btn>




   
  </div>
</template>
<script>
export default {
  props: {
    controller_root: {},
    valid: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    savedata() {
      if (this.valid) {
        console.log(this.valid);

        this.$store.dispatch("send/send_data",  { controller_root: this.controller_root });

        /// hidin dialog si is
        this.$store.commit("dialog/set_dialog", {
          data: [],
          flag: false,
          type: "Hidden",
          controller: this.controller_root
        });

        //show
         this.$store.commit("dialog/set_dialog_btn_send", { flag: true,  });
        // this.senddata=true
      }
    },
    cleardata() {
      this.$store.commit("structure/clear_input_binds", {
        controller_root: this.controller_root
      });
    }
  },computed:{

  },   data() {
    return {senddata:false};
  },
};
</script>