module.exports = {
  id: { typeHtml: "hidden" },

  categorie: { typeHtml: "text", unique: true , nullable: false },
  icon: { typeHtml: "iconinput" ,defaultValue:"mdi-food", nullable: false },
  note: { typeHtml: "Textarea" },
  

  updatedAt: { typeHtml: "hidden" },
  createdAt: { typeHtml: "hidden" },

  user: { typeHtml: "hidden" , to_string: "identifiant"},
};
