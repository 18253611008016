<template>
  <v-container fluid>
    <v-row class="justify-center">
      <!-- mini statistiques start -->
      <v-col cols="12" class="justify-center">
        <v-row class="justify-center">
          <v-col cols="12" lg="4" xs="12">
            <statistiques-mini
              :value="files_count_save"
              :valuetotal="files_count_save + files_count_remove"
              icon="mdi-chart-timeline-variant"
              :title="(files_size_save / 1024 / 1024).toFixed(2) + ' MB'"
              sub-title="les fichiers sauvegarder"
              color="indigo"
              colorchart="primary"
            ></statistiques-mini>
          </v-col>
          <v-col cols="12" lg="4" xs="12">
            <statistiques-mini
              :value="files_count_remove"
              :valuetotal="files_count_save + files_count_remove"
              icon="mdi-chart-timeline-variant"
              :title="(files_size_remove / 1024 / 1024).toFixed(2) + ' MB'"
              sub-title="les fichiers dans corbeille"
              color="red"
              colorchart="orange"
            ></statistiques-mini>
          </v-col>
        </v-row>
      </v-col>
      <!-- mini statistiques  end -->

      <v-col cols="12" sm="12" class="justify-center">
        <v-toolbar class="elevation-0 transparent media-toolbar"></v-toolbar>
        <v-row class="justify-center">
          <v-col cols="6" class="justify-center">
            <v-radio-group v-model="typeFilesFinde" row>
              <v-radio :label="$t('Table')" value="filestableshow"></v-radio>
              <v-radio
                :label="$t('_filesFindeName')"
                value="filesFindeName"
              ></v-radio>
              <v-radio
                :label="$t('_filesFindeCode')"
                value="filesFindeCode"
              ></v-radio>

              <v-radio
                :label="$t('_filesFindeDate')"
                value="filesFindeDate"
              ></v-radio>

              <v-radio
                :label="$t('par explorer fichier')"
                value="filesFindeTree"
              ></v-radio>
            </v-radio-group>

            <v-text-field
              v-show="
                typeFilesFinde != 'filesFindeDate' &&
                typeFilesFinde != 'filestableshow' &&
                typeFilesFinde != 'filesFindeTree'
              "
              v-model="FilesFinde"
              class="mr-4 mt-2 purple-input"
              label="Recherche..."
              hide-details
              color="purple"
            >
              <template v-slot:append-outer>
                <v-btn @click="get_files()" small>
                  <v-icon color="primary">mdi-file-find-outline</v-icon>
                </v-btn>
              </template>
            </v-text-field>

            <v-autocomplete
              v-show="
                typeFilesFinde == 'filesFindeDate' &&
                typeFilesFinde != 'filestableshow'
              "
              label="Select Page Web... "
              @change="select_change()"
              :items="items"
              item-text="title"
              item-value="value"
              v-model="controller"
            >
              <template v-slot:append-outer>
                <v-btn @click="get_files()" small>
                  <v-icon color="primary">mdi-file-find-outline</v-icon>
                </v-btn>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row class="justify-center" v-if="typeFilesFinde != 'filestableshow'">
          <v-col cols="12" sm="4" v-if="typeFilesFinde == 'filesFindeDate'">
            <v-row
              v-show="typeFilesFinde == 'filesFindeDate'"
              class="justify-center"
            >
              <v-switch
                v-model="is_range"
                @change="dates = []"
                :label="is_range ? 'marge ' : 'select '"
              ></v-switch>
            </v-row>
            <v-date-picker
              v-show="typeFilesFinde == 'filesFindeDate'"
              :events="date_content_files['all']"
              :event-color="
                (date) =>
                  !date_content_files['remove'].includes(date)
                    ? 'green lighten-1'
                    : ['green lighten-1', 'red']
              "
              full-width
              v-model="dates"
              :range="is_range"
              :multiple="!is_range"
              :locale="$t('locale')"
            ></v-date-picker>
          </v-col>

          <v-col cols="12" sm="4" v-if="typeFilesFinde == 'filesFindeTree'">
            <v-treeview
              v-model="tree"
              :active.sync="activetree"
              :open="initiallyOpen"
              :items="itemsFilesTree"
              activatable
              item-key="id"
              open-on-click
            >
              <template v-slot:prepend="{ item, open }">
                <v-icon v-if="!item.file">
                  {{ open ? "mdi-folder-open" : "mdi-folder" }}
                </v-icon>
                <v-icon v-else>
                  {{ iconFilesEX[item.file] }}
                </v-icon>
              </template>
            </v-treeview>
            <p class="hidden">{{ selectedTree }}</p>
          </v-col>

          <v-col
            cols="12"
            sm="8"
            class="justify-center"
            v-show="typeFilesFinde != ''"
          >
            <v-layout row wrap class="justify-center">
              <v-flex>
                <v-subheader class="justify-center">{{
                  $t(controller)
                }}</v-subheader>

                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="3">
                    <v-btn-toggle v-model="type_view" class="justify-end">
                      <v-btn :value="true" @click="get_files()">
                        <v-icon color="primary">mdi-view-headline</v-icon>
                      </v-btn>
                      <v-btn :value="false" @click="get_files()">
                        <v-icon color="primary">mdi-view-list</v-icon>
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <template v-if="!type_view" class="justify-center">
                  <readfile-cardfiles
                    class="justify-center"
                    v-for="(file, index) in files_valueshow"
                    :key="index"
                    :file="file"
                  />
                </template>

                <template v-if="type_view" class="justify-center">
                  <v-list three-line>
                    <readfile-listefiles
                      class="justify-center"
                      v-for="(file, index) in files_valueshow"
                      :key="index"
                      :file="file"
                    />
                  </v-list>
                </template>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>

        <v-row class="justify-center" v-if="typeFilesFinde == 'filestableshow'">
          <v-col :cols="12" :lg="11">
            <table-run-files controller_root="gestionfiles" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import i18n from "@/plugins/i18n";
//import ViewMixins from "@/views/show";
export default {
  // mixins: [ViewMixins],

  data() {
    return {
      dates: [],
      typeFilesFinde: "filestableshow",
      FilesFinde: "",
      is_range: false,
      type_view: true,

      controller: "",
      files_valueshow: [],

      files_count_save: 0,
      files_count_remove: 0,

      files_size_save: 0,
      files_size_remove: 0,

      items: [],
      date_content_files: {
        remove: [],
        all: [],
      },
      controller_root: "gestionfiles",
      namemodule: "gestionfiles",
      options_showData: {
        title: " ",
        not_affiche: ["user", "updatedAt", "createdAt"],
        expanded: [],
      },

      initiallyOpen: [],
      iconFilesEX: {
        html: "mdi-language-html5",
        js: "mdi-nodejs",
        json: "mdi-code-json",
        md: "mdi-language-markdown",
        pdf: "mdi-file-pdf",
        png: "mdi-file-image",
        txt: "mdi-file-document-outline",
        xls: "mdi-file-excel",
      },
      tree: [],
      activetree: [],
      itemsFilesTree: [],
    };
  },
  methods: {
    get_files() {
      this.files_valueshow = [];
      if (this.typeFilesFinde == "filesFindeName") {
        this.$store.dispatch("files/get_by_finde_name", {
          FilesFinde: this.FilesFinde,

          fun_finally: (info) => {
            this.files_valueshow = info;

            console.log(info);
          },
        });
      } else if (this.typeFilesFinde == "filesFindeCode") {
        this.$store.dispatch("files/get_by_finde_code", {
          FilesFinde: this.FilesFinde,

          fun_finally: (info) => {
            this.files_valueshow = info;

            console.log(info);
          },
        });
      } else {
        if (this.is_range) {
          this.$store.dispatch("files/get_files_by_date_range", {
            date_range: this.dates,
            controller: this.controller,

            fun_finally: (info) => {
              this.files_valueshow = info;

              console.log(info);
            },
          });
        } else {
          this.$store.dispatch("files/get_files_by_date_multiple", {
            date_multiple: this.dates,
            controller: this.controller,

            fun_finally: (info) => {
              this.files_valueshow = info;

              console.log(info);
            },
          });
        }
      }
    },

    select_change() {
      this.dates = [];
      this.files_valueshow = [];
      this.$store.dispatch("files/date_content_files", {
        controller: this.controller,

        fun_finally: (dates_content) => {
          let date_content_files_remove = [];
          let date_content_files_all = [];

          dates_content.forEach((date_content) => {
            date_content_files_all.push(date_content["dateUpload"]);

            if (date_content["status"] == "remove") {
              date_content_files_remove.push(date_content["dateUpload"]);
            }
          });

          this.date_content_files = {
            remove: date_content_files_remove,
            all: date_content_files_all,
          };

          console.log(date_content_files_all);

          let uniqueArray = date_content_files_all.filter(function (item, pos) {
            return date_content_files_all.indexOf(item) == pos;
          });

          let element = this.itemsFilesTree.find(
            (itemsTree) => itemsTree.controller === this.controller
          );

          let children = [];

          uniqueArray.forEach((date_content) => {
            children.push({
              name: date_content,
              controller: this.controller,
              id: this.controller + "&&" + date_content,
            });
          });

          element["children"] = children;
        },
      });
    },
  },

  mounted() {
    this.$store.dispatch("files/group_by_controller", {
      fun_finally: (info) => {
        // statistique
        info["status"].forEach((element) => {
          if (element["status"] == "remove") {
            this.files_size_remove = (element["sum_size"] || 0) * 1;
            this.files_count_remove = (element["count_files"] || 0) * 1;
          } else if (element["status"] == "save") {
            this.files_size_save = (element["sum_size"] || 0) * 1;
            this.files_count_save = (element["count_files"] || 0) * 1;
          }
        });

        /// charge_items to select
        let items = [];
        let itemsFilesTree = [];
        info["controller"].forEach((element) => {
          items.push({
            value: element["controller"],
            title:
              i18n.t(element["controller"]) +
              "   (" +
              element["count_files"] +
              ")",
          });

          itemsFilesTree.push({
            controller: element["controller"],
            id: element["controller"],
            name:
              i18n.t(element["controller"]) +
              "   (" +
              element["count_files"] +
              ")",
            children: [],
          });
        });

        this.items = items;
        this.itemsFilesTree = itemsFilesTree;
      },
    });
  },
  computed: {
    selectedTree() {
      if (!this.activetree.length) return undefined;

      const key = this.activetree[0];

      let selected = this.itemsFilesTree.find(
        (itemsTree) => itemsTree.id === key
      );

      if (selected != undefined) {
        this.controller = selected["controller"];

        this.select_change();
      } else {
        var res = key.split("&&");
        this.dates = res[1];
        this.controller = res[0];
        this.get_files();
      }

      return "";
    },
  },
  created() {
    document.title = i18n.t("show_" + this.controller_root);

    this.initia();
    this.run();
  },
};
</script>
