import axios from 'axios'
import router from '@/router'
import api_config from '@/#_CONFIG/Entity'

function getNamescontrollers(state, names_controllers) {
  let namescontrollers
  if (names_controllers == undefined) {
    namescontrollers = state.namescontrollers
  } else {
    namescontrollers = names_controllers
  }

  return namescontrollers
}

function chargeDataSelect(
  commit,
  rootGetters,
  controller_root,
  schemas,
  fin_exc,
) {
  /// charge ManyToOne select
  let namesManyToOne = []
  let URLs = []

  for (const key in schemas) {
    const schema = schemas[key]
    if (schema['typeHtml'] == 'select' || schema['typeHtml'] == 'selectajax') {
      const nameInput = schema['from']

      namesManyToOne.push(nameInput)
      const url = rootGetters['get_dataselect'](nameInput)
      let Base_url = rootGetters ['get_Base_url'];
      let JWTToken = rootGetters['get_JWTToken'];

      URLs.push(
        axios.get(Base_url+url, {
          headers: {
            Accept: 'application/ld+json',
            Authorization:JWTToken
          },
          params: {
            itemsPerPage: 1000,
          },
        }),
      )
    }
  }

  axios
    .all(URLs)
    .then(function (responses) {
      let dataselect_load = {}
      for (let index = 0; index < namesManyToOne.length; index++) {
        const nameManyToOne = namesManyToOne[index]
        dataselect_load[nameManyToOne] =
          responses[index]['data']['hydra:member']
      }

      commit('set_data_Select_load', { controller_root, dataselect_load })
    })

    .finally(fin_exc)
}

export default {
  ajax_initialize_struct_from(
    { commit, dispatch, getters, rootGetters, rootState, state },
    { names_controllers },
  ) {
    getNamescontrollers(state, names_controllers).forEach((controller_root) => {
      let schemas = api_config[controller_root].schema
      commit('set_schemas', { controller_root, schemas })
      chargeDataSelect(commit, rootGetters, controller_root, schemas, () => {
        console.log('ok charge data select')
      })

      /// code charg data select !!!!! hir
    })
  },

  ajax_charge_all_DataSelect(
    { commit, dispatch, getters, rootGetters, rootState, state },
    {},
  ) {
    let data_Select_load = state.data_Select_load

    let names_controllers = []

    for (const name in data_Select_load) {
      names_controllers.push(name)
    }

    names_controllers.forEach((controller_root) => {
      let schemas = api_config[controller_root].schema
      chargeDataSelect(commit, rootGetters, controller_root, schemas, () => {
        //  commit("set_schemas", { controller_root, schemas });
      })
    })
  },

  // update or show item
  ajax_custom_fromById(
    { commit, dispatch, getters, rootGetters, rootState, state },
    { names_controllers, id },
  ) {
    if (id == undefined || id == 0) {
      console.error('id errorrrrrrrr  ==>  ' + id)
    }

    getNamescontrollers(state, names_controllers).forEach((controller_root) => {
      const urlPage = rootGetters['get_path'](controller_root)

      console.log('get id pour etat update => ' + id)

      let schemas = api_config[controller_root].schema
      let Base_url = rootGetters ['get_Base_url'];
      let JWTToken = rootGetters['get_JWTToken'];

      axios
        .get(Base_url+urlPage + '/' + id, {
          headers: {
            Accept: 'application/ld+json',
            Authorization:JWTToken
          },
          params: {},
        })
        .then(function (response) {
          // let schemas = response.data.schema;
          let datasave = response.data

          chargeDataSelect(
            commit,
            rootGetters,
            controller_root,
            schemas,
            () => {
              if (id != undefined && datasave) {
                commit('set_inputes_binds', { controller_root, datasave })

                dispatch('addlist/ajax_custom_ListAdd_ByDataSave', {
                  datasave,
                })

                dispatch('multiselect/ajax_custom_Multiselect_ByDataSave', {
                  datasave,
                })
              } else {
                console.error('id error')
              }
            },
          )
        })
        .catch(function (error) {
          console.error('error get info', error)
          //   router.push("/login")
        })
    })
  },

  /// root change ==> event to systeme

  event_root_change({
    commit,
    dispatch,
    getters,
    rootGetters,
    rootState,
    state,
  }) {
    dispatch('addlist/ajax_initialize_struct_ListAdd')
    dispatch('multiselect/ajax_initialize_struct_Multiselect')
  },

  unique_ajax_validation(
    { commit, dispatch, getters, rootGetters, rootState, state },
    { controller_root, name, uniqueby, valueniqueby, values_binds, fun },
  ) {
    const urlPage = rootGetters['get_path'](controller_root)
    let ajax = false
    let params = {}
    params[name] = values_binds

    let Base_url = rootGetters ['get_Base_url'];
    let JWTToken = rootGetters['get_JWTToken'];

    axios

      .get(Base_url+urlPage, {
        params,headers:{Authorization:JWTToken}
      })
      .then((response) => {
        let rep = response.data

        ajax = true
        let str = ''
        if (rep['hydra:totalItems'] == 0) {
          ajax = true
        } else {
          let data = rep['hydra:member'][0]

          for (const key in data) {
            if (
              key == '@type' ||
              key == '@id' ||
              key == 'createdAt' ||
              key == 'updatedAt' ||
              key == 'file' ||
              key == 'files' ||
              key == 'logo' ||
              key == 'user' ||
              typeof data[key] == 'object'
            ) {
              continue
            }

            str = str + ' ' + data[key]
          }

          ajax = 'deja existant  ( id => ' + str + ' )'
        }

        fun(ajax)
      })
      .catch((error) => {
        console.error('error ajax_data  =>', error)
        // router.push("/login")
      })
  },
}
