<template>
  <v-card
    class="v-card--material--has-heading v-card--material pa-3 mb-16 mt-12"
    elevation="8"
    width="100%"
  >

    <v-alert
      border="left"
      colored-border
      :color="item.color"
      
    >
  <v-row>
      <v-col cols="6">
        <div class="d-flex grow flex-wrap">
          <v-avatar
            rounded
            :size="150"
            class="mx-auto v-card--material __avatar elevation-6"
            color="grey"
          >
            <readfile-img :refgroup="item.logo" :size="150" />
          </v-avatar>
        </div>
      </v-col>
      <v-col cols="6">
        <div class="text-overline mb-4">
          <v-chip class="mr-2 text-h7" label>
            {{ item.categories.categorie }}
          </v-chip>
        </div>
        <v-badge
          :value="item.isracine === true || item.isracine === 'X'"
          icon="mdi-food-variant"
          color="orange"
          overlap
        >
          <v-btn class="ma-2" label  icon x-large>
            <v-icon x-large :color="item.color" > {{ item.categories.icon }}</v-icon>
          </v-btn>
        </v-badge>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div class="ml-4">
          <div
            class="card-title font-weight-light text-h5"
            v-text="item.name"
          />
        </div>
      </v-col>
    </v-row>

    <v-card-actions>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
          class="mr-2"
            fab
            dark
            small
            color="orange"
            @click="showItem(item)"
            v-show="isGranted('view', item)"
            v-on="on"
          >
            <v-icon>mdi-view-carousel</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Consulter") }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
          class="mr-2"
            fab
            dark
            small
            color="orange"
            @click="editItem(item)"
            v-show="isGranted('edit', item)"
            v-on="on"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Modifier") }}</span>
      </v-tooltip>

      <v-spacer></v-spacer>

      <v-btn icon @click="show = !show">
        <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
         <p>ID: {{ item.id }}</p>
        <v-card-subtitle>
          <div class="text-center" v-if="item.isracine === true || item.isracine === 'X'">
           <v-icon large
        
          color="orange">mdi-food-variant
          </v-icon>
           
          </div>
        </v-card-subtitle>

        <v-card-text>
          {{ item.description }}
        </v-card-text>
      </div>
    </v-expand-transition>
 
 
  </v-alert>


   



  </v-card>
</template>

<script>
export default {
  props: {
    item: { type: Object },
    controller_root: {},
  },
  data: () => ({
    show: false,
  }),
  methods: {
    isGranted(attribute, item) {
      return this.$store.getters["security/isGranted"](
        attribute,
        this.controller_root,
        item
      );
    },
    showItem() {
      let item = this.item;
      if (this.slot_show) {
        this.$store.dispatch("custom_fromById", {
          names_controllers: [this.controller_root],
          id: item.id,
        });

        this.$store.commit("dialog/set_dialog", {
          data: item,
          flag: true,
          type: "show",
          controller: this.controller_root,
        });
      } else {
        this.$router.push({
          name: this.controller_root + "_show_item",
          params: { id: item.id },
        });
      }
    },
    editItem() {
      let item = this.item;
      if (this.slot_show) {
        this.$store.dispatch("custom_fromById", {
          names_controllers: [this.controller_root],
          id: item.id,
        });

        this.$store.commit("dialog/set_dialog", {
          data: item,
          flag: true,
          type: "update",
          controller: this.controller_root,
        });
      } else {
        this.$router.push({
          name: this.controller_root + "_update",
          params: { id: item.id },
        });
      }
    },
  },
};
</script>

<style scoped>
.v-card--material .__avatar {
  position: relative;
  top: -64px;
  margin-bottom: -32px;
}

.v-card--material .__heading {
  position: relative;
  top: -40px;
  transition: 0.3s ease;
  z-index: 1;
}
</style>
