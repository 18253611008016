<template>
  <div>
    <v-list-item>
      <v-text-field
        v-model="search"
        prepend-icon="mdi-magnify"
        :label="$t('recherche')"
        single-line
        hide-details
      ></v-text-field>
    </v-list-item>

    <v-list-item
      v-for="(widget, v) in widgets_all"
      :key="'Ek' + v"
      v-show="widgets_disponibles.includes(widget)"
    >
      <v-checkbox
        v-model="affiche_widgets"
        :label="$t(widget)"
        :value="widget"
        off-icon="mdi-eye-off"
        on-icon="mdi-eye"
        color="blue darken-3"
      ></v-checkbox>

      <v-chip
        v-if="indexOf_widgets(widget) > 0"
        class="ma-2"
        color="primary"
        text-color="white"
      >
        <strong>{{ indexOf_widgets(widget) }}</strong>
      </v-chip>
    </v-list-item>
  </div>
</template>


<script>
import i18n from "@/plugins/i18n";

import componentsdisponiblesTransactions from "@/widgets/Transactions/componentsNames";
import componentsdisponiblesVentes from "@/widgets/Ventes/componentsNames";
import componentsdisponiblesAchats from "@/widgets/Achats/componentsNames";
import componentsdisponiblesCRM from "@/widgets/CRM/componentsNames";
import componentsdisponiblesProduit from "@/widgets/Produit/componentsNames";
import componentsdisponiblesReglages from "@/widgets/Reglages/componentsNames";
import componentsdisponiblesTrajets from "@/widgets/Trajets/componentsNames";

export default {
  data() {
    return {
      dialog: false,
      widgets_disponibles: [],
      search: "",
    };
  },
  watch: {
    search(val, oldValue) {
      let widgets_disponibles = [];
      this.widgets_disponibles = [];
      let toSearch = val.toLowerCase().trim();

      this.widgets_all.forEach((element) => {
        if (element.toLowerCase().trim().indexOf(toSearch) != -1) {
          widgets_disponibles.push(element);
        } else if (
          i18n.t(element).toLowerCase().trim().indexOf(toSearch) != -1
        ) {
          widgets_disponibles.push(element);
        }
      });

      // Remove duplicate values from JS array [duplicate]
      console.log([...new Set(widgets_disponibles)]);
      console.log(this.affiche_widgets);
      this.widgets_disponibles = [...new Set(widgets_disponibles)];
    },
  },

  methods: {
    indexOf_widgets(widget) {
      let index = this.affiche_widgets.indexOf(widget);
      if (index > -1) {
        return index + 1;
      }
      return 0;
    },
  },

  computed: {
    affiche_widgets: {
      get() {
        return this.$store.getters["theme/widgets"];
      },
      set(val) {
        this.$store.commit("theme/set_widgets", val);
      },
    },

    connecte() {
      return this.$store.getters["security/connecte"];
    },
    isadmin() {
      return this.$store.getters["security/isAdmin"];
    },

    widgets_all() {
      return [].concat(
        /*   componentsdisponiblesTransactions,
        componentsdisponiblesVentes,
        componentsdisponiblesAchats,
        componentsdisponiblesCRM,
       
        componentsdisponiblesReglages,
        componentsdisponiblesTrajets, */
        componentsdisponiblesProduit
      );
    },
  },
  mounted() {
    this.widgets_disponibles = this.widgets_all;
  },
};
</script>




<style>
</style>