<template>
  <div>
    <v-expansion-panels multiple :value="show_listsAdd_default">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-row align="center" class="spacer" no-gutters>
            <v-col cols="4" sm="2" md="1">
              <v-avatar size="36px">
                <v-icon color="orange" v-text="'mdi-package-down'"></v-icon>
              </v-avatar>
            </v-col>

            <v-col cols="7">
              <v-toolbar-title
                >{{ $t(controller_listsAdd) }}
                <span v-if="items.length" class="grey--text">
                  &nbsp;({{ items.length }})
                </span>
              </v-toolbar-title>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="items"
            sort-by="index"
            :loading="loading"
            hide-default-footer
            :items-per-page="items.length"
            item-key="index"
            :show-select="active_default"
            :single-select="active_default"
          >
            <template v-slot:footer>
              <v-toolbar flat>
                <v-spacer></v-spacer>

                <v-dialog v-model="dialog" max-width="90%" persistent>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      block
                      dark
                      small
                      rounded
                      color="orange"
                      v-on="on"
                      @click="addrow()"
                      v-show="edit_listsAdd"
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title>
                      <v-icon>mdi-rotate-3d-variant</v-icon>
                      <v-spacer></v-spacer>
                      <v-btn class="mx-2" fab dark small @click="closerow()">
                        <v-icon dark>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <addlist-input-render
                            v-for="(schema, indexrow) in row"
                            :key="indexrow"
                            :controller_listsAdd="controller_listsAdd"
                            :indexrows="editedIndex"
                            :indexrow="indexrow"
                            :schema="schema"
                            :disabled="false"
                          >
                          </addlist-input-render>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="indigo"
                        @click="closerow()"
                      >
                        <v-icon dark>mdi-hand-okay</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.action="{ item }">
              <div
                style="width: max-content; float: right"
                v-show="edit_listsAdd"
              >
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="orange"
                  @click="editItem(item.index)"
                >
                  <v-icon dark>mdi-pencil-outline</v-icon>
                </v-btn>

                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="error"
                  @click="remove_row(item.index)"
                >
                  <v-icon dark>mdi-delete-forever</v-icon>
                </v-btn>
              </div>
            </template>

            <template v-slot:no-data>
              <v-icon size="60">mdi-food</v-icon>
            </template>
  <template v-slot:item.produits="{ item }">
              <v-chip>
                <v-icon color="orange"  class="pr-2">mdi-food-drumstick</v-icon>
                {{ item.produits }}</v-chip
              >
            </template>

<template v-slot:item.recetteracine="{ item }">
              <v-chip>
                <v-icon color="orange"  class="pr-2">mdi-food-variant</v-icon>
                {{ item.recetteracine }}</v-chip
              >
            </template>

            <template v-slot:item.qte="{ item }">
              <v-chip>
                <v-icon color="orange"  class="pr-2">mdi-scale</v-icon>
                {{ item.qte }}</v-chip
              >
            </template>
             <template v-slot:item.prix="{ item }">
              <v-chip>
                <v-icon color="orange"  class="pr-2">mdi-cash</v-icon>
                {{ item.prix }}</v-chip
              >
            </template>

             <template v-slot:item.total="{ item }">
              <v-chip>
                <v-icon color="orange"  class="pr-2">mdi-cash-register</v-icon>
                {{ item.total }}</v-chip
              >
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>


<script>
import i18n from "@/plugins/i18n";
export default {
  props: {
    controller_listsAdd: {
      type: String,
      required: true,
    },

    edit_listsAdd: {
      type: Boolean,
      default: true,
    },
    show_listsAdd: {
      type: Boolean,
      default: true,
    },
    active_default: {
      type: Boolean,
      default: false,
    },
    name_property_default: {
      type: String,
      default: "is_default",
    },
  },
  computed: {
    show_listsAdd_default() {
      if (this.show_listsAdd) {
        return [0];
      } else {
        return [];
      }
    },

    headers() {
      let headers = this.$store.getters["structure/addlist/titles"](
        this.controller_listsAdd
      );
      headers.push({ text: "", value: "action" });

      return headers;
    },

    items() {
      let items = this.$store.getters["structure/addlist/items"](
        this.controller_listsAdd
      );

      return items;
    },

    rows() {
      return this.$store.getters["structure/addlist/rows"][
        this.controller_listsAdd
      ];
    },

    write() {
      return true;
      //return this.$store.getters["structure/addlist/write"];
    },
  },

  data: () => ({
    dialog: false,
    loading: false,

    editedIndex: -1,

    row: [],
    selected: [],
  }),
  watch: {
    dialog(val) {
      if (val == false) {
        this.row = [];
      }
    },
    selected(val) {
      if (this.active_default == true) {
        //  clear all
        this.rows.forEach((row) => {
          row.forEach((element) => {
            if (element.name == this.name_property_default) {
              element.value = "";
            }
          });
        });

        if (val != null && val != undefined && val.length > 0) {
          let index = val[0]["index"];
          let row = this.rows[index];
          row.forEach((element) => {
            if (element.name == this.name_property_default) {
              element.value = 1;
            }
          });
        }
      }
    },
  },

  methods: {
    row_clone() {
      const schemehtml =
        this.$store.getters["structure/addlist/schemehtml"][
          this.controller_listsAdd
        ];
      return JSON.parse(JSON.stringify(schemehtml));
    },

    addrow() {
      let newrow = this.row_clone();

      this.$store.commit("structure/addlist/add_row", {
        controller_listsAdd: this.controller_listsAdd,
        row: newrow,
      });
      this.editedIndex = this.rows.length - 1;

      this.row = newrow;
    },

    closerow() {
      this.dialog = false;
    },

    editItem(indexrows) {
      this.editedIndex = indexrows * 1;
      this.row = this.rows[indexrows];
      this.dialog = true;
    },

    remove_row(indexrows) {
      this.row = [];
      this.$store.commit("structure/addlist/remove_row", {
        controller_listsAdd: this.controller_listsAdd,
        indexrows,
      });
    },
  },
  mounted() {
    if (this.active_default == true) {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.items.forEach((item) => {
          if (item[this.name_property_default] == true) {
            this.selected = [JSON.parse(JSON.stringify(item))];
          }
        });
      }, 900);
    }
  },
};
</script>
