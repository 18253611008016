<template>
  <div class="text-center justify-center">
    <v-row>
      <v-file-input :label="label" @change="modelbindfile($event)" show-size counter :multiple="true" chips :loading="loading"> </v-file-input>
    </v-row>
    <v-row>
      <v-progress-linear :value="valueProgress" v-show="valueProgress > 0"></v-progress-linear>
    </v-row>
    <v-row>
      <readfile-avatar :refgroup="values_binds"></readfile-avatar>
    </v-row>
  </div>
</template>
<script>
import InputMixin from "./InputMixin";

export default {
  mixins: [InputMixin],
  data: () => ({
    valueProgress: 0,
  }),
  methods: {
    modelbindfile(event) {
      if (event == undefined) {
        this.modelbind("");
      }
      if (Array.isArray(event)) {
        this.valueProgress = 0;
        /// send files to server and respons refgroup
        this.$store.dispatch("files/send_files", {
          controller_root: this.controller_root,
          files_input: event,
          name_input: this.name,
          fun_progress: (etat) => {
            this.valueProgress = etat;
          },
          fun_finally: (refGroup) => {
            this.modelbind(refGroup);
            this.valueProgress = 0;
          },
        });
      }
    },
  },
};
</script>
