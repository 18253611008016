<template>
  <card-card
    icon="mdi-bank"
    title="Soldes des comptes"
    class="px-5 py-3"
    :close="close"
    @close="closeevent"
  >
    <v-simple-table>
      <thead>
        <tr>
          <th class="primary--text">comptes</th>

          <th class="text-right primary--text">Soldes</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(Compte, index) in comptes" :key="index">
          <td>{{ Compte.compte }}</td>

          <td class="text-right subtitle-1 deep-purple--text font-weight-light">
            <strong>{{
              Compte.solde.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })
            }}</strong>
            DH
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </card-card>
</template>


<script>
import i18n from "@/plugins/i18n";
import axios from "axios";

import widgetsMixin from "./../widgetsMixin";
export default {
  mixins: [widgetsMixin],
  data: () => ({
    comptes: [],
  }),
  computed: {
    Base_url_web() {
      return this.$store.getters["get_Base_url"];
    },
  },
  methods: {
    start() {
      let path = "/apidashboardlistsoldescomptes";
      axios
        .get(this.Base_url_web + path, { params: {} })
        .then((response) => {
          this.comptes = response.data.comptes;
        })
        .catch((error) => {
          console.error("path");
        });
    },
  },

  mounted() {
    this.start();
  },
};
</script>
