module.exports = {
  id: { typeHtml: "hidden" },
  /*  reference: { typeHtml: "text" }, */
  designation: { typeHtml: "text", unique: true,nullable: false },

  unite: { typeHtml: "text", defaultValue: "g",nullable: false },
  stock: { typeHtml: "hidden" },
  prixAchatHt: { typeHtml: "hidden", defaultValue: "0" },
  lastdateachat: { typeHtml: "hidden" },
  /*   categoriesproduit: { typeHtml: "selectajax", to_string: "categorie" }, */
  /*  marques: { typeHtml: "text" }, */
  description: { typeHtml: "Textarea" },

  /*   prixAchatHt: { typeHtml: "money" }, */
  /*   taxes: { typeHtml: "money" }, */

  /*  qteMinimum: { typeHtml: "money" }, */
  files: { typeHtml: "files" },

  /* stock: { typeHtml: "hidden" }, */
  /*   articlestocke: { typeHtml: "checkbox" }, */
  stockalerte: { typeHtml: "money", defaultValue: "0",nullable: false },

  updatedAt: { typeHtml: "hidden" },
  createdAt: { typeHtml: "hidden" },
 
  user: { typeHtml: "hidden" , to_string: "identifiant"},
};
