<template>
  <div>
    <v-autocomplete
      :label="label"
      :value="values_binds"
      @change="modelbind"
      :items="items"
      item-text="text"
      item-value="value"
      @focus="init_validation()"
      :rules="rules"
    >
      <template v-slot:append-outer>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-btn
              text
              icon
              color="indigo"
              :to="{ name: from.toLowerCase() + '_add' }"
              v-show="!readonly && isGranted('add', name.toLowerCase())"
              v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>ajouter élément</span>
        </v-tooltip>
      </template>
    </v-autocomplete>
  </div>

  <!--   <bootstrap-select :options="items" searchable @input="modelbind" > </bootstrap-select>
  -->
</template>
<script>
import InputMixin from "./InputMixin";
import router from "@/router";
export default {
  mixins: [InputMixin],

  computed: {
    values_binds() {
      return this.$store.getters["structure/get_input_binds"]({
        controller_root: this.controller_root,
        name: this.name,
      });
    },

    items() {
      let data = this.$store.getters["structure/get_data_Select_load"]({
        controller_root: this.controller_root,
        nameManyToOne: this.from,
      });

      let items = [];

      for (const key in data) {
        let option = {};

        const element = data[key];

        if (typeof this.to_string == "string") {
          option.text = element[this.to_string];
        } else {
          option.text =
            element[this.to_string[0]] + " / " + element[this.to_string[1]];
        }
        option.value = element["@id"];

        items.push(option);
      }

      return items;
    },
  },
  watch: {
    val_root(val) {
      if (this.input_root != "" && val != undefined) {
        let val_defaut_par_root = this.info_root()[this.name + "_id"];

        this.$store.commit("structure/set_input_binds", {
          name: this.name,
          value: val_defaut_par_root,
          controller_root: this.controller_root,
        });
      }
    },
  },
  methods: {
    init_validation() {
      this.rules = [this.validation.required];
    },
    isGranted(attribute, controller) {
      return this.$store.getters["security/isGranted"](attribute, controller);
    },
  },
};
</script>
