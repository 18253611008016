<template>
  <div class="text-center justify-center">
    <v-row>
      <v-col cols="11">
         <v-file-input :label="label" @change="modelbindfile($event)" show-size counter chips :loading="loading"> </v-file-input>
        </v-col>
      <v-col cols="1">
        <v-progress-circular :rotate="360" :size="40" :width="5" :value="valueProgress" color="primary" v-show="valueProgress > 0" />
     </v-col>
    </v-row>
    <v-row>
      <readfile-avatar :refgroup="values_binds"></readfile-avatar>
    </v-row>
  </div>
</template>
<script>
import InputMixin from "./InputMixin";

export default {
  mixins: [InputMixin],
  data: () => ({
    valueProgress: 0,
  }),

  methods: {
    modelbindfile(event) {
      if (event == undefined) {
        this.modelbind("");
      }
      this.valueProgress = 0;
      if (typeof event === "object") {
        /// send files to server and respons refgroup
        this.$store.dispatch("files/send_files", {
          controller_root: this.controller_root,
          files_input: [event],
          name_input: this.name,
          fun_progress: (etat) => {
            this.valueProgress = etat;
          },
          fun_finally: (refGroup) => {
            this.modelbind(refGroup);
            this.valueProgress = 0;
          },
        });
      }
    },
  },
};
</script>
