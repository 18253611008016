<template>
  <div>
    <card-block
      icon="mdi-email"
      id="grouproot1"
      color="light-blue"
      :title="$t('box_title_email_1')"
      :text="$t('box_text_email_1')"
    >
      <v-row>
        <v-col cols="12" lg="6" md="6">
          <v-card>
            <v-form ref="form" v-model="valid">
              <v-card-title>
                <span class="text-h5"> {{ $t("Nouveau message") }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="Subject"
                        append-icon="mdi-form-select"
                        :label="$t('Subject') + ' *'"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-container fluid>
                        <v-combobox
                          append-icon="mdi-email-plus"
                          v-model="Email"
                          :filter="filter"
                          :hide-no-data="!search"
                          :items="items"
                          :search-input.sync="search"
                          hide-selected
                          label="Search for an Email"
                          multiple
                          small-chips
                          solo
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <span class="subheading">
                                {{ $t("Create Email") }}</span
                              >
                              <v-chip
                                :color="`${colors[nonce - 1]} lighten-3`"
                                label
                                small
                              >
                                {{ search }}
                              </v-chip>
                            </v-list-item>
                          </template>
                          <template
                            v-slot:selection="{ attrs, item, parent, selected }"
                          >
                            <v-chip
                              v-if="item === Object(item)"
                              v-bind="attrs"
                              :color="`${item.color} lighten-3`"
                              :input-value="selected"
                              label
                              small
                            >
                              <span class="pr-2">
                                {{ item.text }}
                              </span>
                              <v-icon small @click="parent.selectItem(item)">
                                $delete
                              </v-icon>
                            </v-chip>
                          </template>
                          <template v-slot:item="{ index, item }">
                            <v-text-field
                              v-if="editing === item"
                              v-model="editing.text"
                              autofocus
                              flat
                              background-color="transparent"
                              hide-details
                              solo
                              @keyup.enter="edit(index, item)"
                            ></v-text-field>
                            <v-chip
                              v-else
                              :color="`${item.color} lighten-3`"
                              dark
                              label
                              small
                            >
                              {{ item.text }}
                            </v-chip>
                            <v-spacer></v-spacer>
                            <v-list-item-action @click.stop>
                              <v-btn
                                icon
                                @click.stop.prevent="edit(index, item)"
                              >
                                <v-icon>{{
                                  editing !== item ? "mdi-pencil" : "mdi-check"
                                }}</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </template>
                        </v-combobox>
                      </v-container>
                    </v-col>

                    <v-col cols="12">
                      <ckeditor
                        :editor="editor"
                        v-model="Body"
                        :config="editorConfig"
                      ></ckeditor>
                    </v-col>
                  </v-row>
                </v-container>
                <small> {{ $t("*indicates required field") }} </small>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  color="blue darken-1"
                  text
                  @click="Envoyer"
                  :disabled="!valid"
                >
                  {{ $t("Envoyer") }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
        <v-col cols="12" lg="6" md="6">
          <v-card>
            <iframe width="100%" height="500px" :src="urlPagepdf" />
          </v-card>
        </v-col>
      </v-row>
    </card-block>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
//import router from "@/router";
import { forIn } from "lodash/object";

export default {
  computed: {
    urlPagepdf() {
      let urlPage = this.$store.getters["get_pdf"](this.controller);
      let id = this.id;

      return urlPage + "?id=" + id;
    },
    id() {
      return this.$route.params.id;
    },
    controller() {
      return this.$route.params.controller;
    },
  },
  data: () => ({
    editor: ClassicEditor,

    editorConfig: {
      // The configuration of the editor.
    },
    valid: true,
    SubjectRules: [
      (v) => !!v || "Subject is required",
      (v) => v.length <= 500 || "Subject must be less than 500 characters",
    ],

    activator: null,
    attach: null,
    colors: ["green", "purple", "indigo", "cyan", "teal", "orange"],
    editing: null,
    editingIndex: -1,
    items: [{ header: "Select an Email or create one" }],
    nonce: 1,
    menu: false,
    Subject: "",
    Body: "",
    Email: [],
    x: 0,
    search: null,
    y: 0,
  }),

  watch: {
    Email(val, prev) {
      if (val.length === prev.length) return;

      this.Email = val.map((v) => {
        if (typeof v === "string" && this.validateEmail(v)) {
          v = {
            text: v,
            color: this.colors[this.nonce - 1],
          };

          this.items.push(v);

          this.nonce++;
        }

        return v;
      });
    },
  },

  methods: {
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },

    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
    Envoyer() {
      this.$store.commit("dialog/set_dialog_btn_send", { flag: true });
      let Email = [];
      this.Email.forEach((email) => {
        Email.push(email.text);
      });

      var json_arr = JSON.stringify(Email);
      const formdata = new FormData();
      formdata.append("Email", json_arr);
      formdata.append("controller", this.controller);
      formdata.append("id", this.id);
      formdata.append("Subject", this.Subject);
      formdata.append("Body", this.Body);

      let urlPage = this.$store.getters["get_email"](this.controller);

      axios
        .post(urlPage, formdata, {})
        .then((response) => {
          this.$store.commit("dialog/set_dialog_btn_send", { flag: false });
          let to = this.controller + "_show";
        //  router.push({ name: to });
          console.log(response.data);
        })
        .catch((error) => {
          console.log("Error", error.message);
          console.error("error to POST data =>" + error);
          this.$store.commit("dialog/set_dialog_btn_send", { flag: false });
        });
    },
  },
  mounted() {
    let urlPage = this.$store.getters["get_email"](this.controller);
    const formdata = new FormData();
    formdata.append("getemailcontact", "true");

    formdata.append("id", this.id);

    axios
      .post(urlPage, formdata, {})
      .then((response) => {
        let listcontact = response.data;
        listcontact.forEach((email) => {
          if (typeof email === "string" && this.validateEmail(email)) {
            email = {
              text: email,
              color: this.colors[this.nonce - 1],
            };

            this.items.push(email);
            this.Email.push(email);

            this.nonce++;
          }
        });
      })
      .catch((error) => {
        console.log("Error", error.message);
        console.error("error to POST data =>" + error);
      });
  },
};
</script>

<style>
</style>