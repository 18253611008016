import Material from "vuetify/es5/util/colors";

// export function camel (str) {
//   const camel = (str || '').replace(/-([^-])/g, g => g[1].toUpperCase());

//   return capitalize(camel);
// }

// export function camelActual (str) {
//   return (str || '').replace(/-(\w)/g, (_, c) => (c ? c.toUpperCase() : ''));
// }

// export function kebab (str) {
//   return (str || '').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
// }

// export function capitalize (str) {
//   str = str || '';

//   return `${str.substr(0, 1).toUpperCase()}${str.slice(1)}`;
// }

// export function findProduct (store, id) {
//   return store.state.store.products.find(p => p.id === id);
// }

// export function isOnSale (variants) {
//   return variants.some(variant => {
//     return parseFloat(variant.price) < parseFloat(variant.compareAtPrice);
//   });
// }

// export function randomNumber (min, max) {
//   return Math.floor(Math.random() * max) + min;
// }

// export function randomString (length = 5) {
//   let text = '';
//   const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

//   for (let i = 0; i < length; i++) {
//     text += possible.charAt(Math.floor(Math.random() * possible.length));
//   }

//   return text;
// }

const randomElement = (arr = []) => {
  return arr[Math.floor(Math.random() * arr.length)];
};

const kebab = (str) => {
  return (str || "").replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
};

const toggleFullScreen = () => {
  let doc = window.document;
  let docEl = doc.documentElement;

  let requestFullScreen =
    docEl.requestFullscreen ||
    docEl.mozRequestFullScreen ||
    docEl.webkitRequestFullScreen ||
    docEl.msRequestFullscreen;
  let cancelFullScreen =
    doc.exitFullscreen ||
    doc.mozCancelFullScreen ||
    doc.webkitExitFullscreen ||
    doc.msExitFullscreen;

  if (
    !doc.fullscreenElement &&
    !doc.mozFullScreenElement &&
    !doc.webkitFullscreenElement &&
    !doc.msFullscreenElement
  ) {
    requestFullScreen.call(docEl);
  } else {
    cancelFullScreen.call(doc);
  }
};
const color = () => {
  let colors = [
    "blue",
    "pink",

    "green",
    "purple",
    "indigo",
    "brown",
    "orange",
    "red",

    "cyan",
    "teal",
    "grey",

    "lime",

    "deepPurple",
    "blueGrey",
    "amber",
    "yellow",
  ];

  let backgroundColor = [];
  let valueColor = [];
  let borderColor = [];
  let baseColor = [];
  for (const color of colors) {
    backgroundColor.push(Material[color].lighten4);
    valueColor.push(Material[color].lighten3);
    borderColor.push(Material[color].lighten2);
    baseColor.push(Material[color].base);
  }
  return { color, backgroundColor, valueColor, borderColor, baseColor };
};

function isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  ); // ...and ensure strings of whitespace fail
}
function isDate(date) {
  return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
}

function findInString(value, searchtext) {
  return (
    typeof value === "string" &&
    searchtext != null &&
    searchtext != "" &&
    value != "" &&
    value != null &&
    value != undefined &&
    value
      .toLowerCase()
      .trim()
      .toString()
      .indexOf(searchtext.toLowerCase().trim()) !== -1
  );
}

const customsearchTable = (value, search, item) => {
  //vuetifyjs.com/en/api/v-data-table/#props

  if (value == null || typeof value != "string") {
    return false;
  }

  const searchsPIPE = search.split("||");

  searchsPIPE.push(search);

  for (let index = 0; index < searchsPIPE.length; index++) {
    const searchtext = searchsPIPE[index];

    if (findInString(value, searchtext)) {
      return true;
    }
    ///////////////////////////////////////////////////////////
    const searchsIN = searchtext.split(">");

    if (findInObject(searchsIN, item)) {
      return findInObject(searchsIN, item);
    }
  }
  ///////////////////////////////////////////////////////////

  /*  for (let index = 0; index < searchsPIPE.length; index++) {
    const searchtext = searchsPIPE[index];
    
  } */
};

function findInObject(searchsIN, item) {
  if (searchsIN.length > 1) {
    let results = [];

    for (let i = 0; i < searchsIN.length; i++) {
      const toSearch = searchsIN[i].trim();
      if (toSearch == "") {
        results.push("");
        continue;
      }

      const key_value = toSearch.split("===");
      // find key_value
      if (key_value.length == 2) {
        const key = key_value[0];
        const value_ = key_value[1];
        const v_obj = item[key];

        const value_marge = value_.split("~");
        if (value_marge.length == 2) {
          if (isNumeric(v_obj)) {
            const min = Math.min(...value_marge);
            const max = Math.max(...value_marge);
            console.log(min);
            console.log(max);
            if (v_obj * 1 >= min && v_obj * 1 <= max) {
              results.push("");
              console.log(
                "find key_value_marge type Numeric => results in extern object  :" +
                  value_
              );
              continue;
            }
          } else if (isDate(v_obj)) {
            let times_value_marge = [
              new Date(value_marge[0]).getTime(),
              new Date(value_marge[1]).getTime(),
            ];
            let v_obj_times = new Date(v_obj).getTime();

            console.log("isDate");

            const min = Math.min(...times_value_marge);
            const max = Math.max(...times_value_marge);
            console.log(min);
            console.log(max);
            if (v_obj_times >= min && v_obj_times <= max) {
              results.push("");
              console.log(
                "find key_value_marge type date => results in extern object  :" +
                  value_
              );
              continue;
            }
          }
        } else {
          if (typeof v_obj === "string") {
            if (isNumeric(v_obj) && isNumeric(value_)) {
              if (v_obj * 1 == value_ * 1) {
                results.push("");
                console.log(
                  "find key_value => results in extern object  :" + value_
                );
                continue;
              }
            } else {
              if (findInString(v_obj, value_)) {
                results.push("");
                console.log(
                  "find key_value => results in extern object  :" + value_
                );
                continue;
              }
            }
          } else if (typeof v_obj === "number") {
            if (v_obj * 1 == value_ * 1) {
              results.push("");
              console.log(
                "find key_value => results in extern object  :" + value_
              );
              continue;
            }
          }
        }
      }

      // find global
      else {
        for (const key in item) {
          let v_obj = item[key];

          if (v_obj != null) {
            if (typeof v_obj === "string") {
              if (findInString(v_obj, toSearch)) {
                results.push("");
                console.log(
                  "find global => results in extern object  " + toSearch
                );
                break;
              }
            } else if (typeof v_obj === "object") {
              if (findInObject_intern(toSearch, v_obj["dataSet"])) {
                results.push("");
                console.log(
                  "find global => results in intern object  " + toSearch
                );
                break;
              }
            }
          }
        }
      }

      if (searchsIN.length == results.length) {
        break;
      }
    }

    // console.log(searchsIN);
    // console.log(results);
    // console.log(searchsIN.length == results.length);
    return searchsIN.length == results.length;
  }
}
function findInObject_intern(toSearch, dataSet) {
  for (const keymain in dataSet) {
    let object = dataSet[keymain];
    for (const key in object) {
      if (Object.hasOwnProperty.call(object, key)) {
        const element = object[key];

        if (findInString(element, toSearch)) {
          return true;
        }
      }
    }
  }
  return false;
}

export default {
  randomElement,
  toggleFullScreen,

  kebab,
  customsearchTable,
  color: color(),
};
