<template>
  <v-card class="elevation-0" width="500" max-height="350">
    <v-toolbar dense color="transparent">
      <v-toolbar-title>
        <h4>{{ $t("Notification") }}</h4>
      </v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text class="pa-0">
      <v-list three-line class="pa-0" rounded avatar>
        <template v-for="(item, index) in items">
          <v-divider v-if="item.divider" :key="index"></v-divider>

          <v-list-item v-else :key="index" @click="handleClick" inactive>
            <v-list-item-avatar size="30px">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <img :src="item.avatar" alt="avatar" v-on="on" />
                </template>
                <span>
                  {{ $t("utilisateur") }} : {{ item.user.toUpperCase() }}
                  {{ $t("email") }} :{{ item.email }}</span
                >
              </v-tooltip>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                <v-icon dark :color="item.color">{{ item.icon }}</v-icon>
                <strong class="indigo--text text--darken-2">{{
                  $t(item.title)
                }}</strong>
              </v-list-item-title>

              <v-list-item-subtitle>
                <h4>
                  <v-icon dark :color="item.color">mdi-account</v-icon>
                  <strong class="text--primary subtitle-2">{{
                    item.user.toUpperCase()
                  }}</strong>
                </h4>

                <h4>
                  <v-icon dark :color="item.color">mdi-email</v-icon>
                  <strong> {{ item.text }}</strong>
                </h4>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action
              class="caption justify-center align-content-center"
            >
              <h4>{{ $t("événement") }} : {{ $t(item.action) }}</h4>
              {{ item.date }}
              <br />
              {{ item.time }}
              <!-- <v-btn fab x-small @click="item.click">
                <v-icon x-small dark color="light-blue">mdi-hand-okay</v-icon>
              </v-btn>-->
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
      <v-divider></v-divider>
      <v-btn block class="ma-0" :to="{ name: 'userlog_show' }">{{
        $t("toutes événements")
      }}</v-btn>
      <v-divider></v-divider>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import router from "@/router";
export default {
  data: () => ({}),
  methods: {
    handleClick: (e) => {
      console.log(e);
    },
  },
  mounted() {},
  computed: {
    items() {
      return this.$store.getters["theme/notification"];
    },
  },
};
</script>
