<template>
  <div>
    <v-list-group
      no-action
      v-if="isadmin && connecte && $route.name == '_dashboard'"
    >
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>
            <h4 color="success">{{ $t("Achats") }}</h4>
          </v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item v-for="(widget, v) in widgets_disponibles" :key="'E' + v">
        <v-checkbox
          @change="change_widgets($event)"
          :input-value="affiche_widgets()"
          :label="$t(widget)"
          :value="widget"
          off-icon="mdi-eye-off"
          on-icon="mdi-eye"
          color="blue darken-3"
        ></v-checkbox>
        <v-chip
          v-if="indexOf_widgets(widget) > 0"
          class="ma-2"
          color="primary"
          text-color="white"
        >
          <strong>{{ indexOf_widgets(widget) }}</strong>
        </v-chip>
      </v-list-item>
    </v-list-group>
  </div>
</template>


<script>
import componentsdisponibles from "@/widgets/Achats/componentsNames";

export default {
  methods: {
    affiche_widgets() {
      let affiche_widgets = [];
      affiche_widgets = this.$store.getters["theme/widgets"];
      return affiche_widgets;
    },
    change_widgets(selected) {
      this.$store.commit("theme/set_widgets", selected);
    },

    indexOf_widgets(widget) {
      let affiche_widgets = this.affiche_widgets();

      let index = affiche_widgets.indexOf(widget);
      if (index > -1) {
        return index + 1;
      }
      return 0;
    },
  },

  computed: {
    connecte() {
      return this.$store.getters["security/connecte"];
    },
    isadmin() {
      return this.$store.getters["security/isAdmin"];
    },

    widgets_disponibles() {
      return componentsdisponibles;
    },
  },
};
</script>




<style>
</style>