import i18n from '@/plugins/i18n'
import validation from './validation'

export default {
  props: {
    controller_listsAdd: String,

    schema: Object,
    indexrows: Number,
    indexrow: Number,
    disabled: Boolean,
    controller_root: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      validation: validation(this.schema['name'], this.schema['nullable']),
    }
  },
  computed: {
    singleline() {
      return true
    },
    loading() {
      return false
    },
    readonly() {
      return false
    },
    name() {
      return this.schema.name
    },
    from() {
      return this.schema['from']
    },
    to_string() {
      return this.schema['to_string']
    },

    label() {
      return i18n.t(this.name)
    },

    values_binds() {
      return this.$store.getters['structure/addlist/rows'][
        this.controller_listsAdd
      ][this.indexrows][this.indexrow]['value']
    },
  },

  methods: {
    modelbind($event) {
      this.$store.dispatch('structure/addlist/input_change', {
        name: this.name,
        value: $event,
        input_schema: this.schema,
        controller_listsAdd: this.controller_listsAdd,
        indexrows: this.indexrows,
        indexrow: this.indexrow,
      })
    },
  },
}
