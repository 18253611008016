<template>
  <v-list-item>
    
<v-list-item-icon>
          <v-icon color="indigo">mdi-text</v-icon>
        </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title><u>{{ label }}  :</u> </v-list-item-title>

      <v-btn :color="values_binds" large tile >{{ values_binds }}</v-btn>

    </v-list-item-content>
  </v-list-item>
</template>
<script>
import InputMixin from "./InputMixin";
export default {
  mixins: [InputMixin],
};
</script>
