<template>
<v-col cols="12" sm="6" md="4">
  <v-text-field 
  :label="label" 
  :single-line="singleline"
  
  :value="values_binds"
  @input="modelbind($event)"></v-text-field>
</v-col>
</template>
<script>
import InputMixin from "./InputMixin";
export default {
  mixins: [InputMixin],


};
</script>