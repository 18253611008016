export default {
  controller_root: {},
  namesmodules: {},
  nameinput_root: {},

  namescontroller_listsAdd: [],
  controller_listsLots: {},
  editedIndex_addlist: {}, // ok

  field_attach_to: {}, // data select load

  field_event_to: {}, // event select item => change data row

  calcules: {}, // ok
  filter:{},

  not_affiche: {}, // ok
  unique: {}, // ok

  titles: {}, // ok
  schemehtml: {}, // ok
  id: {},
  data_Select_load: {}, // ok

  rows: {}, // ok

  // inputes_binds: {},
  data_sava: {},
  loading: true,
  write: false,
};
