import i18n from '@/plugins/i18n'

export default {
  props: {
    controller_root: String,
    schema: Object,
  },

  computed: {
    name() {
      return this.schema['name']
    },
    label() {
      let icon = ''
      if (!this.schema['nullable']) {
        // icon = "* "
      }

      return icon + i18n.t(this.name)
    },
    from() {
      return this.schema['from']
    },
    to_string() {
      return this.schema['to_string']
    },

    values_binds() {
      return this.$store.getters['structure/get_input_binds']({
        controller_root: this.controller_root,
        name: this.name,
      })
    },
  },
  methods: {
    modelbind(val) {
      this.val = val

      this.$store.commit('structure/set_input_binds', {
        name: this.name,
        value: val,
        controller_root: this.controller_root,
      })
    },
    labelUpperCase(label) {
      return label.toLocaleUpperCase()
    },
    info_root() {
      if (this.input_root != '') {
        const val_selected = this.val_root
        let data_root_load = this.$store.getters[
          'structure/get_data_Select_load'
        ]({
          controller_root: this.controller_root,
          nameManyToOne: this.input_root,
        })
        for (const key in data_root_load) {
          const element = data_root_load[key]
          if (element['id'] == val_selected) {
            return element
          }
        }
        return {}
      }
      return {}
    },
  },
}
