<template>
  <v-color-picker
    dot-size="13"
    hide-canvas
  mode="hexa"
    hide-mode-switch
    hide-sliders
    show-swatches
    swatches-max-height="200"
    :value="values_binds"
    @input="modelbind($event)"
  ></v-color-picker>
</template>
<script>
import InputMixin from "./InputMixin";

export default {
  mixins: [InputMixin],
  methods: {
    modelbind(color) {
  
      if (typeof color != "string") {
        console.log(color);
        this.val = color["hexa"];
      } else {
        this.val = color;
      }

    

      this.$store.commit("structure/set_input_binds", {
        name: this.name,
        value: this.val,
        controller_root: this.controller_root,
      });
    },
  },
};
</script>