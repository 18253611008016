<template>
  <div style="margin-top: 66px; margin-left: 37px">
    <v-btn @click="pdf">pdf</v-btn>
    <v-btn @click="exceljs">excel </v-btn>

    <v-btn @click="csv">csv</v-btn>
    <v-btn @click="csvall">csv détails</v-btn>

    <!-- <v-btn @click="excel">excel (Mozilla Firefox)</v-btn> -->

    <div id="tablepdf">
      <h1>{{ $t(controller) }}</h1>
      <table-run-pdf :controller_root="controller" />
    </div>

    <div v-show="false">
      <table class="paleBlueRows" ref="documenttable">
        <thead>
          <tr>
            <th v-for="(val, i) in headers" :key="i">{{ $t(val.text) }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, l) in selecteds" :key="l">
            <td v-for="(hed, j) in headers" :key="j">{{ row[hed.value] }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import i18n from "@/plugins/i18n";
import html2pdf from "html2pdf.js";
import ExportJsonExcel from "js-export-excel";

export default {
  computed: {
    controller() {
      return this.$route.params.controller;
    },

    selecteds() {
      return this.$store.getters["show_data/g_selected"]({
        controller_root: this.controller,
      });
    },

    namesexpanded() {
      let controller = this.controller;
      let expanded = this.$store.getters["show_data/g_expanded"]({
        controller_root: controller,
      });

      let namesexpanded = [];
      for (const key in expanded) {
        namesexpanded.push(expanded[key]["controller_attach"]);
      }
      console.log(namesexpanded);
      return namesexpanded;
    },

    headers() {
      let controller = this.controller;

      let not_affiches = [];
      not_affiches = this.$store.getters["show_data/g_not_affiche"]({
        controller_root: controller,
      });

      let headers = this.$store.getters["show_data/g_headers"]({
        controller_root: controller,
        namesexpanded: this.namesexpanded,
        notaffiche: not_affiches,
        name_input_files: [],
      });

      return headers;
    },
  },

  methods: {
    pdf() {


        this.$vuetify.theme.dark = false;
      var element = document.getElementById("tablepdf");
      console.log(element);
      var opt = {
        margin: 2,
        filename: "data.pdf",
        // image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
      };

      // New Promise-based usage:
      html2pdf().set(opt).from(element).save();

    },
    exceljs() {
      let sheetheader = [];
      let sheetheadervalue = [];
      let row = {};
      let dataselecteds = this.selecteds;
      for (let index = 0; index < this.headers.length; index++) {
        const element = this.headers[index];

        if (element.value != "id" && element.value != "ID") {
          sheetheader.push(element.value);
          sheetheadervalue.push(element.value);

          row[element.value] = element.text;
        }
      }
      dataselecteds.unshift(row);

      var option = {};
      let namecontroller = this.controller;
      option.fileName = namecontroller;

      option.datas = [
        {
          sheetName: namecontroller,
          //   sheetFilter: [ "one","two"],
          // sheetHeader: [ "one","two"],
          sheetHeader: sheetheader,
          sheetFilter: sheetheadervalue,
          // columnWidths: [20, 20],
          sheetData: dataselecteds,
        },
      ];

      var toExcel = new ExportJsonExcel(option); //new
      toExcel.saveExcel();
    },

    excel() {
      var tab_text = "<table border='2px'><tr bgcolor='#87AFC6'>";
      var textRange;
      var j = 0;
      let tab = this.$refs["documenttable"]; // id of table

      for (j = 0; j < tab.rows.length; j++) {
        tab_text = tab_text + tab.rows[j].innerHTML + "</tr>";
        //tab_text=tab_text+"</tr>";
      }

      tab_text = tab_text + "</table>";
      tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, ""); //remove if u want links in your table
      tab_text = tab_text.replace(/<img[^>]*>/gi, ""); // remove if u want images in your table
      tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, ""); // reomves input params

      var ua = window.navigator.userAgent;
      var msie = ua.indexOf("MSIE ");

      //other browser not tested on IE 11
      let sa = window.open(
        "data:application/vnd.ms-excel," + encodeURIComponent(tab_text)
      );

      return sa;
    },

    csv() {
      var csv = [];

      var rows = this.$refs["document"].querySelectorAll("table tr");

      for (var i = 0; i < rows.length; i++) {
        var row = [],
          cols = rows[i].querySelectorAll("td, th");

        for (var j = 0; j < cols.length; j++) row.push(cols[j].innerText);

        csv.push(row.join(" ; "));
      }

      // Download CSV file
      let nameCSV = this.controller + ".csv";
      this.downloadCSV(csv.join(" \n "), nameCSV);
    },
    csvall() {
      let headers = this.headers;
      let namesexpanded = this.namesexpanded;
      let selecteds = this.selecteds;
      let csv = this.tableCSV(headers, selecteds, namesexpanded);

      // Download CSV file
      let nameCSV = this.controller + ".csv";
      let d = csv.join(" \n ");
      this.downloadCSV(d, nameCSV);
    },
    tableCSV(headers, selecteds, namesexpanded) {
      let row = [];
      let csv = [];

      for (let i = 0; i < headers.length; i++) {
        row.push(headers[i].text);
      }
      csv.push(row.join(" ; "));

      row = [];
      for (let i = 0; i < headers.length; i++) {
        row.push("************");
      }
      csv.push(row.join(" ; "));

      for (let i = 0; i < selecteds.length; i++) {
        let item = selecteds[i];
        row = [];

        for (let k = 0; k < headers.length; k++) {
          row.push(item[headers[k].value]);
        }

        csv.push(row.join(" ; "));

        for (let k = 0; k < namesexpanded.length; k++) {
          csv.push([""]);

          let c = [];
          let sel = item[namesexpanded[k]];

          c = this.tableCSV(sel["titles"], sel["dataSet"], []);

          csv.push(c.join(" \n "));

          row = [];
          for (let i = 0; i < headers.length; i++) {
            row.push(" ");
          }
          csv.push(row.join(" ; "));
        }

        if (namesexpanded.length > 0 && i < selecteds.length - 1) {
          row = [];
          for (let i = 0; i < headers.length; i++) {
            row.push(headers[i].text);
          }
          csv.push(row.join(" ; "));

          row = [];
          for (let i = 0; i < headers.length; i++) {
            row.push("************");
          }
          csv.push(row.join(" ; "));
        }
      }

      return csv;
    },

    downloadCSV(csv, filename) {
      var csvFile;
      var downloadLink;

      // CSV file
      csvFile = new Blob([csv], { type: "text/csv" });

      // Download link
      downloadLink = document.createElement("a");

      // File name
      downloadLink.download = filename;

      // Create a link to the file
      downloadLink.href = window.URL.createObjectURL(csvFile);

      // Hide download link
      downloadLink.style.display = "none";

      // Add the link to DOM
      document.body.appendChild(downloadLink);

      // Click download link
      downloadLink.click();
    },
  },

mounted () {

},



};
</script>





<style>
table.paleBlueRows {
  font-family: "Times New Roman", Times, serif;
  border: 1px solid #ffffff;

  text-align: center;
  border-collapse: collapse;
  margin: 20px auto;
  width: 90%;
}
table.paleBlueRows td,
table.paleBlueRows th {
  border: 1px solid #ffffff;
  padding: 3px 2px;
}
table.paleBlueRows tbody td {
  font-size: 13px;
}
table.paleBlueRows tr:nth-child(even) {
  background: #d0e4f5;
}
table.paleBlueRows thead {
  background: #0b6fa4;
  border-bottom: 5px solid #ffffff;
}
table.paleBlueRows thead th {
  font-size: 17px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  border-left: 2px solid #ffffff;
}
table.paleBlueRows thead th:first-child {
  border-left: none;
}

table.paleBlueRows tfoot {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  background: #d0e4f5;
  border-top: 3px solid #444444;
}
table.paleBlueRows tfoot td {
  font-size: 14px;
}
</style>
