module.exports = {
  id: { typeHtml: "hidden" },

  titre: { typeHtml: "text", nullable: false },
  date:{ typeHtml: "date" , nullable: false},
  note: { typeHtml: "text" },
  files: { typeHtml: "files" },
  user: { typeHtml: "hidden" , to_string: "identifiant"},
 
  updatedAt:{ typeHtml: "hidden" },
  createdAt:{ typeHtml: "hidden" },
};
