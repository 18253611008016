<template>
  <v-col cols="12" sm="6" md="4">
    <v-combobox
      :label="label"
      v-model="value"
      @change="modelbind($event)"
      :items="items"
      item-text="text"
      item-value="value"
      :single-line="singleline"
      :disabled="disabled"
      :filter="filter"
      hide-selected
    >


      <template v-slot:append-outer>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-btn
              text
              icon
              color="success"
              @click="dialog = true"
              v-show="!readonly && isGranted('add', name.toLowerCase())"
              v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>ajouter élément</span>
        </v-tooltip>

        <div class="text-center">
          <v-dialog v-model="dialog" width="500">
            <v-card>
              <v-form ref="form" v-model="valid">
                <v-card-title class="headline grey lighten-2">
                  Ajouter
                </v-card-title>

                <v-card-text>
                  <box-defaultajax :controller_root="from" />
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <btn-sendaddlistajax
                    @click="dialog = false"
                    :valid="valid"
                    :controller_root="from"
                    :controller_main="controller_root"
                  />
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </div>
        
      </template>



    </v-combobox>
  </v-col>
</template>
<script>
import InputMixin from "./InputMixin";
export default {
  mixins: [InputMixin],
  data() {
    return {
      value: undefined,
      dialog: false,
      valid: false,
    };
  },

  methods: {
    modelbind($event) {
      let value;

      if ($event != undefined) {
        value = $event["value"];
      }
      this.$store.dispatch("structure/addlist/input_change", {
        name: this.name,
        value: value,
        input_schema: this.schema,
        controller_listsAdd: this.controller_listsAdd,
        indexrows: this.indexrows,
        indexrow: this.indexrow,
      });
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      let res =
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1;

      return res;
    },
    isGranted(attribute, controller) {
      return this.$store.getters["security/isGranted"](attribute, controller);
    },
  },

  computed: {
    items() {
      let data =
        this.$store.getters["structure/addlist/data_Select_load"][
          this.controller_listsAdd
        ][this.from];

      let items = [];

      for (const key in data) {
        let option = {};
        const element = data[key];


if (typeof this.to_string == "string") {
   option.text = element[this.to_string];
  
} else {
     let txt=
                
                
                  element[this.to_string[0]] +
                  ' (' +element[this.to_string[1]] +')'

                  if (element[this.to_string[2]]!=undefined&&element[this.to_string[2]]!=""&&element[this.to_string[2]]!=null) {
                    txt=txt+' / '+ element[this.to_string[2]]+"dh"
                  }


option.text=txt
  
}

       




        option.value = element["@id"];
        if (option.value == this.values_binds) {
          this.value = option;
        }
        items.push(option);
      }

      return items;
    },
  },
  mounted() {
    this.$store.dispatch("initialize_struct_from", {
      names_controllers: [this.from],
    });
  },
};
</script>