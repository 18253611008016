<template>
  <v-card :loading="loading" class="mx-auto my-12" max-width="500" v-show="show" 
  
  >
   <v-alert type="error" v-if="status=='remove'">
     files is  remove
    </v-alert>

    <!-- <v-img
      v-if="['image/gif', 'image/jpeg', 'image/png', 'image/tiff', 'image/vnd.microsoft.icon', 'image/vnd.djvu'].includes(type)"
      height="350"
      :src="minifile"
      lazy-src="https://picsum.photos/id/11/10/6"
      aspect-ratio="1"
      class="grey lighten-2"
      max-height="350"
    >
 <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
        </v-row>
      </template>
    </v-img> -->

<readfile-imagecoollightbox
 v-if="[ 'image/avif', 'image/jpg','image/gif', 'image/jpeg', 'image/png', 'image/tiff', 'image/vnd.microsoft.icon', 'image/vnd.djvu'].includes(type)"
      :images="[minifile]"
      size="400px"
 style="margin: 0 auto"
    ></readfile-imagecoollightbox>


    <v-icon color="primary" size="100px" v-else-if="type == 'application/pdf'"> mdi-pdf-box </v-icon>
    <v-icon color="primary" size="100px" v-else> mdi-file-document-outline </v-icon>


    <v-card-title   >{{ controller.toUpperCase() }}



    </v-card-title>

   
    <v-card-subtitle >{{ name }}</v-card-subtitle>

    <v-card-text >
      <v-row align="center" class="mx-0">
        <v-rating :value="size * 5" color="amber" dense half-increments readonly size="14"></v-rating>
        <div class="grey--text ml-4">{{ size }} (MB)</div>
      </v-row>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>
    <v-card-text>
      <v-chip-group v-model="selection" active-class="deep-purple accent-4 white--text" column>
        <v-chip>{{ controller.toUpperCase() }}</v-chip>
        <v-chip>{{ size }} MB</v-chip>

        <v-chip>{{ type }}</v-chip>
        

        <v-chip>{{ name }}</v-chip>
        <v-chip>{{ date_save }}</v-chip>
        <v-chip>{{ time_save }}</v-chip>
        <v-chip>{{ id }}</v-chip>

        <v-chip>
          <router-link
            :to="{
              name: this.controller + '_show_item',
              params: { id: id },
            }"
            >{{ controller }}/{{ id }}</router-link
          >
        </v-chip>
      </v-chip-group>
    </v-card-text>

    <v-card-actions>
      <v-btn color="deep-purple accent-4" text @click="dowlaod(pathDownload)">
        telecharger
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn v-if="status=='remove'" color="red accent-4" text @click="remove(idfile)">
        vide corbeille
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: {
    file: {},
  },
  data() {
    return {
      loading: false,
      selection: 0,
      show: true,
    };
  },
  methods: {
    dowlaod(src_file) {
      var a = document.createElement("a");
      a.href = src_file;
      a.download = "" + this.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    remove(idfile) {
      this.$store.dispatch("files/remove_file_by_id", {
        idfile: idfile,

        fun_finally: (info) => {
          if (info["success"]) {
            this.show = false;
          }

          this.show = false;
          console.log(info);
        },
      });
    },
  },
  computed: {
     Base_url() {
      return this.$store.getters["get_Base_url"];
    },
    name() {
      return this.file.originalName;
    },
    date_save() {
      let date = this.file.dateTimeUpload.split("-");

      let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

      let day = date[2];
      let monthIndex = date[1] - 1;
      let year = date[0];

      return day + " " + monthNames[monthIndex] + " " + year;
    },
    time_save() {
      return this.file.dateTimeUpload_time;
    },
    controller() {
      return this.file.controller;
    },
    id() {
      return this.file.idController;
    },
    size() {
      return ((this.file.size * 1) / 1024 / 1024).toFixed(2); //octe => ko =>mb
    },
    type() {
      return this.file.type;
    },
    pathDownload() {
      return this.Base_url+this.file.pathDownload;
    },
    minifile() {
      return this.Base_url+this.file.minifile;
    },
    idfile() {
      return this.file.id;
    },
    status(){
      return this.file.status;
    },
  },
};
</script>
