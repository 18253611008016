module.exports = {
  id: { typeHtml: "hidden" },
  modepaiement: { typeHtml: "text", unique: true },
  note: { typeHtml: "Textarea" },

  updatedAt: { typeHtml: "hidden" },
  createdAt: { typeHtml: "hidden" },

  user: { typeHtml: "hidden" , to_string: "identifiant"},
};
