import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Components
import '@/components'
import '@/widgets'

import { Capacitor } from '@capacitor/core'
import { CapacitorSQLite, SQLiteConnection } from '@capacitor-community/sqlite'

import {
  defineCustomElements as jeepSqlite,
  applyPolyfills,
} from 'jeep-sqlite/loader'

import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'

///Vue.config.productionTip = false

import 'animate.css'
applyPolyfills().then(() => {
  jeepSqlite(window)
})

window.addEventListener('DOMContentLoaded', async () => {
  const platform = Capacitor.getPlatform()
  const sqlite = new SQLiteConnection(CapacitorSQLite)

  ///const app = createApp(App).use(router);

  try {
    if (platform === 'web') {
      // Create the 'jeep-sqlite' Stencil component
      const jeepSqlite = document.createElement('jeep-sqlite')
      document.body.appendChild(jeepSqlite)
      await customElements.whenDefined('jeep-sqlite')
      // Initialize the Web store
      await sqlite.initWebStore()
    }
    const ret = await sqlite.checkConnectionsConsistency()

    const isConn = (await sqlite.isConnection('db_tab3')).result

    let db
    if (ret.result && isConn) {
      db = await sqlite.retrieveConnection('db_tab3')
    } else {
      db = await sqlite.createConnection('db_tab3', false, 'no-encryption', 1)
    }

    await db.open()

    window.awa_vue = new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: (h) => h(App),
    }).$mount('#app')
  } catch (err) {
    throw new Error(`Error: ${err}`)
  }
})
