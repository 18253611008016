<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-expansion-panels focusable inset>
          <v-row>
            <v-col
              ><h3>{{ label }}</h3></v-col
            ></v-row
          >

          <v-expansion-panel v-for="(label_item, i) in labels" :key="i">
            <v-expansion-panel-header>
              <h4
                v-if="selected.length > 1"
                :class="
                  is_show_items_Group_Choice(LabelShow(label_item))
                    ? 'primary--text'
                    : 'pink--text'
                "
              >
                {{ labelUpperCase($t(LabelShow(label_item))) + " : " + label }}
              </h4>

              <h5 v-else>
                {{ labelUpperCase($t(LabelShow(label_item))) + " : " + label }}
              </h5>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-col cols="12">
                <v-switch
                  @change="modelbind($event)"
                  :value="LabelShow(label_item)"
                  :label="
                    labelUpperCase($t(LabelShow(label_item))) + ' : ' + label
                  "
                  v-model="selected"
                  color="pink"
                  dense
                >
                </v-switch>
              </v-col>
              <v-col cols="12">
                <v-card
                  v-show="
                    insertLabels.length > 0 &&
                    is_show_items_Group_Choice(LabelShow(label_item))
                  "
                >
                  <v-card-text
                    v-for="(insertLabels_item, a) in insertLabels"
                    :key="a"
                  >
                    <v-row>
                      <v-col cols="4">
                        <h5>
                          {{
                            labelUpperCase($t(LabelShow(label_item))) +
                            " : " +
                            $t(insertLabelsshow(insertLabels_item))
                          }}
                        </h5>
                      </v-col>

                      <v-col
                        cols="4"
                        v-for="(perme, b) in fromLabels(label_item)"
                        :key="b"
                      >
                        <v-checkbox
                          dense
                          @change="modelbind($event)"
                          :value="
                            LabelShow(label_item) +
                            '>' +
                            perme +
                            '|' +
                            insertLabelsshow(insertLabels_item)
                          "
                          v-model="selected"
                          :messages="labelUpperCase($t(perme))"
                        >
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import InputMixin from "./InputMixin";
import i18n from "@/plugins/i18n";
export default {
  mixins: [InputMixin],
  data() {
    return {
      selected: [],
    };
  },
  computed: {
    labels() {
      let groups_Choices = this.group_Choice.split("--");
      return groups_Choices;
    },
    insertLabels() {
      let items_Group_Choice = this.items_Group_Choice.split("--");
      if (items_Group_Choice.length < 2) {
        return [];
      }

      return items_Group_Choice;
    },

    group_Choice() {
      return this.schema["group_Choice"];
    },
    items_Group_Choice() {
      return this.schema["items_Group_Choice"];
    },
  },
  mounted() {
    this.selected = this.values_binds.split(",");
  },
  methods: {
    modelbind(val) {
      this.val = val;

      this.$store.commit("structure/set_input_binds", {
        name: this.name,
        value: this.selected.join(","),
        controller_root: this.controller_root,
      });
    },
    is_show_items_Group_Choice(label_item) {
      return !this.selected.includes(label_item);
    },

    fromLabels(label) {
      let l = label.split("|");
      console.log(l);

      if (l.length < 2) {
        return [" "];
      }

      return l[1].split(",");
    },

    insertLabelsshow(insertLabels_item) {
      let from_Group_Choice = insertLabels_item.split("|");
      return from_Group_Choice[0];
    },
    LabelShow(label) {
      let l = label.split("|");
      return l[0];
    },
  },
};
</script>
