<template>
  <card-card
    :icon="icon"
    :avatar="avatar"
   
   
    class="v-card--material-stats"
    v-bind="$attrs"
    v-on="$listeners"
    :color="color"
  >

    <template v-slot:after-heading>
      <div class="ml-auto text-right">
        <div class="body-3 grey--text font-weight-light" v-text="title" />

        <h3 class="  font-weight-light text--primary">
          {{ value }}
          <small class>{{ smallValue }}</small>
          <br>
          <small class v-if="value2"><strong>{{ value2 }}</strong>  (nd)  </small>
        </h3>
         
      </div>
    </template>




    <v-col cols="12" class="px-0">
      <v-divider />
    </v-col>

    

    <v-icon :color="subIconColor" size="16" class="ml-2 mr-1">{{ subIcon }}</v-icon>



    <v-tooltip top v-if="tooltip">
      <template v-slot:activator="{ on }">
        <span
          v-on="on"
          :class="subTextColor"
          class="caption grey--text font-weight-light"
          v-text="subText"
        />
      </template>
      <span>{{tooltip}}</span>
    </v-tooltip>

    <span
      v-else
      :class="subTextColor"
      class="caption grey--text font-weight-light"
      v-text="subText"
    />
  </card-card>
</template>

<script>
import Card from "./Card";

export default {
  name: "CardStatsCard",

  inheritAttrs: false,

  props: {
    ...Card.props,
    
    icon: {
      type: String,
      required: true,
    },
    subIcon: {
      type: String,
      default: undefined,
    },
    subIconColor: {
      type: String,
      default: undefined,
    },
    subTextColor: {
      type: String,
      default: undefined,
    },
    subText: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    value: {
      
      default: undefined,
    },
    smallValue: {
      type: String,
      default: undefined,
    },
     value2: {
     
      default: undefined,
    },
    tooltip: {
      type: String,
      default: undefined,
    },
  },
};
</script>

<style  scoped>
.v-card--material-stats {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.v-card--material-stats div:first-child {
  justify-content: space-between;
}

.v-card--material-stats .v-card {
  border-radius: 4px;
  flex: 0 1 auto;
}

.v-card--material-stats .v-card__text {
  display: inline-block;
  flex: 1 0 calc(100% - 120px);
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}

.v-card--material-stats .v-card__actions {
  flex: 1 0 100%;
}

</style>
