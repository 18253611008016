<template>
  <card-card
    :close="close"
    @close="closeevent"
    icon="mdi-chart-bar"
    color="primary"
    :title="$t('Factures fournisseurs par mois')"
    class="px-5 py-3"
  >
    <v-col cols="12" lg="12">
      <v-row>
        <v-col cols="12">
          <v-row class="justify-space-around align-center">
            <v-col cols="5" sm="5" md="5">
              <v-radio-group v-model="statut" row>
                <v-radio label="Factures  valide" value="valide"> </v-radio>

                <v-radio label="Facture non declarée" value="nd"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="5" sm="5" md="5">
              <v-text-field
                type="number"
                v-model="date"
                :label="$t('date')"
                placeholder="Placeholder"
                append-icon="mdi-refresh"
                @click:append="start"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="justify-center align-center">
            <v-responsive width="100%" style="margin-bottom: 38px">
              <component
                v-if="showchart"
                :is="chart"
                :path-option="option"
                height="450px"
                width="100%"
              ></component>
            </v-responsive>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </card-card>
</template>
<script>
import i18n from "@/plugins/i18n";

import colors from "vuetify/es5/util/colors";
import axios from "axios";

import widgetsMixin from "./../widgetsMixin";
export default {
  mixins: [widgetsMixin],
  data: () => ({
    date: new Date().getFullYear() - 5,
    statut: "valide",

    chart: "",

    isMobile: false,

    showchart: false,

    Graphiques: {
      AvoirsAchats: {},
      FacturesAchats: {},
    },
  }),
  computed: {
    Base_url_web() {
      return this.$store.getters["get_Base_url"];
    },

    listDate() {
      let nowyear = new Date().getFullYear() + 2;
      let item = nowyear - this.date;

      let listyear = [];

      for (let index = 0; index < item; index++) {
        listyear.push(this.date * 1 + index);
      }

      return listyear;
      //return ["2014", "2015", "2016", "2017", "2018", "2019", "2020", "2021"]
    },

    option() {
      return {
        baseOption: {
          toolbox: {
            show: true,
            feature: {
              mark: { show: false },
              dataView: { show: false, readOnly: false },
              restore: { title: "Save", show: false },
              magicType: {
                title: "line/bare",
                show: true,
                type: ["line", "bar"],
              },

              saveAsImage: { title: "Save", show: true },
            },
          },
          color: [
            colors.amber.base,
            colors.indigo.base,
            colors.pink.base,
            colors.green.base,
            colors.teal.base,
            colors.purple.base,
          ],
          timeline: {
            lineStyle: {
              color: colors.indigo.base,
              shadowColor: "rgba(0, 0, 0, 0.5)",
              shadowBlur: 10,
            },
            itemStyle: {
              color: colors.indigo.base,
              shadowColor: "rgba(0, 0, 0, 0.5)",
              shadowBlur: 10,
            },
            checkpointStyle: {
              color: "#316bf3",
              borderColor: "#fff",
              shadowColor: "rgba(0, 0, 0, 0.5)",
              shadowBlur: 5,
            },
            axisType: "category",
            // realtime: false,
            // loop: false,
            autoPlay: true,
            // currentIndex: 2,
            playInterval: 7000,
            // controlStyle: {
            //     position: 'left'
            // },
            data: this.listDate,
            label: {
              formatter: function (s) {
                return new Date(s).getFullYear();
              },
            },
          },
          title: {
            subtext: " par mois",
          },
          tooltip: {},
          legend: {
            left: "center",
            data: ["AvoirsAchats", "FacturesAchats"],

            selected: {
              AvoirsAchats: true,
              FacturesAchats: true,
            },
          },
          calculable: true,
          grid: {
            top: 80,
            bottom: 100,
          },
          xAxis: [
            {
              data: [
                "",
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ],
              type: "category",
              axisLabel: { interval: 0 },
              splitLine: { show: false },
            },
          ],
          yAxis: [
            {
              type: "value",
              name: "MAD / DH",
            },
          ],
          series: [
            {
              name: "AvoirsAchats",
              type: "bar",
              itemStyle: {
                color: colors.blue.base,
              },

              tooltip: {
                trigger: "item",

                formatter: this.formatter_bar,
              },
            },
            {
              name: "FacturesAchats",
              type: "bar",
              itemStyle: {
                color: colors.orange.base,
              },

              tooltip: {
                trigger: "item",

                formatter: this.formatter_bar,
              },
            },

            {
              name: "AvoirsAchats/FacturesAchats",
              type: "pie",
              center: ["80%", 70],
              radius: [40, 50],
              z: 100,
              tooltip: {
                trigger: "item",

                formatter: this.formatter_pie,
              },
            },
          ],
        },

        options: this.optionbaylistDate,
      };
    },
    optionbaylistDate() {
      let op = [];
      this.listDate.forEach((date) => {
        if (
          this.Graphiques.AvoirsAchats[date] != undefined ||
          this.Graphiques.FacturesAchats[date] != undefined
        ) {
          op.push({
            title: { text: i18n.t("Montant de Factures: ") + date + " " },
            series: [
              { data: this.Graphiques.AvoirsAchats[date] },
              { data: this.Graphiques.FacturesAchats[date] },

              {
                data: [
                  {
                    name: "AvoirsAchats",
                    value: this.Graphiques.AvoirsAchats[date + "sum"],
                    itemStyle: {
                      color: colors.blue.base,
                    },
                  },
                  {
                    name: "FacturesAchats",
                    value: this.Graphiques.FacturesAchats[date + "sum"],
                    itemStyle: {
                      color: colors.orange.base,
                    },
                  },
                ],
              },
            ],
          });
        } else {
          op.push({
            title: { text: i18n.t("Montant de Factures: ") + date + "" },
            series: [
              { data: [] },
              { data: [] },

              {
                data: [
                  {
                    name: "AvoirsAchats",
                    value: 0,
                    itemStyle: {
                      color: colors.blue.base,
                    },
                  },
                  {
                    name: "FacturesAchats",
                    value: 0,
                    itemStyle: {
                      color: colors.orange.base,
                    },
                  },
                ],
              },
            ],
          });
        }
      });
      return op;
    },
  },

  methods: {
    start() {
      this.chart = "theme-hiba";
      let path = this.Base_url_web + "/apidashboardchartfacturefournisseur";

      axios
        .get(path, { params: { date: this.date, statut: this.statut } })
        .then((response) => {
          this.showchart = false;

          this.Graphiques = response.data["Graphiques"];

          this.showchart = true;
          this.chart = "plugins-chart";
          window.dispatchEvent(new Event("optimizedResize"));
        })
        .catch((error) => {
          console.error("path");
        });

      window.dispatchEvent(new Event("optimizedResize"));
    },
    formatter_bar: (params) => {
      console.log(params);
      let seriesName = i18n.t(params["seriesName"]);
      let color = params["color"];
      let value = params["value"];
      let annee = "";
      let mois = "";
      let list = [];
      let listHTML = "<ul style='list-style-type: square'>";

      if (params["data"] != undefined) {
        annee = params["data"]["annee"];
        mois = params["data"]["mois"];
        list = params["data"]["list"];
        value = params["value"].toLocaleString(undefined, {
          minimumFractionDigits: 2,
        });
      }

      list.forEach((element) => {
        listHTML =
          listHTML +
          `<li>

                    id: ` +
          element.reffournisseur +
          `  ` +
          element.titre +
          ` / ` +
          (element.montantHt * 1).toLocaleString(undefined, {
            minimumFractionDigits: 2,
          }) +
          ` TTC  dh 
                    
                    </li>`;
      });

      listHTML = listHTML + `</ul>`;

      return (
        `<table>
        <tr>
        <td valign="bottom">` +
        listHTML +
        `<strong> Total :` +
        seriesName +
        `</strong><br>
                    <span style=" 
                       height: 10px;
                       width: 10px;
                       margin-right:6px;
                      background-color: ` +
        color +
        `;
                      border-radius: 50%;
                      display: inline-block;"></span>` +
        mois +
        `/` +
        annee +
        ` : <strong>` +
        value +
        " </strong>DH <br>" +
        `</td>
        <td>` +
        `</td>
        </tr>
        </table>
        
        `
      );
    },
    formatter_pie: (params) => {
      console.log(params);
      let seriesName = i18n.t(params["seriesName"]);
      let color = params["color"];
      let percent = params["percent"];
      let name = params["name"];

      return (
        `<strong>` +
        seriesName +
        `</strong><br>
                    <span style=" 
                       height: 10px;
                       width: 10px;
                       margin-right:6px;
                      background-color: ` +
        color +
        `;
                      border-radius: 50%;
                      display: inline-block;"></span>` +
        name +
        `/` +
        ` : <strong>` +
        params.value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
        }) +
        " </strong>DH  <br>" +
        percent +
        "%"
      );
    },
  },

  mounted() {
    this.start();
    this.isMobile = window.innerWidth < 600;
  },
  watch: {
    statut() {
      // this.showchart = false;

      this.start();
    },
  },
};
</script>
