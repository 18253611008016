"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  title: function title(state) {
    if (Array.isArray(state.organisation) && state.organisation[0] != undefined) {
      return state.organisation[0]["restaurant"];
    }

    return "application gestion";
  },
  menu: function menu(state) {
    return state.menu;
  },
  organisation: function organisation(state) {
    if (Array.isArray(state.organisation) && state.organisation[0] != undefined) {
      return state.organisation[0];
    }

    return {};
  },
  modules: function modules(state) {
    return state.modules;
  },
  drawer_right: function drawer_right(state) {
    return state.drawer_right;
  },
  drawer_i18n: function drawer_i18n(state) {
    return state.drawer_i18n;
  },
  drawer_left: function drawer_left(state) {
    return state.drawer_left;
  },
  dark: function dark(state) {
    return state.dark;
  },
  count_notification: function count_notification(state) {
    return state.count_notification;
  },
  notification: function notification(state) {
    return state.notification;
  },
  widgets: function widgets(state) {
    // Récupérer des données depuis localStorage
    var widgets = localStorage.getItem("widgets");

    if (widgets == null) {
      localStorage.setItem("widgets", [].join());
    } else {
      state.widgets = widgets.split(",");
    }

    var index = state.widgets.indexOf("");

    if (index > -1) {
      state.widgets.splice(index, 1);
    }

    return state.widgets;
  }
};
exports["default"] = _default;