<template>
  <div>
    <!-- select -->
    <v-autocomplete
      v-if="typeinput == 'select'"
      :clearable="true"
      :label="label"
      @change="modelbind"
      :items="items"
      item-text="text"
      item-value="value"
      :value="value"
      :disabled="disabled"
    >
    </v-autocomplete>

    <!-- date -->
    <div v-if="typeinput == 'date'">
      <v-dialog
        ref="dialog"
        v-model="menu2"
        :return-value.sync="date"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            :value="value"
            :label="label"
            prepend-icon="mdi-calendar"
            readonly
            clearable
            v-bind="attrs"
            v-on="on"
            :disabled="disabled"
          ></v-text-field>
        </template>
        <v-date-picker v-model="date" range scrollable>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="$refs.dialog.save(date)">
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </div>
    <!-- number -->
    <div v-if="typeinput == 'number'">
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :disabled="disabled"
              v-model="margenumbre"
              :value="value"
              :label="label"
              prepend-icon="mdi-matrix"
              readonly
              clearable
              v-bind="attrs"
              v-on="on"
              @click:clear="clearmodelbindnumbre($event)"
            ></v-text-field>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ label }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="min"
                      label="min"
                      type="number"
                      prepend-icon="mdi-matrix"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="max"
                      label="max"
                      type="number"
                      prepend-icon="mdi-matrix"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="blue darken-1" text @click="modelbindnumbre()">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>

    <!--  Text -->
    <div v-if="typeinput == 'text'">
      <v-row justify="center">
        <v-col cols="12">
          <v-text-field
            :label="label"
            clearable
            v-model="textvalue"
            placeholder="recherche"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>

    <!--  checkbox -->
    <div v-if="typeinput == 'checkbox'">
      <v-row justify="center">
        <v-col cols="12">
          <v-checkbox
            v-model="checkboxvalue"
            :label="label"
            false-value="0"
            true-value="1"
          ></v-checkbox>
        </v-col>
      </v-row>
    </div>

    <!--  color -->
    <div v-if="typeinput == 'color'">
      <v-row justify="center">
        <v-col cols="12">
          <v-color-picker
            :label="label"
            dot-size="13"
            hide-canvas
           mode="hexa"
            hide-mode-switch
            hide-sliders
            show-swatches
            swatches-max-height="200"
            v-model="colorvalue"
          ></v-color-picker>
        </v-col>
      </v-row>
    </div>

    <!--  Text -->
    <div v-if="typeinput == 'notsuport'">
      <v-row justify="center"> not suport {{ label }} </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    label: { default: "" },
    name: { default: "" },
    controller_root: { default: "" },
    value: { default: null },
  },

  data: (vm) => ({
    menu2: false,
    textvalue: "",
    colorvalue: "",
    checkboxvalue: null,
    date: "",
    margenumbre: "",
    dialog: false,

    min: 0,
    max: 0,
    data_load: [],
    from: "",
    to_string: "",
  }),

  computed: {
    disabled() {
      return this.notaffiche.indexOf(this.name) != -1;
    },
    notaffiche() {
      return this.$store.getters["show_data/g_not_affiche"]({
        controller_root: this.controller_root,
      });
    },
    show_data_items() {
      return this.$store.getters["show_data/g_items"]({
        controller_root: this.controller_root,
      });
    },
    schemas() {
      return this.$store.getters["show_data/g_schemas"]({
        controller_root: this.controller_root,
      });
    },
    typeinput() {
      let key = this.name;

      let typeHtml = "";
      this.schemas.forEach((element) => {
        if (element["name"] == key) {
          typeHtml = element["typeHtml"];
          this.from = element["from"];
          this.to_string = element["to_string"] ? element["to_string"] : "@id";
        }
      });

      if (
        key == "updatedAt" ||
        key == "createdAt" ||
        key == "date" ||
        typeHtml == "date"
      ) {
        return "date";
      }

      if (
        typeHtml == "money" ||
        typeHtml == "number" ||
        key == "quantite" ||
        key == "stock" ||
        key == "id" ||
        key == "rating"
      ) {
        return "number";
      }

      if (
        typeHtml == "Textarea" ||
        typeHtml == "text" ||
        typeHtml == "tel" ||
        typeHtml == "email" ||
        typeHtml == "password" ||
        typeHtml == "url"
      ) {
        return "text";
      }
      if (typeHtml == "select" || typeHtml == "selectajax" || key == "user") {
        return "select";
      }

      if (typeHtml == "checkbox") {
        return "checkbox";
      }

      if (typeHtml == "color") {
        return "color";
      }

      return "notsuport";
    },

    items() {
      let data = this.data_load;

      let items = [];

      for (const key in data) {
        let option = {};

        const element = data[key];

        option.text = element[this.to_string];

        option.value = element["id"];

        items.push(option);
      }

      return items;
    },
    valueNumbre() {
      let items = [];
      let key = this.name;

      this.show_data_items.forEach((element) => {
        if (Object.hasOwnProperty.call(element, key)) {
          items.push(element[key]);
        }
      });

      return items;
    },
  },

  methods: {
    modelbind(val) {
      this.$emit("change", { value: val, from: this.name });
    },
    modelbind_betwen(val) {
      this.$emit("change", { value: val, from: "_betwen" });
    },

    modelbindnumbre() {
      this.dialog = false;

      this.margenumbre = this.min + "~" + this.max;
      this.modelbind("");

      this.modelbind_betwen(
        this.name + "[between]=" + this.min + ".." + this.max
      );
    },
    clearmodelbindnumbre() {
      this.dialog = false;
      this.min = 0;
      this.max = 10000000;

      this.margenumbre = this.min + "~" + this.max;
      this.modelbind("");
      this.modelbind_betwen("");
    },
  },

  mounted() {
    if (this.typeinput == "number") {
      this.min = Math.min(...this.valueNumbre);
      this.max = Math.max(...this.valueNumbre);
    }
    if (this.typeinput == "select") {
      const url = this.$store.getters["get_dataselect"](this.from);

      let Base_url = this.$store.getters["get_Base_url"];
      let JWTToken = this.$store.getters["get_JWTToken"];

      axios
        .get(Base_url + url, {
          params: {
            itemsPerPage: 
999999
          },
          headers: {
            "Content-Type": "application/ld+json",
            Accept: "application/ld+json",
            Authorization: JWTToken,
          },
        })
        .then((response) => {
          this.data_load = response["data"]["hydra:member"];
        });
    }
  },
  watch: {
    date(val) {
      //createdAt[after]=2018-03-19
      if (val) {
        if (val.length == 2) {
          console.log(val);
          const a = new Date(`${val[0]}T00:00:00`).getTime();
          const b = new Date(`${val[1]}T00:00:00`).getTime();
          if (a < b) {
            this.modelbind_betwen(
              this.name +
                "[after]=" +
                val[0] +
                "&" +
                this.name +
                "[before]=" +
                val[1]
            );
          } else if (a > b) {
            this.modelbind_betwen(
              this.name +
                "[after]=" +
                val[1] +
                "&" +
                this.name +
                "[before]=" +
                val[0]
            );
          } else {
            // this.modelbind_betwen(this.name+'='+val[1]);
          }
        } else {
          // this.modelbind(val.join("&"));
        }
      } else {
        this.modelbind("");
        this.modelbind_betwen("");
      }
    },
    textvalue() {
      setTimeout(() => {
        this.$emit("change", { value: this.textvalue, from: this.name });
      }, 600);
    },
    checkboxvalue() {
      this.$emit("change", { value: this.checkboxvalue, from: this.name });
    },
    colorvalue() {
      if ( this.colorvalue!="") {
         this.$emit("change", { value: this.colorvalue, from: this.name });
      }
     
    },
  },
};
</script>
