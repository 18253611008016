export default {

  set_connected(state, data) {
    state.connected = data
  },
  set_connectionType(state, data) {
    state.connectionType = data
  },

 

  set_username(state, data) {
 
    state.username = data
  },
  set_password(state, data) {
    state.password = data
  },
  set_JWTToken(state, data) {
    state.JWTToken = data
  },


  set_Base_url(state, Base_url) {
    state.Base_url = Base_url;
  },

  set_paths(state, paths) {
    state.paths = paths;
  },
  set_lazy(state, paths) {
    state.lazy = paths;
  },
  set_created(state, paths) {
    state.created = paths;
  },

  set_dataselect(state, paths) {
    state.dataselect = paths;
  },

  set_add(state, paths) {
    state.add = paths;
  },
  set_update(state, paths) {
    state.update = paths;
  },
  set_delete(state, paths) {
    state.delete = paths;
  },

  set_attachs(state, paths) {
    state.attachs = paths;
  },
  set_load(state, paths) {
    state.load = paths;
  },
  set_statistiques(state, paths) {
    state.statistiques = paths;
  },
  set_pdf(state, paths) {
    state.pdf = paths;
  },
  set_email(state, paths) {
    state.email = paths;
  },
  set_fusionner(state, paths) {
    state.fusionner = paths;
  },
  set_importexcel(state, paths) {
    state.importexcel = paths;
  },

  set_gestion_files(state, gestion_files) {
    state.gestion_files = gestion_files;
  }
};
