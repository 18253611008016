export default {
  namescontrollers: [],
  namesmodules: {},
  nameinput_root: {},

  inputes_schemas: {},
  data_Select_load: {},
  inputes_binds: {},
  id: {}, /// pour etat update
  actionTraiter: "", /// pour etat btn Action Traiter

  ////////////
};