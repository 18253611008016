"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  connecte: function connecte(state) {
    return state.connecte;
  },
  user: function user(state) {
    if (state.user.length > 0) {
      return state.user[0];
    }

    return {};
  },
  logo: function logo(state) {
    if (state.user.length > 0) {
      return state.Base_url + state.user[0]["user_log"];
    }

    return "";
  },
  refGroup: function refGroup(state) {
    if (state.user.length > 0) {
      return state.user[0]["refGroup"];
    }

    return "";
  },
  isAdmin: function isAdmin(state) {
    return state.isAdmin;
  },
  isGranted: function isGranted(state) {
    return function (attribute, name_Module_Controller) {
      var item = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (state.isAdmin) {
        return true;
      }

      return false;
    };
  }
};
exports["default"] = _default;