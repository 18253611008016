//let colorconsole="color: red"
let colorconsole = "color: gray"
let colorconsoleyellow = "color: magenta"
//let colorconsole="color: blue"
//let colorconsole="color: magenta"
//let colorconsole="color: cyan"
//let colorconsole="color: gray"
//let colorconsole="color: grey"



export default {
  set_init(state, controllers) {
    console.groupCollapsed("%c  mutation => dialog/set_init", colorconsole)



    let init_dialog = {};

    for (const key in controllers) {
      const param_tables = controllers[key];
      init_dialog[param_tables["controller_root"]] = {
        type: "show",
        flag: false,
        data: {},
      };
    }
    console.table(init_dialog)
    state.controllers = init_dialog;
    console.groupEnd();
  },



  set_dialog_type(state, { controller, type }) {
    console.log("%c  mutation => dialog/set_dialog_type de " + controller + "value" + type, colorconsole)
    state.controllers[controller].type = type;
  },
  set_dialog_data(state, { controller, data }) {
    console.log("%c  mutation => dialog/set_dialog_data de " + controller + "value", data, colorconsole)
    state.controllers[controller].data = data;
  },
  set_dialog_flag(state, { controller, flag }) {
    console.log("%c  mutation => dialog/set_dialog_flag de " + controller + "value", flag, colorconsole)
    state.controllers[controller].flag = flag;
  },


  set_dialog(state, { type, data, flag, controller }) {

    state.controllers[controller].type = type;
    state.controllers[controller].data = data;
    state.controllers[controller].flag = flag;
  },


    set_dialog_btn_send(state, { flag}) {
    console.log("%c  mutation => dialog/set_dialog_btn_send flag de  " + flag )
    state.showbtnsend = flag;
  },
    
};
