<template>
  <v-text-field
    :readonly="readonly"
    @blur="uniqueajax_validation()"
    @focus="init_validation()"
    :rules="rules"
    :label="label"
    :value="values_binds*1"
    @input="modelbind($event)"
    type="number"
    :loading="loading"
  ></v-text-field>
</template>
<script>
import InputMixin from "./InputMixin";
export default {
  mixins: [InputMixin],
  methods: {
    init_validation() {
      this.rules = [this.validation.number];
    },
    modelbind(val) {
      this.val = val+'';

      this.$store.commit("structure/set_input_binds", {
        name: this.name,
        value: val*1,
        controller_root: this.controller_root,
      });
    },
  },
};
</script>