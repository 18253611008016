export default {
  

  connecte: (state) => {
    return state.connecte;
  },
  
  user: (state) => {
    if (state.user.length > 0) {
      return state.user[0];
    }
    return {};
  },

  logo: (state) => {
    if (state.user.length > 0) {

      
      return state.Base_url+state.user[0]["user_log"];
    }
    return "";
  },

  refGroup: (state) => {
    if (state.user.length > 0) {

      
      return state.user[0]["refGroup"];
    }
    return "";
  },
  isAdmin: (state) => {
    return state.isAdmin;
  },
  isGranted: (state) => (attribute, name_Module_Controller, item = {}) => {
    if (state.isAdmin) {
      return true;
    }
  
    return false;
  },
};
