<template>
  <v-col :cols="grid" v-show="typeHtml != 'hidden'">
    <component
      :is="typeVuetify"
      :controller_root="controller_root"
      :input_root="input_root"
      :schema="schema"
    ></component>
  </v-col>
</template>
<script>
export default {
  props: {
    controller_root: String,
    input_root: String,
    typeHtml: String,
    schema: Object,
    grade: String,
  },

  computed: {
    typeVuetify() {
      switch (this.typeHtml) {
        case "Textarea":
          return "box-input-textarea";
          break;

        case "Ckeditor":
          return "box-input-ckeditor";
          break;

        case "select":
          return "box-input-selectsimple";
          break;
        case "selectajax":
          return "box-input-selectsimple";
          break;

        case "file":
          return "box-input-file";
          break;
        case "files":
          return "box-input-files";
          break;
        case "logo":
          return "box-input-logo";
          break;

        case "checkbox":
          return "box-input-checkbox";
          break;
        case "radio":
          return "box-input-radio";
          break;
        case "groupcheckbox":
          return "box-input-groupcheckbox";
          break;
        case "decimal":
          return "box-input-decimal";
          break;
        case "number":
          return "box-input-number";
          break;
        case "money":
          return "box-input-money";
          break;
        case "hidden":
          return "box-input-hidden";
          break;
        case "date":
          return "box-input-date";
          break;
        case "DateMonth":
          return "box-input-datemonth";
          break;

        case "tel":
          return "box-input-tel";
          break;
        case "fax":
          return "box-input-fax";
          break;
        case "email":
          return "box-input-email";
          break;

        case "url":
          return "box-input-url";
          break;

        case "addlist":
          return "box-input-empty";
          break;
        case "multiselect":
          return "box-input-empty";
          break;

        case "password":
          return "box-input-password";
          break;
        case "color":
          return "box-input-color";
          break;
        case "rating":
          return "box-input-rating";
          break;
        default:
          return "box-input-text";
          break;
      }
    },
    grid() {
      if (this.grade == "master") {
        return "8 ";
      }
      if (this.grade == "slave") {
        return "4 ";
      }
      return "12";
    },
  },
};
</script>
