"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _router = _interopRequireDefault(require("@/router"));

var _axios = _interopRequireDefault(require("axios"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = {
  send_files: function send_files(_ref, _ref2) {
    var commit = _ref.commit,
        dispatch = _ref.dispatch,
        getters = _ref.getters,
        rootGetters = _ref.rootGetters,
        rootState = _ref.rootState,
        state = _ref.state;
    var controller_root = _ref2.controller_root,
        files_input = _ref2.files_input,
        name_input = _ref2.name_input,
        fun_progress = _ref2.fun_progress,
        fun_finally = _ref2.fun_finally,
        refGroup = _ref2.refGroup;
    var urlPage = rootGetters['get_gestion_files']['upload'];
    var Base_url = rootGetters['get_Base_url'];
    var formdata = new FormData();
    formdata.append('controller', controller_root);
    formdata.append('name_input', name_input);

    if (refGroup != undefined && refGroup != '' && refGroup != null) {
      formdata.append('refGroup', refGroup);
    }

    var i = 0;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = files_input[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var file_set = _step.value;
        formdata.append(name_input + '[' + i + ']', file_set);
        i++;
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator["return"] != null) {
          _iterator["return"]();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    var JWTToken = rootState.JWTToken;
    var param = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: function onUploadProgress(progressEvent) {
        var etat = Math.round(progressEvent.loaded * 100 / progressEvent.total);
        fun_progress(etat);
      }
    };
    param['headers']['Authorization'] = JWTToken;

    _axios["default"].post(Base_url + urlPage, formdata, param).then(function (response) {
      fun_finally(response.data);
    });
  },

  /* get_files_save(
    { commit, dispatch, getters, rootGetters, rootState, state },
    { refGroup, fun_finally },
  ) {
    let urlPage = '/api/gestion_files' + '?page=1&refGroup=' + refGroup
     let Base_url = rootGetters['get_Base_url']
   
    let JWTToken = rootState.JWTToken
     let param = {
      headers: {
       
      },
    }
   
    param['headers']['Authorization']=JWTToken
     axios
      .get(Base_url + urlPage, param)
      .then((response) => {
        fun_finally(response.data['hydra:member'])
      })
      .catch((response) => {
        console.error(response)
      })
  }, */
  get_files_save: function get_files_save(_ref3, _ref4) {
    var commit = _ref3.commit,
        dispatch = _ref3.dispatch,
        getters = _ref3.getters,
        rootGetters = _ref3.rootGetters,
        rootState = _ref3.rootState,
        state = _ref3.state;
    var refGroup = _ref4.refGroup,
        fun_finally = _ref4.fun_finally;
    var Base_url = rootGetters['get_Base_url'];

    if (RegExp('\\b' + '#path' + '\\b').test(refGroup)) {
      var path = refGroup.split('#path')[1];
      fun_finally([{
        name: path,
        originalName: path,
        pathDownload: path,
        minifile: path
      }]);
    } else {
      var urlPage = '/api/gestion_files' + '?page=1&refGroup=' + refGroup;
      var JWTToken = rootState.JWTToken;

      _axios["default"].get(Base_url + urlPage, {
        headers: {
          Authorization: JWTToken
        }
      }).then(function (response) {
        fun_finally(response.data['hydra:member']);
      })["catch"](function (response) {
        console.error(response);
      });
    }
  }
};
exports["default"] = _default;