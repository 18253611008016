
function splitPermissions(permissionsController, Key) {
  let permissionsControllerKey = {};

  permissionsController.forEach((permissions) => {
    let ControllerPerm = permissions.split("|");

    if (ControllerPerm[1] != undefined && ControllerPerm[1] != "") {
      let nameController = ControllerPerm[1];

      permissionsControllerKey[nameController] = [];
    }
  });

  //// charge permission
  permissionsController.forEach((permissions) => {
    let ControllerPerm = permissions.split("|");

    if (ControllerPerm[1] != undefined && ControllerPerm[1] != "") {
      let nameController = ControllerPerm[1];
      let permissionBy = ControllerPerm[0].split(">");
      if (permissionBy[1] == Key) {
        permissionsControllerKey[nameController].push(permissionBy[0]);
      }
    }
  });

  return permissionsControllerKey;
}





export default {
  set_user(state, user) {
    state.user = user;
  },

  set_Base_url(state, Base_url) {
    state.Base_url = Base_url;
  },
  set_connecte(state, connecte) {
    state.connecte = connecte;
  },
  set_isAdmin(state, isAdmin) {
    state.isAdmin = isAdmin;
  },
 
};
