<template>
  <v-rating
    :label="label + ' '"
    :value="values_binds"
    readonly
    background-color="orange lighten-3"
    color="orange"
  ></v-rating>
</template>
<script>
import InputMixin from "./InputMixin";

export default {
  mixins: [InputMixin],

  methods: {
    init_validation() {
      this.rules = [this.validation.required, this.validation.text];
    },
  },
};
</script>