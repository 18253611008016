<template>
  <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
</template>


<script>
import Material from "vuetify/es5/util/colors";
import interact from "interactjs";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import InputMixin from "./InputMixin";
export default {
  mixins: [InputMixin],
  data: () => ({
    editor: ClassicEditor,

    editorConfig: {
      // The configuration of the editor.
    },
  }),

  mounted() {},
  computed: {
    editorData: {
      get() {
        return this.$store.getters["structure/get_input_binds"]({
          controller_root: this.controller_root,
          name: this.name,
        });
      },
      set(val) {
        this.$store.commit("structure/set_input_binds", {
          name: this.name,
          value: val,
          controller_root: this.controller_root,
        });
      },
    },
  },
};
</script>