<template>
  <v-form ref="form" v-model="valid">
     <v-row class="justify-center">
      <v-col cols="12"
        ><btn-action
          :controller_root="controller_root"
          :item="{ id: id }"
        ></btn-action
      ></v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col :cols="12" :lg="5">
        <card-box
          id="groupa1"
          color="orange"
          :title="$t('show_commandes_infoplat_1')"
          :text="$t('show_commandes_infoplat_2')"
        >
          <template v-slot:chart> </template>

          <show-group
            :names="[
              'code',
              'client',
              'validateur',
              'serveur',
              'valide',
              'dureepreparation',
            ]"
            :controller_root="controller_root"
          />
        </card-box>
      </v-col>
      <v-col :cols="12" :lg="5">
        <card-box
          id="groupa1"
          color="orange"
          :title="$t('show_commandes_infoplat_3')"
          :text="$t('show_commandes_infoplat_4')"
        >
          <template v-slot:chart> </template>

          <show-group
            :names="['modepaiement', 'total', 'especes', 'rendu']"
            :controller_root="controller_root"
          />
        </card-box>
      </v-col>
      <v-col :cols="12" lg="7">
        <card-box
          id="addlist2"
          icon="mdi-file-star-outline"
          :title="$t('show_commandes_infoplat_5')"
          color="success"
        >
          <addlist-table
            :edit_listsAdd="false"
            controller_listsAdd="commandeplats"
          />
        </card-box>
      </v-col>
      <v-col :cols="12" :lg="3">
        <card-box
          id="groupa1"
          color="orange"
          :title="$t('show_commandes_infoplat_6')"
          :text="$t('show_commandes_infoplat_7')"
        >
          <template v-slot:chart> </template>

          <show-group
            :names="['montantHt', 'montantTva', 'montantTtc']"
            :controller_root="controller_root"
          />
        </card-box>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import i18n from "@/plugins/i18n";
import { mapGetters } from "vuex";
import ViewMixin from "@/views/show_item";

export default {
  mixins: [ViewMixin],

  props: {},
  data() {
    return {
      controller_root: "commandes",
      namemodule: "CRM",
      lists_Add: [
        {
          controller_listsAdd: "commandeplats",
        },
      ],
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  created() {
    document.title = i18n.t("show_item_" + this.controller_root);
    this.initia();
    this.run();
  },

  methods: {
    isGranted(controller) {
      return this.$store.getters["security/isGranted"]("view", controller);
    },
  },
};
</script>
