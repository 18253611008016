<template>
  <v-radio-group
    :value="values_binds"
    :label="label"
    @change="modelbind($event)"
    :loading="loading"
    :readonly="readonly"
  >
    <v-radio
      v-for="(label_item, i) in labels"
      :key="i"
      :label="labelUpperCase($t(label_item))"
      :value="label_item"
    ></v-radio>
  </v-radio-group>
</template>
<script>
import InputMixin from "./InputMixin";
import i18n from "@/plugins/i18n";
export default {
  mixins: [InputMixin],
  computed: {
    labels() {
      return this.group_Choice.split("--");
    },
    group_Choice() {
      return this.schema["group_Choice"];
    },
  },
};
</script>
