<template>
  <div>
    <v-list-item v-if="isadmin && connecte && $route.name == '_dashboard'">
      <v-checkbox
        label="widgets"
        v-model="dialog"
        off-icon="mdi-widgets"
        on-icon="mdi-widgets"
        color="white"
      ></v-checkbox>
    </v-list-item>

    <div
      class="text-center"
      v-if="isadmin && connecte && $route.name == '_dashboard'"
    >
      <v-dialog v-model="dialog" min-width="600">
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t("widgets") }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="dialog = false"> Save </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>
            <v-row style="margin-top: 12px">
              <v-col
                cols="12"
                lg="3"
                v-for="(widget, v) in widgets_disponibles"
                :key="'Ekl' + v"
              >
                <v-card
                  :elevation="indexOf_widgets(widget) > 0 ? 12 : 1"
                  class="mx-auto"
                  max-width="344"
                  outlined
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div
                        class="overline mb-4"
                        :color="indexOf_widgets(widget) > 0 ? 'primary' : ''"
                      >
                        <v-icon
                          :color="indexOf_widgets(widget) > 0 ? 'primary' : ''"
                          >mdi-widgets</v-icon
                        >
                        {{ $t("Wg_" + widget) }}
                        {{ "  " + indexOf_widgets(widget) }}
                      </div>
                      <v-list-item-title>
                        {{ $t("Wt_" + widget) }}
                      </v-list-item-title>

                      <v-list-item-subtitle>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">{{
                              $t("Wsubt_" + widget)
                            }}</span>
                          </template>
                          <span>{{ $t("Wsubt_" + widget) }}</span>
                        </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-avatar>
                      <v-checkbox
                        x-large
                        @change="change_widgets($event)"
                        :input-value="affiche_widgets()"
                        label=""
                        :value="widget"
                        off-icon="mdi-eye-off"
                        on-icon="mdi-eye"
                        color="blue darken-3"
                      >
                      </v-checkbox>
                    </v-list-item-avatar>
                  </v-list-item>

                  <v-card-actions> </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>


<script>
import componentsdisponiblesTransactions from "@/widgets/Transactions/componentsNames";
import componentsdisponiblesVentes from "@/widgets/Ventes/componentsNames";
import componentsdisponiblesAchats from "@/widgets/Achats/componentsNames";
import componentsdisponiblesCRM from "@/widgets/CRM/componentsNames";
import componentsdisponiblesProduit from "@/widgets/Produit/componentsNames";
import componentsdisponiblesReglages from "@/widgets/Reglages/componentsNames";
import componentsdisponiblesTrajets from "@/widgets/Trajets/componentsNames";

export default {
  data() {
    return {
      dialog: false,
    };
  },

  methods: {
    affiche_widgets() {
      let affiche_widgets = [];
      affiche_widgets = this.$store.getters["theme/widgets"];
      return affiche_widgets;
    },
    change_widgets(selected) {
      this.$store.commit("theme/set_widgets", selected);
    },

    indexOf_widgets(widget) {
      let affiche_widgets = this.affiche_widgets();

      let index = affiche_widgets.indexOf(widget);
      if (index > -1) {
        return index + 1;
      }
      return 0;
    },
  },

  computed: {
    connecte() {
      return this.$store.getters["security/connecte"];
    },
    isadmin() {
      return this.$store.getters["security/isAdmin"];
    },

    widgets_disponibles() {
      return [].concat(
        componentsdisponiblesTransactions
        // componentsdisponiblesVentes,
        //componentsdisponiblesAchats,
        //componentsdisponiblesCRM,
        //componentsdisponiblesProduit,
        //componentsdisponiblesReglages,
        //componentsdisponiblesTrajets
      );
    },
  },
};
</script>




<style>
</style>