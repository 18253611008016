<template>
  <v-autocomplete :value="values_binds" :items="itemsload" @change="modelbind">
    <template v-slot:item="data">
      <template>
        {{ data.item.text }}
        <v-list-item-avatar>
          <v-icon> {{ data.item.value }}</v-icon>
        </v-list-item-avatar></template
      >
    </template>
  </v-autocomplete>
</template>
<script>
import InputMixin from "./InputMixin";

export default {
  mixins: [InputMixin],
  data() {
    return {
      itemsload: [
        { value: "mdi-food", text: "food" },
        { value: "mdi-food-apple", text: "apple" },
        { value: "mdi-food-drumstick", text: "drumstick" },
        { value: "mdi-baguette", text: "baguette" },
        { value: "mdi-blender", text: "blender" },
        { value: "mdi-bottle-soda", text: "soda" },
        { value: "mdi-bowl", text: "bowl" },
        { value: "mdi-cake", text: "cake" },
        { value: "mdi-candy", text: "candy" },
        { value: "mdi-cheese", text: "cheese" },
        { value: "mdi-chili-hot", text: "chili" },
        { value: "mdi-coffee", text: "coffee" },
        { value: "mdi-cookie", text: "cookie" },
        { value: "mdi-corn", text: "corn" },
        { value: "mdi-cupcake", text: "cupcake" },
        { value: "mdi-egg-fried", text: "fried" },
        { value: "mdi-fish", text: "fish" },
        { value: "mdi-food-steak", text: "steak" },
        { value: "mdi-fruit-cherries", text: "cherries" },
        { value: "mdi-ice-cream", text: "ice-cream" },
        { value: "mdi-pizza", text: "pizza" },
        { value: "mdi-rice", text: "rice" },
        { value: "mdi-tea", text: "tea" },

        { value: "mdi-hamburger", text: "hamburger" },
        { value: "mdi-food-fork-drink", text: "drink" },
      ],
    };
  },

  methods: {},
  
  computed: {
    values_binds() {
      return this.$store.getters["structure/get_input_binds"]({
        controller_root: this.controller_root,
        name: this.name,
      });
    },
  },
};
</script>