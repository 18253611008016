
//let colorconsole="color: red"
//let colorconsole="color: green"
//let colorconsole="color: yellow"
let colorconsole = "color: blue"
//let colorconsole="color: magenta"
//let colorconsole="color: cyan"
//let colorconsole="color: gray"
//let colorconsole="color: grey"


export default {
  set_init(state, param_tables) {
   
    // principal
    let namescontrollers = [];
    let namesmodules = {};

    // les options
    let titles = {}; // of string
    let not_affiches = {}; // of array
    let expandeds = {}; // of array

    // start component
    let search = {};
    let schemas = {};
    let headers = {};
    let items = {};
    let options = {};

  let CurrentPageNumber = {};
    let ItemNumberPerPage = {};
      let TotalItemCount = {};


    let selected = {};
    let item_key = {};
    let page = {};
    let pageCount = {};
    let itemsPerPage = {};

    for (const index in param_tables) {
      const controller_root = param_tables[index].controller_root;

      namescontrollers.push(controller_root);
      namesmodules[controller_root] = param_tables[index]["namemodule"];

      // les options
      const options_showData = param_tables[index]["options_showData"];
      titles[controller_root] = options_showData.title || "";

      expandeds[controller_root] = options_showData.expanded || [];

      for (const key in expandeds[controller_root]) {
        let controller_expanded =
          expandeds[controller_root][key]["controller_attach"];
        // Récupérer des données depuis localStorage
        let not_affiches_sessionStorage_expandeds = localStorage.getItem(
          controller_root + controller_expanded
        );

        if (not_affiches_sessionStorage_expandeds == null) {
          localStorage.setItem(
            controller_root + controller_expanded,
            expandeds[controller_root][key]["not_affiche"].join()
          );
        } else {
          expandeds[controller_root][key][
            "not_affiche"
          ] = not_affiches_sessionStorage_expandeds.split(",");
        }
      }

      not_affiches[controller_root] = options_showData.not_affiche;

      // Récupérer des données depuis localStorage
      let not_affiches_sessionStorage = localStorage.getItem(controller_root);
      if (not_affiches[controller_root]) {
        if (not_affiches_sessionStorage == null) {
          localStorage.setItem(
            controller_root,
            not_affiches[controller_root].join()
          );
        } else {
          not_affiches[controller_root] = not_affiches_sessionStorage.split(
            ","
          );
        }
      }

      search[controller_root] = "";
      schemas[controller_root] = [];

      headers[controller_root] = [];
      items[controller_root] = [];
      options[controller_root] = [];
       CurrentPageNumber[controller_root] = 0;
        ItemNumberPerPage[controller_root] = 0;
         TotalItemCount[controller_root] = 0;
      selected[controller_root] = [];
      item_key[controller_root] = "id";
      page[controller_root] = 1;
      pageCount[controller_root] = 1;
      itemsPerPage[controller_root] = 10;
    }

    state["namesmodules"] = namesmodules;
    state["namescontrollers"] = namescontrollers;

    state["titles"] = titles;
    state["not_affiches"] = not_affiches;
    state["expandeds"] = expandeds;

    state["search"] = search;
    state["schemas"] = schemas;
    state["headers"] = headers;
    state["items"] = items;
    state["options"] = options;
     state["CurrentPageNumber"] = CurrentPageNumber;
      state["ItemNumberPerPage"] = ItemNumberPerPage;
       state["TotalItemCount"] = TotalItemCount;

    state["selected"] = selected;
    state["item_key"] = item_key;
    state["page"] = page;
    state["pageCount"] = pageCount;
    state["itemsPerPage"] = itemsPerPage;
  },

  set_schemas(state, { data, controller_root }) {
    
    let a = state.schemas;
    if (a == undefined) {
      state.schemas = { controller_root: [] };
    }
    state.schemas[controller_root] = [].concat(data);
  },

  set_headers(state, { data, controller_root }) {
   
    let a = state.headers;
    if (a == undefined) {
      state.headers = { controller_root: [] };
    }
    state.headers[controller_root] = [].concat(data);
  },
  set_items(state, { data, controller_root }) {
  
    let a = state.items;
    if (a == undefined) {
      state.items = { controller_root: [] };
    }
    
    state.items[controller_root] = [].concat(data);
  },

  set_options(state, { data, controller_root }) {
  
    let a = state.options;
    if (a == undefined) {
      state.options = { controller_root: [] };
    }

    state.options[controller_root] = [].concat(data);
  },

  set_CurrentPageNumber(state, { data, controller_root }) {
   
    let a = state.CurrentPageNumber;
    if (a == undefined) {
      state.CurrentPageNumber = { controller_root: [] };
    }

    state.CurrentPageNumber[controller_root] = data;
  },

  set_ItemNumberPerPage(state, { data, controller_root }) {
   
    let a = state.ItemNumberPerPage;
    if (a == undefined) {
      state.ItemNumberPerPage = { controller_root: [] };
    }

    state.ItemNumberPerPage[controller_root] = data;
  },
  set_TotalItemCount(state, { data, controller_root }) {
 
    let a = state.TotalItemCount;
    if (a == undefined) {
      state.TotalItemCount = { controller_root: [] };
    }

    state.TotalItemCount[controller_root] = data;
  },

  set_search(state, { data, controller_root }) {
    state.search[controller_root] = data;
  },

  push_headers(state, { data, controller_root }) {
    let a = state.headers;
    if (a == undefined) {
      state.headers = { controller_root: [] };
    }
    state.headers[controller_root] = state.headers[controller_root].concat(
      data
    );
  },
  push_items(state, { data, controller_root }) {
    let a = state.items;
    if (a == undefined) {
      state.items = { controller_root: [] };
    }

    state.items[controller_root] = state.items[controller_root].concat(data);
  },

  set_not_affiches(state, { data, controller_root }) {
    // Enregistrer des données dans localStorage
    localStorage.setItem(controller_root, data.join());

    state.not_affiches[controller_root] = data;
  },

  set_not_affiches_expandeds(
    state,
    { data, controller_root, controller_expanded }
  ) {
    state.not_affiches[controller_root] = data;

    let expanded = state.expandeds[controller_root];

    for (const key in expanded) {
      if (expanded[key]["controller_attach"] == controller_expanded) {
      
        // Enregistrer des données dans localStorage
        localStorage.setItem(
          controller_root + controller_expanded,
          data.join()
        );
        expanded[key]["not_affiche"] = data;
      }
    }
  },

  set_selected(state, { data, controller_root }) {
    state.selected[controller_root] = data;
  },
  set_item_key(state, { data, controller_root }) {
    state.item_key[controller_root] = data;
  },

  set_page(state, { data, controller_root }) {
    state.page[controller_root] = data;
  },
  set_pageCount(state, { data, controller_root }) {
    state.pageCount[controller_root] = data;
  },
  set_itemsPerPage(state, { data, controller_root }) {
    state.itemsPerPage[controller_root] = data;
  },
};
