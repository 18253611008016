<template>
<v-card :width="width" :height="height">
    <div >
    <div class="dots" >
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>

    <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
      <defs>
        <filter id="goo">
          <feGaussianBlur in="SourceGraphic" stdDeviation="12" result="blur" />
          <feColorMatrix
            in="blur"
            mode="matrix"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
            result="goo"
          />
          <feBlend in="SourceGraphic" in2="goo" />
        </filter>
      </defs>
    </svg>
  </div>
</v-card>
</template>

<script>
export default {

  name: "theme-hiba",
   props: {
    // args of  ECharts.init(dom, theme, opts)
    width: { type: String, default: "auto" },
    height: { type: String, default: "400px" },}
}
</script>



<style lang="scss" scoped>
$dot-color: #79c3cd;
$dot-size: 35px;
$circle-size: 70px;
$animation-time: 4s;

$color-yellow: #fbd301;
$color-red: #ff3270;
$color-blue: #208bf1;
$color-green: #afe102;

$colors: ($color-red, $color-blue, $color-green, $color-yellow);

body {
  background: #ffffff;
}

.dots {
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;

  filter: url(#goo);
}

.dot {
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;

  &:before {
    content: "";
    width: $dot-size;
    height: $dot-size;
    border-radius: 50px;
    background: $color-yellow;
    position: absolute;
    left: 50%;
    transform: translateY(0) rotate(0deg);
    margin-left: -($dot-size/2);
    margin-top: -($dot-size/2);
  }

  @keyframes dot-move {
    0% {
      transform: translateY(0);
    }

    18%,
    22% {
      transform: translateY(-($circle-size));
    }

    40%,
    100% {
      transform: translateY(0);
    }
  }

  @keyframes dot-colors {
    @for $i from 0 to 4 {
      #{$i*25%} {
        background-color: nth($colors, ($i + 3) %4 + 1);
      }
    }

    100% {
      background-color: nth($colors, 4);
    }
  }

  &:nth-child(5):before {
    z-index: 100;
    width: $dot-size * 1.3;
    height: $dot-size * 1.3;
    margin-left: -($dot-size * 0.65);
    margin-top: -($dot-size * 0.65);
    animation: dot-colors $animation-time ease infinite;
  }

  @for $i from 0 to 4 {
    @keyframes dot-rotate-#{$i + 1} {
      0% {
        transform: rotate(#{($i + 1) * 270deg - 375deg});
      }

      100% {
        transform: rotate(#{($i + 1) * 270deg + 0deg});
      }
    }

    &:nth-child(#{$i + 1}) {
      animation: dot-rotate-#{$i +
        1}
        $animation-time
        #{$i *
        $animation-time /
        4}
        linear
        infinite;

      &:before {
        background-color: nth($colors, $i + 1);
        animation: dot-move
          $animation-time
          #{$i *
          $animation-time/4}
          ease
          infinite;
      }
    }
  }
}
</style>

