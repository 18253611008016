<template>
  <div>
    <!-- form -->

    <v-row>
      <v-combobox
        :label="label + '    '"
        @change="selected($event)"
        @click:clear="items_selected_info = ''"
        v-model="value"
        :items="items"
        item-text="text"
        item-value="value"
        :filter="filter"
        hide-selected
        :clearable="!readonly"
        :readonly="readonly"
        :disabled="!edit_root"
      >
        <template v-slot:append-outer>
          <v-btn
            text
            icon
            color="indigo"
            :to="{ name: input_rootsecond + '_add' }"
            v-show="
              !readonly && isGranted('add', input_rootsecond.toLowerCase())
            "
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
      </v-combobox>
    </v-row>

    <!-- /.form -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import i18n from "@/plugins/i18n";
export default {
  props: {
    controller_root: {
      type: String,
      required: true,
    },

    input_root: {
      type: String,
      required: true,
    },
    input_rootsecond: {
      type: String,
      required: true,
    },

    info: {
      type: Boolean,
      default: false,
    },
    edit_root: {
      type: Boolean,
      default: true,
    },
    info_title: {
      type: String,
      default: "",
    },
    info_subtitle: {
      type: String,
      default: "",
    },
    info_text: {
      type: String,
      default: "",
    },
    info_image: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      value: undefined,

      items_selected_info: "",
      file_logo: "",
    };
  },
  computed: {
    label() {
      return i18n.t(this.input_rootsecond);
    },

    items() {
      let data = this.data_load;

      let items = [];

      for (const key in data) {
        let option = {};

        const element = data[key];

        option.text = element["toString"];
        option.value = element["id"];
        if (option.value == this.values_binds) {
          this.value = option;
        }
        items.push(option);
      }

      return items;
    },

    data_load() {
      return this.$store.getters["structure/get_data_Select_load"]({
        controller_root: this.controller_root,
        nameManyToOne: this.input_rootsecond,
      });
    },
    values_binds() {
      return this.$store.getters["structure/get_input_binds"]({
        controller_root: this.controller_root,
        name: this.input_rootsecond,
      });
    },
    values_binds_root() {
      return this.$store.getters["structure/get_input_binds"]({
        controller_root: this.controller_root,
        name: this.input_root,
      });
    },

    readonly() {
      return false;
    },
  },
  watch: {
    value(val) {
      if (val != undefined) {
        this.selected_info(val.value);
      } else {
        this.items_selected_info = "";
      }
    },
  },

  methods: {
    isGranted(attribute, controller) {
      return this.$store.getters["security/isGranted"](attribute, controller);
    },
    selected(val_selected) {
      let value;

      if (val_selected != undefined) {
        value = val_selected["value"];
      }

      this.$store.commit("structure/set_input_binds", {
        name: this.input_rootsecond,
        value: value,
        controller_root: this.controller_root,
      });

      let event = {
        name: this.input_root,
        value: this.values_binds_root,
        controller_root: this.controller_root,
        name2: this.input_rootsecond,
        value2: value,
      };

      console.log("change root event => ", event);
      this.$store.dispatch("structure/event_root_change", { event });
    },

    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      let res =
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1;

      return res;
    },
    selected_info(val_selected) {
      let data = this.data_load;
      this.file_logo = "";
      for (const key in data) {
        const element = data[key];
        if (element["id"] == val_selected) {
          this.items_selected_info = element;
        }
      }
    },
  },
};
</script>

<style></style>
