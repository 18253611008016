<template>
 <v-list-item>
      <v-list-item-icon>
          <v-icon color="blue">mdi-check-all</v-icon>
        </v-list-item-icon>
    <v-list-item-content>
      <!-- <v-list-item-title>{{ label }}  : </v-list-item-title> -->
      <h2 color="blue" >{{ $t(values_binds) }}</h2>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
import InputMixin from "./InputMixin";

export default {
  mixins: [InputMixin],
  


};
</script>
