<template>
  <div>
    <v-expansion-panels multiple :value="show_listsAdd_default">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-row align="center" class="spacer" no-gutters>
            <v-col cols="4" sm="2" md="1">
              <v-avatar size="36px">
                <v-icon color="orange" v-text="'mdi-package-down'"></v-icon>
              </v-avatar>
            </v-col>

            <v-col cols="7">
              <v-toolbar-title
                >{{ $t(controller_listsAdd) }}
                <span v-if="rows.length" class="grey--text">
                  &nbsp;({{ rows.length }})
                </span>
              </v-toolbar-title>
            </v-col>
          </v-row>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-chip-group
            v-if="edit_listsAdd"
            v-model="selected_item"
            column
            multiple
            @change="change()"
          >
            <v-chip
              filter
              outlined
              v-for="(val, indexrow) in dataselect"
              :key="indexrow"
              :value="val['@id']"
            >
              {{ val[schema_item_listAdd.to_string] }}
            </v-chip>
          </v-chip-group>

          <div v-else>
            <span
              v-for="(val, indexrow) in dataselect"
              :key="indexrow"
              :value="val['@id']"
            >
              <v-chip
                v-if="selected_item.includes(val['@id'])"
                color="orange"
                dark
                class="ma-2"
              >
                {{ val[schema_item_listAdd.to_string] }}
              </v-chip>
            </span>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>


<script>
import i18n from "@/plugins/i18n";
import api_config from "@/#_CONFIG/Entity";
export default {
  props: {
    controller_listsAdd: {
      type: String,
      required: true,
    },
    item_listsAdd: {
      type: String,
      required: true,
    },

    edit_listsAdd: {
      type: Boolean,
      default: true,
    },

    show_listsAdd: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    schema_item_listAdd() {
      let schema = {};
      let Entity = api_config[this.controller_listsAdd].schema;
      Entity.forEach((s) => {
        if (s.name == this.item_listsAdd) {
          schema = s;
        }
      });
      console.log(schema);

      return schema;
    },
    dataselect() {
      let data =
        this.$store.getters["structure/addlist/data_Select_load"][
          this.controller_listsAdd
        ][this.schema_item_listAdd.from];

      return data;
    },

    show_listsAdd_default() {
      if (this.show_listsAdd) {
        return [0];
      } else {
        return [];
      }
    },

    rows() {
      return this.$store.getters["structure/addlist/rows"][
        this.controller_listsAdd
      ];
    },
  },

  data: () => ({
    editedIndex: -1,
    selected_item: [],

    row: [],
  }),
  watch: {
    rows() {
      let selected = [];
      this.rows.forEach((row) => {
        row.forEach((item) => {
          if (item.from == this.schema_item_listAdd.from) {
            selected.push(item.value);
          }
        });
      });
      this.selected_item = selected;
    },
  },

  methods: {
    change() {
      this.$store.commit("structure/addlist/remove_rows", {
        controller_listsAdd: this.controller_listsAdd,
      });

      this.selected_item.forEach((element) => {
        this.addrow();

        this.row.forEach((item) => {
          if (item.from == this.schema_item_listAdd.from) {
            item.value = element;
          }
        });
      });
    },
    row_clone() {
      const schemehtml =
        this.$store.getters["structure/addlist/schemehtml"][
          this.controller_listsAdd
        ];
      return JSON.parse(JSON.stringify(schemehtml));
    },

    addrow() {
      let newrow = this.row_clone();

      this.$store.commit("structure/addlist/add_row", {
        controller_listsAdd: this.controller_listsAdd,
        row: newrow,
      });
      this.editedIndex = this.rows.length - 1;

      this.row = newrow;
    },
  },
};
</script>
