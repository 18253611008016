import { mapGetters } from "vuex";

export default {
  data: function() {
    return {
      valid: true,
      controller_root: "",
      namemodule: "",

      input_root: "",
      lists_Add: [
        /*{controller_listsAdd: "",
     controller_listsLot: "",field_attach_to: {},field_event_to: {},calcules: {}}*/
      ],
      multiselect: [
        /* {
              controller_multiselect:    ["bons$achats","commandes"]  , 
              controller_listsAdd_attach:  "r_bons$achats_produits",
              options_showData:{
                        title: "",
                        pieceID: "commandes",
                        not_affiche: [],
 
                        expanded: {
                             
                             title: "detail ..",
                             controller_expanded: "produits ..", 
                             not_affiche: [],
 
                                 },
            }
         
        },*/
      ],
      options_showData: { title: "", not_affiche: [], expanded: [] },
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },

  methods: {
    initia() {
      this.$store.dispatch("initialize_view", {
        controllers: [
          {
            controller_root: this.controller_root,
            namemodule: this.namemodule,
            input_root: this.input_root,

            lists_Add: this.lists_Add,
            multiselect: this.multiselect,
            options_showData: this.options_showData,
          },
        ],
      });
    },

    
    run() {
      this.$store.dispatch("custom_fromById", {
        names_controllers: undefined,
        id: this.id,
      });
    },

    val_root() {
      return this.$store.getters["structure/get_input_binds"]({
        controller_root: this.controller_root,
        name: this.input_root,
      });
    },
    sum_value_array(array) {
      // var array = [1, 2, 3, 4, 5];

      // Getting sum of numbers
      let sum = array.reduce(function(a, b) {
        return a * 1 + b * 1;
      }, 0);

      //console.log(sum); // Prints: 15

      return sum.toFixed(3);
    },
  },
};
