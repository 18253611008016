import i18n from '@/plugins/i18n'
export default {
  g_nametables: (state) => () => {
    return state.nametables
  },
  g_title: (state) => ({ controller_multiselect }) => {
    return state.titles[controller_multiselect]
  },
  g_not_affiche: (state) => ({ controller_multiselect }) => {
    return state.not_affiches[controller_multiselect]
  },

  g_controller_listsAdd_attach: (state) => ({ controller_multiselect }) => {
    return state.controller_listsAdd_attach[controller_multiselect]
  },
  g_pieceID: (state) => ({ controller_multiselect }) => {
    return state.pieceID[controller_multiselect]
  },

  g_expanded: (state) => ({ controller_multiselect }) => {
    return state.expandeds[controller_multiselect]
  },
  ///////////////////////////////////////
  //// send data
  namescontroller_multiselect: (state) => ({ controller_root }) => {
    const namescontroller_multiselect = []

    state.namescontroller_multiselect.forEach((controller_multiselect) => {
      if (
        state['controller_root_attach'][controller_multiselect] ==
        controller_root
      ) {
        namescontroller_multiselect.push(controller_multiselect)
      }
    })
    return namescontroller_multiselect
  },
  /// get <object> data set to view (pour send data to server)
  inputes_binds: (state) => ({ controller_multiselect }) => {
    const rows = state.selected[controller_multiselect]
    const data = []
    for (const key in rows) {
      const row = rows[key]
      data.push({ id: row['id'] })
    }

    return data
  },

  /////////////////////////////
  g_search: (state) => ({ controller_multiselect }) => {
    return state.search[controller_multiselect]
  },

  g_headers: (state) => ({
    controller_multiselect,
    namesexpanded,
    notaffiche,
  }) => {
    let headers = []

    let titles = state.headers[controller_multiselect]

    for (const key in titles) {
      const obj_title = titles[key]

      if (notaffiche.indexOf(obj_title.value) == -1) {
        var regex = /FOREIGN_KEY_/gi
        var found = obj_title.value.match(regex)
        /// is not array namesexpanded
        if (
          obj_title.value != undefined &&
          namesexpanded.indexOf(obj_title.value) == -1 &&
          found == null
        ) {
          obj_title.text = i18n.t(obj_title.value)

          headers.push(obj_title)
        }
      }
    }

    console.log(namesexpanded)

    return headers
  },

  g_items: (state) => ({ controller_multiselect }) => {
    return state.items[controller_multiselect]
  },
  g_selected: (state) => ({ controller_multiselect }) => {
    return state.selected[controller_multiselect]
  },
  g_item_key: (state) => ({ controller_multiselect }) => {
    return state.item_key[controller_multiselect]
  },
  g_page: (state) => ({ controller_multiselect }) => {
    return state.page[controller_multiselect]
  },
  g_pageCount: (state) => ({ controller_multiselect }) => {
    return state.pageCount[controller_multiselect]
  },
  g_itemsPerPage: (state) => ({ controller_multiselect }) => {
    return state.itemsPerPage[controller_multiselect]
  },
  loading: (state) => {
    return state.loading
  },
  write: (state) => {
    return state.write
  },
}
