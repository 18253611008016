<template>
  <div>
     <v-data-table
            :headers="headers"
            :items="items"
            sort-by="index"
            :loading="loading"
            hide-default-footer
            :items-per-page="items.length"
          >
            <template v-slot:footer>
              <v-toolbar flat>
                <v-spacer></v-spacer>

                <v-dialog v-model="dialog" max-width="500" persistent>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      block
                      dark
                      small
                      rounded
                      color="orange"
                      v-on="on"
                      @click="addrow()"
                      v-show="edit_listsAdd"
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title>
                      <v-icon>mdi-chef-hat</v-icon>
                      <v-spacer></v-spacer>
                      <v-btn class="mx-2" fab dark small @click="closerow()" color="orange">
                        <v-icon dark>mdi-hand-okay</v-icon>
                      </v-btn>
                    </v-card-title>

                    <v-card-text>
                      <div>
                        <addlist-input-render
                          style="max-width: 100%"
                          v-for="(schema, indexrow) in row"
                          :key="indexrow"
                          :controller_listsAdd="controller_listsAdd"
                          :indexrows="editedIndex"
                          :indexrow="indexrow"
                          :schema="schema"
                          :disabled="false"
                        >
                        </addlist-input-render>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.action="{ item }">
              <div
                style="width: max-content; float: right"
                v-show="edit_listsAdd"
              >
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  @click="editItem(item.index)"
                >
                  <v-icon dark>mdi-pencil-outline</v-icon>
                </v-btn>

                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="error"
                  @click="remove_row(item.index)"
                >
                  <v-icon dark>mdi-delete-forever</v-icon>
                </v-btn>
              </div>
            </template>

            <template v-slot:no-data>
              <v-icon size="60">mdi-chef-hat</v-icon>
            </template>
          </v-data-table>
  </div>
</template>


<script>
import i18n from "@/plugins/i18n";
export default {
  props: {
    controller_listsAdd: {
      type: String,
      required: true,
    },

    edit_listsAdd: {
      type: Boolean,
      default: true,
    },
    show_listsAdd: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    show_listsAdd_default() {
      if (this.show_listsAdd) {
        return [0];
      } else {
        return [];
      }
    },

    headers() {
      let headers = this.$store.getters["structure/addlist/titles"](
        this.controller_listsAdd
      );
      headers.push({ text: "", value: "action" });

      return headers;
    },

    items() {
      return this.$store.getters["structure/addlist/items"](
        this.controller_listsAdd
      );
    },

    rows() {
      return this.$store.getters["structure/addlist/rows"][
        this.controller_listsAdd
      ];
    },

    write() {
      return true;
      //return this.$store.getters["structure/addlist/write"];
    },
    loading() {
      return false;
      //return this.$store.getters["structure/addlist/loading"];
    },
  },

  data: () => ({
    dialog: false,

    editedIndex: -1,

    row: [],
  }),
  watch: {
    dialog(val) {
      if (val == false) {
        this.row = [];
      }
    },
  },

  methods: {
    row_clone() {
      const schemehtml =
        this.$store.getters["structure/addlist/schemehtml"][
          this.controller_listsAdd
        ];
      return JSON.parse(JSON.stringify(schemehtml));
    },

    addrow() {
      let newrow = this.row_clone();

      this.$store.commit("structure/addlist/add_row", {
        controller_listsAdd: this.controller_listsAdd,
        row: newrow,
      });
      this.editedIndex = this.rows.length - 1;

      this.row = newrow;
    },

    closerow() {
      this.dialog = false;
    },

    editItem(indexrows) {
      this.editedIndex = indexrows * 1;
      this.row = this.rows[indexrows];
      this.dialog = true;
    },

    remove_row(indexrows) {
      this.row = [];
      this.$store.commit("structure/addlist/remove_row", {
        controller_listsAdd: this.controller_listsAdd,
        indexrows,
      });
    },
  },
};
</script>
