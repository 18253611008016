<template>
  <v-menu
    transition="scale-transition"
    :loading="loading"
    v-model="selectedOpen"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        v-if="
          typeof itemObject === 'object' &&
          itemObject != '' &&
          itemObject != null &&
          itemObject['@id'] != '' &&
          itemObject['@id'] != null &&
          itemObject['@id'] != undefined
        "
        class="subtitle-2 orange--text"
       
        v-bind="attrs"
        v-on="on"
      >

       <v-chip>
            <v-icon color="orange"  class="pr-2">mdi-clipboard-multiple-outline</v-icon>
            {{show_style_not_string()}}</v-chip
          >


      </div>

      <div v-else class="body-2" v-text="show_style_not_string()"></div>

    </template>

    <v-card
      min-width="350px"
      flat
      :loading="loading"
      v-if="
          typeof itemObject === 'object' &&
          itemObject != '' &&
          itemObject != null &&
          itemObject['@id'] != '' &&
          itemObject['@id'] != null &&
          itemObject['@id'] != undefined
        "
    >
      <v-toolbar color="orange" dark>
        <v-btn icon @click="showItem()" v-show="isGranted('view')">
          <v-icon>mdi-view-carousel</v-icon>
        </v-btn>

        <v-toolbar-title>
          {{ show_style_not_string() }} / id {{ id }}</v-toolbar-title
        >
        <v-btn icon @click="editItem()" v-show="isGranted('view')">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>

        <v-btn icon @click="selectedOpen = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-list two-line>
          <v-list-item
            v-for="(value, key) in itemObject"
            :key="key"
            v-show="key != '@id' && key != '@type'"
          >
            <v-list-item-icon>
              <v-icon color="indigo"> mdi-star </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t(key) }}</v-list-item-title>
              <v-list-item-subtitle>{{ value }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <span> </span>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      selectedElement: null,
      selectedOpen: false,
      loading: false,
    };
  },
  props: {
    itemObject: {},
    itemShowString: {
      type: Array,
      default: () => {
        return ["id"];
      },
    },
  },

  computed: {
    id() {
      return this.itemObject["@id"].split("/").pop();
    },
    controller() {
      return this.itemObject["@type"].toLowerCase();
    },
  },
  methods: {
    show_style_not_string() {
        let $str = "";
      if  (
          typeof this.itemObject === 'object' &&
          this.itemObject != '' &&
          this.itemObject != null &&
          this.itemObject['@id'] != '' &&
          this.itemObject['@id'] != null &&
          this.itemObject['@id'] != undefined
      ){
          for (const key in this.itemShowString) {
        const name = this.itemShowString[key];
        $str = $str + " " + this.itemObject[name];
      }

      }else{
        $str =this.itemObject 
      }

    
    
      return $str;
    },

    showItem() {
      this.$router.push({
        name: this.controller + "_show_item",
        params: { id: this.id },
      });
    },
    editItem() {
      this.$router.push({
        name: this.controller + "_update",
        params: { id: this.id },
      });
    },
    isGranted(attribute) {
      return true;
    },
  },
};
</script>
