var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('btn-action',{attrs:{"controller_root":_vm.controller_root,"item":{ id: _vm.id }}})],1)],1),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"12","lg":"5"}},[_c('card-box',{attrs:{"id":"a1","icon":"mdi-file-star-outline","title":_vm.$t('show_plat_infoplat_1'),"color":"success"}},[_c('box-root',{attrs:{"edit_root":false,"input_root":_vm.input_root,"controller_root":_vm.controller_root}}),_c('show-group',{attrs:{"names":[
            'name',
            'minidescription',
            'description',
            'rating',
            'position',
            'recommander',
          ],"controller_root":_vm.controller_root}})],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"5"}},[_c('card-box',{attrs:{"id":"a2","icon":"mdi-file-star-outline","title":_vm.$t('show_plat_infoplat_2'),"color":"success"}},[_c('show-group',{attrs:{"names":['prix', 'remise', 'active'],"controller_root":_vm.controller_root}})],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('card-box',{attrs:{"id":"addlist2","icon":"mdi-file-star-outline","title":_vm.$t('show_plat_infoplat_3'),"color":"success"}},[_c('addlist-table',{attrs:{"edit_listsAdd":false,"controller_listsAdd":"platrecettes"}})],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('card-box',{attrs:{"id":"addlist2x","icon":"mdi-file-star-outline","title":_vm.$t('show_plat_infoplat_4'),"color":"success"}},[_c('addlist-table',{attrs:{"edit_listsAdd":false,"controller_listsAdd":"platoptiongroups"}})],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":12,"lg":12}},[_c('card-box',{attrs:{"id":"groupa1","color":"orange","title":_vm.$t('show_plat_infoplat_4'),"text":_vm.$t('show_plat_box_text_4')},scopedSlots:_vm._u([{key:"chart",fn:function(){return undefined},proxy:true}])},[_c('show-group',{attrs:{"names":['logo'],"controller_root":_vm.controller_root}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }