<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>
        <strong>
          <u>{{ label }} :</u></strong
        >
        <v-icon :color="color">{{ icon }}</v-icon>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
import InputMixin from "./InputMixin";
import i18n from "@/plugins/i18n";
export default {
  mixins: [InputMixin],
  computed: {
    name() {
      return this.schema["name"];
    },
    label() {
      let icon = "";
      if (!this.schema["nullable"]) {
        // icon = "* "
      }

      return icon + i18n.t(this.name);
    },

    values_binds() {
      return this.$store.getters["structure/get_input_binds"]({
        controller_root: this.controller_root,
        name: this.name,
      });
    },
    icon() {
      let icon = "";
      if (this.values_binds == "true" || this.values_binds === true) {
        icon = "mdi-check";
      } else {
        icon = "mdi-cancel";
      }
      return icon;
    },
    color() {
      let icon = "";
      if (this.values_binds == "true" || this.values_binds === true) {
        icon = "success";
      } else {
        icon = "error";
      }
      return icon;
    },
  },
};
</script>