module.exports = {
  id: { typeHtml: "hidden" },
  restaurant: { typeHtml: "text" },
  updatedAt: { typeHtml: "hidden" },
  createdAt: { typeHtml: "hidden" },
  pays: { typeHtml: "text" },
  regions: { typeHtml: "text" },
  adresse: { typeHtml: "Textarea" },
  codePostal: { typeHtml: "text" },
  ville: { typeHtml: "text" },
  telephone: { typeHtml: "tel" },
  fax: { typeHtml: "tel" },
  email: { typeHtml: "email" },
  siteweb: { typeHtml: "url" },
  logo: { typeHtml: "logo" },
  files: { typeHtml: "files" },
  note: { typeHtml: "Textarea" },
  ice: { typeHtml: "text" },
  identifiantfiscal: { typeHtml: "text" },
  urlFacebook: { typeHtml: "text" },
  urlTwitter: { typeHtml: "text" },
  urlInstagram: { typeHtml: "text" },
  urlYoutube: { typeHtml: "text" },
  pageInfo: { typeHtml: "text" },




  block1: { typeHtml: "logo" },
  block2: { typeHtml: "logo" },
  block3: { typeHtml: "logo" },
  blocknav: { typeHtml: "logo" },

  colorapp: { typeHtml: "color" },
  colorsecondary: { typeHtml: "color" },

  maxtimecommande: { typeHtml: "numbre", defaultValue: 60 },
  autoremovecommande: {typeHtml: "checkbox", defaultValue: "1" },


  nombretable: { typeHtml: "numbre", defaultValue: 10 },
  activelivraison: { typeHtml: "checkbox", defaultValue: "1" },
  fraislivraison: { typeHtml: "money", defaultValue: 1 },
  gratuitlivraison: { typeHtml: "money", defaultValue: 1 },


 // colorfont: { typeHtml: "color" },



/*   oauth_google_id: { typeHtml: "password" },
  oauth_google_secret: { typeHtml: "password" },
  oauth_facebook_id: { typeHtml: "password" },
  oauth_facebook_secret: { typeHtml: "password" }, */
};
