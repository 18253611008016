<template>
  <v-app-bar app dense>
    <v-app-bar-nav-icon @click.stop="btndrawer_left()"></v-app-bar-nav-icon>

    <v-toolbar-title>{{ title }}</v-toolbar-title> <v-spacer /> 
{{Base_url}}
    <v-spacer /> 

    <v-toolbar-items>
      <v-flex align-center layout py-2>

<v-btn icon @click="btndrawer_i18n()" v-show="isadmin">
          <v-icon>mdi-translate-variant</v-icon>
        </v-btn>

        <v-btn icon @click="handleFullScreen()">
          <v-icon>mdi-fullscreen</v-icon>
        </v-btn>

        <!--  <v-menu
          v-if="isadmin"
          offset-y
          origin="center center"
          class="elelvation-1"
          :nudge-right="140"
          :nudge-bottom="14"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="notification">
              <v-badge color="red" overlap v-show="count_notification != 0">
                <span slot="badge">{{ count_notification }}</span>
                <v-icon medium>mdi-bell-ring</v-icon>
              </v-badge>
              <v-icon medium v-show="count_notification == 0">mdi-hail</v-icon>
            </v-btn>
          </template>
          <theme-notification></theme-notification>
        </v-menu> -->

        <v-menu
          offset-y
          origin="center center"
          :nudge-right="140"
          :nudge-bottom="10"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn icon large v-on="on">
              <v-avatar size="30px">
                <readfile-img  :refgroup="refGroup" />
              </v-avatar>
            </v-btn>
          </template>
          <v-list class="pa-0">
            <v-list-item
              v-for="(item, index) in items"
              @click="item.click"
              ripple="ripple"
              :disabled="item.disabled"
              :target="item.target"
              rel="noopener"
              :key="index"
            >
              <v-list-item-action v-if="item.icon">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-flex>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import Util from "@/util/index";
import { mapGetters } from "vuex";
import router from "@/router";

export default {
  data: function () {
    return {
      dialog: false,
    };
  },
  methods: {
    handleFullScreen() {
      Util.toggleFullScreen();
    },
    btndrawer_left() {
      this.$store.commit("theme/toggledrawer_left");
    },

     btndrawer_i18n() {
          
          let key=this.$i18n.locale

       let msgs=  localStorage.getItem('lang_i18n_' + key, "")


     this.$i18n.setLocaleMessage(key, JSON.parse(msgs));
      

      this.$store.commit("theme/toggledrawer_i18n");
    },
    notification() {
      this.$store.dispatch("theme/notification");
    },
  },
  computed: {
    ...mapGetters({
      // attacher `this.doneCount` à `this.$store.getters.doneTodosCount`

      dark: "theme/dark",
    }),
    count_notification() {
      return this.$store.getters["theme/count_notification"];
    },

    title() {
      let title = this.$store.getters["theme/title"];
      if (title == "" && this.connecte) {
        router.push({ name: "organisation_update", params: { id: 1 } });
      }
      return title;
    },
    info_user() {
      return this.$store.getters["security/user"]["toString"];
    },
    logo() {
      return this.$store.getters["security/logo"];
    },

    refGroup() {
      return this.$store.getters["security/refGroup"];
    },
    info_organisation() {
      return this.$store.getters["theme/organisation"];
    },
    connecte() {
      return this.$store.getters["security/connecte"];
    },
    isadmin() {
      return this.$store.getters["security/isAdmin"];
    },
    items() {
      return [
        {
          icon: "mdi-home",

          title: this.$i18n.t("accueil"),
          disabled: !this.connecte,
          click: (e) => {
            router.push({ name: "_dashboard" });
          },
        },

        {
          icon: "mdi-settings",

          title: this.$i18n.t("Settings"),
          disabled: !this.connecte,
          click: (e) => {
            this.$store.commit("theme/toggledrawer_right");
          },
        },
        {
          icon: "mdi-face-profile",

          title: this.$i18n.t("Profile"),
          disabled: !this.connecte,
          click: (e) => {
            this.$router.push({
              name: "user_show_item",
              params: { id: this.$store.getters["security/user"]["id"] },
            });
            // this.dialog = true;
          },
        },
        {
          icon: "mdi-login",

          title: this.$i18n.t("Login"),
          disabled: this.connecte,
          click: (e) => {
            router.push({ name: "login" });
          },
        },
        {
          icon: "mdi-logout",

          title: this.$i18n.t("Logout"),
          disabled: !this.connecte,
          click: (e) => {
            window.location.href = "/logout";
          },
        },
        {
          icon: "mdi-refresh",

          title: this.$i18n.t("refresh"),
          disabled: !this.connecte,
          click: (e) => {
            window.location.href = "/";
          },
        },
        {
          icon: "mdi-restart",

          title: this.$i18n.t("restart_config_user"),
          disabled: !this.connecte,
          click: (e) => {
            window.localStorage.clear();
            window.location.href = "/";
          },
        },
      ];
    },  Base_url() {
      return this.$store.getters["get_Base_url"];
    },
  },
};
</script>


<style scoped>
</style>