<template>
  <div class="text-center">
    <v-dialog v-model="dialog_flag" :value="value_flag" width="600">

      <slot :name="dialog_type"></slot>

    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value_flag: false,
    };
  },
  props: ["controller_root"],

  computed: {
    /*  dialog_data() {
      return this.$store.getters["dialog/g_data"](this.controller_root);
    }, */
    dialog_type() {
      return this.$store.getters["dialog/g_type"](this.controller_root);
    },
    dialog_flag: {
      get() {
        return this.$store.getters["dialog/g_flag"](this.controller_root);
      },
      set(value) {
        this.$store.commit("dialog/set_dialog_flag", {
          controller: this.controller_root,
          flag: value, // true \/false
        });
      },
    },
  },
};
</script>
