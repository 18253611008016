<template>
  <v-col cols="12" sm="6" md="4">
    <v-text-field

    
    :label="label"
    :value="values_binds" 
    @input="modelbind($event)" 
    type="url"
    
    :rules="[validation.url, validation.text]" 
    
    ></v-text-field>
  </v-col>
 
</template>
<script>
import InputMixin from "./InputMixin";
export default {
   mixins: [InputMixin],
};
</script>