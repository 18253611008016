<template>
  <div class="text-center">
    <v-btn @click="savedata" :disabled="!valid || senddata" color="success">
      Enregistrer <v-icon>mdi-content-save</v-icon>
    </v-btn>

    <v-btn @click="close" color="warning">
      <v-icon>mdi-close-circle</v-icon>
    </v-btn>
  </div>
</template>
<script>
export default {
  props: {
    controller_root: {},
    controller_main: {},
    valid: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    savedata() {
      if (this.valid) {
        console.log(this.valid);

        this.$store.dispatch("send/send_data_ajax", {
          controller_root: this.controller_root,
          controller_main: this.controller_main,
        });

        /// hidin dialog si is
        // this.$store.commit("dialog/set_dialog", {
        //   data: [],
        //   flag: false,
        //   type: "Hidden",
        //   controller: this.controller_root
        // });

        //show
        this.$store.commit("dialog/set_dialog_btn_send", { flag: true });
        // this.senddata=true
      }

      this.$emit("click");
    },
    close() {
      this.$emit("click");
    },
  },
  computed: {},
  data() {
    return { senddata: false };
  },
};
</script>