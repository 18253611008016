<template>
  <div>
    <div style="width: max-content; float: right" v-if="action_traitement">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            class="mx-2"
            outlined
            fab
            dark
            color="pink"
            small
            @click="alertshow()"
            v-show="isGranted('delete', item)"
            v-on="on"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>{{$t('Supprimer')}}</span>
      </v-tooltip>

      <v-snackbar
        v-model="alert"
        elevation="24"
        :top="true"
        centered
        vertical
        color="error"
      >
        <v-icon>mdi-home-alert</v-icon>{{$t('Êtes-vous sûr de vouloir supprimer cette ligne')}}
        <br />
        <strong>{{ text }}</strong>

        <div style="display-2">
          <v-btn dark text @click="alert = false">{{$t('Fermer')}}</v-btn>
          <v-btn dark text @click="deleteItem()">{{$t('oui')}}</v-btn>
        </div>
      </v-snackbar>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return { alert: false, delete: false, text: "" };
  },
  props: {
    item: {},
    controller_root: {},
    action_traitement: { default: true },
    slot_show: {},
  },

  computed: {},

  methods: {
    isGranted(attribute, item) {
      return this.$store.getters["security/isGranted"](
        attribute,
        this.controller_root,
        item
      );
    },

    alertshow() {
      this.alert = true;

      this.text =
        "(  ID = " +
        this.item["id"] +
        "   /      " +
        this.item["toString"] +
        "  ) ";
    },

    deleteItem() {
      this.alert = false;

      this.$store.dispatch("send/delete_data", {
        controller_root: this.controller_root,
        item: this.item,
      });
    },
  },
};
</script>
