<template>
  <v-card-title>
    {{title}}
    <div class="flex-grow-1"></div>
    <v-text-field
      :value="search"
      @input="find($event)"
      append-icon="mdi-file-find"
      label="recherche "
      single-line
      hide-details
    ></v-text-field>
  </v-card-title>
</template>


<script>
import { mapGetters } from "vuex";
export default {
  props: {
    nametableroot: {},
    title: {}
  },
  data() {
    return {
      
    };
  },
  methods: {
    find(val) {
      this.$store.commit("structure/multiselect/set_search", {
        controller_multiselect: this.nametableroot,
        data: val
      });
    }
  },
  computed: {
    search() {
      return this.$store.getters["structure/multiselect/g_search"]({
        controller_multiselect: this.nametableroot
      });
    }
  }
};
</script>

