"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  set_init: function set_init(state, param_tables) {
    var namemodule = {};
    var namescontroller_multiselect = []; //let _id = {};
    // let _controller = {};
    // let controllers_multiselects = [];

    var controller_root_attach = {};
    var where_attach = {};
    var where_attach_get_value = {};
    var default_Jointure = {};
    var controller_listsAdd_attach = {};
    var pieceID = {};
    var titles = {};
    var not_affiches = {};
    var expandeds = {};
    var search = {};
    var headers = {};
    var items = {};
    var selected = {};
    var item_key = {};
    var page = {};
    var pageCount = {};
    var itemsPerPage = {};

    for (var index in param_tables) {
      var multiselects = param_tables[index]["multiselect"];

      for (var key in multiselects) {
        var multiselect = multiselects[key];
        var controller_multiselect = multiselect["controller_multiselect"];
        /**************************/

        controller_root_attach[controller_multiselect] = multiselect["controller_root_attach"];
        where_attach[controller_multiselect] = multiselect["where_attach"];
        where_attach_get_value[controller_multiselect] = multiselect["where_attach_get_value"];
        default_Jointure[controller_multiselect] = multiselect["default_Jointure"];
        controller_listsAdd_attach[controller_multiselect] = multiselect["controller_listsAdd_attach"];
        namescontroller_multiselect.push(controller_multiselect);
        /**************************/

        namemodule[controller_multiselect] = param_tables[index]["namemodule"];
        /**************************/

        var options_showData = multiselect["options_showData"];
        titles[controller_multiselect] = options_showData["title"];
        pieceID[controller_multiselect] = options_showData["pieceID"];
        not_affiches[controller_multiselect] = options_showData["not_affiche"];
        expandeds[controller_multiselect] = options_showData["expanded"];
        /**************************/

        search[controller_multiselect] = "";
        headers[controller_multiselect] = [];
        items[controller_multiselect] = [];
        selected[controller_multiselect] = [];
        item_key[controller_multiselect] = "id";
        page[controller_multiselect] = 1;
        pageCount[controller_multiselect] = 1;
        itemsPerPage[controller_multiselect] = 5;
      }
    }

    state["namesmodules"] = namemodule;
    state["namescontroller_multiselect"] = namescontroller_multiselect;
    state["controller_root_attach"] = controller_root_attach;
    state["where_attach"] = where_attach;
    state["where_attach_get_value"] = where_attach_get_value;
    state["jointure"] = default_Jointure;
    state["controller_listsAdd_attach"] = controller_listsAdd_attach;
    state["pieceID"] = pieceID;
    state["titles"] = titles;
    state["not_affiches"] = not_affiches;
    state["expandeds"] = expandeds;
    state["search"] = search;
    state["headers"] = headers;
    state["items"] = items;
    state["selected"] = selected;
    state["item_key"] = item_key;
    state["page"] = page;
    state["pageCount"] = pageCount;
    state["itemsPerPage"] = itemsPerPage;
  },
  set_jointure: function set_jointure(state, _ref) {
    var data = _ref.data,
        controller_multiselect = _ref.controller_multiselect;
    state.jointure[controller_multiselect] = data;
  },
  set_search: function set_search(state, _ref2) {
    var data = _ref2.data,
        controller_multiselect = _ref2.controller_multiselect;
    state.search[controller_multiselect] = data;
  },
  set_headers: function set_headers(state, _ref3) {
    var data = _ref3.data,
        controller_multiselect = _ref3.controller_multiselect;
    state.headers[controller_multiselect] = data;
  },
  set_items: function set_items(state, _ref4) {
    var data = _ref4.data,
        controller_multiselect = _ref4.controller_multiselect;
    state.items[controller_multiselect] = data;
  },
  set_selected: function set_selected(state, _ref5) {
    var data = _ref5.data,
        controller_multiselect = _ref5.controller_multiselect;
    state.selected[controller_multiselect] = data;
  },
  set_item_key: function set_item_key(state, _ref6) {
    var data = _ref6.data,
        controller_multiselect = _ref6.controller_multiselect;
    state.item_key[controller_multiselect] = data;
  },
  set_page: function set_page(state, _ref7) {
    var data = _ref7.data,
        controller_multiselect = _ref7.controller_multiselect;
    state.page[controller_multiselect] = data;
  },
  set_pageCount: function set_pageCount(state, _ref8) {
    var data = _ref8.data,
        controller_multiselect = _ref8.controller_multiselect;
    state.pageCount[controller_multiselect] = data;
  },
  set_itemsPerPage: function set_itemsPerPage(state, _ref9) {
    var data = _ref9.data,
        controller_multiselect = _ref9.controller_multiselect;
    state.itemsPerPage[controller_multiselect] = data;
  },
  remove_all: function remove_all(state, _ref10) {
    var controller_multiselect = _ref10.controller_multiselect;
    console.log("ddd"); // state.selected[controller_multiselect] = [];
    // state.headers[controller_multiselect] =[];

    state.items[controller_multiselect] = [];
  }
};
exports["default"] = _default;