module.exports = {
  id: { typeHtml: "hidden" },

  date: { typeHtml: "date" },
  produits: { typeHtml: "select", to_string: "designation" },
  recetteracine: { typeHtml: "select" },
  type: { typeHtml: "text" },
  quantite: { typeHtml: "text" },
  origine: { typeHtml: "text" },

  bonsachats: { typeHtml: "select" , to_string: "reffournisseur"},
  perte: { typeHtml: "select", to_string: "titre" },
  inventaire: { typeHtml: "select", to_string: "createdAt" },
  commandes: { typeHtml: "select", to_string: "code" },
 

  recette: { typeHtml: "select" , to_string: "name"},

  updatedAt: { typeHtml: "date" },
  createdAt: { typeHtml: "date" },
  user: { typeHtml: "hidden" , to_string: "identifiant"},
};
