<template>
  <card-card
  style="    margin-top: 50px;
"
    :icon="icon"
    :avatar="avatar"
    class="v-card--material-stats"
    v-bind="$attrs"
    v-on="$listeners"
    :color="color"
  >
    <template v-slot:after-heading>
      <div class="ml-auto text-right">
        <div class="body-3 grey--text font-weight-light" v-text="title" />
      </div>
    </template>

    <v-row class="justify-center">
      <v-col cols="11">
        <slot />
      </v-col>
    </v-row>
  </card-card>
</template>

<script>
import Card from "./Card";

export default {
  name: "CardStatsCard",

  inheritAttrs: false,

  props: {
    ...Card.props,

    icon: {
      type: String,
      default: "mdi-map-marker-circle",
    },
    color: {
      type: String,
      default: "success",
    },

    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style  scoped>
.v-card--material-stats {
  flex-wrap: wrap;
  position: relative;
}

.v-card--material-stats div:first-child {
  justify-content: space-between;
}

.v-card--material-stats .v-card {
  border-radius: 4px;
  flex: 0 1 auto;
}

.v-card--material-stats .v-card__text {
  display: inline-block;
  flex: 1 0 calc(100% - 120px);
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}

.v-card--material-stats .v-card__actions {
  flex: 1 0 100%;
}
</style>
