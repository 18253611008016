import i18n from '@/plugins/i18n'
import { truncate } from 'lodash'

export default {
  props: {
    close: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {}
  },

  computed: {},

  methods: {
    closeevent() {
      console.log(this.$options._componentTag)
      let widgets = this.$store.getters['theme/widgets']

      const index = widgets.indexOf(this.$options._componentTag)
      if (index > -1) {
        widgets.splice(index, 1)
      }

      this.$store.commit('theme/set_widgets', widgets)
    },
  },
  watch: {},
  mounted() {},
}
