import all from "./index";
import index from "../index";
//import add   from    "@/views/Produit/mouvementsstock/add";
//import  show    from  "@/views/Produit/mouvementsstock/show";
//import update from     "@/views/@";
//import show_item from "@/views/@";
let namemodule = "Stocks";
let namecontroller = "mouvementsstock";
export default [
  {
    path: "/" + namecontroller,
    component: index,

    children: [
      { path: "", component: all, name: namecontroller + "_show" },
      {
        path: "voir/:id([0-9]+)",
        component: all,
        name: namecontroller + "_show_item",
        props: true,
      },
      {
        path: ":id([0-9]+)",
        component: all,
        name: namecontroller + "_update",
        props: true,
      },
      { path: "ajouter", component: all, name: namecontroller + "_add" },
    ],
  },
];
