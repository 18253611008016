<template>
  <!-- true-value="oui"
  false-value="non"-->
  <v-checkbox
    :label="label"
    :input-value="values_binds"
    @change="modelbind($event)"
    :loading="loading"
  ></v-checkbox>
</template>
<script>
import InputMixin from "./InputMixin";
export default {
  mixins: [InputMixin]
};
</script>