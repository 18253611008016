<template>
  <v-text-field
    :label="label"
    :value="val"
    @input="modelbind($event)"
   @blur="uniqueajax_validation()"
    @focus="init_validation()"
    :rules="rules"
    
    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
    :type="show1 ? 'text' : 'password'"
    counter
    @click:append="show1 = !show1"
  ></v-text-field>
</template>
<script>
import InputMixin from "./InputMixin";
export default {
  mixins: [InputMixin],
    methods: {
    init_validation() {
      this.rules = [ this.validation.password];
    },
  },

  data() {
    return {
      show1: false,
      val: ""
    };
  }
};
</script>