<template>
  <div style="margin-top: 60px; margin-left: 37px; margin-bottom: 44px">
    <h1 class="justify-center">{{ $t(controller) }}</h1>

    <v-row class="justify-center">
      <v-col :cols="12" :lg="11">
        <v-btn @click="exceljs">module excel de {{ $t(controller) }}</v-btn>

        <v-file-input
          :label="'excel de ' + $t(controller)"
          @change="red($event)"
          show-size
          counter
          chips
        >
        </v-file-input>

        <div ref="document">
          <p>* champ obligatoir</p>
          <table class="paleBlueRows" ref="documenttable">
            <thead>
              <tr>
                <th v-for="(val, i) in headers" :key="i">
                  <span
                    v-if="obligatoir.indexOf(val.value) > -1"
                    class="orange--text"
                  >
                    <u> {{ val.text }} *</u>
                  </span>
                  <span v-else>
                    {{ val.text }}
                  </span>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(row, l) in selecteds_show_table" :key="l">
                <td
                  v-for="(hed, j) in headers"
                  :key="j"
                  :class="
                    row[hed.value] == undefined ? '' : row[hed.value]['color']
                  "
                >
                  {{
                    row[hed.value] == undefined ? "" : row[hed.value]["text"]
                  }}
                </td>
              </tr>
            </tbody>
          </table>

          <v-btn
            @click="send"
            color="success"
            v-show="selecteds.length > 0"
            :disabled="!valid"
          >
            Enregistrer <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </div>
      </v-col>
      <br /><br /><br /><br /><br />
      <p>.</p>
    </v-row>

    <div class="text-center justify-center">
      <v-dialog v-model="dialog_erreur" hide-overlay persistent>
        <v-card color="error" dark class="justify-center">
          <v-card-text
            style="width: max-content; margin: 0 auto"
            class="justify-center"
          >
            il y a des valeurs entrée n'est pas appropriée au masque de saisie :
            <br />
            <h3>{{ messageerrors }}</h3>

            <v-data-table
              style="display: inline-block; width: max-content; margin: 0 auto"
              loading
              :headers="headers"
              :items="data_error"
              :items-per-page="5"
              class="elevation-1"
              locale="fr"
              hide-default-footer
            ></v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialog_erreur = false">
              <v-icon color="orange">mdi-close-box</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center">
      <v-dialog v-model="dialog_show" hide-overlay persistent>
        <v-card>
          <v-card-text>
            <v-row class="justify-center">
              <v-col cols="12" lg="6" v-if="data_update.length > 0">
                <v-data-table
                  style="display: inline-block"
                  :headers="[
                    { text: 'etat', value: 'etat' },
                    { text: 'pour ', value: 'pour ' },
                  ]"
                  :items="data_update"
                  :items-per-page="5"
                  class="elevation-1"
                  locale="fr"
                  :footer-props="{
                    itemsPerPageText: 'Élements par page:',
                    itemsPerPageAll: 'Tous',
                    nextPage: 'Page suivante',
                    prevPage: 'Page précédente',
                    firstPage: 'Première page',
                    lastPage: 'Dernière page',
                    pageText: '{0}-{1} de {2}',
                  }"
                ></v-data-table
              ></v-col>

              <v-col cols="12" lg="6" v-if="data_add.length > 0">
                <v-data-table
                  style="display: inline-block"
                  :headers="[
                    { text: 'etat', value: 'etat' },
                    { text: 'pour ', value: 'pour ' },
                  ]"
                  :items="data_add"
                  :items-per-page="5"
                  class="elevation-1"
                  locale="fr"
                  :footer-props="{
                    itemsPerPageText: 'Élements par page:',
                    itemsPerPageAll: 'Tous',
                    nextPage: 'Page suivante',
                    prevPage: 'Page précédente',
                    firstPage: 'Première page',
                    lastPage: 'Dernière page',
                    pageText: '{0}-{1} de {2}',
                  }"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="termine()">
              <v-icon>mdi-close-box</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import ExportJsonExcel from "js-export-excel";
import readXlsxFile from "read-excel-file";
import axios from "axios";
//import router from "@/router";
const Ajv = require("ajv");
export default {
  data() {
    return {
      selecteds: [],
      selecteds_show_table: [],
      dialog_erreur: false,
      data_add: [],
      data_update: [],
      data_error: [],
      messageerrors: "",
      valid: false,
      dialog_show: false,
    };
  },

  computed: {
     Base_url_web() {
      return this.$store.getters["get_Base_url"];
    }, JWTToken() {
      return this.$store.getters["get_JWTToken"];
    },
    controller() {
      return this.$route.params.controller;
    },

    // selecteds() {
    //   return this.$store.getters["show_data/g_selected"]({
    //     controller_root: this.controller,
    //   });
    // },

    namesexpanded() {
      let controller = this.controller;

      let expanded = this.$store.getters["show_data/g_expanded"]({
        controller_root: controller,
      });

      let namesexpanded = [];
      for (const key in expanded) {
        namesexpanded.push(expanded[key]["controller_attach"]);
      }

      return namesexpanded;
    },
    obligatoir() {
      let controller = this.controller;
      let obligatoir = this.$store.getters["show_data/g_obligatoir"]({
        controller_root: controller,
      });

      // console.log(obligatoir);
      return obligatoir;
    },

    headers() {
      let controller = this.controller;

      let not_affiches = [];
      not_affiches = this.$store.getters["show_data/g_not_affiche"]({
        controller_root: controller,
      });
      // console.log(not_affiches);
      let headers = this.$store.getters["show_data/g_headers"]({
        controller_root: controller,
        namesexpanded: this.namesexpanded,
        notaffiche: not_affiches,
        name_input_files: [],
        obligatoir: this.obligatoir,
      });

      return headers;
    },
  },

  methods: {
    red(ev) {
      readXlsxFile(ev).then((rows) => {
        let headers = rows[0];
        let data = [];
        let data_show_table = [];
        for (let index = 2; index < rows.length; index++) {
          let d = {};
          let d2 = {};
          const row = rows[index];
          for (let i = 0; i < headers.length; i++) {
            const key = headers[i];
            const value = row[i];
            d[key] = value;
            d2[key] = {};
            d2[key]["text"] = value;
            d2[key]["color"] = "";
          }
          data.push(d);
          data_show_table.push(d2);
        }
        this.selecteds_show_table = data_show_table;
        this.selecteds = data;
        this.validate(data);

        // `rows` is an array of rows
        // each row being an array of cells.
      });
    },

    validate(data) {
      this.schemas_validate(data);
    },
    schemas_validate(data) {
      let controller = this.controller;

      let schemas = [];
      schemas = this.$store.getters["show_data/g_schemas"]({
        controller_root: controller,
      });

      let properties = {};
      for (let index = 0; index < schemas.length; index++) {
        const schema = schemas[index];
        const propertie_name = schema["from"];

        let schema_validate = {};
        schema_validate["type"] = schema["typeValidate"];
        schema_validate["nullable"] = true;
        if (schema["patternValidate"] != false) {
          schema_validate["pattern"] = schema["patternValidate"][0];
        }
        properties[propertie_name] = schema_validate;
      }

      let sh = {
        type: "object",
        properties: properties,
      };

      const ajv = new Ajv({ allErrors: true }); // options can be passed, e.g. {allErrors: true}

      const schema = {
        type: "array",
        items: sh,
      };

      const validate = ajv.compile(schema);

      this.valid = validate(data);
      console.log(schema);
      console.log(this.valid);
      if (!this.valid) {
        let errors = validate.errors;
        errors.forEach((error) => {
          let dataPath = error.dataPath;
          let XY = this.coordonnees(dataPath);
          this.selecteds_show_table[XY[0]][XY[1]]["color"] =
            "white--text red darken-2 text-center font-weight-bold";
        });
      }
    },
    coordonnees(dataPath) {
      let X = dataPath.split(".")[1];
      let Y = parseInt(
        dataPath.split(".")[0].replaceAll("[", "").replaceAll("]", ""),
        10
      );
      return [Y, X];
    },

    exceljs() {
      let sheetheader = [];
      let sheetheadervalue = [];
      //  let dataselecteds= this.selecteds
      //console.log(this.selecteds);
      let row = {};
      let rowexemple = {};
      for (let index = 0; index < this.headers.length; index++) {
        const element = this.headers[index];
        if (element.value != "id" && element.value != "ID") {
          sheetheader.push(element.value);
          sheetheadervalue.push(element.value);

          row[element.value] = element.text;
          rowexemple[element.value] = "ajouter ..";
        }
      }

      var option = {};
      let namecontroller = this.controller;
      option.fileName = "module_" + namecontroller;

      option.datas = [
        {
          sheetName: "module_" + namecontroller,
          //   sheetFilter: [ "one","two"],
          // sheetHeader: [ "one","two"],
          sheetHeader: sheetheader,
          sheetFilter: sheetheadervalue,
          // columnWidths: [20, 20],
          sheetData: [row, rowexemple],
        },
      ];

      var toExcel = new ExportJsonExcel(option); //new
      toExcel.saveExcel();
    },
    send() {
      //show
      this.$store.commit("dialog/set_dialog_btn_send", { flag: true });

      let store = this.$store;

      const formdata = JSON.stringify({
        data_list_excel: this.selecteds,
        name_controller: this.controller,
      });

      let urlPage = this.$store.getters["get_importexcel"](this.controller);
 let JWTToken = this.JWTToken;
      axios
        .post(this.Base_url_web+urlPage, formdata, {
        params: {},
        headers: {
          Authorization: JWTToken,
        },
      })
        .then((response) => {
          store.commit("dialog/set_dialog_btn_send", { flag: false });
          this.dialog_show = true;
          this.data_add = response.data.add;
          this.data_update = response.data.update;
        })
        .catch((error) => {
          store.commit("dialog/set_dialog_btn_send", { flag: false });
          this.dialog_erreur = true;
          this.data_add = error.response.data.add;
          this.data_update = error.response.data.update;
          this.data_error = error.response.data.error;
          this.messageerrors = error.response.data.messageerrors;

          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          console.log("Error", error.message);
          console.error("error to POST data =>" + error);
        });
    },
    termine() {
      this.dialog_show = false;
      let to = this.controller + "_show";
       window.location.href ='/'
    },
  },
};
</script>





<style>
table.paleBlueRows {
  font-family: "Times New Roman", Times, serif;
  border: 1px solid #ffffff;

  text-align: center;
  border-collapse: collapse;
  margin: 20px auto;
  width: 90%;
}
table.paleBlueRows td,
table.paleBlueRows th {
  border: 1px solid #ffffff;
  padding: 3px 2px;
}
table.paleBlueRows tbody td {
  font-size: 13px;
}
table.paleBlueRows tr:nth-child(even) {
  background: #d0e4f5;
}
table.paleBlueRows thead {
  background: #0b6fa4;
  border-bottom: 5px solid #ffffff;
}
table.paleBlueRows thead th {
  font-size: 17px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  border-left: 2px solid #ffffff;
}
table.paleBlueRows thead th:first-child {
  border-left: none;
}

table.paleBlueRows tfoot {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  background: #d0e4f5;
  border-top: 3px solid #444444;
}
table.paleBlueRows tfoot td {
  font-size: 14px;
}
</style>
