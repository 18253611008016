import router from '@/router'

import axios from 'axios'

export  async function ajax_JWTToken(
  Base_url,
  username,
  password,
  routerlogin = true,
) {
  const JWTToken = await axios
    .post(Base_url + '/api/login_check', {
      email: username,
      password: password,
    })
    .then((response) => 'Bearer ' + response.data.token)
    .catch((response) => {
      if (routerlogin) {
        router.push({ name: 'login' })
      } else {
        return ''
      }
    })
  return JWTToken
}
