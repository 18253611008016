<template>
  <card-card
    icon="mdi-wallet-travel"
    :title="$t('Wt_' + 'Accueil')"
    :text="$t('Wsubt_' + 'Accueil')"
    class="px-5 py-3 d-inline-block mx-auto"
    color="orange"
    :close="close"
    @close="closeevent"
  >
    <v-container>
      <v-row dense>
        <v-col cols="12">
          <v-row class="justify-space-around align-center">
            <v-col cols="5" sm="5" md="5">
              <v-radio-group v-model="statut" row>
                <v-radio label="Factures  valide" value="valide"> </v-radio>

                <v-radio label="Facture non declarée" value="nd"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="5" sm="5" md="5">
              <v-text-field
                type="number"
                v-model="date"
                :label="$t('date')"
                placeholder="Placeholder"
                append-icon="mdi-refresh"
                @click:append="start"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" class="pt-0 mt-0">
          <v-card
            min-height="110"
            class="ma-0 justify-center text-center"
            color="grey lighten-1"
            dark
            elevation="8"
          >
            <v-card-title class="headline" style="">
              Chiffre d'affaires :
            </v-card-title>

            <v-card-subtitle
              class="headline font-weight-bold white--text"
              style="font-size: 40px !important ; padding-bottom: 0px"
              >{{ CA.toLocaleString(undefined, { minimumFractionDigits: 2 }) }}
              DH
            </v-card-subtitle>

            <v-card-text>
              <span class="font-weight-black primary--text"> Recettes: </span
              ><strong class="success--text" style="font-size: 20px !important"
                >{{
                  Recettes.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })
                }}
                DH</strong
              >
              -
              <span class="font-weight-black primary--text"> Dépenses: </span>
              <strong class="red--text" style="font-size: 20px !important"
                >{{
                  Depenses.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })
                }}
                DH</strong
              >
              <br />
              <span class="font-weight-black primary--text"> TVA: </span>
              <strong class="orange--text" style="font-size: 20px !important"
                >{{
                  TVA.toLocaleString(undefined, { minimumFractionDigits: 2 })
                }}
                DH</strong
              >
              - <span class="font-weight-black primary--text"> Résultat: </span
              ><strong class="purple--text" style="font-size: 20px !important"
                >{{
                  Resultat.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })
                }}
                DH</strong
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="space-between">
        <v-col cols="9">
          <v-row class>
            <v-col cols="12" class="pt-0 mt-0">
              <statistiques-mini
                :valuetotal="CA"
                :value="T_CA"
                colorchart="indigo"
                icon="mdi-chart-timeline-variant"
                :title="
                  T_CA.toLocaleString(undefined, { minimumFractionDigits: 2 }) +
                  ' DH   /' +
                  T_trimestriel
                "
                sub-title="CA"
                color="indigo"
              ></statistiques-mini>
            </v-col>

            <v-col cols="12" class="pt-0 mt-0">
              <statistiques-mini
                :valuetotal="Recettes"
                :value="T_Recettes"
                colorchart="success"
                icon="mdi-chart-timeline-variant"
                :title="
                  T_Recettes.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  }) +
                  ' DH   /' +
                  T_trimestriel
                "
                sub-title="Recettes"
                color="success"
              ></statistiques-mini>
            </v-col>

            <v-col cols="12" class="pt-0 mt-0">
              <statistiques-mini
                :valuetotal="Depenses"
                :value="T_Depenses"
                colorchart="red"
                icon="mdi-chart-timeline-variant"
                :title="
                  T_Depenses.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  }) +
                  ' DH   /' +
                  T_trimestriel
                "
                sub-title="Dépenses"
                color="red"
              ></statistiques-mini>
            </v-col>
            <v-col cols="12" class="pt-0 mt-0" v-show="statut=='valide'">
              <statistiques-mini
                :valuetotal="TVA"
                :value="T_TVA"
                colorchart="orange"
                icon="mdi-chart-timeline-variant"
                :title="
                  T_TVA.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  }) +
                  ' DH   /' +
                  T_trimestriel
                "
                sub-title="TVA"
                color="orange"
              ></statistiques-mini>
            </v-col>
            <v-col cols="12" class="pt-0 mt-0">
              <statistiques-mini
                :valuetotal="Resultat"
                :value="T_Resultat"
                colorchart="purple"
                icon="mdi-chart-timeline-variant"
                :title="
                  T_Resultat.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  }) +
                  ' DH   /' +
                  T_trimestriel
                "
                sub-title="Résultat"
                color="purple"
              ></statistiques-mini>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="3" class="text-center pl-0">
          <v-row
            class="flex-column ma-0 fill-height"
            justify="center"
            align-center
            align-content-center
          >
            <v-col justify-center align-center>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    @click="trimestriel('T1')"
                    :color="T_trimestriel == 'T1' ? 'primary' : ''"
                    large
                  >
                    <v-icon>mdi-calendar</v-icon>
                    <strong>T1</strong>
                  </v-btn>
                </template>
                <span>Trimestriel 1</span>
              </v-tooltip>
            </v-col>
            <v-col justify-center align-center>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    @click="trimestriel('T2')"
                    :color="T_trimestriel == 'T2' ? 'primary' : ''"
                    large
                  >
                    <v-icon>mdi-calendar</v-icon>
                    <strong>T2</strong>
                  </v-btn>
                </template>
                <span>Trimestriel 2</span>
              </v-tooltip>
            </v-col>
            <v-col justify-center align-center>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    @click="trimestriel('T3')"
                    :color="T_trimestriel == 'T3' ? 'primary' : ''"
                    large
                  >
                    <v-icon>mdi-calendar</v-icon>
                    <strong>T3</strong>
                  </v-btn>
                </template>
                <span>Trimestriel 3</span>
              </v-tooltip>
            </v-col>
            <v-col justify-center align-center>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    @click="trimestriel('T4')"
                    :color="T_trimestriel == 'T4' ? 'primary' : ''"
                    large
                  >
                    <v-icon>mdi-calendar</v-icon>
                    <strong>T4</strong>
                  </v-btn>
                </template>
                <span>Trimestriel 4</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </card-card>
</template>
<script>
import axios from "axios";

import widgetsMixin from "./../widgetsMixin";
export default {
  mixins: [widgetsMixin],
  data: () => ({
    date: new Date().getFullYear() ,
    statut: "valide",
    CA: 0,
    Recettes: 0,
    Depenses: 0,
    TVA: 0,
    Resultat: 0,

    T1: {
      CA: 0,
      Recettes: 0,
      Depenses: 0,
      TVA: 0,
      Resultat: 0,
    },
    T2: {
      CA: 0,
      Recettes: 0,
      Depenses: 0,
      TVA: 0,
      Resultat: 0,
    },
    T3: {
      CA: 0,
      Recettes: 0,
      Depenses: 0,
      TVA: 0,
      Resultat: 0,
    },
    T4: {
      CA: 0,
      Recettes: 0,
      Depenses: 0,
      TVA: 0,
      Resultat: 0,
    },

    T_trimestriel: "T1",
    T_CA: 0,
    T_Recettes: 0,
    T_Depenses: 0,
    T_TVA: 0,
    T_Resultat: 0,
  }),
  computed: {
    Base_url_web() {
      return this.$store.getters["get_Base_url"];
    },
  },
  methods: {
    start() {
      let path = "/apidashboardaccueil";
      axios
        .get(this.Base_url_web + path, { params: { date: this.date, statut: this.statut } })
        .then((response) => {
          let data = response.data;

          this.CA = data.CA;
          this.Recettes = data.Recettes;
          this.Depenses = data.Depenses;
          this.TVA = data.TVA;
          this.Resultat = data.Resultat;
          this.T1 = data.T1;
          this.T2 = data.T2;
          this.T3 = data.T3;
          this.T4 = data.T4;
          this.trimestriel("T1");
        })
        .catch((error) => {
          console.error("path");
        });
    },
    trimestriel(T) {
      this.T_trimestriel = T;
      this.T_CA = this[T]["CA"];
      this.T_Recettes = this[T]["Recettes"];
      this.T_Depenses = this[T]["Depenses"];
      this.T_TVA = this[T]["TVA"];
      this.T_Resultat = this[T]["Resultat"];
    },
  },

  mounted() {
    this.start();
  },watch:{
    statut(val){
      this.start();
    }
  }
};
</script>