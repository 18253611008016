<template>
  <v-card
    
    class="v-card--material--has-heading v-card--material pa-3 mb-16 mt-12 "
     elevation="8"
    width="100%"
  >
    <v-alert border="left" colored-border :color="'grey'" >
      <v-row>
        <v-col cols="6" >
          <div class="d-flex grow flex-wrap">
            <v-avatar
            
              rounded
              :size="150"
              class="mx-auto v-card--material __avatar elevation-6"
              color="transparent"
            >


            <v-img
             v-if="item.refGroup != '' && item.refGroup != null"
            
             :src="Base_url + item.minifile"
    :alt="item.originalName"
             ></v-img>
            
              <v-icon x-large v-else>mdi-file</v-icon>
            </v-avatar>
          </div>
        </v-col>
        <v-col cols="6" class="ml-0 text-truncate"  >
            <div class="text-overline mb-2 ">
              <v-chip class="mr-1 " label >
                {{ item.originalName }}
              </v-chip>
            </div>
          
        </v-col>
      </v-row>

    

      <v-row>
        <v-col cols="12">
          <div class="ml-2">
             <div class="font-weight-light text-h7" v-text="item.telephone" />
            <div class="font-weight-light text-h7" v-text="item.email" />
          </div>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mr-2"
              fab
              dark
              small
              color="orange"
              @click="showItem(item)"
              v-show="isGranted('view', item)"
              v-on="on"
            >
              <v-icon>mdi-view-carousel</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("Consulter") }}</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mr-2"
              fab
              dark
              small
              color="orange"
              @click="editItem(item)"
              v-show="isGranted('edit', item)"
              v-on="on"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("Modifier") }}</span>
        </v-tooltip>

        <v-spacer></v-spacer>

        <v-btn icon @click="show = !show">
          <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="show">
          <v-divider></v-divider>

          <v-card-text>
            <p>ID: {{ item.id }}</p>
         
          
            <p>code:{{ item.refGroup }}</p>
            <p>type:{{ item.type }}</p>
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-alert>
  </v-card>
</template>

<script>
export default {
  props: {
    item: { type: Object },
    controller_root: {},
  },
  data: () => ({
    show: false,
  }),
  computed: {
    Base_url() {
      return this.$store.getters["get_Base_url"];
    },
  },
  methods: {
  

    isGranted(attribute, item) {
      return this.$store.getters["security/isGranted"](
        attribute,
        this.controller_root,
        item
      );
    },
    showItem() {
      let item = this.item;
      if (this.slot_show) {
        this.$store.dispatch("custom_fromById", {
          names_controllers: [this.controller_root],
          id: item.id,
        });

        this.$store.commit("dialog/set_dialog", {
          data: item,
          flag: true,
          type: "show",
          controller: this.controller_root,
        });
      } else {
        this.$router.push({
          name: this.controller_root + "_show_item",
          params: { id: item.id },
        });
      }
    },
    editItem() {
      let item = this.item;
      if (this.slot_show) {
        this.$store.dispatch("custom_fromById", {
          names_controllers: [this.controller_root],
          id: item.id,
        });

        this.$store.commit("dialog/set_dialog", {
          data: item,
          flag: true,
          type: "update",
          controller: this.controller_root,
        });
      } else {
        this.$router.push({
          name: this.controller_root + "_update",
          params: { id: item.id },
        });
      }
    },
  },
};
</script>

<style scoped>
.v-card--material .__avatar {
  position: relative;
  top: -64px;
  margin-bottom: -32px;
}

.v-card--material .__heading {
  position: relative;
  top: -40px;
  transition: 0.3s ease;
  z-index: 1;
}
</style>
