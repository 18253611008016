<template>
  
    <input 
    type="hidden" 
     :value="values_binds"  
     @input="modelbind($event)" />
 
</template>
<script>
import InputMixin from "./InputMixin";
export default {
     mixins: [InputMixin],
};
</script>