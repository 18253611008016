<template>
  <v-list-item ripple="ripple" rel="noopener">
   
    <v-select
      prepend-icon="mdi-flag-variant-outline"
      :items="langs"
      item-text="state"
      item-value="lang"
      v-model="$i18n.locale"
      outlined
    >
    </v-select>
     <p v-show="false">{{langsselect}}</p>
  </v-list-item>
</template>

<script>
export default {
  data() {
    return {
      select: { state: "Français", lang: "fr" },
    };
  },
  computed: {
    langs() {
      let lang = [];
      for (const key in this.$i18n.messages) {
        lang.push({ state: this.$i18n.messages[key]["lang"], lang: key });
      }
      return lang;
    },
     langsselect() {

      this.$vuetify.lang.current = this.$i18n.locale.split("-")[0].toLowerCase();
      return this.$i18n.locale;
    },
  },

  
};
</script>

<style>
</style>