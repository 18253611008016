<template>
  <v-card
    class="v-card--material--has-heading v-card--material pa-3 mb-16 mt-12"
    elevation="8"
    width="100%"
  >
    <v-alert
      border="left"
      colored-border
    
    >
      <v-row>
        <v-col cols="6">
          <div class="d-flex grow flex-wrap mb-0 pb-0">
            <v-avatar
              rounded
              :size="150"
              class="mx-auto v-card--material __avatar elevation-6"
              color="grey"
            >
              <readfile-img
                :refgroup="item.user.logo"
                :size="150"
                v-if="item.user.logo != '' && item.user.logo != null"
              />
              <v-icon x-large v-else>mdi-human-dolly</v-icon>
            </v-avatar>
          </div>
          <v-card-title class="mt-n5 pt-0">{{
            item.user.identifiant
          }}</v-card-title>
        </v-col>
        <v-col cols="6" class="ml-0 text-truncate">
          <strong
            class="red--text text--lighten-1 text-h7"
            v-if="
              item.titre != null && item.titre.trim() != ''
            "
          >
           {{$t('titre')}} :
          </strong>
          <span class="text-h6"> {{ item.titre }}</span>
          <br />

          <div class="text-overline mb-2">
            <v-chip class="mr-1 weight--text" label color="orange">
              {{ item.user.identifiant }}
            </v-chip>
          </div>
        </v-col>
      </v-row>





      <v-row>
        <v-col cols="12" class="ml-2 mt-n8">
         
          
             <div class="font-weight-light text-h7" v-text="'Date à '+convertDate(item.date)" />
           
         
        </v-col>
      </v-row>

      <v-card-actions>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mr-2"
              fab
              dark
              small
              color="orange"
              @click="showItem(item)"
              v-show="isGranted('view', item)"
              v-on="on"
            >
              <v-icon>mdi-view-carousel</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("Consulter") }}</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mr-2"
              fab
              dark
              small
              color="orange"
              @click="editItem(item)"
              v-show="isGranted('edit', item)"
              v-on="on"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("Modifier") }}</span>
        </v-tooltip>

        <v-spacer></v-spacer>

        <v-btn icon @click="show = !show">
          <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="show">
          <v-divider></v-divider>

          <v-card-text>
            <p>ID: {{ item.id }}</p>
             <p>note: {{ item.note }}</p>

  <div>
    <v-responsive
     
      class="mx-auto "
    >
     
    </v-responsive>

    <v-card
      elevation="16"
     
      class="mx-auto"
    >
      <v-virtual-scroll
        
        :items="item.produitspertes"
        :height="64*item.produitspertes.length"
        item-height="64"
      >
        <template v-slot:default="{ item }">

          <v-list-item :key="item.id">

            <v-list-item-action>
              <v-btn
                
                color="orange"
                depressed
                
              >
                {{ item.qte }} qte
              </v-btn>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.produits.designation }} 
              </v-list-item-title>
            </v-list-item-content>

           
          </v-list-item>

          <v-divider></v-divider>
        </template>
      </v-virtual-scroll>
    </v-card>

      <v-card
      elevation="16"
     
      class="mx-auto"
    >
      <v-virtual-scroll
        
        :items="item.recettepertes"
        :height="64*item.recettepertes.length"
        item-height="64"
      >
        <template v-slot:default="{ item }">

          <v-list-item :key="item.id">

            <v-list-item-action>
              <v-btn
                
                color="orange"
                depressed
                
              >
                {{ item.qte }} qte
              </v-btn>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.recetteracine.name }} 
              </v-list-item-title>
            </v-list-item-content>

           
          </v-list-item>

          <v-divider></v-divider>
        </template>
      </v-virtual-scroll>
    </v-card>
  </div>





            
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-alert>
  </v-card>
</template>

<script>
export default {
  props: {
    item: { type: Object },
    controller_root: {},
  },
  data: () => ({
    show: false,
  }),
  methods: {
     convertDate(inputFormat) {
      if (
        inputFormat == "" ||
        inputFormat == null ||
        inputFormat == undefined
      ) {
        return "";
      }
      function pad(s) {
        return s < 10 ? "0" + s : s;
      }
      var d = new Date(inputFormat);
      return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(
        "/"
      );
      
    },
  

    isGranted(attribute, item) {
      return this.$store.getters["security/isGranted"](
        attribute,
        this.controller_root,
        item
      );
    },
    showItem() {
      let item = this.item;
      if (this.slot_show) {
        this.$store.dispatch("custom_fromById", {
          names_controllers: [this.controller_root],
          id: item.id,
        });

        this.$store.commit("dialog/set_dialog", {
          data: item,
          flag: true,
          type: "show",
          controller: this.controller_root,
        });
      } else {
        this.$router.push({
          name: this.controller_root + "_show_item",
          params: { id: item.id },
        });
      }
    },
    editItem() {
      let item = this.item;
      if (this.slot_show) {
        this.$store.dispatch("custom_fromById", {
          names_controllers: [this.controller_root],
          id: item.id,
        });

        this.$store.commit("dialog/set_dialog", {
          data: item,
          flag: true,
          type: "update",
          controller: this.controller_root,
        });
      } else {
        this.$router.push({
          name: this.controller_root + "_update",
          params: { id: item.id },
        });
      }
    },
  },
};
</script>

<style scoped>
.v-card--material .__avatar {
  position: relative;
  top: -64px;
  margin-bottom: -32px;
}

.v-card--material .__heading {
  position: relative;
  top: -40px;
  transition: 0.3s ease;
  z-index: 1;
}
</style>
