module.exports = {
  id: { typeHtml: "hidden" },
  email: { typeHtml: "email", nullable: false},
  identifiant: { typeHtml: "text", nullable: false },

  name: { typeHtml: "text" },
  telephone: { typeHtml: "tel" },
  adresse: { typeHtml: "Textarea" },
  logo: { typeHtml: "logo" },

  active: { typeHtml: "checkbox", defaultValue: "1"  },
  isAdmin: { typeHtml: "checkbox" , defaultValue: "0" },
  isvalidateur: { typeHtml: "checkbox" , defaultValue: "0" },
  isserveur: { typeHtml: "checkbox" , defaultValue: "0" },
  isdispatcher: { typeHtml: "checkbox" , defaultValue: "0" },
  ischeff: { typeHtml: "checkbox" , defaultValue: "0" },
  ischeffmaster: { typeHtml: "checkbox", defaultValue: "0"  },

  updatedAt: { typeHtml: "hidden" },
  createdAt: { typeHtml: "hidden" },
  password: { typeHtml: "password" },

  
};
