<template>
  <v-data-table
 dense
        :loading="loading"
        class="elevation-1"
        no-results-text="Aucun élément trouvé"
        no-data-text="Aucun élément trouvé"
  
    hide-default-footer
        :headers="headers"
        :items="selected"
        
       
 :items-per-page="selected.length"

        :item-key="item_key"
        
  
      >
  

        <template v-slot:expanded-item="{ headers, item }">
          <table-expand
            :controller_root="controller_root"
            :item="item"
            :numbretd="headers.length"
          ></table-expand>
        </template>

        <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->
        <template v-slot:item.action="{ item }">
          <table-action
            :item="item"
            :controller_root="controller_root"
            :slot_show="slot_show"
            :action_traitement="action_traitement"
          ></table-action>
        </template>

        <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->
        <template v-slot:item.id="{ item }">
          <v-chip small   color="orange" dark
            ><strong>{{ item.id }}</strong></v-chip
          >
        </template>

        <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->
        <template v-slot:item.date="{ item }">
          <v-chip small   v-show="item.date">
            <v-icon color="orange" small class="pr-2"
              >mdi-calendar-clock-outline</v-icon
            >
            {{ convertDate(item.date) }}</v-chip
          >
        </template>
        <template v-slot:item.createdAt="{ item }">
          <v-chip small   v-show="item.createdAt">
            <v-icon color="orange" small class="pr-2"
              >mdi-calendar-clock-outline</v-icon
            >
            {{ convertDate(item.createdAt) }}</v-chip
          >
        </template>
        <template v-slot:item.updatedAt="{ item }">
          <v-chip small   v-show="item.updatedAt">
            <v-icon color="orange" small class="pr-2"
              >mdi-calendar-clock-outline</v-icon
            >
            {{ convertDate(item.updatedAt) }}</v-chip
          >
        </template>
        <template v-slot:item.dateUpload="{ item }">
          <v-chip small   v-show="item.dateUpload">
            <v-icon color="orange" small class="pr-2"
              >mdi-calendar-clock-outline</v-icon
            >
            {{ convertDate(item.dateUpload) }}</v-chip
          >
        </template>

        <template v-slot:item.dateCommande="{ item }">
          <v-chip small   v-show="item.dateCommande">
            <v-icon color="orange" small class="pr-2"
              >mdi-calendar-clock-outline</v-icon
            >
            {{ convertDate(item.dateCommande) }}</v-chip
          >
        </template>
        <template v-slot:item.dateLivraison="{ item }">
          <v-chip small   v-show="item.dateLivraison">
            <v-icon color="orange" small class="pr-2"
              >mdi-calendar-clock-outline</v-icon
            >
            {{ convertDate(item.dateLivraison) }}</v-chip
          >
        </template>

        <template v-slot:item.dateoperation="{ item }">
          <v-chip small   v-show="item.dateoperation">
            <v-icon color="orange" small class="pr-2"
              >mdi-calendar-clock-outline</v-icon
            >
            {{ convertDate(item.dateoperation) }}</v-chip
          >

          }</template
        >

        <template v-slot:item.lastdateachat="{ item }">
          <v-chip small   v-show="item.lastdateachat">
            <v-icon color="orange" small class="pr-2"
              >mdi-calendar-clock-outline</v-icon
            >
            {{ convertDate(item.lastdateachat) }}</v-chip
          >
        </template>

        <template v-slot:item.icon="{ item }">
          <v-icon> {{ item.icon }} </v-icon>
        </template>

        <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->
        <template v-slot:item.files="{ item }">
          <!-- <table-setfiles v-if="!item.files || item.files==''" name="files" :controller_root="controller_root" :id="item.id"  ></table-setfiles>
            -->
          <table-avatar
            :controller_root="controller_root"
            name="files"
            :id="item.id"
            size="30px"
            :refgroup="item.files"
            :hasdowlaod="true"
          ></table-avatar>
        </template>
        <template v-slot:item.file="{ item }">
          <!-- <table-setfiles v-if="!item.file || item.file==''" name="file" :controller_root="controller_root" :id="item.id"  ></table-setfiles>
        -->
          <table-avatar
            :controller_root="controller_root"
            name="file"
            :id="item.id"
            size="30px"
            :refgroup="item.file"
            :hasdowlaod="true"
          ></table-avatar>
        </template>
        <template v-slot:item.logo="{ item }">
          <!-- <table-setfiles v-if="!item.logo || item.logo==''" name="logo" :controller_root="controller_root" :id="item.id"  ></table-setfiles>
        -->
          <table-avatar
            :controller_root="controller_root"
            name="logo"
            :id="item.id"
            size="30px"
            :refgroup="item.logo"
            :hasdowlaod="true"
          ></table-avatar>
        </template>

     

        <!-- foreign_key wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->

        <template v-slot:item.user="{ item }">
          <table-foreignkey
            :itemObject="item.user"
            :itemShowString="['identifiant']"
          ></table-foreignkey>
        </template>

        <template v-slot:item.client="{ item }">
          <table-foreignkey
            :itemObject="item.client"
            :itemShowString="['identifiant']"
          ></table-foreignkey>
        </template>
        <template v-slot:item.validateur="{ item }">
          <table-foreignkey
            :itemObject="item.validateur"
            :itemShowString="['identifiant']"
          ></table-foreignkey>
        </template>

        <template v-slot:item.serveur="{ item }">
          <table-foreignkey
            :itemObject="item.serveur"
            :itemShowString="['identifiant']"
          ></table-foreignkey>
        </template>
        <template v-slot:item.mastercheff="{ item }">
          <table-foreignkey
            :itemObject="item.mastercheff"
            :itemShowString="['identifiant']"
          ></table-foreignkey>
        </template>

        <template v-slot:item.fournisseur="{ item }">
          <table-foreignkey
            :itemObject="item.fournisseur"
            :itemShowString="['fournisseur']"
          ></table-foreignkey>
        </template>

        <template v-slot:item.modepaiement="{ item }">
          <table-foreignkey
            :itemObject="item.modepaiement"
            :itemShowString="['modepaiement']"
          ></table-foreignkey>
        </template>

        <template v-slot:item.categories="{ item }">
          <table-foreignkey
            :itemObject="item.categories"
            :itemShowString="['categorie']"
          ></table-foreignkey>
        </template>

        <template v-slot:item.categorieplat="{ item }">
          <table-foreignkey
            :itemObject="item.categorieplat"
            :itemShowString="['categorie']"
          ></table-foreignkey>
        </template>

        <template v-slot:item.inventaire="{ item }">
          <table-foreignkey
            :itemObject="item.inventaire"
            :itemShowString="['createdAt']"
          ></table-foreignkey>
        </template>

        <template v-slot:item.recette="{ item }">
          <table-foreignkey
            :itemObject="item.recette"
            :itemShowString="['name']"
          ></table-foreignkey>
        </template>

        <template v-slot:item.recetteracine="{ item }">
          <table-foreignkey
            :itemObject="item.recetteracine"
            :itemShowString="['name']"
          ></table-foreignkey>
        </template>

        <template v-slot:item.commandes="{ item }">
          <table-foreignkey
            :itemObject="item.commandes"
            :itemShowString="['code']"
          ></table-foreignkey>
        </template>
        <template v-slot:item.plat="{ item }">
          <table-foreignkey
            :itemObject="item.plat"
            :itemShowString="['name']"
          ></table-foreignkey>
        </template>

        <template v-slot:item.produits="{ item }">
          <table-foreignkey
            :itemObject="item.produits"
            :itemShowString="['designation']"
          ></table-foreignkey>
        </template>

        <template v-slot:item.bonsachats="{ item }">
          <table-foreignkey
            :itemObject="item.bonsachats"
            :itemShowString="['reffournisseur']"
          ></table-foreignkey>
        </template>
        <template v-slot:item.perte="{ item }">
          <table-foreignkey
            :itemObject="item.perte"
            :itemShowString="['titre']"
          ></table-foreignkey>
        </template>

        <template v-slot:item.color="{ item }">
          <v-card
            style="min-width: 64px; height: 34px"
            :color="item.color"
            class="align-center justify-center"
          >
            <v-card-text>{{ item.color }}</v-card-text>
          </v-card>
        </template>

        <template v-slot:item.stockalerte="{ item }">
          <v-chip small  >
            <v-icon color="error" small class="pr-2">mdi-bell-ring</v-icon>
            {{ item.stockalerte }}</v-chip
          >
        </template>

        <template v-slot:item.unite="{ item }">
          <v-chip small  >
            <v-icon color="orange" small class="pr-2">mdi-scale</v-icon>
            {{ item.unite }}</v-chip
          >
        </template>

        <template v-slot:item.stock="{ item }">
          <v-chip small  >
            <v-icon color="orange" small class="pr-2"
              >mdi-database-eye-outline</v-icon
            >
            {{ item.stock }}</v-chip
          >
        </template>

     
      </v-data-table>
</template>

<script>
import util from "@/util/index";
import i18n from "@/plugins/i18n";

export default {
  props: {
    controller_root: {},

    slot_show: { default: false },
    action_traitement: { default: true },

    calculemontantitem: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      isMobile: false,

    
    };
  },

  computed: {
   

    notaffiche() {
      return this.$store.getters["show_data/g_not_affiche"]({
        controller_root: this.controller_root,
      });
    },

  
    
  
 

    selected() {
      return this.$store.getters["show_data/g_selected"]({
        controller_root: this.controller_root,
      });
    },
    item_key() {
      return this.$store.getters["show_data/g_item_key"]({
        controller_root: this.controller_root,
      });
    },
    expanded() {
      return this.$store.getters["show_data/g_expanded"]({
        controller_root: this.controller_root,
      });
    },
    namesexpanded() {
      let expanded = this.expanded;
      let namesexpanded = [];
      for (const key in expanded) {
        namesexpanded.push(expanded[key]["controller_attach"]);
      }
      return namesexpanded;
    },
    headers() {
      //************************************************************ */
      let name_input_files = [];
  

      let headers = this.$store.getters["show_data/g_headers"]({
        controller_root: this.controller_root,
        namesexpanded: this.namesexpanded,
        notaffiche: this.notaffiche,
        name_input_files,
      });
    

 

      return headers;
    },

    loading() {
      return this.$store.state["show_data"].loading;
    },
  },
  methods: {
   
  
   

  
   
    convertDate(inputFormat) {
      if (
        inputFormat == "" ||
        inputFormat == null ||
        inputFormat == undefined
      ) {
        return "";
      }
      function pad(s) {
        return s < 10 ? "0" + s : s;
      }
      var d = new Date(inputFormat);
      return (
        [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/") +
        " " +
        [pad(d.getHours()), pad(d.getMinutes())].join(":")
      );
    },
  

  

  
  },
};
</script>
