<template>

    <div>
      <readfile-imagecoollightbox :images="images" :size="size"  style="display: inline">
      </readfile-imagecoollightbox>

      <v-avatar
        style="display: inline"
        :size="size"
        v-for="(file, indexke) in files_not_viewer"
        :key="indexke"
        @click="dowlaod(file.pathDownload, file.originalName)"
      >
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon color="primary" v-on="on" :size="size"
              >mdi-file-download-outline</v-icon
            >
          </template>
          <span>{{ file.originalName }}</span>
        </v-tooltip>
      </v-avatar>

     <!--  <v-file-input
        style="display: inline-block; float: right"
     
        @change="modelbindfile($event)"
        hide-input
        :multiple="true"
      >
      </v-file-input> -->

      <v-row v-if="valueProgress > 0">
        <v-progress-linear
          :value="valueProgress"
          v-show="valueProgress > 0"
        ></v-progress-linear>
      </v-row>
    </div>

</template>
<script>
export default {
  props: {
    id: Number,
    controller_root: String,
    name: String,
    refgroup: {
      type: String,
      default: "",
    },
    groupfilses: {
      type: Array,
      default: function () {
        return [];
      },
    },
    hasdowlaod: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "29px",
    },
  },
  data: () => ({
    valueProgress: 0,
   
    val: "",
    files_valueshow: [],
    files_not_viewer: [],
    images: [],
    index: null,
  }),
  methods: {
    setIndex(index) {
      this.index = index;
    },
    getfile(val) {
      this.files_valueshow = [];

      if (val != "" && val != undefined) {
        this.$store.dispatch("files/get_files_save", {
          refGroup: val,
          fun_finally: (info) => {
            this.gestionfiles(info);
          },
        });
      }
    },

    gestionfiles(info) {
      this.files_valueshow = info;
      this.images = [];
      this.files_not_viewer = [];
      let Base_url=this.Base_url

      for (const key in info) {
        let file = info[key];

        if (
          [
             "image/avif",
             "image/jpg",
            "image/gif",
            "image/jpeg",
           
            "image/png",
            "image/tiff",
            "image/vnd.microsoft.icon",
            "image/vnd.djvu",
          ].includes(file.type)
        ) {
          this.images.push({
            title: file["originalName"],
            description: file["type"],

            src: Base_url+file["pathDownload"],
            mediaType: "image",
            backgroundImage: Base_url+file["minifile"],
          });
        } else if (["application/pdf"].includes(file.type)) {
          this.images.push({
            title: file["originalName"],
            description: file["type"],

            src: Base_url+file["pathDownload"],

            mediaType: "iframe",
             thumb:Base_url+ "/src/img/pdf.png",
            backgroundImage:Base_url+ "/src/img/pdf.png",
          });
        } else if (
          ["video/mp4", "video/webm", "video/ogg"].includes(file.type)
        ) {
          this.images.push({
            title: file["originalName"],
            description: file["type"],

            src:Base_url+ file["pathDownload"],
            thumb:Base_url+ "/src/img/video.png",
            backgroundImage:Base_url+ "/src/img/video.png",
          });
        } else {
            this.images.push({
            title: file["originalName"],
            description: file["type"],

            src: Base_url+file["pathDownload"],
            mediaType: "image",
            backgroundImage: Base_url+file["minifile"],
          });
        }
      }
    },
   dowlaod(src_file, name) {
      let Base_url=this.Base_url
      if (this.hasdowlaod) {
        var a = document.createElement("a");
        a.href =Base_url+ src_file;
        a.download = "" + name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    },

    modelbindfile(event) {

      if (event == undefined) {
        // this.modelbind("");
      }
      if (Array.isArray(event)) {
        this.valueProgress = 0;
        /// send files to server and respons refgroup
        this.$store.dispatch("files/send_files", {
          controller_root: this.controller_root,
          files_input: event,
          name_input: this.name,
          refGroup: this.val,

          fun_progress: (etat) => {
            this.valueProgress = etat;
          },
          fun_finally: (refGroup) => {

            this.modelbind(refGroup);
            this.getfile(refGroup);

            this.valueProgress = 0;
          },
        });
      }
    },

    modelbind(val) {
      this.val = val;
     

      this.$store.dispatch("send/send_refGroup_files", {
        controller_root: this.controller_root,
        controller_id: this.id,
        name: this.name,
        value: val,
      });
      
      this.$store.commit("dialog/set_dialog_btn_send", { flag: true });
    },
  },

  watch: {
    refgroup(val) {
      this.getfile(val);
    },
  },

  mounted() {
    this.val=this.refgroup;
    if (this.refgroup != "") {

      this.getfile(this.refgroup);
    } else {
      this.gestionfiles(this.groupfilses);
    }
  },computed: {
    Base_url() {
      return this.$store.getters["get_Base_url"];
    },}
};
</script>
<style scoped>
</style>