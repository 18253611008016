<template>
  <div class="v-list_add">
    <div class="v-main_table" v-if="headers.length > 0">
      <!--  plugine-->
      <table-headers
        :title="title"
        :controller_root="controller_root"
        :slot_show="true"
        :action_traitement="action_traitement"
      ></table-headers>

      <v-col cols="3">
        <v-text-field
          append-icon="mdi-lastpass"
          type="number"
          v-model="last_items"
          @click:append="get_date_http"
        >
        </v-text-field>
      </v-col>

      <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->
      <v-data-table
        :loading="loading"
        class="elevation-1"
        no-results-text="Aucun élément trouvé"
        no-data-text="Aucun élément trouvé"
        :show-expand="expanded.length > 0"
        :single-expand="true"
        :headers="headers"
        :items="items"
        :show-select="true"
        :value="selected"
        @input="select($event)"
        :item-key="item_key"
        :options.sync="optionsDataTable"
        :server-items-length="TotalItemCount"
      >
        <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->

        <template v-slot:expanded-item="{ headers, item }">
          <table-expand
            :controller_root="controller_root"
            :item="item"
            :numbretd="headers.length"
          ></table-expand>
        </template>

        <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->
        <template v-slot:item.action="{ item }">
          <table-action
            :item="item"
            :controller_root="controller_root"
            :slot_show="slot_show"
            :action_traitement="action_traitement"
          ></table-action>
        </template>

        <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->

        <template v-slot:item.idevent="{ item }">
          <v-chip color="primary" dark>
            <strong>{{ $t(item.controller) }} =>ID / {{ item.idevent }}</strong>
          </v-chip>
        </template>

        <template v-slot:item.levelName="{ item }">
          <v-chip color="blue" dark v-if="item.levelName == 'add'">
            <strong>{{ $t(item.levelName) }}</strong>
          </v-chip>
          <v-chip color="green" dark v-if="item.levelName == 'update'">
            <strong>{{ $t(item.levelName) }}</strong>
          </v-chip>
          <v-chip color="red" dark v-if="item.levelName == 'delete'">
            <strong>{{ $t(item.levelName) }}</strong>
          </v-chip>
          <v-chip color="pink" dark v-if="item.levelName == 'login'">
            <strong>{{ $t(item.levelName) }}</strong>
          </v-chip>
        </template>

        <template v-slot:item.controller="{ item }">{{
          $t(item.controller)
        }}</template>
        <template v-slot:item.module="{ item }">{{ $t(item.module) }}</template>

        <template v-slot:item.createdAt="{ item }">{{
          convertDate(item.createdAt)
        }}</template>

        <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->

        <template v-slot:item.logo="{ item }">
          <readfile-avatar
            size="30px"
            :refgroup="item.logo"
            :hasdowlaod="true"
          ></readfile-avatar>
        </template>

        <template v-slot:item.user="{ item }">
          <table-foreignkey
            controller="user"
            :id="item['user_id']"
            :text="item.user"
            :itemshow="['identifiant', 'prenom', 'nom', 'telephone', 'gsm']"
          ></table-foreignkey>
        </template>
      </v-data-table>
      <!--  plugine-->
      <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->
      <!--
        
          :hide-default-footer="true"
        :page="page"
        :items-per-page="itemsPerPage"
        @page-count="page_count($event)"
        
        
        
         <table-pagination :items="items" :controller_root="controller_root"></table-pagination> -->
      <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->

      <table-dialog :controller_root="controller_root" v-if="slot_show">
        <template v-slot:show>
          <slot name="show"></slot>
        </template>

        <template v-slot:add>
          <slot name="add"></slot>
        </template>

        <template v-slot:update>
          <slot name="update"></slot>
        </template>
      </table-dialog>
    </div>
  </div>
</template>

<script>
import util from "@/util/index";

import { mapGetters } from "vuex";
export default {
  props: {
    controller_root: {},
    slot_show: { default: false },
    action_traitement: { default: true },
  },
  data() {
    return { optionsDataTable: {}, last_items: 300 };
  },
  watch: {
    optionsDataTable(options) {
      this.get_date_http();
    },
    search(search) {
      this.get_date_http();
    },
  },

  computed: {
    title() {
      return this.$store.getters["show_data/g_title"]({
        controller_root: this.controller_root,
      });
    },
    notaffiche() {
      return this.$store.getters["show_data/g_not_affiche"]({
        controller_root: this.controller_root,
      });
    },

    search() {
      return this.$store.getters["show_data/g_search"]({
        controller_root: this.controller_root,
      });
    },
    page() {
      return this.$store.getters["show_data/g_page"]({
        controller_root: this.controller_root,
      });
    },
    itemsPerPage() {
      return this.$store.getters["show_data/g_itemsPerPage"]({
        controller_root: this.controller_root,
      });
    },
    TotalItemCount() {
      return this.$store.getters["show_data/g_TotalItemCount"]({
        controller_root: this.controller_root,
      });
    },
    items() {
      return this.$store.getters["show_data/g_items"]({
        controller_root: this.controller_root,
      });
    },
    selected() {
      return this.$store.getters["show_data/g_selected"]({
        controller_root: this.controller_root,
      });
    },
    item_key() {
      return this.$store.getters["show_data/g_item_key"]({
        controller_root: this.controller_root,
      });
    },
    expanded() {
      return this.$store.getters["show_data/g_expanded"]({
        controller_root: this.controller_root,
      });
    },
    namesexpanded() {
      let expanded = this.expanded;
      let namesexpanded = [];
      for (const key in expanded) {
        namesexpanded.push(expanded[key]["controller_attach"]);
      }
      return namesexpanded;
    },
    headers() {
      //************************************************************ */
      let name_input_files = [];
      /*    let shema_inputs = this.$store.getters["g_SCHEMA_GlobalHTML"][this.controller_root];
    

      if (shema_inputs != undefined) {
        for (const shema_input of shema_inputs["html"]) {
          if (shema_input.type == "file" || shema_input.type == "files" || shema_input.type == "logo") {
            name_input_files.push(shema_input.name);
          }
        }
      } */

      //*********************************************************************** */

      let headers = this.$store.getters["show_data/g_headers"]({
        controller_root: this.controller_root,
        namesexpanded: this.namesexpanded,
        notaffiche: this.notaffiche,
        name_input_files,
      });

      headers.unshift({
        text: "createdAt_time",
        value: "createdAt_time",

        align: "start",
      });

      if (this.action_traitement) {
        headers.push({
          text: "Les Actions des traitement ",
          value: "action",
          sortable: false,
          align: "end",
        });
      }

      return headers;
    },
    loading() {
      return this.$store.state["show_data"].loading;
    },
  },
  methods: {
    get_date_http() {
      /* this.optionsDataTable["itemsPerPage"]=-1
      this.optionsDataTable["sortBy"]=[]
      this.optionsDataTable["sortDesc"]=[] */

      console.log(this.search);
      setTimeout(() => {
        const params = {
          [this.controller_root]: {
            optionsDataTable: JSON.stringify(this.optionsDataTable),
            searchDataTable: JSON.stringify(this.search),
            AttributesTableHide: JSON.stringify(this.notaffiche),
            last_items: this.last_items,
          },
        };
        this.$store.dispatch("run_show", {
          names_controllers: undefined,
          params,
        });
        console.log(this.controller_root);
        console.log(this.optionsDataTable);
        console.log(this.search);
      }, 300);
    },
    customsearch: util.customsearchTable,
    convertDate(inputFormat) {
      function pad(s) {
        return s < 10 ? "0" + s : s;
      }
      var d = new Date(inputFormat);
      return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(
        "/"
      );
    },
    page_count(val) {
      this.$store.commit(
        "show_data/set_pageCount",

        {
          data: val,
          controller_root: this.controller_root,
        }
      );
    },
    select(selected) {
      this.$store.commit(
        "show_data/set_selected",

        {
          data: selected,
          controller_root: this.controller_root,
        }
      );
    },

    isGranted(attribute) {
      return this.$store.getters["security/isGranted"](
        attribute,
        this.controller_root
      );
    },
  },
};
</script>
