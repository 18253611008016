module.exports = {
  id: { typeHtml: "hidden" },

  user: { typeHtml: "hidden" , to_string: "identifiant"},
  datestart: { typeHtml: "date" },
  datefin: { typeHtml: "date" },

  commandes: { typeHtml: "select", to_string: "code" },
  plat: { typeHtml: "select", to_string: "name" },
  recette: { typeHtml: "select", to_string: "name" },
  personnaliserecette: { typeHtml: "Textarea" },
  extrasrecette: { typeHtml: "Textarea" },

  qte: { typeHtml: "money" },
  note: { typeHtml: "text" },
  ok: { typeHtml: "checkbox" },
  isvalidecommande: { typeHtml: "checkbox" },
  updatedAt: { typeHtml: "hidden" },
  createdAt: { typeHtml: "hidden" },
};
