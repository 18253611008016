export default {

  get_connected: (state) => {
    return state.connected
  },
  get_connectionType: (state) => {
    return state.connectionType
  },

 

  get_username: (state) => {
    return state.username
  },

  get_password: (state) => {
    return state.password
  },

  get_JWTToken: (state) => {
    return state.JWTToken
  },
  
  get_Base_url: (state) => {
    return state.Base_url;
  },
  
  get_paths: (state) => {
    return state.paths;
  },
  get_attachs: (state) => {
    return state.attachs;
  },

  get_gestion_files: (state) => {
    return state.gestion_files;
  },
  get_statistiques: (state) => {
    return state.statistiques;
  },

  get_path: (state) => (controller) => {
    
    if (controller == undefined || typeof controller != "string") {
      console.error("path==undefined  de  code cache plz ....." + controller);
      return false;
    }
    let index = controller.toLowerCase();
    let path = state.paths[index];
    if (path == undefined) {
      index = index + "s";
      path = state.paths[index];
    }

    if (path == undefined) {
      console.error(
        "path==undefined  de  code cache plz ....." + index,
        state.paths
      );
      return false;
    }
    
    return path;
  },





  get_lazy: (state) => (controller) => {
  
    if (controller == undefined || typeof controller != "string") {
      console.error("path==undefined  de  code cache plz ....." + controller);
      return false;
    }
    let index = controller.toLowerCase();
    let path = state.lazy[index];
    if (path == undefined) {
      index = index + "s";
      path = state.lazy[index];
    }

    if (path == undefined) {
      console.error(
        "path==undefined  de  code cache plz ....." + index,
        state.lazy
      );
      return false;
    }

    return path;
  },
  get_created: (state) => (controller) => {
    return false;
    if (controller == undefined || typeof controller != "string") {
      console.error("path==undefined  de  code cache plz ....." + controller);
      return false;
    }
    let index = controller.toLowerCase();
    let path = state.created[index];
    if (path == undefined) {
      index = index + "s";
      path = state.created[index];
    }

    if (path == undefined) {
      console.error(
        "path==undefined  de  code cache plz ....." + index,
        state.created
      );
      return false;
    }

    return path;
  },

  get_dataselect: (state) => (controller) => {
   
    if (controller == undefined || typeof controller != "string") {
      console.error("path==undefined  de  code cache plz ....." + controller);
      return false;
    }
    let index = controller.toLowerCase();
    let path = state.dataselect[index];
    if (path == undefined) {
      index = index + "s";
      path = state.dataselect[index];
    }

    if (path == undefined) {
      console.error(
        "path==undefined  de  code cache plz ....." + index,
        state.dataselect
      );
      return false;
    }

    return path;
  },

  get_add: (state) => (controller) => {

    if (controller == undefined || typeof controller != "string") {
      console.error("path==undefined  de  code cache plz ....." + controller);
      return false;
    }
    let index = controller.toLowerCase();
    let path = state.add[index];
    if (path == undefined) {
      index = index + "s";
      path = state.add[index];
    }

    if (path == undefined) {
      console.error(
        "path==undefined  de  code cache plz ....." + index,
        state.add
      );
      return false;
    }

    return path;
  },
  get_update: (state) => (controller) => {
   
    if (controller == undefined || typeof controller != "string") {
      console.error("path==undefined  de  code cache plz ....." + controller);
      return false;
    }
    let index = controller.toLowerCase();
    let path = state.update[index];
    if (path == undefined) {
      index = index + "s";
      path = state.update[index];
    }

    if (path == undefined) {
      console.error(
        "path==undefined  de  code cache plz ....." + index,
        state.update
      );
      return false;
    }

    return path;
  },
  get_delete: (state) => (controller) => {
   
    if (controller == undefined || typeof controller != "string") {
      console.error("path==undefined  de  code cache plz ....." + controller);
      return false;
    }
    let index = controller.toLowerCase();
    let path = state.delete[index];
    if (path == undefined) {
      index = index + "s";
      path = state.delete[index];
    }

    if (path == undefined) {
      console.error(
        "path==undefined  de  code cache plz ....." + index,
        state.delete
      );
      return false;
    }

    return path;
  },
  get_importexcel: (state) => (controller) => {

    if (controller == undefined || typeof controller != "string") {
      return false;
    }
    let index = controller.toLowerCase();
    let importexcel = state.importexcel[index];
    if (importexcel == undefined) {
      return false;
    }

    return importexcel;
  },


















  get_attach: (state) => (controller) => {
    return false;
    if (controller == undefined || typeof controller != "string") {
      console.error("attach==undefined  de  code cache plz ....." + controller);
      return false;
    }
    let index = controller.toLowerCase();
    let attach = state.attachs[index];
    if (attach == undefined) {
      console.error(
        "attach==undefined  de  code cache plz ....." + index,
        state.attachs
      );
      return false;
    }

    return attach;
  },
  get_load: (state) => (controller) => {
    return false;
    if (controller == undefined || typeof controller != "string") {
      console.error("load==undefined  de  code cache plz ....." + controller);
      return false;
    }
    let index = controller.toLowerCase();
    let load = state.load[index];
    if (load == undefined) {
      console.error(
        "load==undefined  de  code cache plz ....." + index,
        state.load
      );
      return false;
    }

    return load;
  },
  get_pdf: (state) => (controller) => {
    return false;
    if (controller == undefined || typeof controller != "string") {
      return false;
    }
    let index = controller.toLowerCase();
    let pdf = state.pdf[index];
    if (pdf == undefined) {
      return false;
    }

    return pdf;
  },

  get_email: (state) => (controller) => {
    return false;
    if (controller == undefined || typeof controller != "string") {
      return false;
    }
    let index = controller.toLowerCase();
    let email = state.email[index];
    if (email == undefined) {
      return false;
    }
    

    return email;
  },

  get_fusionner: (state) => (controller) => {
    return false;
    if (controller == undefined || typeof controller != "string") {
      return false;
    }
    let index = controller.toLowerCase();
    let fusionner = state.fusionner[index];
    if (fusionner == undefined) {
      return false;
    }

    return fusionner;
  },


};
