<template>
  <td v-if="rows" :colspan="numbretd">
    <div v-for="(row, indexrows) in rows" :key="indexrows">
      <v-data-table
        :headers="headers[indexrows]"
        :items="dataSet[indexrows]"
        :items-per-page="items_per_page[indexrows]"
        hide-default-footer
        dense
      >
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr>
              <th
                :colspan="headers.length"
                class="blue lighten-1 center text-center black--text title"
              >
                {{ $t(titles[indexrows]) }}
              </th>
            </tr>
          </thead>
        </template>

        <template v-slot:item.date="{ item }">{{
          convertDate(item.date)
        }}</template>
        <template v-slot:item.createdAt="{ item }">{{
          convertDate(item.createdAt)
        }}</template>
        <template v-slot:item.updatedAt="{ item }">{{
          convertDate(item.updatedAt)
        }}</template>
        <template v-slot:item.dateUpload="{ item }">{{
          convertDate(item.dateUpload)
        }}</template>
        <template v-slot:item.dateCommande="{ item }">{{
          convertDate(item.dateCommande)
        }}</template>
        <template v-slot:item.dateLivraison="{ item }">{{
          convertDate(item.dateLivraison)
        }}</template>
        <template v-slot:item.dateoperation="{ item }">{{
          convertDate(item.dateoperation)
        }}</template>

        <template v-slot:item.files="{ item }">
          <table-setfiles
            v-if="!item.files || item.files == ''"
            name="files"
            :controller_root="controller_root"
            :id="item.id"
          ></table-setfiles>
          <readfile-avatar
            v-else
            size="30px"
            :refgroup="item.files"
            :hasdowlaod="true"
          ></readfile-avatar>
        </template>
        <template v-slot:item.file="{ item }">
          <table-setfiles
            v-if="!item.file || item.file == ''"
            name="file"
            :controller_root="controller_root"
            :id="item.id"
          ></table-setfiles>
          <readfile-avatar
            v-else
            size="30px"
            :refgroup="item.file"
            :hasdowlaod="true"
          ></readfile-avatar>
        </template>
        <template v-slot:item.logo="{ item }">
          <table-setfiles
            v-if="!item.logo || item.logo == ''"
            name="logo"
            :controller_root="controller_root"
            :id="item.id"
          ></table-setfiles>
          <readfile-avatar
            v-else
            size="30px"
            :refgroup="item.logo"
            :hasdowlaod="true"
          ></readfile-avatar>
        </template>

        <!-- foreign_key wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->

        <template v-slot:item.bonsVentes="{ item }">
          <table-foreignkey
            controller="bonsventes"
            :id="item['bonsVentes_id']"
            :text="item.bonsVentes"
            :itemshow="[
              'titre',
              'ref',
              'client',
              'montantTtc',
              'date',
              'entrepot',
              'user',
            ]"
          ></table-foreignkey>
        </template>
        <template v-slot:item.bonsAchats="{ item }">
          <table-foreignkey
            controller="bonsachats"
            :id="item['bonsAchats_id']"
            :text="item.bonsAchats"
            :itemshow="[
              'titre',
              'reffournisseur',
              'fournisseur',
              'montantTtc',
              'dateLivraison',
              'entrepot',
              'user',
            ]"
          ></table-foreignkey>
        </template>

        <template v-slot:item.bonsventes="{ item }">
          <table-foreignkey
            controller="bonsventes"
            :id="item['bonsventes_id']"
            :text="item.bonsventes"
            :itemshow="[
              'titre',
              'ref',
              'client',
              'montantTtc',
              'date',
              'entrepot',
              'user',
            ]"
          ></table-foreignkey>
        </template>
        <template v-slot:item.bonsachats="{ item }">
          <table-foreignkey
            controller="bonsachats"
            :id="item['bonsachats_id']"
            :text="item.bonsachats"
            :itemshow="[
              'titre',
              'reffournisseur',
              'fournisseur',
              'montantTtc',
              'dateLivraison',
              'entrepot',
              'user',
            ]"
          ></table-foreignkey>
        </template>
        <template v-slot:item.avoirsventes="{ item }">
          <table-foreignkey
            controller="avoirsventes"
            :id="item['avoirsventes_id']"
            :text="item.avoirsventes"
            :itemshow="[
              'titre',
              'ref',
              'client',
              'montantTtc',
              'date',
              'entrepot',
              'user',
            ]"
          ></table-foreignkey>
        </template>
        <template v-slot:item.avoirsachats="{ item }">
          <table-foreignkey
            controller="avoirsachats"
            :id="item['avoirsachats_id']"
            :text="item.avoirsachats"
            :itemshow="[
              'titre',
              'reffournisseur',
              'fournisseur',
              'montantTtc',
              'dateLivraison',
              'entrepot',
              'user',
            ]"
          ></table-foreignkey>
        </template>
        <template v-slot:item.facturesAchats="{ item }">
          <table-foreignkey
            controller="facturesachats"
            :id="item['facturesAchats_id']"
            :text="item.facturesAchats"
            :itemshow="[
              'titre',
              'reffournisseur',
              'fournisseur',
              'montantTtc',
              'date',
              'user',
            ]"
          ></table-foreignkey>
        </template>
        <template v-slot:item.facturesachats="{ item }">
          <table-foreignkey
            controller="facturesachats"
            :id="item['facturesachats_id']"
            :text="item.facturesachats"
            :itemshow="[
              'titre',
              'reffournisseur',
              'fournisseur',
              'montantTtc',
              'date',
              'user',
            ]"
          ></table-foreignkey>
        </template>
        <template v-slot:item.facturesventes="{ item }">
          <table-foreignkey
            controller="facturesventes"
            :id="item['facturesventes_id']"
            :text="item.facturesventes"
            :itemshow="['titre', 'ref', 'client', 'montantTtc', 'date', 'user']"
          ></table-foreignkey>
        </template>
        <template v-slot:item.facturesVentes="{ item }">
          <table-foreignkey
            controller="facturesventes"
            :id="item['facturesVentes_id']"
            :text="item.facturesVentes"
            :itemshow="['titre', 'ref', 'client', 'montantTtc', 'date', 'user']"
          ></table-foreignkey>
        </template>
        <template v-slot:item.user="{ item }">
          <table-foreignkey
            controller="user"
            :id="item['user_id']"
            :text="item.user"
            :itemshow="['identifiant', 'prenom', 'nom', 'telephone', 'gsm']"
          ></table-foreignkey>
        </template>
        <template v-slot:item.produit="{ item }">
          <table-foreignkey
            controller="produits"
            :id="item['produit_id']"
            :text="item.produit"
            :itemshow="['prixVenteHt', 'taxes', 'categoriesProduit', 'user']"
          ></table-foreignkey>
        </template>
        <template v-slot:item.produits="{ item }">
          <table-foreignkey
            controller="produits"
            :id="item['produits_id']"
            :text="item.produits"
            :itemshow="['prixVenteHt', 'taxes', 'categoriesProduit', 'user']"
          ></table-foreignkey>
        </template>
        <template v-slot:item.fournisseur="{ item }">
          <table-foreignkey
            controller="fournisseur"
            :id="item['fournisseur_id']"
            :text="item.fournisseur"
            :itemshow="[
              'personnephysiqueSociete',
              'ville',
              'ref',
              'telephone',
              'reduction',
            ]"
          ></table-foreignkey>
        </template>
        <template v-slot:item.client="{ item }">
          <table-foreignkey
            controller="client"
            :id="item['client_id']"
            :text="item.client"
            :itemshow="[
              'personnephysiqueSociete',
              'ville',
              'ref',
              'telephone',
              'reduction',
            ]"
          ></table-foreignkey>
        </template>
        <template v-slot:item.entrepot="{ item }">
          <table-foreignkey
            controller="entrepot"
            :id="item['entrepot_id']"
            :text="item.entrepot"
            :itemshow="['description', 'adresse', 'telephone', 'ville']"
          ></table-foreignkey>
        </template>
        <template v-slot:item.versentrepot="{ item }">
          <table-foreignkey
            controller="entrepot"
            :id="item['versentrepot_id']"
            :text="item.versentrepot"
            :itemshow="['description', 'adresse', 'telephone', 'ville']"
          ></table-foreignkey>
        </template>

        <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->
      </v-data-table>
    </div>
  </td>
  <td v-else :colspan="numbretd">..........</td>
</template>


<script>
import i18n from "@/plugins/i18n";
import { mapGetters } from "vuex";
export default {
  props: {
    controller_root: {},
    item: {},
    numbretd: {},
  },
  computed: {
    headers() {
      let Headers = [];
      for (const index in this.vuex_expanded) {
        const name_expanded = this.vuex_expanded[index]["controller_attach"];
        let notaffiche = this.vuex_expanded[index]["not_affiche"];

        let titles = this.item[name_expanded].titles;

        let headers = [];

        for (const key in titles) {
          const obj_title = titles[key];
          var regex = /FOREIGN_KEY_/gi;
          var found = obj_title.value.match(regex);

          if (notaffiche.indexOf(obj_title.value) == -1 && found == null) {
            obj_title.text = i18n.t(obj_title.value);

            //  obj_title.value .replace(new RegExp("[$]", "g"), " ")  .toLocaleUpperCase();

            headers.push(obj_title);
          }
        }

        Headers.push(headers);
      }
      return Headers;
    },
    dataSet() {
      let dataSet = [];
      for (const index in this.vuex_expanded) {
        const name_expanded = this.vuex_expanded[index]["controller_attach"];

        dataSet.push(this.item[name_expanded].dataSet);
      }
      return dataSet;
    },
    items_per_page() {
      let items_per_page = [];
      for (const index in this.vuex_expanded) {
        const name_expanded = this.vuex_expanded[index]["controller_attach"];

        items_per_page.push(this.item[name_expanded].dataSet.length);
      }
      return items_per_page;
    },
    rows() {
      let rows = [];
      for (const index in this.vuex_expanded) {
        const name_expanded = this.vuex_expanded[index]["controller_attach"];

        rows.push(this.item[name_expanded]);
      }
      return rows;
    },
    titles() {
      let titles = [];
      for (const index in this.vuex_expanded) {
        titles.push(this.vuex_expanded[index]["title"]);
      }
      return titles;
    },
    vuex_expanded() {
      return this.$store.getters["show_data/g_expanded"]({
        controller_root: this.controller_root,
      });
    },
  },
  methods: {
    convertDate(inputFormat) {
      function pad(s) {
        return s < 10 ? "0" + s : s;
      }
      var d = new Date(inputFormat);
      return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(
        "/"
      );
    },
  },
};
</script>

