<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="DateFormatted"
        :label="label"
        prepend-icon="mdi-calendar-range"
        readonly
        v-on="on"
      ></v-text-field>
    </template>

    <v-date-picker
      type="month"
      :value="values_binds"
      @input="modelbind($event)"
      :readonly="readonly"
   
      color="green lighten-1"
      header-color="primary"
      no-title
      scrollable
    >
      <v-btn text color="primary" @click="menu = false" v-show="!readonly"
        >ok</v-btn
      >
    </v-date-picker>
  </v-menu>
</template>
<script>
import InputMixin from "./InputMixin";
export default {
  mixins: [InputMixin],
  data: (vm) => ({
    date: new Date().toISOString().substr(0, 7),
    menu: false,
  }),

  computed: {
    DateFormatted() {
      return this.formatDate(this.values_binds);
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month] = date.split("-");
      return `${month}/${year}`;
    },
  },
};
</script>
