var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-expansion-panels',{attrs:{"focusable":"","inset":""}},[_c('v-row',[_c('v-col',[_c('h3',[_vm._v(_vm._s(_vm.label))])])],1),_vm._l((_vm.labels),function(label_item,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',[(_vm.selected.length > 1)?_c('h4',{class:_vm.is_show_items_Group_Choice(_vm.LabelShow(label_item))
                  ? 'primary--text'
                  : 'pink--text'},[_vm._v(" "+_vm._s(_vm.labelUpperCase(_vm.$t(_vm.LabelShow(label_item))) + " : " + _vm.label)+" ")]):_c('h5',[_vm._v(" "+_vm._s(_vm.labelUpperCase(_vm.$t(_vm.LabelShow(label_item))) + " : " + _vm.label)+" ")])]),_c('v-expansion-panel-content',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{attrs:{"value":_vm.LabelShow(label_item),"label":_vm.labelUpperCase(_vm.$t(_vm.LabelShow(label_item))) + ' : ' + _vm.label,"color":"pink","dense":""},on:{"change":function($event){return _vm.modelbind($event)}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.insertLabels.length > 0 &&
                  _vm.is_show_items_Group_Choice(_vm.LabelShow(label_item))
                ),expression:"\n                  insertLabels.length > 0 &&\n                  is_show_items_Group_Choice(LabelShow(label_item))\n                "}]},_vm._l((_vm.insertLabels),function(insertLabels_item,a){return _c('v-card-text',{key:a},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('h5',[_vm._v(" "+_vm._s(_vm.labelUpperCase(_vm.$t(_vm.LabelShow(label_item))) + " : " + _vm.$t(_vm.insertLabelsshow(insertLabels_item)))+" ")])]),_vm._l((_vm.fromLabels(label_item)),function(perme,b){return _c('v-col',{key:b,attrs:{"cols":"4"}},[_c('v-checkbox',{attrs:{"dense":"","value":_vm.LabelShow(label_item) +
                          '>' +
                          perme +
                          '|' +
                          _vm.insertLabelsshow(insertLabels_item),"messages":_vm.labelUpperCase(_vm.$t(perme))},on:{"change":function($event){return _vm.modelbind($event)}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)})],2)],1)}),1)],1)],1)],1)})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }