import index from "../index";

import show_item from "./show_item";


import update from "./update";

let namemodule = "";
let namecontroller = "restaurant";

export default [
  {
    path: "/" + namecontroller,
    component: index,

    children: [
      {
        path: "",
        component: show_item,
        name: namecontroller + "_show",
        props: true,
      },
      {
        path: "",
        component: show_item,
        name: namecontroller + "_show_item",
        props: true,
      },
      {
        path: ":id([0-9]+)",
        component: update,
        name: namecontroller + "_update",
        props: true,
      },
    ],
  },
];