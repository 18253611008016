<template>
  <div class="viewport" id="clock">
    <div class="clock">
      <div class="face">
        <span
          ><span
            ><span
              ><span><span></span></span></span></span
        ></span>
        <div class="brand">AWA</div>
        <div class="logo"><i class="fal fa-globe"></i></div>
        <div class="digital">
          {{ hourDisplay }}:{{ minDisplay }}:{{ secDisplay }}
        </div>
      </div>
      <div class="hour">
        <div class="hr" id="hr" :style="hourTurn"></div>
      </div>
      <div class="min">
        <div class="mn" id="mn" :style="minTurn"></div>
      </div>
      <div class="sec">
        <div class="sc" id="sc" :style="secTurn"></div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      timerID: "",
      hour: "",
      min: "",
      sec: "",
      hourCount: "",
      minCount: "",
      secCount: "",
      showDebug: 0.1,
    };
  },
  destroyed() {
    clearInterval(this.timerID);
  },

  mounted() {
    this.getTime();

    this.timerID = setInterval(this.updateTime, 1000);
  },
  methods: {
    toggleDebug() {
      this.showDebug = this.showDebug === 0 || this.showDebug === 0.1 ? 1 : 0;
    },
    updateTime() {
      var cd = new Date();
      this.hour = cd.getHours();
      this.min = cd.getMinutes();
      this.sec = cd.getSeconds();
      this.secCount++;
      if (this.secCount % 60 === 0) {
        this.minCount++;
        if (this.minCount % 60 === 0) {
          this.hourCount++;
        }
      }
    },

    getTime() {
      var cd = new Date();
      this.hour = this.hourCount = cd.getHours();
      this.min = this.minCount = cd.getMinutes();
      this.sec = this.secCount = cd.getSeconds();
    },
  },
  computed: {
    debugOpacity: function () {
      return { opacity: this.showDebug };
    },
    hourDeg: function () {
      return (this.hourCount % 12) * 30 + (this.minCount % 60) * 0.5;
    },
    minDeg: function () {
      return this.minCount * 6 + (this.secCount % 60) * 0.1;
    },
    secDeg: function () {
      return this.secCount * 6;
    },
    hourTurn: function () {
      return { transform: "rotateZ(" + this.hourDeg + "deg)" };
    },
    minTurn: function () {
      return { transform: "rotateZ(" + this.minDeg + "deg)" };
    },
    secTurn: function () {
      return { transform: "rotateZ(" + this.secDeg + "deg)" };
    },
    hourDisplay: function () {
      return ("00" + this.hour).slice(-2);
    },
    minDisplay: function () {
      return ("00" + this.min).slice(-2);
    },
    secDisplay: function () {
      return ("00" + this.sec).slice(-2);
    },
  },
};
</script>

<style scoped  lang="scss" >
@import url("https://fonts.googleapis.com/css?family=Forum&display=swap");

body {
  display: flex;
  justify-content: center;
  align-items: center;
  background: steelblue;
  min-height: 100vh;
}

.clock {
  width: 25rem;
  height: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #efefef;
  border-radius: 50%;
  border: 1rem solid #333;
  box-shadow: inset 0 0 1.5rem rgba(0, 0, 0, 0.2),
    0 1rem 1rem rgba(0, 0, 0, 0.3), 0 0 0 0.2rem rgba(65, 65, 65, 1);

  &:before {
    content: "";
    position: absolute;
    width: 0.4rem;
    height: 0.4rem;
    background: #333;
    border-radius: 50%;
    z-index: 1;
  }

  .hour,
  .min,
  .sec {
    position: absolute;
  }
  .hour,
  .hr {
    width: 10rem;
    height: 10rem;
  }
  .min,
  .mn {
    width: 16rem;
    height: 16rem;
  }
  .sec,
  .sc {
    width: 20rem;
    height: 20rem;
  }
  .hr,
  .mn,
  .sc {
    display: flex;
    justify-content: center;
    position: absolute;
    border-radius: 50%;
  }
  .hr::before {
    content: "";
    position: absolute;
    width: 0.8rem;
    height: 5rem;
    background: #333;
    border-radius: 0.6rem 0.6rem 0 0;
    z-index: 6;
    box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.4);
  }
  .mn::before {
    content: "";
    position: absolute;
    width: 0.4rem;
    height: 8rem;
    background: #333;
    border-radius: 0.2rem 0.2rem 0 0;
    z-index: 8;
    box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.4);
  }
  .mn::after {
    content: "";
    position: absolute;
    width: 1.4rem;
    height: 1.4rem;
    background: #fff;
    border-radius: 50%;
    margin-top: 7.3rem;
    z-index: 10;
    box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.4);
  }
  .sc::before {
    content: "";
    position: absolute;
    width: 0.2rem;
    height: 12rem;
    background: #f00;
    border-radius: 0.6rem 0.6rem 0 0;
    z-index: 110;
    box-shadow: 0 0 0.2em rgba(0, 0, 0, 0.4);
  }
  .sc::after {
    content: "";
    position: absolute;
    width: 1rem;
    height: 1rem;
    margin-top: 9.5rem;
    background: #f00;
    border-radius: 50%;
    z-index: 500;
    box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.4);
  }
  .sc {
    transition: transform 0.1s linear;
  }
  .mn {
    transition: transform 1s linear;
  }
  .hr {
    transition: transform 60s linear;
  }
  .face {
    &:before {
      content: "";
      position: absolute;
      background: #333;
      width: 0.5rem;
      height: 3rem;
      margin-top: -12rem;
      margin-left: -0.25rem;
    }
    &:after {
      content: "";
      position: absolute;
      background: #333;
      width: 0.5rem;
      height: 3rem;
      margin-top: 9rem;
      margin-left: -0.25rem;
    }
    .brand,
    .digital,
    .logo {
      position: absolute;
      font-family: "Forum", cursive;
      font-variant: small-caps;
      text-align: center;
      color: #666;
    }
    .brand {
      letter-spacing: 1.25rem;
      margin-top: -5rem;
      width: 14rem;
      margin-left: -6.5rem;
      font-size: 1.5rem;
    }
    .digital {
      letter-spacing: 0.5rem;
      margin-top: 4rem;
      width: 10rem;
      margin-left: -5.5rem;
      font-size: 1rem;
      border: 1px solid #bbb;
      border-radius: 1rem;
      padding: 0.25rem;
      box-shadow: inset 0 0 0.25rem rgba(0, 0, 0, 0.5);
      background: #eaeaea;
    }
    .logo {
      margin-top: 1.4rem;
      width: 6rem;
      margin-left: -3rem;
      font-size: 1.8rem;
    }
    & > span {
      &:before {
        content: "";
        position: absolute;
        background: #333;
        width: 3rem;
        height: 0.5rem;
        margin-left: -12rem;
        margin-top: -0.25rem;
      }
      &:after {
        content: "";
        position: absolute;
        background: #333;
        width: 3rem;
        height: 0.5rem;
        margin-left: 9rem;
        margin-top: -0.25rem;
      }
      & > span {
        &:before {
          content: "";
          position: absolute;
          background: #666;
          width: 0.5rem;
          height: 2rem;
          margin-left: 5.1rem;
          margin-top: -10.25rem;
          transform: rotateZ(30deg);
        }
        &:after {
          content: "";
          position: absolute;
          background: #666;
          width: 0.5rem;
          height: 2rem;
          margin-left: -5.6rem;
          margin-top: -10.25rem;
          transform: rotateZ(-30deg);
        }
        & > span {
          &:before {
            content: "";
            position: absolute;
            background: #666;
            width: 0.5rem;
            height: 2rem;
            margin-left: 9rem;
            margin-top: -6.3rem;
            transform: rotateZ(60deg);
          }
          &:after {
            content: "";
            position: absolute;
            background: #666;
            width: 0.5rem;
            height: 2rem;
            margin-left: -9.5rem;
            margin-top: -6.3rem;
            transform: rotateZ(-60deg);
          }
          & > span {
            &:before {
              content: "";
              position: absolute;
              background: #666;
              width: 0.5rem;
              height: 2rem;
              margin-left: 9rem;
              margin-top: 4.3rem;
              transform: rotateZ(120deg);
            }
            &:after {
              content: "";
              position: absolute;
              background: #666;
              width: 0.5rem;
              height: 2rem;
              margin-left: -9.5rem;
              margin-top: 4.3rem;
              transform: rotateZ(-120deg);
            }
            & > span {
              &:before {
                content: "";
                position: absolute;
                background: #666;
                width: 0.5rem;
                height: 2rem;
                margin-left: 5.25rem;
                margin-top: 8.5rem;
                transform: rotateZ(150deg);
              }
              &:after {
                content: "";
                position: absolute;
                background: #666;
                width: 0.5rem;
                height: 2rem;
                margin-left: -5.75rem;
                margin-top: 8.5rem;
                transform: rotateZ(-150deg);
              }
            }
          }
        }
      }
    }
  }
}

.debug {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  color: #fff;
  transition: opacity 0.1s linear;
  font-size: 0.75rem;
  table {
    border-collapse: collapse;
  }
  th,
  td {
    border: 1px solid #fff;
    text-align: center;
    background: #333;
    width: 3em;
  }
  th.empty {
    border-color: transparent #fff #fff transparent;
    background: transparent;
  }
}
</style>