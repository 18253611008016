<template>
  <div>
    <v-list-group
      no-action
      v-for="(controller, i) in controllers"
      :key="'D' + i"
      v-show="
        connecte &&
        $route.name != '_dashboard' &&
        headers(controller).length != 0
      "
    >
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>
            <h4 color="primary">{{ $t(controller) }}</h4>
          </v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item>
        <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
          :label="$t('recherche')"
          single-line
          hide-details
        ></v-text-field>
      </v-list-item>
      <v-list-item
        v-for="(header, key) in headers(controller)"
        :key="'C' + key"
        v-show="
          header.text
            .toLowerCase()
            .trim()
            .indexOf(search.toLowerCase().trim()) != -1 ||
          header.value
            .toLowerCase()
            .trim()
            .indexOf(search.toLowerCase().trim()) != -1
        "
      >
        <v-checkbox
          v-if="header.text !== 'ID'"
          :value="header.value"
          :label="header.text"
          @change="change($event, controller)"
          :input-value="not_affiches(controller)"
          off-icon="mdi-eye"
          on-icon="mdi-eye-off"
          color="red darken-3"
        >
          <template v-slot:label>
            <span class="awacolor">{{ header.text }}</span>
          </template>
        </v-checkbox>
        <span v-else> <v-icon>mdi-crosshairs-gps</v-icon>ID </span>
      </v-list-item>

      <theme-drawer-expanded :controller_root="controller" />
    </v-list-group>
  </div>
</template>

<script>
import router from "@/router";

export default {
  data() {
    return {
      search: "",
    };
  },
  methods: {
    not_affiches(controller) {
      let not_affiches = [];
      not_affiches = this.$store.getters["show_data/g_not_affiche"]({
        controller_root: controller,
      });

      return not_affiches;
    },

    headers(controller) {
      let name_input_files = [];
      let shema_inputs = [];

      let expanded = this.$store.getters["show_data/g_expanded"]({
        controller_root: controller,
      });
      let namesexpanded = [];
      for (const key in expanded) {
        namesexpanded.push(expanded[key]["controller_attach"]);
      }

      return this.$store.getters["show_data/g_headers"]({
        controller_root: controller,
        namesexpanded,
        notaffiche: [],
        name_input_files,
      });
    },

    change(selected, controller) {
      this.$store.commit("show_data/set_not_affiches", {
        data: selected,
        controller_root: controller,
      });
    },
  },

  computed: {
    connecte() {
      return this.$store.getters["security/connecte"];
    },

    menu() {
      return this.$store.getters["theme/menu"];
    },
    Controllers_global() {
      let Controllers_global = {};
      for (const m of this.menu) {
        //  let Controllers=m.Controllers              //Object
        Controllers_global = Object.assign(
          {},
          Controllers_global,
          m.Controllers
        );
        let IconModule = m.IconModule; // " mdi-toolbox-outline "
        let NameModule = m.NameModule; // "Unites"
      }
      return Controllers_global;
    },

    controllers() {
      let names = [];
      return this.$store.state.show_data.namescontrollers;
      for (const key in controllers) {
        const element = controllers[key];
        names.push(element);
      }
      return names;
    },
  },
};
</script>

<style>
</style>