module.exports = {
  id: { typeHtml: "hidden" },

  code: { typeHtml: "text" },

  valide: { typeHtml: "checkbox" },
  dureepreparation: { typeHtml: "money" },

  modepaiement: { typeHtml: "select", to_string: "modepaiement" },

  client: { typeHtml: "select", to_string: "identifiant", from: "user" },
  validateur: { typeHtml: "select", to_string: "identifiant", from: "user" },
  serveur: { typeHtml: "select", to_string: "identifiant", from: "user" },
  mastercheff: { typeHtml: "select", to_string: "identifiant", from: "user" },

  total: { typeHtml: "money" },
  especes: { typeHtml: "money" },
  rendu: { typeHtml: "money" },

  livrerpar: { typeHtml: "hidden" },
  fraislivrer: { typeHtml: "hidden" },

  /*   commandeplats: { typeHtml: "hidden" }, */

  montantHt: { typeHtml: "money" },
  montantTva: { typeHtml: "money" },
  montantTtc: { typeHtml: "money" },
  rating: { typeHtml: "rating" },
  commandecreatedby: { typeHtml: "text" },
  updatedAt: { typeHtml: "hidden" },
  createdAt: { typeHtml: "hidden" },
};
