<template>


  <v-textarea
    :readonly="readonly"
    :label="label"
    :value="values_binds"
    @change="modelbind($event)"
    outlined
    rows="1"
    :loading="loading"
     @focus="init_validation()"
    :rules="rules"
  >
  </v-textarea>
</template>
<script>
import InputMixin from "./InputMixin";
export default {
  mixins: [InputMixin], methods: {
    init_validation() {
      this.rules = [this.validation.required, this.validation.text];
    },
  },

};
</script>