export default {
  set_init(state, param_tables) {
    let namemodule = {};

    let namescontroller_multiselect = [];

    //let _id = {};
    // let _controller = {};
    // let controllers_multiselects = [];
    let controller_root_attach = {};
    let where_attach = {};
    let where_attach_get_value = {};
    let default_Jointure = {};

    let controller_listsAdd_attach = {};
    let pieceID = {};

    let titles = {};
    let not_affiches = {};
    let expandeds = {};

    let search = {};
    let headers = {};
    let items = {};
    let selected = {};
    let item_key = {};
    let page = {};
    let pageCount = {};
    let itemsPerPage = {};

    for (const index in param_tables) {
      const multiselects = param_tables[index]["multiselect"];

      for (const key in multiselects) {
        const multiselect = multiselects[key];

        const controller_multiselect = multiselect["controller_multiselect"];

        /**************************/
        controller_root_attach[controller_multiselect] =
          multiselect["controller_root_attach"];
        where_attach[controller_multiselect] = multiselect["where_attach"];
        where_attach_get_value[controller_multiselect] =
          multiselect["where_attach_get_value"];
        default_Jointure[controller_multiselect] =
          multiselect["default_Jointure"];

        controller_listsAdd_attach[controller_multiselect] =
          multiselect["controller_listsAdd_attach"];

        namescontroller_multiselect.push(controller_multiselect);
        /**************************/

        namemodule[controller_multiselect] = param_tables[index]["namemodule"];

        /**************************/

        const options_showData = multiselect["options_showData"];
        titles[controller_multiselect] = options_showData["title"];
        pieceID[controller_multiselect] = options_showData["pieceID"];
        not_affiches[controller_multiselect] = options_showData["not_affiche"];






         // Récupérer des données depuis localStorage
        let not_affiches_sessionStorage = localStorage.getItem(controller_multiselect+"multiselect");

        if (not_affiches_sessionStorage == null) {

          localStorage.setItem(controller_multiselect+"multiselect", not_affiches[controller_multiselect].join());
        } else {
          not_affiches[controller_multiselect] = not_affiches_sessionStorage.split(",");
        }





        expandeds[controller_multiselect] = options_showData["expanded"];

        /**************************/
        search[controller_multiselect] = "";
        headers[controller_multiselect] = [];
        items[controller_multiselect] = [];
        selected[controller_multiselect] = [];
        item_key[controller_multiselect] = "id";
        page[controller_multiselect] = 1;
        pageCount[controller_multiselect] = 1;
        itemsPerPage[controller_multiselect] = 5;
      }
    }

    state["namesmodules"] = namemodule;

    state["namescontroller_multiselect"] = namescontroller_multiselect;

    state["controller_root_attach"] = controller_root_attach;
    state["where_attach"] = where_attach;
    state["where_attach_get_value"] = where_attach_get_value;
    state["jointure"] = default_Jointure;

    state["controller_listsAdd_attach"] = controller_listsAdd_attach;

    state["pieceID"] = pieceID;

    state["titles"] = titles;
    state["not_affiches"] = not_affiches;
    state["expandeds"] = expandeds;

    state["search"] = search;
    state["headers"] = headers;
    state["items"] = items;
    state["selected"] = selected;
    state["item_key"] = item_key;
    state["page"] = page;
    state["pageCount"] = pageCount;
    state["itemsPerPage"] = itemsPerPage;
  },

  set_jointure(state, { data, controller_multiselect }) {
    state.jointure[controller_multiselect] = data;
  },

  set_search(state, { data, controller_multiselect }) {
    state.search[controller_multiselect] = data;
  },
  set_headers(state, { data, controller_multiselect }) {
    state.headers[controller_multiselect] = data;
  },

  set_not_affiches(state, { data, controller_multiselect }) {
    // Enregistrer des données dans localStorage
    localStorage.setItem(controller_multiselect + "multiselect", data.join());

    state.not_affiches[controller_multiselect] = data;
  },
  set_items(state, { data, controller_multiselect }) {
    state.items[controller_multiselect] = data;
  },
  set_selected(state, { data, controller_multiselect }) {
    state.selected[controller_multiselect] = data;
  },
  set_item_key(state, { data, controller_multiselect }) {
    state.item_key[controller_multiselect] = data;
  },
  set_page(state, { data, controller_multiselect }) {
    state.page[controller_multiselect] = data;
  },
  set_pageCount(state, { data, controller_multiselect }) {
    state.pageCount[controller_multiselect] = data;
  },
  set_itemsPerPage(state, { data, controller_multiselect }) {
    state.itemsPerPage[controller_multiselect] = data;
  },
  remove_all(state, { controller_multiselect }) {
    console.log("ddd");
    // state.selected[controller_multiselect] = [];
    // state.headers[controller_multiselect] =[];
    state.items[controller_multiselect] = [];
  },
};
