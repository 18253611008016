<template>
  <v-form ref="form" v-model="valid">
     <v-row class="justify-center">
      <v-col cols="12"
        ><btn-action
          :controller_root="controller_root"
          :item="{ id: id }"
        ></btn-action
      ></v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="12">
        <v-row class="justify-center">
          <v-col :cols="12" :lg="6">
            <card-box
              id="groupt1"
              color="orange"
                :title="$t('show_user_infoplat_1')"
              :text="$t('show_user_infoplat_2')"
            >
              <show-default :controller_root="controller_root" />
            </card-box>
          </v-col>
        </v-row>
      </v-col>
     <!--  <v-col :cols="12" :lg="6">
        <card-box
          id="addlist2"
          icon="mdi-file-star-outline"
          :title="$t('usercategories')"
          color="success"
        >
          <addlist-chip
            :edit_listsAdd="false"
            item_listsAdd="categories"
            controller_listsAdd="usercategories"
          />
        </card-box>
      </v-col> -->
    </v-row>
  </v-form>
</template>
<script>
import i18n from "@/plugins/i18n";
import { mapGetters } from "vuex";
import ViewMixin from "@/views/show_item";

export default {
  mixins: [ViewMixin],

  props: {},
  data() {
    return {
      controller_root: "user",
      namemodule: "CRM",
      lists_Add: [
        {
          controller_listsAdd: "usercategories",
        },
      ],
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  created() {
    document.title = i18n.t("show_item_" + this.controller_root);
    this.initia();
    this.run();
  },

  methods: {
    isGranted(controller) {
      return this.$store.getters["security/isGranted"]("view", controller);
    },
  },
};
</script>
