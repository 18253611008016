<template>
  <v-list-item>
 <v-list-item-icon>
          <v-icon color="indigo">mdi-image</v-icon>
        </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title><u>{{ label }}  :</u> </v-list-item-title>
      <v-list-item-subtitle>
        <v-navigation-drawer max-width="80" :value="true" stateless>
          <readfile-img :refgroup="values_binds" ></readfile-img>
        </v-navigation-drawer>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
import InputMixin from "./InputMixin";

export default {
  mixins: [InputMixin],
};
</script>
