<template>
<v-col cols="12" sm="6" md="4">
  <v-textarea
    :readonly="readonly"
    :label="label"
    :value="values_binds"
    @change="modelbind($event)"
    solo
    rows="1"
    :loading="loading"
  ></v-textarea>
</v-col>
</template>
<script>
import InputMixin from "./InputMixin";
export default {
  mixins: [InputMixin]
};
</script>