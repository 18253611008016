<template>
  <v-card
    class="v-card--material--has-heading v-card--material pa-3 mb-16 mt-12"
    elevation="8"
    width="100%"
  >
       <v-row class="align-center justify-center">
        <v-col cols="11" >
          <v-card flat tile width="100%" class="mx-auto v-card--material __avatar elevation-6 ">
        <v-window v-model="onboarding" reverse>
          <v-window-item v-for="n in length" :key="`card-${n}`">
            <v-card color="grey" height="200" width="100%">
             



             <readfile-img class="grey darken-4"  style="height: 150px;"
                  :refgroup="item.optiongrouprecettes[n - 1].recette.logo"
                  :size="150"
                  v-if="
                    item.optiongrouprecettes[n - 1].recette.logo != '' &&
                    item.optiongrouprecettes[n - 1].recette.logo != null
                  "
                > </readfile-img>
                <v-icon x-large v-else>mdi-human-dolly</v-icon>

            <v-card-text class=" align-center justify-center" color="transparent">
              
               <div class=" text-h5 white--text " style="display:inline-block;">{{ item.optiongrouprecettes[n - 1].recette.name }}</div>
            </v-card-text>
         




              


       
     

                  

               
                
            
            </v-card>
          </v-window-item>
        </v-window>

        <v-card-actions class="justify-space-between">
          <v-btn text @click="prev">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-item-group v-model="onboarding" class="text-center" mandatory>
            <v-item
              v-for="n in length"
              :key="`btn-${n}`"
              v-slot="{ active, toggle }"
            >
              <v-btn :input-value="active" icon @click="toggle">
                <v-icon>mdi-record</v-icon>
              </v-btn>
            </v-item>
          </v-item-group>
          <v-btn text @click="next">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
        </v-col>

       
      </v-row>

       <v-col cols="6" class="ml-0 text-truncate">
          <div class="text-overline mb-2">
            <v-chip class="mr-1 white--text" label color="orange" >
              {{ item.titre }}
            </v-chip>
          </div>
        </v-col>

      <v-card-actions>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mr-2"
              fab
              dark
              small
              color="orange"
              @click="showItem(item)"
              v-show="isGranted('view', item)"
              v-on="on"
            >
              <v-icon>mdi-view-carousel</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("Consulter") }}</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mr-2"
              fab
              dark
              small
              color="orange"
              @click="editItem(item)"
              v-show="isGranted('edit', item)"
              v-on="on"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("Modifier") }}</span>
        </v-tooltip>

        <v-spacer></v-spacer>

        <v-btn icon @click="show = !show">
          <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="show">
          <v-divider></v-divider>

          <v-card-text>
            <p>ID: {{ item.id }}</p>
          </v-card-text>
        </div>
      </v-expand-transition>

    <v-row>
     
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    item: { type: Object },
    controller_root: {},
  },
  data: () => ({
    show: false,

    onboarding: 0,
  }),
  computed: {
    length() {
      return this.item.optiongrouprecettes.length;
    },
  },
  methods: {
    isGranted(attribute, item) {
      return this.$store.getters["security/isGranted"](
        attribute,
        this.controller_root,
        item
      );
    },
    showItem() {
      let item = this.item;
      if (this.slot_show) {
        this.$store.dispatch("custom_fromById", {
          names_controllers: [this.controller_root],
          id: item.id,
        });

        this.$store.commit("dialog/set_dialog", {
          data: item,
          flag: true,
          type: "show",
          controller: this.controller_root,
        });
      } else {
        this.$router.push({
          name: this.controller_root + "_show_item",
          params: { id: item.id },
        });
      }
    },
    editItem() {
      let item = this.item;
      if (this.slot_show) {
        this.$store.dispatch("custom_fromById", {
          names_controllers: [this.controller_root],
          id: item.id,
        });

        this.$store.commit("dialog/set_dialog", {
          data: item,
          flag: true,
          type: "update",
          controller: this.controller_root,
        });
      } else {
        this.$router.push({
          name: this.controller_root + "_update",
          params: { id: item.id },
        });
      }
    },
    next() {
      this.onboarding =
        this.onboarding + 1 === this.length ? 0 : this.onboarding + 1;
    },
    prev() {
      this.onboarding =
        this.onboarding - 1 < 0 ? this.length - 1 : this.onboarding - 1;
    },
  },
};
</script>

<style scoped>
.v-card--material .__avatar {
  position: relative;
  top: -64px;
  margin-bottom: -32px;
}

.v-card--material .__heading {
  position: relative;
  top: -40px;
  transition: 0.3s ease;
  z-index: 1;
}
</style>
