

export default {
  set_show(state, value) {
    state.show = value;
  },
  set_color(state, value) {
    state.color = value;
  },
  set_coordonneX(state, value) {
    state.coordonneX = value;
  },
  set_coordonneY(state, value) {
    state.coordonneY = value;
  },
  set_message(state, value) {
    state.message = value;
  },
  set_multi_line(state, value) {
    state.multi_line = value;
  },
  set_timeout(state, value) {
    state.timeout = value;
  },
};
