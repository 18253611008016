module.exports = {
  id: { typeHtml: "hidden" },
  titre: { typeHtml: "text" },
  updatedAt: { typeHtml: "hidden" },
  createdAt: { typeHtml: "hidden" },
  date: { typeHtml: "date" },
  ref: { typeHtml: "text" },
  adresse: { typeHtml: "Textarea" },
  note: { typeHtml: "Textarea" },
  files: { typeHtml: "files" },
  montantHt: { typeHtml: "money" },
  montantTva: { typeHtml: "money" },
  timbrefiscal: { typeHtml: "text" },
  autreimpots: { typeHtml: "text" },
  montantTtc: { typeHtml: "money" },

  modepaiement: { typeHtml: "select", to_string: "modepaiement" },
  user: { typeHtml: "hidden" , to_string: "identifiant"},
};
