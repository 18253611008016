<template>
  <v-row class="justify-center align-center" style="margin-top: 10px">
    <v-col cols="12" class="justify-center align-center">
      <v-card class="elevation-3 pa-0" style="margin: 0 auto">
        <v-card-text>
          <div class="layout column align-center">
            <h1 class="primary--text display-1 page-login_title">
              {{ check_url ? "START" : "STOP error " }}
            </h1>
            <!-- <img
              :src="protocol + uri_B() + '/src/img/logo.jpg'"

              alt="logouser"
              width="100"
              height="100"
            /> -->
            <img
              v-if="check_url"
              :src="require('@/assets/conect.png')"
              alt="logouser"
              width="100"
              height="100"
            />
            <img
              v-else
              :src="require('@/assets/notconect.png')"
              alt="logouser"
              width="100"
              height="100"
            />
          </div>
          <v-form class="my-3">
            <v-radio-group v-model="protocol" row>
              <v-radio label="https" value="https://"></v-radio>
              <v-radio label="http" value="http://"></v-radio>
            </v-radio-group>

            <v-text-field
              append-icon="mdi-web"
              name="Base_url"
              :label="'Adresse serveur SVP'"
              type="text"
              v-model="Base_url"
            ></v-text-field>

            <v-text-field
              :disabled="!check_url"
              append-icon="mdi-account"
              name="login"
              :label="'Email'"
              type="text"
              v-model="name"
            ></v-text-field>

            <v-text-field
              :disabled="!check_url"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              counter
              @click:append="show1 = !show1"
              name="password"
              :label="'Password'"
              id="password"
              v-model="password"
              v-on:keyup.enter="submit"
            ></v-text-field>
          </v-form>

          <v-alert v-show="alert" outlined type="error" prominent border="left">
            Impossible de se connecter au serveur
            <strong> Email:ERROR OR Password :ERROR</strong>
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="!check_url"
            color="primary"
            @click="submit"
            :loading="loading"
            right
            >{{ "Login" }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { ajax_JWTToken } from "../util/ajax_JWTToken";
import axios from "axios";
export default {
  data: () => ({
    alert: false,
    protocol: "https://",
    check_url: false,
    Base_url: "",
    name: "",
    password: "",

    loading: false,
    show1: false,
  }),
  computed: {},
  mounted() {
    let Base = this.$store.getters["get_Base_url"];
    var b = Base.split("//");
    this.protocol = b[0] + "//";
    this.Base_url = b[1];
    this.name = this.$store.getters["get_username"];
    this.password = this.$store.getters["get_password"];
  },

  methods: {
    async submit() {
      this.loading = true;
      this.alert = false;

      const JWTToken = await ajax_JWTToken(
        this.protocol + this.uri_B(),
        this.name.trim(),
        this.password,
        false
      );

      if (JWTToken != "") {
        this.$store.commit("set_Base_url", this.protocol + this.uri_B());
        this.$store.commit("set_username", this.name.trim());
        this.$store.commit("set_password", this.password);
        this.$store.commit("set_JWTToken", JWTToken);

        this.$store.dispatch("run_get_metaData");
      } else {
        this.alert = true;
      }

      this.loading = false;

      //
    },
    clear() {
      this.name = "";
      this.password = "";
    },
    check_URL() {
      axios
        .get(this.protocol + this.uri_B())
        .then((response) => (this.check_url = true))
        .catch((response) => (this.check_url = false));
    },
    uri_B() {
      let uri = this.Base_url.replace("https://", "")
        .replace("http://", "")
        .replace("www.", "")
        .replace("/", "")

        .trim();
      if (this.protocol == "https://") {
        uri = uri.replace(".appcomptable.com", "") + ".appcomptable.com";
      }
      return uri;
    },
  },
  watch: {
    Base_url(newValue, oldValue) {
      this.check_URL();
    },
  },
};
</script>
<style scoped lang="css">
#login {
  margin: 0;
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
</style>