export default {
  namesmodules: [],
  namescontrollers: [],

  path: {},
  not_affiches: {},
  expandeds: {},

  //////////////////////////
  titles: {},
  search: {},
  //////////////////////////
  schemas: {},
  headers: {},
  items: {},
  options: {},
  CurrentPageNumber: {},
  ItemNumberPerPage: {},
  TotalItemCount: {},
  //////////////////////////
  selected: {},
  item_key: {},

  //pagination
  page: {},
  pageCount: {},
  itemsPerPage: {},
  //////////////////////////
  loading: true,
};
