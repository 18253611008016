<template>
  <div>
    <div class="text-center">
      <v-row>
        <v-col cols="11">
          <v-file-input :label="label" @change="modelbind($event)" show-size counter chips :loading="loading"></v-file-input>
        </v-col>
        <v-col cols="1">
          <v-progress-circular :rotate="360" :size="40" :width="5" :value="valuec" :color="color" v-show="valuec > 0">
            {{ valuec }}
          </v-progress-circular>
        </v-col>
      </v-row>
    </div>
  
    <div>
      <v-avatar v-for="(url, i) in files_valueshowupload" :key="i">
        <img :src="url" alt="John" />
      </v-avatar>
    </div>

    <div>
     <v-avatar size="29px" v-for="(file, index) in files_valueshow" :key="index">
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <img :src="'/' + file.path" :alt="file.name" v-if="file.type == 'image'" v-on="on" />
                        <v-icon color="primary" v-if="file.type == 'pdf'" v-on="on"> mdi-pdf-box </v-icon>
                        <v-icon color="primary" v-if="file.type == 'file'" v-on="on"> mdi-file-document-outline </v-icon>
                      </template>
                      <span>{{ file.name }}</span>
                    </v-tooltip>
                  </v-avatar>
    </div>
  </div>
</template>
<script>
import InputMixin from "./InputMixin";
export default {
  mixins: [InputMixin],
  data: () => ({
    files_valueshow: [],
    files_valueshowupload: [],
    color: "primary",
    valueshowtext: "",

    valuec: 0,
  }),

  watch: {
    valueshow(val) {
      this.files_valueshowupload = [];
      this.files_valueshow = [];

      this.valuec = 0;
      this.color = "primary";
      console.log(val);
      /// schow file style
      if (typeof val === "object") {
        this.files_valueshowupload.push(URL.createObjectURL(val));

        /// send files to server
        this.$store.dispatch("files/send_files", {
          controller_root: this.controller_root,
          files_input: [val],
          name_input: this.name,
          fun_progress: etat => {
            this.valuec = etat;
          },
        });

        let schemas_files = this.valueshowtext;
        /// delete_files_attends to server
        this.$store.dispatch("files/delete_files_attends", 
        { controller_root: this.controller_root, schemas_files });
      } else {
        if (val != undefined) {
          this.valueshowtext = val;
          console.log(val);
          /// get_files_save to server
          this.$store.dispatch("files/get_files_save", {
            controller_root: this.controller_root,
            schemas_files: val,
            fun_info_files: info => {
              this.files_valueshow = info;
            },
          });
        }
      }
    },
  },
};
</script>
