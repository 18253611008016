import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
// Translation provided by Vuetify (typescript)
import ar from "vuetify/es5/locale/ar";
import fr from "vuetify/es5/locale/fr";
import en from "vuetify/es5/locale/en";

Vue.use(Vuetify)
import colors from 'vuetify/lib/util/colors'
export default new Vuetify({
  theme: {
    themes: {
      light: {
        //  base: colors.purple,
        //   primary: colors.purple,
        //  secondary: colors.grey.darken1,
        //  accent: colors.shades.black,
        //   error: colors.red.accent3,
      },
      dark: {
        //   primary: colors.purple,
        //  secondary: colors.grey.darken1,
        //  accent: colors.shades.black,
        // error: colors.red.accent3,
        // orange: colors.red.accent3,
      },
    },
  },

  lang: {
    locales: {
      "fr-fr": fr,
      "ar-ar": ar,
      "en-en": en,
      "fr": fr,
      "ar": ar,
      "en": en,
    },
    current: "fr-fr",
  },
})
