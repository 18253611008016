<template>
<v-col cols="12" sm="6" md="4">
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field :value="values_binds" :label="label" prepend-icon="md-event" readonly v-on="on"></v-text-field>
    </template>
    <v-date-picker :value="values_binds"
    :readonly="readonly"
   
     color="green lighten-1" header-color="primary" no-title scrollable @input="modelbind($event)">
      <div class="flex-grow-1"></div>
      <v-btn text color="primary" @click="menu = false" v-show="!readonly">Cancel</v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(date)" v-show="!readonly">OK</v-btn>
    </v-date-picker>
  </v-menu>
</v-col>
  
</template>
<script>
import InputMixin from "./InputMixin";
export default {
  mixins: [InputMixin],
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false
  })
};
</script>