<template>
  <div>
    <div class="text-center justify-center" v-if="get_actionTraiter() == 'Traiter'">
      <v-chip color="success" class="mx-2" dark small>
        <v-icon dark>mdi-hand-okay</v-icon>
      </v-chip>
    </div>

    <div
      class="text-center justify-center"
      v-else
      
    >
      <v-chip v-if="avoir == '1'" color="brown" class="mx-2" dark>
        <v-icon dark>mdi-package-down</v-icon>
      </v-chip>

      <v-tooltip top v-else>
        <template v-slot:activator="{ on }">
          <v-btn
            dark
            color="red"
            small
            @click="avoirItem(item)"
            v-show="isGranted('edit', item)"
            v-on="on"
          >
            <v-icon>mdi-reply-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("avoir") }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return { alert: false, delete: false, text: "", fab: false };
  },
  props: {
    item: {},
    controller_root: {},
    action_traitement: { default: true },
    avoir: { default: false },
    slot_show: {},
  },

  computed: {},

  methods: {
    isGranted(attribute, item) {
      return this.$store.getters["security/isGranted"](
        attribute,
        this.controller_root,
        item
      );
    },

    avoirItem() {
      let item = this.item;
      if (this.slot_show) {
        this.$store.dispatch("custom_fromById", {
          names_controllers: [this.controller_root],
          id: item.id,
        });

        this.$store.commit("dialog/set_dialog", {
          data: item,
          flag: true,
          type: "update",
          controller: this.controller_root,
        });
      } else {
        this.$router.push({
          name: this.controller_root + "_avoir",
          params: { id: item.id },
        });
      }
    },

    get_statut() {
      return this.item["statut"];
    },
    get_actionTraiter() {
      return this.item["actionTraiter"];
    },
  },
};
</script>
<style>
/* This is for documentation purposes and will not be needed in your application */
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
</style>