module.exports = {
  id: { typeHtml: "hidden" },
  categorieplat: { typeHtml: "select", to_string: "categorie" },
  name: { typeHtml: "text", unique: true , nullable: false},

  prix: { typeHtml: "money", defaultValue: "0", nullable: false },
  remise: { typeHtml: "money", defaultValue: "0" },
  /*   mindescription: { typeHtml: "Ckeditor" },
  description: { typeHtml: "Ckeditor" }, */

  mindescription: { typeHtml: "Textarea", defaultValue: "", nullable: false },
  description: { typeHtml: "Textarea" , defaultValue: "", nullable: false},
  rating: { typeHtml: "rating", defaultValue: 3 },
  recommander: { typeHtml: "checkbox", defaultValue: "1" },
  position: { typeHtml: "money", defaultValue: 1 },
  cal: { typeHtml: "money", defaultValue: "" },
  protein: { typeHtml: "money", defaultValue: "" },
  fat: { typeHtml: "money", defaultValue: "" },
  carbs: { typeHtml: "money", defaultValue: "" },

  logo: { typeHtml: "logo" },
  active: { typeHtml: "checkbox", defaultValue: "1" },

  updatedAt: { typeHtml: "hidden" },
  createdAt: { typeHtml: "hidden" },
  user: { typeHtml: "hidden" , to_string: "identifiant"},
};

