<template>
  <v-form ref="form" v-model="valid">
    <v-row class="justify-center">
      <v-col cols="12" lg="5">
        <card-box
          id="box"
          icon="mdi-file-star-outline"
          :title="$t('add_recettes_infoplat_1')"
          color="success"
        >
          <box-root
            :input_root="input_root"
            :controller_root="controller_root"
          />
          <box-inputs
            :names="['name', 'description']"
            :controller_root="controller_root"
          />

          <v-row>
            <v-col cols="6">
              <box-inputs
                :names="['color']"
                :controller_root="controller_root"
              />
            </v-col>
            <v-col cols="6">
              <box-inputs
                :names="['logo']"
                :controller_root="controller_root"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <box-inputs
                :names="['isracine']"
                :controller_root="controller_root"
            /></v-col>
            <v-col cols="4"  v-show="val_isracine()==true">
              <box-inputs :names="['unite']" :controller_root="controller_root"
            /></v-col>
            <v-col cols="4" v-show="val_isracine()==true">
              <box-inputs
                :names="['qtereturn','stockalerte']"
                :controller_root="controller_root"
            /></v-col>
          </v-row>
        </card-box>
      </v-col>

      <v-col cols="12" lg="7" v-show="val_root() != ''">
        <card-box
          id="addlist2"
          icon="mdi-file-star-outline"
          :title="$t('add_recettes_infoplat_2')"
          color="success"
        >
          <addlist-table controller_listsAdd="produitsrecettes" />
        </card-box>

        <card-box
        v-show="val_isracine()==false"
          id="addlist22"
          icon="mdi-file-star-outline"
          :title="$t('add_recettes_infoplat_3')"
          color="success"
        >
          <addlist-table controller_listsAdd="recetteforrecettes" />
        </card-box>
      </v-col>

      <v-col cols="12" lg="11" v-show="val_root() != ''">
        <card-box
        v-show="val_isracine()==false"
          id="addlist2x"
          icon="mdi-file-star-outline"
          :title="$t('add_recettes_infoplat_4')"
          color="success"
        >
          <v-row>
            <v-col cols="12" lg="4">
              <addlist-foradd
                item_listsAdd="personnalisez"
                controller_listsAdd="recettepersonnalisezs"
              />
            </v-col>

            <v-col cols="12" lg="4">
              <addlist-foradd
                item_listsAdd="extras"
                controller_listsAdd="recetteextras"
              />
            </v-col>
            <v-col cols="12" lg="4">
              <addlist-foradd
                item_listsAdd="tags"
                controller_listsAdd="tagsrecettes"
              />
            </v-col>
          </v-row>
        </card-box>
      </v-col>

      <v-col :cols="12" :lg="4" v-show="val_root() != ''">
        <card-box id="default">
          <btn-send :controller_root="controller_root" :valid="valid" />
        </card-box>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import i18n from "@/plugins/i18n";
import { mapGetters } from "vuex";
import ViewMixin from "@/views/add";

export default {
  mixins: [ViewMixin],
 

  props: {},
  data() {
    return {
      controller_root: "recette",
      input_root: "categories",
      namemodule: "Menu",
      lists_Add: [
        {
          controller_listsAdd: "produitsrecettes",
        },
        {
          controller_listsAdd: "recetteforrecettes",
        },
        { controller_listsAdd: "recetteextras" },
        { controller_listsAdd: "recettepersonnalisezs" },
        { controller_listsAdd: "tagsrecettes" },
      ],
    };
  },
  created() {
    document.title = i18n.t("add_" + this.controller_root);
    this.initia();
    this.run();
  },
  methods: {
    val_isracine() {
      
      let value = this.$store.getters["structure/get_input_binds"]
      ({ controller_root: this.controller_root, name:"isracine", });
      
      if (value == undefined) {
        return false;
      }
     
      return value;
    },
  },
};
</script>
