<template>
  <div>
    <!-- form -->
    <v-list two-line subheader>
      <show-item-render
        v-for="(schema_input, index) in schemas_inputs"
        :key="index"
        :typeHtml="schema_input.typeHtml"
        :name="schema_input.name"
        :schema="schema_input"
        :controller_root="controller_root"
        :grade="grade(schema_input.name)"

       
       
      />
    </v-list>

    <!-- /.form -->
  </div>
</template>
<script >
import { mapGetters } from "vuex";
export default {
  props: {
    controller_root: {
      type: String,
      required:true
    },
    names: {
      type: Array,
      required:true
    },
  },
  computed: {
    schemas_inputs() {
      let Global_inputs = this.$store.getters["structure/get_schemas"]({
        controller_root: this.controller_root
      });

      let inputs = Global_inputs.filter(input =>
        this.to_array_simple(this.names).includes(input.name)
      );
      return inputs;
    }
  },
  methods: {
    to_array_simple(group) {
      let res = [];
      for (const key in group) {
        const g = group[key];
        if (typeof g == "string") {
          res.push(g);
        } else {
          Object.keys(g).forEach(ref => {
            res.push(ref);
            res.push(g[ref]);
          });
        }
      }
      return res;
    },
    grade(name_input) {
      let grade = "normal";

      for (const key in this.names) {
        const element = this.names[key];

        if (typeof element == "string") {
          if (element == name_input) {
            grade = "normal";
          }
        } else {
          Object.keys(element).forEach(ref => {
            if (ref == name_input) {
              grade = "master";
            }
            if (element[ref] == name_input) {
              grade = "slave";
            }
          });
        }
      }

      return grade;
    }
  },

  mounted() {}
};
</script>

<style >
</style>