<template>
  <v-row class="justify-center">
    <v-col :cols="12" :lg="11">
      <card-box
        id="table"
        color="orange"
        :title="$t('bonsachats_box_title_1')"
        :text="$t('bonsachats_box_text_1')"
      >
        <template v-slot:chart>
          <div>
            <!-- <baseCrm></baseCrm> -->
          </div>
        </template>

        <tablestyle-run :controller_root="controller_root" card="tablestyle-cardbonsachats" />
      </card-box>
    </v-col>
    <v-col :cols="12" :lg="11"> </v-col>
  </v-row>
</template>

<script>
import i18n from "@/plugins/i18n";
import { mapGetters } from "vuex";
import ViewMixin from "@/views/show";

export default {
  mixins: [ViewMixin],
  data() {
    return {
      controller_root: "bonsachats",
      namemodule: "CRM",
      options_showData: {
        title: "liste des  clients sauvegarder",
        not_affiche: [],
        expanded: [],
      },
    };
  },
  created() {
    this.initia();
    this.run();

    document.title = i18n.t("show_" + this.controller_root);
  },
};
</script>
