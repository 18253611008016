import router from '@/router'
import api_Menu from '@/#_CONFIG/Menu'
import axios from 'axios'
import {ctl_i18n_lang} from '@/util/system_i18n_lang'


function security_access(names_controllers, state, rootGetters, access, if_) {
  let namescontrollers
  if (names_controllers === undefined) {
    namescontrollers = state.namescontrollers
  } else {
    namescontrollers = names_controllers
  }

  namescontrollers.forEach((controller_root) => {
    if (if_ != controller_root) {
      if (rootGetters['security/isGranted'](access, controller_root) == false) {
        router.push('/accessdenied')
      }
    }
  })
}

export default {
  run_get_metaData({ state, commit, dispatch, rootState, getters }) {

    localStorage.setItem('pool_lang_i18n', '')
    let Base_url = rootState.Base_url
    let JWTToken = rootState.JWTToken

    let urlPage = state.Index_url

    if (JWTToken == '') {
      router.push({ name: 'login' })
      return
    }

   

    let param = {
      headers: {
        Accept: 'application/ld+json',
      },
    }

    param['headers']['Authorization'] = JWTToken

    ctl_i18n_lang(Base_url, JWTToken,14000)

    axios
      .get(Base_url + urlPage, param)
      .then((response) => {
        if (response.data.security.connecte == false) {
          router.push({ name: 'login' })
          return
        }

        commit('theme/set_menu', api_Menu)

        commit('set_Base_url', response.data.BaseUrl)
        commit('set_paths', response.data.paths)
        commit('set_lazy', response.data.lazy)
        commit('set_created', response.data.created)

        commit('set_dataselect', response.data.dataselect)

        commit('set_add', response.data.add)
        commit('set_update', response.data.update)
        commit('set_delete', response.data.delete)
        commit('set_importexcel', response.data.importexcel)

        commit('set_attachs', response.data.attachs)
        commit('set_load', response.data.load)
        commit('set_pdf', response.data.pdf)
        commit('set_email', response.data.email)
        commit('set_fusionner', response.data.fusionner)

        commit('set_statistiques', response.data.statistiques)

        commit('set_gestion_files', response.data.gestion_files)

        commit('theme/set_count_notification', response.data.count_notification)

        commit('theme/set_organisation', response.data.organisation)
        commit('security/set_Base_url', response.data.BaseUrl)
        commit('security/set_user', response.data.security.user)

        commit('security/set_connecte', response.data.security.connecte)
        commit('security/set_isAdmin', response.data.security.isAdmin)

        if (response.data.security.user[0].active == false) {
          router.push({ name: 'accessdenied' })
        }

        router.push({ name: '_dashboard' })
      })
      .catch(() => {
        router.push({ name: 'login' })
        return
      })
  },

  initialize_view({ state, commit, dispatch, rootState }, { controllers }) {
    commit('show_data/set_init', controllers)
    commit('structure/set_init', controllers)

    commit('structure/addlist/set_init', controllers)
    commit('structure/multiselect/set_init', controllers)

    commit('dialog/set_init', controllers)
  },

  run_show(
    { state, commit, dispatch, rootState, rootGetters },
    { names_controllers, params },
  ) {
    security_access(names_controllers, state['show_data'], rootGetters, 'view')

    dispatch('show_data/ajax_data', { names_controllers, params })
  },

  initialize_struct_from(
    { state, commit, dispatch, rootState, getters, rootGetters },
    { names_controllers },
  ) {
    dispatch('structure/ajax_initialize_struct_from', { names_controllers })
  },

  custom_fromById(
    { state, commit, dispatch, rootState, getters, rootGetters },
    { names_controllers, id },
  ) {
    security_access(names_controllers, state['structure'], rootGetters, 'edit')
    dispatch('structure/ajax_custom_fromById', { names_controllers, id })
  },

  custom_showById(
    { state, commit, dispatch, rootState, getters, rootGetters },
    { names_controllers, id },
  ) {
    security_access(
      names_controllers,
      state['structure'],
      rootGetters,
      'view',
      'user',
    )
    dispatch('structure/ajax_custom_fromById', { names_controllers, id })
  },

  run_item(
    { state, commit, dispatch, rootState, getters, rootGetters },
    { controllers },
  ) {
    rootState.structure.loading = true
    rootState.structure.addlist.loading = true
    rootState.structure.multiselect.loading = true

    rootState.structure.write = false
    rootState.structure.addlist.write = false
    rootState.structure.multiselect.write = false

    commit('set_init', controllers)
    commit('dialog/set_init', controllers)
    commit('structure/set_init', controllers)
    commit('structure/addlist/set_init', controllers)
    commit('structure/multiselect/set_init', controllers)

    const APIprefixe = state.APIprefixe
    for (const key in controllers) {
      const controller = controllers[key]
      const controller_root = controller.controller_root

      //  const urlPage = APIprefixe + controller_root;
      const urlPage = state.metaData.paths[controller_root] ///APIprefixe + controller_root;

      let Base_url = rootState.Base_url
      let JWTToken = rootState.JWTToken
      let param = {
        params: {
          get: 'schema',
          schema: 'all',
        },
      }
      if (JWTToken != '') {
        param['headers'] = {
          Authorization: JWTToken,
        }
      }

      //// schema
      axios
        .get(Base_url + urlPage, param)
        .then(function (response) {
          const schemaGlobalHTML = response.data
          commit('add_schemaGlobalHTML', { schemaGlobalHTML, controller_root })
          commit('structure/addlist/set_schemehtml', {
            schemaGlobalHTML,
            controller_root,
          })
          commit('structure/init_Select_load', {
            schemaGlobalHTML,
            controller_root,
          })
          commit('structure/addlist/init_Select_load', {
            schemaGlobalHTML,
            controller_root,
          })
        })
        .finally(function () {
          let Base_url = rootState.Base_url
          let JWTToken = rootState.JWTToken
          let param = {
            params: {
              get: 'load',
              typeselect: 'selectsimple',
              where: controller.controller_root + '.id=' + controller.id,
            },
          }
          if (JWTToken != '') {
            param['headers'] = {
              Authorization: JWTToken,
            }
          }

          //// data save

          axios
            .get(Base_url + urlPage, param)
            .then(function (response) {
              commit('structure/set_values_inputs', {
                controller_root,
                item: response.data.dataSet[0],
              })
              dispatch('structure/ajax_data_Select_load', {
                controller: controller_root,
              })
              dispatch('structure/multiselect/ajax_headers', {
                controller_root,
              })
            })
            .finally(function () {
              dispatch('structure/multiselect/ajax_data_joinsave', {
                name:
                  rootGetters['structure/get_root'][controller.controller_root]
                    .name,
                value:
                  rootGetters['structure/get_root'][controller.controller_root]
                    .value,
                controller_root,
              })

              dispatch('structure/addlist/ajax_data_save', { controller_root })

              dispatch('structure/addlist/ajax_data_Select_load', {
                name:
                  rootGetters['structure/get_root'][controller.controller_root]
                    .name,
                value:
                  rootGetters['structure/get_root'][controller.controller_root]
                    .value,
                controller_root,
              })
            })
        })
    }
  },
}
