export default {
  controllers: {},
  showbtnsend:false,

/*   dialog["nametable"]:
  { type: "show",
  flag: false,
  data: {}} */
 
 
};
