<template>
  <v-footer
    id="theme-footer"
    height="auto"
    absolute
    class="pa-1 app--footer font-weight-medium"
  >
    <v-spacer />
    <span>dev: app AWA v0.3 &copy; {{ new Date().getFullYear() }}</span>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style>
</style>
