import router from '@/router'
import axios from 'axios'

const param = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  onUploadProgress: function (progressEvent) {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    )
    //console.log("onUploadProgress => " + percentCompleted);
  },
}

function createFormData(formData, key, data) {
  /**
     * 
     * Usage example:
        var data = {a: '1', b: 2, c: {d: '3'}};
        var formData = new FormData();
        createFormData(formData, 'data', data);
        
 * Sent data:
        data[a]=1&
        data[b]=2&
        data[c][d]=3
     */

  if (data === Object(data) || Array.isArray(data)) {
    for (var i in data) {
      createFormData(formData, key + '[' + i + ']', data[i])
    }
  } else {
    if (data !== null && data !== 'null') {
      formData.append(key, data)
    } else {
      console.log('null   ' + data)
    }
  }
}

function data_binds(controller_root, rootGetters) {
  // const data_binds = rootGetters["structure/get_inputes_binds"]({
  //   controller_root,
  // });
  // return data_binds;

  let data = {}

  const data_binds = rootGetters['structure/get_inputes_binds']({
    controller_root,
  })

  for (const key in data_binds) {
    if (Object.hasOwnProperty.call(data_binds, key)) {
      const element = data_binds[key]
      if (typeof element != 'object') {
        data[key] = element
      }
    }
  }

  return data
}

export default {
  ajax_chargeDataSelect(
    { commit, dispatch, getters, rootGetters, rootState, state },
    { idproduit, identrepot, controller_root, qte_not_zero },
  ) {
    const url = rootGetters['get_dataselect']('lot')
    let Base_url = rootState.Base_url;
    let JWTToken = rootState.JWTToken


    let param = {
      headers: {
       
      },  params: {
        idproduit,
        identrepot,
        controller_root,
        qte_not_zero,
      },
    }
    param['headers']['Authorization']=JWTToken
    axios
      .get(Base_url+url, param)
      .then((response) => {
        let data_Select_load = response['data']['dataSet']
        commit('set_data_Select_load', { data_Select_load })
      })
      .catch(function (error) {
        console.error('error get info', error)
      })
  },

  ajax_add_chargeDataInfoLot(
    { commit, dispatch, getters, rootGetters, rootState, state },
    { idproduit, identrepot, controller_root, qte_not_zero },
  ) {
    const url = rootGetters['get_dataselect']('lot')

    let Base_url = rootState.Base_url;
    let JWTToken = rootState.JWTToken


    let param = {
      headers: {
       
      },   params: {
        idproduit,
        identrepot,
        controller_root,
        qte_not_zero,
      },
    }
    param['headers']['Authorization']=JWTToken




    axios
      .get(Base_url+url, param)
      .then((response) => {
        let last = JSON.parse(JSON.stringify(state.Data_Info_Lot))
        let newdata = response['data']['dataSet']

        let Data_Info_Lot = [].concat(last, newdata)
        commit('set_Data_Info_Lot', { Data_Info_Lot })
      })
      .catch(function (error) {
        console.error('error get info', error)
      })
  },

  /**
   *
   * 3tini data data binds o nsiftha machrlich fik
   *
   *
   */
  send_data_ajax(
    { commit, dispatch, getters, rootGetters, rootState, state },
    { controller_root },
  ) {
    let data = data_binds('lot', rootGetters)
    // data = data_binds_listsAdd("lot", rootGetters, data);
    // data = data_binds_multiselect("lot", rootGetters, data);

    console.log(data)
    const formdata = new FormData()
    createFormData(formdata, 'data_binds', data)
    let urlPage = ''

    if (data['id'] == '') {
      urlPage = rootGetters['get_add']('lot')
      commit(
        'flash/set_message',
        "C'est bon!   Un élément a été  enregistrée",
        { root: true },
      )
      commit('flash/set_color', 'success', { root: true })
    } else {
      urlPage = rootGetters['get_update']('lot')
      commit('flash/set_message', "C'est bon!   Un élément a été modifié", {
        root: true,
      })
      commit('flash/set_color', 'info', { root: true })
    }


    let Base_url = rootState.Base_url;
    let JWTToken = rootState.JWTToken


   
    param['headers']['Authorization']=JWTToken

    axios
      .post(Base_url+urlPage, formdata, param)
      .then((response) => {
        commit('dialog/set_dialog_btn_send', { flag: false }, { root: true })

        commit('flash/set_show', true, { root: true })

        // let controller_root = this.controller_root;
        // dispatch("lots/ajax_chargeDataSelect", {}, { root: true });

        // dispatch(
        //  "run_show",
        //  { names_controllers: [controller_main] },
        //    { root: true }
        //  );
        // dispatch("lots/ajax_chargeDataSelect", {}, { root: true });

        dispatch('theme/count_notification', {}, { root: true })
        let idproduit = response.data.dataSet[0]['produits_id']
        let identrepot = response.data.dataSet[0]['entrepot_id']

        dispatch(
          'lots/ajax_chargeDataSelect',
          {
            idproduit: idproduit,
            controller_root: controller_root,
            identrepot,
            qte_not_zero: false,
          },
          { root: true },
        )
        // commit(
        //   "structure/set_input_binds",
        //   {
        //     name: controller_root,
        //     value: id,
        //     controller_root: controller_main,
        //   },
        //   { root: true }
        // );
      })
      .catch((error) => {
        console.error('error to POST data =>' + error)
        commit('dialog/set_dialog_btn_send', { flag: false }, { root: true })
        // router.push('/login')
      })
  },
}
