<template>
<v-col cols="12" sm="6" md="4">
    <v-text-field :readonly="readonly" 
    :rules="[validation.decimal]" 
    :label="label" 
    :value="values_binds" 
     @input="modelbind($event)" type="number" :loading="loading" ></v-text-field>
<slot></slot>
</v-col>


</template>
<script>
import InputMixin from "./InputMixin";
export default {
   mixins: [InputMixin],
};
</script>