<template>
  <v-card
    v-bind="$attrs"
    :class="classes"
    class="v-card--material pa-3"
    elevation="3"
  >
    <div class="d-flex grow flex-wrap">
      <v-avatar
        v-if="avatar"
        :size="sizeAvatar"
        class="mx-auto v-card--material __avatar elevation-6"
        color="grey"
      >
        <v-img :src="avatar" />
      </v-avatar>

      <v-sheet
        v-else
        :class="{ 'pa-5': !$slots.image }"
        :color="color"
        :max-height="icon ? 90 : undefined"
        :width="icon ? 'auto' : '100%'"
        elevation="6"
        class="text-start v-card--material __heading"
        dark
        shaped
      >
        <slot v-if="$slots.heading" name="heading" />

        <slot v-else-if="$slots.image" name="image" />

        <div
          v-else-if="title && !icon"
          class="display-1 font-weight-light"
          v-text="title"
        />

        <v-icon v-else-if="icon && !text" size="32" v-text="icon" />

        <v-tooltip top color="blue" v-else-if="icon && text" max-width="460">
          <template v-slot:activator="{ on }">
            <v-icon size="32" v-text="icon" v-on="on" />
          </template>
          <span class="subtitle-2 font-weight-thin">{{ text }}</span>
        </v-tooltip>

        <div
          v-if="!icon && text"
          class="headline font-weight-thin"
          v-text="text"
        />
      </v-sheet>

      <div v-if="$slots['after-heading']" class="ml-6">
        <slot name="after-heading" />
      </div>

      <div v-else-if="icon && title" class="ml-4">
        <div class="card-title font-weight-light" v-text="title" />
      </div>
<v-spacer></v-spacer>
      <v-btn
        icon
        
        v-if="close"
         @click="closeevent()" 
        class="right"> <v-icon>mdi-close</v-icon> </v-btn>
    </div>

    <slot />

    <template v-if="$slots.actions">
      <v-divider class="mt-2" />

      <v-card-actions class="pb-0">
        <slot name="actions" />
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
export default {
  name: "MaterialCard",

  props: {
    avatar: {
      type: String,
      default: "",
    },

    sizeAvatar: {
      type: String,
      default: "128",
    },

    color: {
      type: String,
      default: "success",
    },
    icon: {
      type: String,
      default: undefined,
    },
    image: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    }, 
    close: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      return {
        "v-card--material--has-heading": this.hasHeading,
      };
    },
    hasHeading() {
      return Boolean(this.$slots.heading || this.title || this.icon);
    },
    hasAltHeading() {
      return Boolean(this.$slots.heading || (this.title && this.icon));
    },
  },
  methods: {
    closeevent() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.v-card--material .__avatar {
  position: relative;
  top: -64px;
  margin-bottom: -32px;
}

.v-card--material .__heading {
  position: relative;
  top: -40px;
  transition: 0.3s ease;
  z-index: 1;
}
</style>
