module.exports = {
  id: { typeHtml: "hidden" },
  fournisseur: { typeHtml: "text", unique: true, nullable: false  },

  adresse: { typeHtml: "Textarea", nullable: false  },
  codePostal: { typeHtml: "text" },

  telephone: { typeHtml: "tel" },
  gsm: { typeHtml: "tel" },
  email: { typeHtml: "email" },
  files: { typeHtml: "files" },
  logo: { typeHtml: "logo" },
  formesJusidique: { typeHtml: "text" },
  ice: { typeHtml: "text" },
  fax: { typeHtml: "tel" },
  siteweb: { typeHtml: "url" },
  note: { typeHtml: "Textarea" },

/*   pays: { typeHtml: "selectajax", to_string: "pays" },
  regions: { typeHtml: "selectajax", to_string: "region" },
  ville: { typeHtml: "selectajax", to_string: "ville" }, */

  /* reduction: { typeHtml: "decimal" }, */
  user: { typeHtml: "hidden" , to_string: "identifiant"},

  updatedAt: { typeHtml: "hidden" },
  createdAt: { typeHtml: "hidden" },
};
