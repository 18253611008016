import i18n from '@/plugins/i18n'

export default {
  schemehtml: (state) => {
    return state.schemehtml
  },

  rows: (state) => {
    return state.rows
  },

  titles: (state) => (controller_listsAdd) => {
    // return state.titles[controller_listsAdd];

    let headers = []

    let titles = state.titles[controller_listsAdd]

    for (const key in titles) {
      const obj_title = titles[key]

      /// is not array namesexpanded
      if (obj_title.value != undefined) {
        obj_title.text = i18n.t(obj_title.value)
        headers.push(obj_title)
      }
    }

    return headers
  },

  controller_listsLots: (state) => (controller_listsAdd) => {
    return state.controller_listsLots[controller_listsAdd]
  },

  editedIndex_addlist: (state) => (controller_listsAdd) => {
    return state.editedIndex_addlist[controller_listsAdd]
  },

  items: (state) => (controller_listsAdd) => {
    // array of object <item>
    let items = []
    let rows = state.rows[controller_listsAdd]

    for (const index in rows) {
      const row = rows[index]
      let item = { index: index }

      for (const k in row) {
        const input = row[k]
        const value = input.value

        const name = input.from

        if (name == 'id') {
          item[name] = index
        } else if (
          input.typeHtml == 'select' ||
          input.typeHtml == 'selectajax'
        ) {
          const nameselect = name
          const id = value

          let data = state.data_Select_load[controller_listsAdd][nameselect]

          for (const key in data) {
            const element = data[key]
            if (element['@id'] == id) {
              if (typeof input.to_string == 'string') {
                item[input.name] = element[input.to_string]
              } else {
                let txt=
                
                
                  element[input.to_string[0]] +
                  ' (' +element[input.to_string[1]] +')'

                  if (element[input.to_string[2]]!=undefined&&element[input.to_string[2]]!=""&&element[input.to_string[2]]!=null) {
                    txt=txt+' / '+ element[input.to_string[2]]+"dh"
                  }

                  item[input.name] =txt
              }
              item['_id_' + input.name] = id
            }
          }
        } else {
          item[name] = value
        }
      }

      items.push(item)
    }

    return items
  },

  columns: (state) => (controller_listsAdd, name) => {
    let rows = state.rows[controller_listsAdd]
    let columns = []

    for (const row of rows) {
      for (const item of row) {
        if (item['name'] == name) {
          columns.push(item['value'])
        }
      }
    }
    return columns
  },

  data_Select_load: (state) => {
    return state.data_Select_load
  },

  field_attach_to: (state) => {
    return state.field_attach_to
  },

  calcules: (state) => {
    return state.calcules
  },

  filter: (state) => {
    return state.filter
  },

  loading: (state) => {
    return state.loading
  },
  write: (state) => {
    return state.write
  },

  /// get <object> data par value id select
  find_id: (state) => ({ id, controller_listsAdd, nameselect }) => {
    let data = state.data_Select_load[controller_listsAdd][nameselect]

    for (const key in data) {
      const element = data[key]
      if (element['@id'] == id) {
        return element
      }
    }
    return false
  },

  //// send data
  namescontroller_listsAdd: (state) => ({ controller_root }) => {
    const namescontroller_listsAdd = []

    state.namescontroller_listsAdd.forEach((controller_listsAdd) => {
      if (state['controller_root'][controller_listsAdd] == controller_root) {
        namescontroller_listsAdd.push(controller_listsAdd)
      }
    })
    return namescontroller_listsAdd
  },
  /// get <object> data set to view (pour send data to server)
  inputes_binds: (state) => ({ controller_listsAdd }) => {
    const rows = state.rows[controller_listsAdd]
    const data = []
    for (const key in rows) {
      const row = rows[key]
      const data_row = {}
      for (const i in row) {
        const input = row[i]
        data_row[input['name']] = input['value']
      }
      data.push(data_row)
    }
    return data
  },

  /*  Value_Sava: state => {
          return state.schemeformlist;
        }, */
}
