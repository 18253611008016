var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"transition":"scale-transition","loading":_vm.loading,"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
        typeof _vm.itemObject === 'object' &&
        _vm.itemObject != '' &&
        _vm.itemObject != null &&
        _vm.itemObject['@id'] != '' &&
        _vm.itemObject['@id'] != null &&
        _vm.itemObject['@id'] != undefined
      )?_c('div',_vm._g(_vm._b({staticClass:"subtitle-2 orange--text"},'div',attrs,false),on),[_c('v-chip',[_c('v-icon',{staticClass:"pr-2",attrs:{"color":"orange"}},[_vm._v("mdi-clipboard-multiple-outline")]),_vm._v(" "+_vm._s(_vm.show_style_not_string()))],1)],1):_c('div',{staticClass:"body-2",domProps:{"textContent":_vm._s(_vm.show_style_not_string())}})]}}]),model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[(
        typeof _vm.itemObject === 'object' &&
        _vm.itemObject != '' &&
        _vm.itemObject != null &&
        _vm.itemObject['@id'] != '' &&
        _vm.itemObject['@id'] != null &&
        _vm.itemObject['@id'] != undefined
      )?_c('v-card',{attrs:{"min-width":"350px","flat":"","loading":_vm.loading}},[_c('v-toolbar',{attrs:{"color":"orange","dark":""}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.isGranted('view')),expression:"isGranted('view')"}],attrs:{"icon":""},on:{"click":function($event){return _vm.showItem()}}},[_c('v-icon',[_vm._v("mdi-view-carousel")])],1),_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.show_style_not_string())+" / id "+_vm._s(_vm.id))]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.isGranted('view')),expression:"isGranted('view')"}],attrs:{"icon":""},on:{"click":function($event){return _vm.editItem()}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-spacer'),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.selectedOpen = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-list',{attrs:{"two-line":""}},_vm._l((_vm.itemObject),function(value,key){return _c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(key != '@id' && key != '@type'),expression:"key != '@id' && key != '@type'"}],key:key},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v(" mdi-star ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(key)))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(value))])],1)],1)}),1),_c('span')],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }