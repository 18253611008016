<template>
  <v-row class="justify-center align-center" style="margin-bottom: :40px;">
    <v-col
      :cols="12"
      :lg="6"
      
      class="justify-center align-center"
      v-if="!isadmin"
    >
      <theme-clock />
    </v-col>

    <v-col
      :cols="12"
      
      :lg="7"
      v-for="(widget, index) in widgets"
      :key="index"
    >
      <component :is="widget" v-if="isadmin"></component>

      <br /><br />
    </v-col>

    <!--  <theme-clock />  -->
    <v-col
       :cols="12"
      :lg="7"
      
      class="justify-center align-center"
      v-if="widgets.length == 0 && isadmin"
    >
      <steppers-app />
    </v-col>
  </v-row>
</template>

<script>
import card from "vuetify/es5/util/colors";
import interact from "interactjs";
import i18n from "@/plugins/i18n";

export default {
  layout: "dashboard",

  data: () => ({}),

  created() {
    document.title = "Tableau de bord";
  },

  computed: {
    widgets() {
      return this.$store.getters["theme/widgets"].filter(function (el) {
        return el != null && el != "";
      });
    },
    isadmin() {
      return this.$store.getters["security/isAdmin"];
    },
  },
};
</script>
<style lang="scss" scoped>
</style>

