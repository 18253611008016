module.exports = {
  id: { typeHtml: "hidden" },

  paiement: { typeHtml: "text" },
  updatedAt: { typeHtml: "hidden" },
  createdAt: { typeHtml: "hidden" },
  commandes: { typeHtml: "select" },
  user: { typeHtml: "hidden" , to_string: "identifiant"},
  date: { typeHtml: "date" },
  montantHt: { typeHtml: "money" },
  montantTva: { typeHtml: "money" },
  timbrefiscal: { typeHtml: "text" },
  autreimpots: { typeHtml: "text" },
  montantTtc: { typeHtml: "money" },
  modepaiement: { typeHtml: "select" },
  especes: { typeHtml: "text" },
  rendu: { typeHtml: "text" },
};
