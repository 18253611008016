<template>
  <v-form ref="form" v-model="valid">
    <v-row class="justify-center">
      <v-col :cols="12" :lg="6">
        <card-box
          id="groupa1"
          color="orange"
          :title="$t('add_tags_infoplat_1')"
          :text="$t('add_tags_infoplat_2')"
        >
          <template v-slot:chart> </template>

          <box-default :controller_root="controller_root" />
        </card-box>
      </v-col>
    

      <v-col :cols="12" :lg="4">
        <card-box id="default">
          <btn-send :controller_root="controller_root" :valid="valid" />
        </card-box>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import i18n from "@/plugins/i18n";
import { mapGetters } from "vuex";
import ViewMixin from "@/views/add";

export default {
  mixins: [ViewMixin],

  data() {
    return {
      controller_root: "tags",
      namemodule: "CRM",
      lists_Add: [
        {
          controller_listsAdd: "tagsrecettes",
        },
      ],
    };
  },
  created() {
    document.title = i18n.t("add_" + this.controller_root);
    this.initia();
    this.run();

    this.$store.dispatch("structure/addlist/ajax_initialize_struct_ListAdd");
  },
};
</script>
