<template>
  <div>
    <v-row class="justify-center">
      <v-col cols="12" lg="5" class="justify-center">
        <h2>{{ $t("Administrateur de base de données") }}</h2>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col cols="11" lg="5">
        <v-col cols="12">
          <v-date-picker
            :disabled="load || valueProgress > 0"
            :events="dateEvents"
            full-width
            v-model="date"
            :locale="$t('locale')"
          ></v-date-picker>
        </v-col>
        <v-col cols="12">
          <v-file-input
            :label="$t('importer base données format sql ')"
            @change="modelbindfile($event)"
            show-size
            counter
            chips
            icon="mdi-database-import-outline"
          ></v-file-input>
          <br /><br />
        </v-col>


          <v-col cols="12">
          <v-file-input
            :label="$t('importer  format zip (base données/files) ')"
            @change="modelbindfilezip($event)"
            show-size
            counter
            chips
            icon="mdi-database-import-outline"
          ></v-file-input>
          <br /><br />
        </v-col>
      </v-col>

      <v-col cols="11" lg="5" class="justify-center">
        <v-row class="justify-center">
          <v-col cols="10">
            <h3>{{ $t("Gestion base de données") }}</h3>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn @click="save_backupdb()" v-on="on">
                  <v-icon color="primary">mdi-content-save</v-icon>
                </v-btn>
              </template>
              <span> {{ $t("savangarder base donnée a") }} </span>
              <strong>{{ new Date().toISOString().substr(0, 10) }}</strong>
            </v-tooltip>
          </v-col>
          <v-col cols="3">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="restoredb()"
                  v-on="on"
                  :disabled="radioGroupDate == ''"
                >
                  <v-icon color="orange">mdi-file-restore-outline</v-icon>
                </v-btn>
              </template>
              <span> {{ $t("restauration base donnée a") }} </span>
              <strong>{{ radioGroupDate }}</strong>
            </v-tooltip>
          </v-col>

          <v-col cols="3">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="dowload_restoredb()"
                  v-on="on"
                  :disabled="radioGroupDate == ''"
                >
                  <v-icon color="orange">mdi-download</v-icon>
                </v-btn>
              </template>
              <span> {{ $t("telecharger base donnée a") }}</span>
              <strong>{{ radioGroupDate }}</strong>
            </v-tooltip>
          </v-col>
          <v-col cols="3">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="dowload_all()"
                  v-on="on"
                  :loading="loadbtn"
                  :disabled="loadbtn"
                >
                  <v-icon color="red">mdi-download-lock-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("telecharger tous") }} </span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="11" v-show="valueProgress > 0">
            <v-progress-linear
              white="100%"
              :value="valueProgress"
              color="success"
              v-show="valueProgress > 0"
            />
          </v-col>

          <v-col cols="11" v-show="load">
            <theme-progressfuk v-if="load" white="100%" />
            <!-- <v-progress-circular
                size="200"
                v-if="load"
                indeterminate
                color="primary"
                width="17"
              ></v-progress-circular> -->
          </v-col>

          <v-col cols="11">
            <div v-html="message"></div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-radio-group
              v-model="radioGroupDate"
              :disabled="load || valueProgress > 0"
            >
              <v-radio
                v-for="listFile in listFiles"
                :key="listFile.id"
                :label="listFile.formation"
                :value="listFile.id"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import i18n from "@/plugins/i18n";
import axios from "axios";
export default {
  data() {
    return {
      //  date: new Date().toISOString().substr(0, 10),
      date: "",
      loadbtn: false,
      load: false,
      radioGroupDate: "",

      listDate: [],
      allFiles: {},
      listFiles: [],

      message: "",
      valueProgress: 0,
    };
  },

  methods: {
    save_backupdb() {
      let JWTToken = this.JWTToken;
      this.load = true;
      this.date = "";
      this.message = "";
      axios.post(this.Base_url_web + "/backup/backupdb",{},{
        params: {},
        headers: {
          Authorization: JWTToken,
        },
      }).then((response) => {
        this.load = false;
        this.message = response.data;

        this.listbackup();
      });
    },
    restoredb() {
      this.load = true;
      this.message = "";
      let JWTToken = this.JWTToken;
      let formdata = new FormData();
      formdata.append("date", this.radioGroupDate);

      axios
        .post(this.Base_url_web + "/backup/restoredb", formdata, {
        params: {},
        headers: {
          Authorization: JWTToken,
        },
      })
        .then((response) => {
          this.message = response.data;
          this.load = false;
        });
    },
    listbackup() {
      let JWTToken = this.JWTToken;
      this.load = true;
      this.message = "";
      axios.post(this.Base_url_web + "/backup/listbackup",{},{
        params: {},
        headers: {
          Authorization: JWTToken,
        },
      }).then((response) => {
        this.listDate = response.data["listDate"];
        this.allFiles = response.data["allFiles"];
        this.load = false;
        this.date = new Date().toISOString().substr(0, 10);
      });
    },

    dateEvents(date) {
      if (this.listDate.indexOf(date) != -1) {
        return "success";
      }

      // const [, , day] = date.split("-");

      // if ([12, 17, 28].includes(parseInt(day, 10))) return true;
      // if ([1, 19, 22].includes(parseInt(day, 10))) return ["red", "#00f"];

      return false;
    },
    dowload_restoredb() {
      this.load = true;
      this.message = "";
      let JWTToken = this.JWTToken;
      let formdata = new FormData();
      formdata.append("date", this.radioGroupDate);

      axios
        .post(this.Base_url_web + "/backup/pathrestoredb", formdata, {
        params: {},
        headers: {
          Authorization: JWTToken,
        },
      })
        .then((response) => {
          this.message = response.data["path"];
          this.load = false;
          let a = document.createElement("a");
          a.href = this.Base_url_web+'/'+response.data["path"];
          a.download = response.data["name"];
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
    },
    dowload_all() {
      this.loadbtn = true;
      let a = document.createElement("a");
      a.href = this.Base_url_web+'/'+"dowloadzip";
      a.download = "dowloadzip";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    modelbindfile(event) {
      if (event == undefined) {
      }
      this.valueProgress = 0;
      if (typeof event === "object") {
        /// send files to server and respons refgroup
        let formdata = new FormData();

        formdata.append("backup", event);
let JWTToken = this.JWTToken;


        let param = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: JWTToken,
          },
          onUploadProgress: (progressEvent) => {
            let etat = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            this.valueProgress = etat;
          },
        };
        axios
          .post(this.Base_url_web + "/backup/uploadrestoredb", formdata, param)
          .then((response) => {
            this.listbackup();
            this.valueProgress = 0;
            this.message = response.data;
            //  fun_finally(response.data);
          });
      }
    },

      modelbindfilezip(event) {
      if (event == undefined) {
      }
      this.valueProgress = 0;
      if (typeof event === "object") {
        /// send files to server and respons refgroup
        let formdata = new FormData();

        formdata.append("zipfile", event);
let JWTToken = this.JWTToken;


        let param = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: JWTToken,
          },
          onUploadProgress: (progressEvent) => {
            let etat = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            this.valueProgress = etat;
          },
        };
        axios
          .post(this.Base_url_web + "/uploadzip", formdata, param)
          .then((response) => {
            this.listbackup();
            this.valueProgress = 0;
            this.message = response.data;
            //  fun_finally(response.data);
          });
      }
    },
  },
  watch: {
    date(newValue, oldValue) {
      let listFiles = [];

      for (const filename in this.allFiles) {
        let res = filename.substr(0, 10);
        if (res == this.date) {
          const formation = this.allFiles[filename];

          listFiles.push({
            id: filename,
            formation: formation,
          });
        }
      }
      this.listFiles = listFiles;
    },
  },

  mounted() {
    this.listbackup();
  },
  computed: {
    Base_url_web() {
      return this.$store.getters["get_Base_url"];
    },JWTToken() {
      return this.$store.getters["get_JWTToken"];
    },
  },
};
</script>
