<template>
  <v-navigation-drawer
    app
    v-model="drawerValue"
    temporary
    id="theme-drawer_left"
    width="320px"
  >
    <v-list dense>
      <v-list-item @click.stop="toggledrawer_left()">
        <v-list-item-avatar>
          <readfile-img :refgroup="refGroup" />
        </v-list-item-avatar>

        <v-list-item-title>{{ info_user["identifiant"] }}</v-list-item-title>
      </v-list-item>

      <v-list-item link :to="{ name: '_dashboard' }" v-if="connecte" exact>
        <v-list-item-title>{{ $t("Mon tableau de bord") }}</v-list-item-title>
        <v-list-item-icon>
          <v-icon>mdi-home-analytics</v-icon>
        </v-list-item-icon>
      </v-list-item>

      <v-list-group v-for="(menu_item, i) in menu" :key="i">
        <template v-slot:activator>
          <v-list-item>
            <v-list-item-title>{{
              $t(menu_item.NameModule)
            }}</v-list-item-title>

            <v-list-item-icon>
              <v-icon>{{ menu_item.IconModule }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </template>
        <span v-for="(controller, key) in menu_item.Controllers" :key="key">
          <v-list-item
            link
            v-show="isGranted(key)"
            v-if="controller['definition']['show']"
            :to="{ name: key + '_show' }"
          >
            <v-list-item-icon>
              <v-icon dense>mdi-arrow-right</v-icon>
            </v-list-item-icon>

            <v-list-item-title dense>
              {{ $t(controller["definition"]["title"]) }}</v-list-item-title
            >

            <v-list-item-icon dense>
              <v-icon> {{ controller["definition"]["IconController"] }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </span>
      </v-list-group>

      <!--  <v-list-item :to="{ name: 'files_show' }" v-if="connecte && isadmin">
        <v-list-item-title>{{ $t("files") }}</v-list-item-title>
        <v-list-item-icon>
          <v-icon>mdi-paperclip</v-icon>
        </v-list-item-icon>
      </v-list-item> -->

      <!--  <v-list-item :to="{ name: 'backup_show' }" v-if="connecte && isadmin">
        <v-list-item-title>{{ $t("backup_show") }}</v-list-item-title>
        <v-list-item-icon>
          <v-icon>mdi-cloud-upload</v-icon>
        </v-list-item-icon>
      </v-list-item> -->

      <v-list-item :to="{ name: '_i18n' }" v-if="connecte && isadmin">
        <v-list-item-title>{{ $t("_i18n") }}</v-list-item-title>
        <v-list-item-icon>
          <v-icon>mdi-translate</v-icon>
        </v-list-item-icon>
      </v-list-item>

      <v-list-item :to="{ name: '_oauth' }" v-if="connecte && isadmin">
        <v-list-item-title>{{ $t("_oauth") }}</v-list-item-title>
        <v-list-item-icon>
          <v-icon>mdi-security-network</v-icon>
        </v-list-item-icon>
      </v-list-item>
     

      
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data: () => ({}),
  methods: {
    toggledrawer_left() {
      this.$store.commit("theme/toggledrawer_left");
    },

    isGranted(controller) {
      return this.$store.getters["security/isGranted"]("view", controller);
    },
  },
  computed: {
    ...mapGetters({
      // attacher `this.doneCount` à `this.$store.getters.doneTodosCount`
      menu: "theme/menu",
      connecte: "security/connecte",
      info_user: "security/user",
    }),

    logo() {
      return this.$store.getters["security/logo"];
    },
    refGroup() {
      return this.$store.getters["security/refGroup"];
    },
    drawerValue: {
      get() {
        return this.$store.getters["theme/drawer_left"];
      },
      set(val) {
        this.$store.commit("theme/set_drawer_left", val);
      },
    },

    isadmin() {
      return this.$store.getters["security/isAdmin"];
    },
  },
};
</script>

<style></style>
