<template>
  <v-list-item>
      <v-list-item-icon>
          <v-icon color="indigo">mdi-calendar-range</v-icon>
        </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title><u>{{ label }}  :</u> </v-list-item-title>
      <h3>{{ convertDate(values_binds)  }}</h3>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
import InputMixin from "./InputMixin";
export default {
  mixins: [InputMixin],

  methods: {
     convertDate(inputFormat) {
      if (
        inputFormat == "" ||
        inputFormat == null ||
        inputFormat == undefined
      ) {
        return "";
      }
      function pad(s) {
        return s < 10 ? "0" + s : s;
      }
      var d = new Date(inputFormat);
      return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(
        "/"
      );
    },
  },

};
</script>
