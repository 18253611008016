export default {
  show: (state) => {
    return state.show;
  },
  color: (state) => {
    return state.color;
  },
  coordonneX: (state) => {
    return state.coordonneX;
  },
  coordonneY: (state) => {
    return state.coordonneY;
  },
  message: (state) => {
    return state.message;
  },
  multi_line: (state) => {
    return state.multi_line;
  },
  timeout: (state) => {
    return state.timeout;
  },
};
