module.exports = {
  id: { typeHtml: "hidden" },
  fournisseur: { typeHtml: "selectajax", to_string: "fournisseur" },
  titre: { typeHtml: "text", unique: true },
  reffournisseur: { typeHtml: "text", unique: true, nullable: false  },
  dateLivraison: { typeHtml: "date", nullable: false  },
  dateCommande: { typeHtml: "date" , nullable: false },
  note: { typeHtml: "Textarea",defaultValue:"" },
  files: { typeHtml: "files" },
  montantHt: { typeHtml: "money" ,defaultValue:"0"},
  montantTva: { typeHtml: "money" ,defaultValue:"0"},
  montantTtc: { typeHtml: "money",defaultValue:"0" },

  
  modepaiement: { typeHtml: "selectajax", to_string: "modepaiement", nullable: false },
  user: { typeHtml: "hidden" , to_string: "identifiant"},
  updatedAt: { typeHtml: "hidden" },
  createdAt: { typeHtml: "hidden" },
  /*   produitsbonsachats: { typeHtml: "hidden" }, */
};
