let colorconsole = "color: red";

export default {
  //ok
  set_init(state, param_tables) {
    let controller_root = {};
    let namemodule = {};
    let nameinput_root = {};
    let editedIndex_addlist = {};

    let namescontroller_listsAdd = [];

    let controller_listsLots = {};

    let titles = {};
    //let id = {};
    let fieldattach = {};
    let field_event_to = {};

    let calcules = {};
    let filter = {};

    let not_affiche = {}; // ok
    let unique = {}; // ok
    let schemehtml = {};
    let rows = {};
    //let inputes_binds = {};
    let data_sava = {};
    let data_Select_load = {};

    for (const index in param_tables) {
      const lists_Adds = param_tables[index]["lists_Add"];

      for (const key in lists_Adds) {
        const lists_Add = lists_Adds[key];

        const controller_listsAdd = lists_Add["controller_listsAdd"];
        namescontroller_listsAdd.push(controller_listsAdd);
        /**************************/

        controller_root[controller_listsAdd] =
          param_tables[index]["controller_root"];
        namemodule[controller_listsAdd] = param_tables[index]["namemodule"];

        nameinput_root[controller_listsAdd] = param_tables[index]["input_root"];

        /**************************/

        //id[controller_listsAdd] = _id;
        fieldattach[controller_listsAdd] = lists_Add.field_attach_to||"";
        field_event_to[controller_listsAdd] = lists_Add.field_event_to || "";
        calcules[controller_listsAdd] = lists_Add.calcules || "";
        filter[controller_listsAdd] = lists_Add.filter || "";
        controller_listsLots[controller_listsAdd] =
          lists_Add.controller_listsLot || "";

        not_affiche[controller_listsAdd] = lists_Add.not_affiche || [];
        unique[controller_listsAdd] = lists_Add.unique || [];

        titles[controller_listsAdd] = [];
        schemehtml[controller_listsAdd] = {};
        rows[controller_listsAdd] = [];
        editedIndex_addlist[controller_listsAdd] = [];

        // inputes_binds[controller_listsAdd] = [];

        data_sava[controller_listsAdd] = [];
        data_Select_load[controller_listsAdd] = [];
      }
    }

    state["controller_root"] = controller_root;
    state["namesmodules"] = namemodule;
    state["nameinput_root"] = nameinput_root;

    state["namescontroller_listsAdd"] = namescontroller_listsAdd;

    state["schemehtml"] = schemehtml;
    state["titles"] = titles;

    state["data_Select_load"] = data_Select_load;

    state["rows"] = rows;

    state["editedIndex_addlist"] = editedIndex_addlist;
    //state["inputes_binds"] = inputes_binds;

    state["data_sava"] = data_sava;

    //state["id"] = id;

    state["field_attach_to"] = fieldattach;
    state["field_event_to"] = field_event_to;
    state["calcules"] = calcules;
    state["filter"] = filter;

    state["not_affiche"] = not_affiche;
    state["controller_listsLots"] = controller_listsLots;
    state["unique"] = unique;

   
  },

  //ok
  set_editedIndex_addlist(state, { controller_listsAdd, index }) {
   
    state["editedIndex_addlist"][controller_listsAdd] = index;
  },
  //ok
  set_schema(state, { controller_listsAdd, schema }) {
   
    
    let not_affiche = state["not_affiche"][controller_listsAdd];
    let schemehtml = [];

    schema.forEach((sch) => {
      if (not_affiche.includes(sch.name)) {
        sch.typeHtml = "hidden";
      }
      schemehtml.push(sch);
    });

    state["schemehtml"][controller_listsAdd] = schema;
  },
  //ok
  set_titles(state, { controller_listsAdd, titles }) {
   

    let not_affiche = state["not_affiche"][controller_listsAdd];
    let controller_listsLots = state["controller_listsLots"][controller_listsAdd];

    let titlesshow = [];

    titles.forEach((title) => {
      if (!not_affiche.includes(title.value) ) {
        titlesshow.push(title);
      }
    });
    state["titles"][controller_listsAdd] = titlesshow;
  },
  //ok
  set_data_Select_load(state, { controller_listsAdd, data_Select_load }) {
   

    state["data_Select_load"][controller_listsAdd] = data_Select_load;
  },

  //ok event and (datsave update)
  add_row(state, { controller_listsAdd, row }) {
    

    state["rows"][controller_listsAdd].push(row);
  },
  //ok event
  change_data_row(state, { controller_listsAdd, indexrows, indexrow, value }) {
    let row = state.rows[controller_listsAdd][indexrows];

    let input = row[indexrow];
    input.value = value;
    row.splice(indexrow, 1, input);
    state["rows"][controller_listsAdd].splice(indexrows, 1, row);
  },

  ///ok event
  event_to_select(state, { controller_listsAdd, indexrows, name, value }) {
    let nameInput = name;
    let field_event_to = state.field_event_to[controller_listsAdd][nameInput];

    if (field_event_to != undefined) {
      let rows = state["rows"][controller_listsAdd][indexrows];

      // data load selet par id

      let data = this.getters["structure/addlist/find_id"]({
        controller_listsAdd,
        nameselect: name,
        id: value,
      });

      
      /// set key attach

      for (const key in field_event_to) {
        const key_at = field_event_to[key];
        data[key_at] = data[key];
      }
      ///set data to rows
      for (const key in rows) {
        const input = rows[key];
        ///set data to input
console.log(input.name);
        if (data[input.name] != undefined) {
          input.value = data[input.name];
        }
      }
    }
  },
  // ok event
  calcule_row(state, { controller_listsAdd, indexrows }) {
    let row = state["rows"][controller_listsAdd][indexrows];
    let objectrow = {}; /// {nameinput : value ....}

    for (const key in row) {
      const input = row[key];

      let name_input = input.name;

      let type_input = input.typeHtml;
      let value_input;
      if (type_input == "select") {
        value_input = this.getters["structure/addlist/find_id"]({
          id: input.value,
          controller_listsAdd: controller_listsAdd,
          nameselect: name_input,
        })["toString"];
      } else {
        value_input = input.value;
      }

      objectrow[name_input] = value_input; /// value numdre
    }

    /// calcule
    let calculesHandle = state.calcules[controller_listsAdd] || {};

    for (const nameinput in calculesHandle) {
      const funcalcule = calculesHandle[nameinput];
      objectrow[nameinput] = funcalcule(objectrow);
    }

    /// Handle
    for (const key in row) {
      const input = row[key];
      const name_input = input.name;
      const type_input = input.typeHtml;
      if (type_input != "select") {
        input.value = objectrow[name_input];
        row.splice(key, 1, input);
        state.rows[controller_listsAdd].splice(indexrows, 1, row);
      }
    }
  },

  ////remove
  remove_all_data_Select_load(state) {
    for (const key in state.data_Select_load) {
      for (const subkey in state.data_Select_load[key]) {
        state["data_Select_load"][key][subkey] = [];
      }
    }
  },

  remove_all_rows(state) {
    for (const key in state.rows) {
      state["rows"][key] = [];
    }
  },

  remove_all_scheme(state) {
    for (const key in state.schemehtml) {
      state["schemehtml"][key] = [];
    }
  },

  remove_row(state, { controller_listsAdd, indexrows }) {

    state["rows"][controller_listsAdd].splice(indexrows, 1);
    
  },
  remove_rows(state, { controller_listsAdd }) {
    state["rows"][controller_listsAdd].splice(0);
  },

  //ok relation multiselect and addlist
  remove_rows_by_idpieace(state, { controller_listsAdd, pieceID }) {
    let rows = state["rows"][controller_listsAdd];
    let rest_rows = [];
    for (const row of rows) {
      let flag = true;
      for (const input of row) {
        if (input.name == "piece" && input.value == pieceID) {
          flag = false;
        }
      }
      if (flag) {
        rest_rows.push(row);
      }
    }

    state.rows[controller_listsAdd] = rest_rows;

    ///  state.rows[controller_listsAdd].splice(0);
  },

  //ok relation multiselect and addlist
  remove_rows_by_idpieace_chargetrage(state, { controller_listsAdd, pieceID }) {
    let rows = state["rows"][controller_listsAdd];
    let rest_rows = [];
    for (const row of rows) {
      let flag = true;
      for (const input of row) {
        if (input.name == "piece") {
          let pieceID_ = input.value.split("/")[0];
          if (pieceID_ == pieceID) {
            flag = false;
          }
        }
      }
      if (flag) {
        rest_rows.push(row);
      }
    }

    state.rows[controller_listsAdd] = rest_rows;

    ///  state.rows[controller_listsAdd].splice(0);
  },
};
