<template>
  <card-card
    icon="mdi-clipboard-text"
    title="Statistiques de la base"
    text="Tableau de bord des éléments"
    class="px-5 py-3"
    :close="close"
    @close="closeevent"
  >
    <v-card-text>
      <v-responsive>
        <plugins-chart
          :path-option="option"
          height="400px"
          width="100%"
        ></plugins-chart>
      </v-responsive>
    </v-card-text>

    <div class="layout row ma-0">
      <div class="subheading">
        TOTAL D'ÉLÉMENTS UNIQUES
        <strong>{{ totalitems }}</strong>
      </div>
      <v-spacer></v-spacer>
      <div class="caption">
        <v-btn icon small @click="reload()"><v-icon>mdi-reload</v-icon></v-btn>
      </div>
    </div>
  </card-card>
</template>

<script>
import axios from "axios";
import i18n from "@/plugins/i18n";
import util from "@/util/index";
import widgetsMixin from "./../widgetsMixin";
export default {
  mixins: [widgetsMixin],
  data() {
    return {
      util,
      isMobile: false,

      count_: {
        commandes: 0,
        bonsachats: 0,
        facturesachats: 0,
        avoirsachats: 0,

        client: 0,
        fournisseur: 0,
        contactsfournisseur: 0,
        contactsclients: 0,

        biens: 0,
        service: 0,
        autre: 0,

        unites: 0,
        uniteslongueur: 0,
        unitespoids: 0,
        unitessurface: 0,
        unitestemps: 0,
        unitesvolume: 0,

        pays: 0,
        regions: 0,
        modepaiement: 0,
        taxes: 0,

        devis: 0,
        bonsventes: 0,
        facturesventes: 0,
        avoirsventes: 0,
      },

      legenddata: [],
      datassetApp: [],
      datassetModule: [],
    };
  },
  computed: {
    Base_url_web() {
      return this.$store.getters["get_Base_url"];
    },
    option() {
      let legenddata = this.legenddata;
      let datassetApp = this.datassetApp;
      let datassetModule = this.datassetModule;
      let isMobile = this.isMobile;

      let opt = {
        // title: {
        //   text: "statistique base",
        //   subtext: "nombre",
        //   left: "center",
        // },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}:Total {c} ({d}%)",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          right: 0,
          top: 20,
          bottom: 20,
          data: legenddata,
          show: !isMobile,
        },
        series: [
          {
            name: "Modules",
            type: "pie",
            roseType: "radius",
            selectedMode: "single",
            radius: [0, "30%"],

            center: isMobile ? ["50%", "50%"] : ["35%", "50%"],
            label: {
              position: "inner",
              fontSize: 14,
              show: false,
            },
            labelLine: {
              show: false,
            },
            data: datassetModule.sort(function (a, b) {
              return a.value - b.value;
            }),
          },
          {
            name: "Application",
            type: "pie",
            radius: ["45%", "60%"],
            center: isMobile ? ["50%", "50%"] : ["35%", "50%"],
            labelLine: {
              length: 30,
            },
            label: {},

            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            data: datassetApp,
          },
        ],
      };

      return opt;
    },

    controllers() {
      let controllers = [];
      for (const key in this.count_) {
        controllers.push(key);
      }
      return controllers;
    },
    labels() {
      let labels = [];
      for (const item of this.controllers) {
        labels.push(this.$t(item));
      }
      return labels;
    },

    datasets() {
      let data = [];
      for (const item of this.controllers) {
        data.push(this.count_[item]);
      }

      return [
        {
          label: "statistique",
          data,
          backgroundColor: util.color.backgroundColor,
          //   borderColor: util.color.borderColor,
        },
      ];
    },
    totalitems() {
      let total = 0;
      for (const item of this.controllers) {
        total = total + this.count_[item] * 1;
      }

      return total;
    },

    paths_statistiques() {
      return this.$store.getters["get_statistiques"];
    },
  },
  methods: {
    reload() {
      let n = this.datassetApp.length;
      for (let index = 0; index < n; index++) {
        this.datassetApp.shift();
        window.dispatchEvent(new Event("optimizedResize"));
      }

      this.start();
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },

    count(paths, controller) {
      let path = paths[controller];
      if (path != undefined) {
        axios
          .get(this.Base_url_web + path, { params: {} })
          .then((response) => {
            this.count_[controller] = response.data["count"] * 1;

            this.datassetApp.push({
              value: response.data["count"] * 1,
              name: i18n.t(controller),
            });

            this.calculdatassetModule(true);
            window.dispatchEvent(new Event("optimizedResize"));
          })
          .catch((error) => {
            console.log(error);
            console.error("path " + controller);
          });
      }
    },

    calculdatassetModule(update = false) {
      if (update) {
        let valueAchats =
          this.count_.commandes +
          this.count_.bonsachats +
          this.count_.facturesachats +
          this.count_.avoirsachats;

        let valueCRM =
          this.count_.client +
          this.count_.fournisseur +
          this.count_.contactsfournisseur +
          this.count_.contactsclients;

        let valueProduit =
          this.count_.biens + this.count_.service + this.count_.autre;

        let valueReglages =
          this.count_.unites +
          this.count_.uniteslongueur +
          this.count_.unitespoids +
          this.count_.unitessurface +
          this.count_.unitestemps +
          this.count_.unitesvolume +
          this.count_.pays +
          this.count_.regions +
          this.count_.modepaiement +
          this.count_.taxes;

        let valueTrajets = 0;
        let valueTransactions = 0;

        let valueVentes =
          this.count_.devis +
          this.count_.bonsventes +
          this.count_.facturesventes +
          this.count_.avoirsventes;

        this.datassetModule.push({
          value: valueAchats,
          name: i18n.t("Achats"),
        });
        this.datassetModule.push({ value: valueCRM, name: i18n.t("CRM") });
        this.datassetModule.push({
          value: valueProduit,
          name: i18n.t("Produit"),
        });
        this.datassetModule.push({
          value: valueReglages,
          name: i18n.t("Reglages"),
        });
        this.datassetModule.push({
          value: valueTrajets,
          name: i18n.t("Trajets"),
        });
        this.datassetModule.push({
          value: valueTransactions,
          name: i18n.t("Transactions"),
        });
        this.datassetModule.push({
          value: valueVentes,
          name: i18n.t("Ventes"),
        });

        this.datassetModule.shift();
        this.datassetModule.shift();
        this.datassetModule.shift();
        this.datassetModule.shift();
        this.datassetModule.shift();
        this.datassetModule.shift();
        this.datassetModule.shift();
      } else {
        this.datassetModule.push({ value: 0, name: i18n.t("Achats") });
        this.datassetModule.push({ value: 0, name: i18n.t("CRM") });
        this.datassetModule.push({ value: 0, name: i18n.t("Produit") });
        this.datassetModule.push({ value: 0, name: i18n.t("Reglages") });
        this.datassetModule.push({ value: 0, name: i18n.t("Trajets") });
        this.datassetModule.push({ value: 0, name: i18n.t("Transactions") });
        this.datassetModule.push({ value: 0, name: i18n.t("Ventes") });
      }
    },
    start() {
      for (const controller of this.controllers) {
        this.legenddata.push(i18n.t(controller));
        this.count(this.paths_statistiques, controller);
      }
    },
  },

  created() {
    this.calculdatassetModule();
    this.start();
    this.onResize();
  },
  watch: {
    paths_statistiques() {
      this.start();
    },
  },
};
</script>
