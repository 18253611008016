module.exports = {
  id: { typeHtml: "hidden" },
  name: { typeHtml: "text" },
  size: { typeHtml: "text" },
  originalName: { typeHtml: "text" },
  type: { typeHtml: "text" },
  path: { typeHtml: "text" },
  refGroup: { typeHtml: "text" },
  controller: { typeHtml: "text" },
  idController: { typeHtml: "text" },
  userAdd: { typeHtml: "hidden" , to_string: "identifiant"},
  minifile: { typeHtml: "text" },
  dateUpload: { typeHtml: "date" },
  dateTimeUpload: { typeHtml: "date" },
  pathDownload: { typeHtml: "text" },
  status: { typeHtml: "text" },
  updatedAt: { typeHtml: "hidden" },
  createdAt: { typeHtml: "hidden" },
};
