import { render, staticRenderFns } from "./block.vue?vue&type=template&id=0cdf2880&scoped=true&"
import script from "./block.vue?vue&type=script&lang=js&"
export * from "./block.vue?vue&type=script&lang=js&"
import style0 from "./block.vue?vue&type=style&index=0&id=0cdf2880&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cdf2880",
  null
  
)

export default component.exports