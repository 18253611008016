import index from "../index";
import show from "./show";
import show_item from "./show_item";

import add from "./add";
import update from "./update";

let namemodule = "";
let namecontroller = "recette";
export default [
  {
    path: "/" + namecontroller,
    component: index,

    children: [
      { path: "", component: show, name: namecontroller + "_show" },

      {
        path: "voir/:id([0-9]+)",
        component: show_item,
        name: namecontroller + "_show_item",
        props: true,
      },
      {
        path: ":id([0-9]+)",
        component: update,
        name: namecontroller + "_update",
        props: true,
      },
      { path: "ajouter", component: add, name: namecontroller + "_add" },
    ],
  },
];
