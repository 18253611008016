import axios from 'axios'



// source local ==> ../locales
export function loadLocaleMessages() {
  const locales = require.context(
    '../locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i,
  )
  const messages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export function loadServerMessages() {
  // source server ==> http:// .... show system_i18n_lang

  let messages = {}
  let keylang_i18n = localStorage.getItem('keylang_i18n', '')

  if (keylang_i18n != null) {
    let keys = keylang_i18n.split(',')

    keys.forEach((key_i18n) => {
      //  console.log(key_i18n);
      let lang_i18n_ = localStorage.getItem('lang_i18n_' + key_i18n)
      let lang_i18n_object = JSON.parse(lang_i18n_)
      //   console.log(lang_i18n_object);
      messages[key_i18n] = lang_i18n_object
    })
  }

  return messages
}



//////////////////////////////////////////////////////////////////////////////////
export function get_save_i18n_lang_server(Base_url, JWTToken) {
  localStorage.setItem('drawer_i18n', '')
  localStorage.setItem('pool_lang_i18n', '')

  let param = {
    headers: {
      Accept: 'application/ld+json',
      Authorization: JWTToken,
    },
  }

  // charge lang to system
  axios.get(Base_url + `/i18n/lang`, param).then((response) => {
    let msgs = response.data
    let keylang_i18n = []
    for (const key in msgs) {
      window.awa_vue.$i18n.setLocaleMessage(key, msgs[key])
      localStorage.setItem('lang_i18n_' + key, JSON.stringify(msgs[key]))
      keylang_i18n.push(key)
    }
    localStorage.setItem('keylang_i18n', keylang_i18n.join())
  })

  // gestion pool
}

export function system_pool_i18n_lang(Base_url, JWTToken, interval) {
  localStorage.setItem('drawer_i18n', '')

  let param = {
    headers: {
      Accept: 'application/ld+json',
      Authorization: JWTToken,
    },
  }

  setInterval(() => {
    let pool_lang_i18n = localStorage.getItem('pool_lang_i18n', '')

    if (pool_lang_i18n != '') {
      let formdata = new FormData()

      formdata.append('pool_lang_i18n', pool_lang_i18n)

      axios
        .post(Base_url + '/i18n/pool_lang_i18n', formdata, param)

        .then((response) => {
          let msgs = response.data
          let keylang_i18n = []
          for (const key in msgs) {
            window.awa_vue.$i18n.setLocaleMessage(key, msgs[key])

            localStorage.setItem('lang_i18n_' + key, JSON.stringify(msgs[key]))

            keylang_i18n.push(key)
          }
          localStorage.setItem('keylang_i18n', keylang_i18n.join())

          localStorage.setItem('pool_lang_i18n', '')
        })
        .catch((error) => {
          console.error('error to POST data key i18n=>' + error)
        })
    }
  }, interval)
}

export function ctl_i18n_lang(Base_url, JWTToken, interval) {
  localStorage.setItem('drawer_i18n', '')
  ///system new key par  pool new lang
  localStorage.setItem('pool_lang_i18n', '')

  get_save_i18n_lang_server(Base_url, JWTToken)
  system_pool_i18n_lang(Base_url, JWTToken, interval)
}
