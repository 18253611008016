<template>
  <div>
    <v-list-group
      no-action
      sub-group
      v-for="(controller_expanded, index_expanded) in controllers_expanded"
      :key="'G' + index_expanded"
      v-show="connecte && $route.name != '_dashboard'"
    >
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>
            <h5 color="primary">{{ $t(controller_expanded) }}</h5>
          </v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item
        v-for="(header_expanded, key_expanded) in headers_expanded(
          controller_expanded
        )"
        :key="'L' + key_expanded"
      >
        <v-checkbox
          v-if="header_expanded.text !== 'ID'"
          :value="header_expanded.value"
          :label="header_expanded.text"
          @change="change_expanded($event, controller_expanded)"
          :input-value="not_affiches_expanded(controller_expanded)"
          off-icon="mdi-eye"
          on-icon="mdi-eye-off"
          color="red darken-3"
        >
          <template v-slot:label>
            <span class="awacolor">{{ header_expanded.text }}</span>
          </template>
        </v-checkbox>
        <span v-else> <v-icon>mdi-crosshairs-gps</v-icon>ID </span>
      </v-list-item>
    </v-list-group>
  </div>
</template>


<script>
import router from "@/router";
import i18n from "@/plugins/i18n";

export default {
  props: {
    controller_root: {},
  },

  methods: {
    not_affiches_expanded(controller) {
      let controller_root = this.controller_root;
      let expanded = this.$store.getters["show_data/g_expanded"]({
        controller_root: controller_root,
      });

      for (const key in expanded) {
        if (expanded[key]["controller_attach"] == controller) {
          console.log(expanded[key]["not_affiche"]);
          return expanded[key]["not_affiche"];
        }
      }

      return [];
    },

    headers_expanded(controller) {
      let controller_root = this.controller_root;
      let data = this.$store.getters["show_data/g_items"]({
        controller_root: controller_root,
      });

      let item = data[0];

      let titles = item[controller]["titles"];

      let headers = [];

      for (const key in titles) {
        const obj_title = titles[key];
        var regex = /FOREIGN_KEY_/gi;
        var found = obj_title.value.match(regex);

        if (found == null) {
          obj_title.text = obj_title.text = i18n.t(obj_title.value);

          // obj_title.value .replace(new RegExp("[$]", "g"), " ")  .toLocaleUpperCase();

          headers.push(obj_title);
        }
      }

      return headers;
    },

    change_expanded(selected, controller) {
      let controller_root = this.controller_root;
      this.$store.commit("show_data/set_not_affiches_expandeds", {
        data: selected,
        controller_expanded: controller,
        controller_root: controller_root,
      });
    },
  },

  computed: {
    connecte() {
      return this.$store.getters["security/connecte"];
    },

    controllers_expanded() {
      let controller_root = this.controller_root;
      let expanded = this.$store.getters["show_data/g_expanded"]({
        controller_root: controller_root,
      });

      let namesexpanded = [];
      for (const key in expanded) {
        namesexpanded.push(expanded[key]["controller_attach"]);
      }
      console.log(namesexpanded);
      return namesexpanded;
    },
  },
};
</script>




<style>
</style>