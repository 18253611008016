<template>
  <v-card
    
    class="v-card--material--has-heading v-card--material pa-3 mb-16 mt-12 "
    elevation="8"
    width="100%"
  >
    <v-alert border="left" colored-border :color="item.active === true || item.active === 'X' ? getcolor()['is_']:'grey'" >
      <v-row>
        <v-col cols="6" >
          <div class="d-flex grow flex-wrap">
            <v-avatar
            
              rounded
              :size="150"
              class="mx-auto v-card--material __avatar elevation-6"
              color="grey"
            >
              <readfile-img
                :refgroup="item.logo"
                :size="150"
                v-if="item.logo != '' && item.logo != null"
              />
              <v-icon x-large v-else>mdi-account</v-icon>
            </v-avatar>
          </div>
        </v-col>
        <v-col cols="6" class="ml-0 text-truncate"  >
          <v-badge
          left
          class="ml-0"
            v-if="item.active === true || item.active === 'X'"
            icon="mdi-account-multiple-check"
            color="orange"
            overlap
          >
            <div class="text-overline mb-2 ">
              <v-chip class="mr-1 " label :disabled="!item.active">
                {{ item.identifiant }}
              </v-chip>
            </div>
          </v-badge>
          <v-badge
          left
           class="ml-0"
            v-else
            icon="mdi-account-multiple-remove"
            color="red"
            overlap
          >
            <div class="text-overline mb-2 text-truncate">
              <v-chip class="mr-1 " label :disabled="!item.active">
                {{ item.identifiant }}
              </v-chip>
            </div>
          </v-badge>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="align-center justify-center" >
          <v-btn min-width="130" v-if="item.isAdmin === true || this.item.isAdmin === 'X'" x-small class="ml-2" :color="getcolor()['admin']" dark>
            Admin
            <v-icon dark right>mdi-account-tie </v-icon>
          </v-btn>
          <v-btn min-width="130" v-if="item.ischeffmaster === true || this.item.ischeffmaster === 'X'" x-small class="ml-2" :color="getcolor()['cheffmaster']" dark>
            master cheff
            <v-icon dark right> mdi-chef-hat </v-icon>
          </v-btn>
          <v-btn min-width="130" v-if="item.ischeff === true || this.item.ischeff === 'X'" x-small class="ml-2" :color="getcolor()['cheff']" dark>
            cheff
            <v-icon dark right> mdi-human-capacity-decrease </v-icon>
          </v-btn>

          <v-btn min-width="130" v-if="item.isdispatcher === true || this.item.isdispatcher === 'X'" x-small class="ml-2" :color="getcolor()['dispatcher']" dark>
            dispatcher
            <v-icon dark right> mdi-account-voice </v-icon>
          </v-btn>
          <v-btn min-width="130" v-if="item.isvalidateur === true || this.item.isvalidateur === 'X'" x-small class="ml-2" :color="getcolor()['validateur']" dark>
            validateur
            <v-icon dark right> mdi-account-cash-outline </v-icon>
          </v-btn>

          <v-btn min-width="130" v-if="item.isserveur === true || this.item.isserveur === 'X'" x-small class="ml-2" :color="getcolor()['serveur']" dark>
            serveur
            <v-icon dark right> mdi-walk </v-icon>
          </v-btn>

          <v-btn min-width="130" x-small class="ml-2" :color="getcolor()['client']" dark>
            client
            <v-icon dark right> mdi-account-box </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <div class="ml-2">
            <div class="font-weight-light text-h7" v-text="item.email" />
          </div>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mr-2"
              fab
              dark
              small
              color="orange"
              @click="showItem(item)"
              v-show="isGranted('view', item)"
              v-on="on"
            >
              <v-icon>mdi-view-carousel</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("Consulter") }}</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mr-2"
              fab
              dark
              small
              color="orange"
              @click="editItem(item)"
              v-show="isGranted('edit', item)"
              v-on="on"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("Modifier") }}</span>
        </v-tooltip>

        <v-spacer></v-spacer>

        <v-btn icon @click="show = !show">
          <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="show">
          <v-divider></v-divider>

          <v-card-text>
            <p>ID: {{ item.id }}</p>
            <p>{{ item.name }}</p>
            <p>{{ item.telephone }}</p>
            <p>{{ item.adresse }}</p>
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-alert>
  </v-card>
</template>

<script>
export default {
  props: {
    item: { type: Object },
    controller_root: {},
  },
  data: () => ({
    show: false,
  }),
  methods: {
    getcolor() {
      let colors = {};
      colors["client"] = "info";
      colors["is_"] = "info";

 if (this.item.isvalidateur === true || this.item.isvalidateur === "X") {
        colors["validateur"] = "blue-grey";
        colors["is_"] = "blue-grey";
      }
      if (this.item.isserveur === true || this.item.isserveur === "X") {
        colors["serveur"] = "grey";
        colors["is_"] = "grey";
      }
      if (this.item.isdispatcher === true || this.item.isdispatcher === "X") {
        colors["dispatcher"] = "orange";
        colors["is_"] = "orange";
      }
       if (this.item.ischeff === true || this.item.ischeff === "X") {
        colors["cheff"] = "lime";
        colors["is_"] = "lime";
      }
 if (this.item.ischeffmaster === true || this.item.ischeffmaster === "X") {
        colors["cheffmaster"] = "success";
        colors["is_"] = "success";
      }
     
      if (this.item.isAdmin === true || this.item.isAdmin === "X") {
        colors["admin"] = "red";
        colors["is_"] = "red";
      }

     
      
     
      return colors;
    },

    isGranted(attribute, item) {
      return this.$store.getters["security/isGranted"](
        attribute,
        this.controller_root,
        item
      );
    },
    showItem() {
      let item = this.item;
      if (this.slot_show) {
        this.$store.dispatch("custom_fromById", {
          names_controllers: [this.controller_root],
          id: item.id,
        });

        this.$store.commit("dialog/set_dialog", {
          data: item,
          flag: true,
          type: "show",
          controller: this.controller_root,
        });
      } else {
        this.$router.push({
          name: this.controller_root + "_show_item",
          params: { id: item.id },
        });
      }
    },
    editItem() {
      let item = this.item;
      if (this.slot_show) {
        this.$store.dispatch("custom_fromById", {
          names_controllers: [this.controller_root],
          id: item.id,
        });

        this.$store.commit("dialog/set_dialog", {
          data: item,
          flag: true,
          type: "update",
          controller: this.controller_root,
        });
      } else {
        this.$router.push({
          name: this.controller_root + "_update",
          params: { id: item.id },
        });
      }
    },
  },
};
</script>

<style scoped>
.v-card--material .__avatar {
  position: relative;
  top: -64px;
  margin-bottom: -32px;
}

.v-card--material .__heading {
  position: relative;
  top: -40px;
  transition: 0.3s ease;
  z-index: 1;
}
</style>
