<template>
  <v-form ref="form" v-model="valid">
    <v-row class="justify-center">
      <v-col :cols="12">
        <div style="width: 230px; float: left">
          <v-tooltip top style="float: left">
            <template v-slot:activator="{ on }">
              <v-btn
                elevation="7"
                class="mx-2"
                outlined
                fab
                dark
                color="green"
                :to="{
                  name: 'restaurant_update',
                  params: { id: 1 },
                }"
                v-on="on"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>modifier</span>
          </v-tooltip>
        </div>
      </v-col>
      <v-col cols="12">
        <v-row class="justify-center">
          <v-col :cols="12" :lg="4">
            <card-box
              id="groupt1"
              color="orange"
                :title="$t('show_restaurant_infoplat_1')"
              :text="$t('show_restaurant_infoplat_2')"
            >
              <show-default :controller_root="controller_root" />
            </card-box>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import i18n from "@/plugins/i18n";
import { mapGetters } from "vuex";
import ViewMixin from "@/views/show_item";

export default {
  mixins: [ViewMixin],

  props: {},
  data() {
    return {
      controller_root: "restaurant",
      namemodule: "CRM",
    };
  },
  computed: {
    id() {
      return 1;
    },
  },
  created() {
    document.title = i18n.t("show_item_" + this.controller_root);
    this.initia();
    this.run();
  },

  methods: {
    isGranted(controller) {
      return this.$store.getters["security/isGranted"]("view", controller);
    },
  },
};
</script>
