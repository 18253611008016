<template>
  <v-container fluid>
    <v-row class="justify-center">
      <v-col :cols="12" :lg="11">
        <card-box
          id="table"
          color="pink"
          :title="$t('box_title_mouvementsstock')"
          :text="$t('box_text_mouvementsstock')"
        >
          <table-run
            :controller_root="controller_root"
           
            :action_traitement="false"
          >
            <template v-slot:show>
              <show-default :controller_root="controller_root" />
            </template>

            <template v-slot:add>
              <card-box id="default">
                <box-default :controller_root="controller_root" />
                <btn-send :valid="valid" :controller_root="controller_root" />
              </card-box>
            </template>

            <template v-slot:update>
              <card-box id="defaultupdate">
                <box-default :controller_root="controller_root" />
                <btn-send :valid="valid" :controller_root="controller_root" />
              </card-box>
            </template>
          </table-run>
        </card-box>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from "@/plugins/i18n";

import ViewMixins from "@/views/show";

export default {
  mixins: [ViewMixins],
  data() {
    return {
      controller_root: "mouvementsstock",
      namemodule: "Produit",
      options_showData: {
        title: "liste des  mouvementsstock  de produits ",
        not_affiche: ["user", "updatedAt", "createdAt"],
        expanded: [],
      },
    };
  },
  created() {
    document.title = i18n.t("show_" + this.controller_root);

    this.initia();
    this.run();
  },
};
</script>