'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})
exports['default'] = void 0

var _i18n = _interopRequireDefault(require('@/plugins/i18n'))

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

var _default = {
  g_nametables: function g_nametables(state) {
    return function () {
      return state.nametables
    }
  },
  g_title: function g_title(state) {
    return function (_ref) {
      var controller_multiselect = _ref.controller_multiselect
      return state.titles[controller_multiselect]
    }
  },
  g_not_affiche: function g_not_affiche(state) {
    return function (_ref2) {
      var controller_multiselect = _ref2.controller_multiselect
      return state.not_affiches[controller_multiselect]
    }
  },
  g_controller_listsAdd_attach: function g_controller_listsAdd_attach(state) {
    return function (_ref3) {
      var controller_multiselect = _ref3.controller_multiselect
      return state.controller_listsAdd_attach[controller_multiselect]
    }
  },
  g_pieceID: function g_pieceID(state) {
    return function (_ref4) {
      var controller_multiselect = _ref4.controller_multiselect
      return state.pieceID[controller_multiselect]
    }
  },
  g_expanded: function g_expanded(state) {
    return function (_ref5) {
      var controller_multiselect = _ref5.controller_multiselect
      return state.expandeds[controller_multiselect]
    }
  },
  ///////////////////////////////////////
  //// send data
  namescontroller_multiselect: function namescontroller_multiselect(state) {
    return function (_ref6) {
      var controller_root = _ref6.controller_root
      var namescontroller_multiselect = []
      state.namescontroller_multiselect.forEach(function (
        controller_multiselect,
      ) {
        if (
          state['controller_root_attach'][controller_multiselect] ==
          controller_root
        ) {
          namescontroller_multiselect.push(controller_multiselect)
        }
      })
      return namescontroller_multiselect
    }
  },
  /// get <object> data set to view (pour send data to server)
  inputes_binds: function inputes_binds(state) {
    return function (_ref7) {
      var controller_multiselect = _ref7.controller_multiselect
      var rows = state.selected[controller_multiselect]
      var data = []

      for (var key in rows) {
        var row = rows[key]
        data.push({
          id: row['id'],
        })
      }

      return data
    }
  },
  /////////////////////////////
  g_search: function g_search(state) {
    return function (_ref8) {
      var controller_multiselect = _ref8.controller_multiselect
      return state.search[controller_multiselect]
    }
  },
  g_headers: function g_headers(state) {
    return function (_ref9) {
      var controller_multiselect = _ref9.controller_multiselect,
        namesexpanded = _ref9.namesexpanded,
        notaffiche = _ref9.notaffiche
      var headers = []
      var titles = state.headers[controller_multiselect]

      for (var key in titles) {
        var obj_title = titles[key]

        if (notaffiche.indexOf(obj_title.value) == -1) {
          var regex = /FOREIGN_KEY_/gi
          var found = obj_title.value.match(regex) /// is not array namesexpanded

          if (
            obj_title.value != undefined &&
            namesexpanded.indexOf(obj_title.value) == -1 &&
            found == null
          ) {
            obj_title.text = _i18n['default'].t(obj_title.value)
            headers.push(obj_title)
          }
        }
      }

      console.log(namesexpanded)
      return headers
    }
  },
  g_items: function g_items(state) {
    return function (_ref10) {
      var controller_multiselect = _ref10.controller_multiselect
      return state.items[controller_multiselect]
    }
  },
  g_selected: function g_selected(state) {
    return function (_ref11) {
      var controller_multiselect = _ref11.controller_multiselect
      return state.selected[controller_multiselect]
    }
  },
  g_item_key: function g_item_key(state) {
    return function (_ref12) {
      var controller_multiselect = _ref12.controller_multiselect
      return state.item_key[controller_multiselect]
    }
  },
  g_page: function g_page(state) {
    return function (_ref13) {
      var controller_multiselect = _ref13.controller_multiselect
      return state.page[controller_multiselect]
    }
  },
  g_pageCount: function g_pageCount(state) {
    return function (_ref14) {
      var controller_multiselect = _ref14.controller_multiselect
      return state.pageCount[controller_multiselect]
    }
  },
  g_itemsPerPage: function g_itemsPerPage(state) {
    return function (_ref15) {
      var controller_multiselect = _ref15.controller_multiselect
      return state.itemsPerPage[controller_multiselect]
    }
  },
  loading: function loading(state) {
    return state.loading
  },
  write: function write(state) {
    return state.write
  },
}
exports['default'] = _default
