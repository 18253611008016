export default {
  title: (state) => {
    
    if (
      Array.isArray(state.organisation) &&
      state.organisation[0] != undefined
    ) {
      return state.organisation[0]["restaurant"];
    }
    return "application gestion";
  },
  menu: (state) => {
    return state.menu;
  },
  organisation: (state) => {
    if (
      Array.isArray(state.organisation) &&
      state.organisation[0] != undefined
    ) {
      return state.organisation[0];
    }
    return {};
  },

  modules: (state) => {
    return state.modules;
  },
  drawer_right: (state) => {
    return state.drawer_right;
  },

  drawer_i18n: (state) => {
    return state.drawer_i18n;
  },
  drawer_left: (state) => {
    return state.drawer_left;
  },
  dark: (state) => {
    return state.dark;
  },
  count_notification: (state) => {
    return state.count_notification;
  },
  notification: (state) => {
    return state.notification;
  },
  widgets: (state) => {
    // Récupérer des données depuis localStorage
    let widgets = localStorage.getItem("widgets");

    if (widgets == null) {
      localStorage.setItem("widgets", [].join());

    } else {
      
      state.widgets = widgets.split(",");
    }


    const index = state.widgets.indexOf("");
    if (index > -1) {
      state.widgets.splice(index, 1);
    }
    return state.widgets;
  },
};
