/// https://vuetifyjs.com/en/components/forms/


let error = {};

export default function (name, nullable) {

  return {
    required: value => {
      let test = nullable || !!value

      let message = "Required."
      // console.log(nullable , !!value, message)
      

      return test || message;

    },
    text: value => {
      if (value != undefined && value.length > 0) {
        
        let test = value.length <= 255
        let message = "Max 20 characters"
        
        return test || message;
      }
      return nullable;
    },
    email: value => {
      if (value != undefined && value.length > 0) {
        
        
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        let test = pattern.test(value)
        let message = "Invalid e-mail."
        
        return test || message;
      }
      return nullable;
    },
    url: value => {
      if (value != undefined && value.length > 0) {
        
        const pattern = /^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/;
        let test = pattern.test(value)
        let message = "Invalid url"
        
        return test || message;
      }
      return nullable;
    },
    decimal: value => {
      if (value != undefined && value.length > 0) {
        
        const pattern = /^-?[0-9]\d*(\.\d+)?$/;
        let test = pattern.test(value)
        let message = "Invalid nomber decimal 00.00"
        
        return test || message;
      }
      return nullable;
    },
    number: value => {
      if (value != undefined && value.length > 0) {
        
        const pattern = /^-?[0-9]\d*(\.\d+)?$/;
        let test = pattern.test(value)
        let message = "Invalid nombre 00"
        
        return test || message;
      }
      return nullable;
    },
    tel: value => {
      if (value != undefined && value.length > 0) {
        
        const pattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
        let test = pattern.test(value)
        let message = "Invalid tel "
        
        return test || message;
      }
      return nullable;
    }
  }




}  