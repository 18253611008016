<template>
  <v-form ref="form" v-model="valid">
     <v-row class="justify-center">
      <v-col cols="12"
        ><btn-action
          :controller_root="controller_root"
          :item="{ id: id }"
        ></btn-action
      ></v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="12">
        <v-row class="justify-center">
         
          <v-col cols="12">
        <card-box
          id="addlist2"
          icon="mdi-file-star-outline"
          :title="$t('show_inventaire_infoplat_1')"
          color="success"
        >
          <addlist-table :edit_listsAdd="false"  controller_listsAdd="produitsinventaires" />
        </card-box>
        <card-box
          id="addlist2"
          icon="mdi-file-star-outline"
          :title="$t('show_inventaire_infoplat_2')"
          color="success"
        >
          <addlist-table :edit_listsAdd="false"  controller_listsAdd="recetteinventaires" />
        </card-box>
      </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import i18n from "@/plugins/i18n";
import { mapGetters } from "vuex";
import ViewMixin from "@/views/show_item";

export default {
  mixins: [ViewMixin],

  props: {},
  data() {
    return {
      controller_root: "inventaire",
      namemodule: "CRM",lists_Add: [
        {
          controller_listsAdd: "produitsinventaires",
        },
        {
          controller_listsAdd: "recetteinventaires",
        },
      ],
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  created() {
    document.title = i18n.t("show_item_" + this.controller_root);
    this.initia();
    this.run();
  },

  methods: {
    isGranted(controller) {
      return this.$store.getters["security/isGranted"]("view", controller);
    },
  },
};
</script>
