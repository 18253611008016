<template>
  <v-text-field
    :label="label + ' ' "
    :value="values_binds"
    @input="modelbind($event)"

    @blur="uniqueajax_validation()"
    @focus="init_validation()"
    :rules="rules"
    
  ></v-text-field>
</template>
<script>
import InputMixin from "./InputMixin";

export default {
  mixins: [InputMixin],

  methods: {
    init_validation() {
      this.rules = [this.validation.required, this.validation.text];
    },
  },
};
</script>