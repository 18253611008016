<template>
  <div>
    <v-btn :href="'tel:'+info_organisation.telephone" color="orange" elevation="2"  class="ma-2" 
      ><v-icon>mdi-phone</v-icon> Contactez-nous
    </v-btn>
    <v-stepper v-model="e6">
      <v-stepper-header>
        <v-stepper-step editable :complete="e6 > 1" step="1">
          {{ $t("s1") }}
          <small> {{ $t("Srestaurant") }} </small>
        </v-stepper-step>
        <v-stepper-step editable :complete="e6 > 2" step="2">
          {{ $t("s2") }} <small> {{ $t("Sinfo") }} </small>
        </v-stepper-step>
        <v-stepper-step editable :complete="e6 > 3" step="3">
          {{ $t("s3") }} <small> {{ $t("Sproduits") }} </small>
        </v-stepper-step>
        <v-stepper-step editable :complete="e6 > 4" step="4">
          {{ $t("s4") }} <small> {{ $t("Scheff") }} </small>
        </v-stepper-step>
        <v-stepper-step editable :complete="e6 > 5" step="5">
          {{ $t("s5") }} <small> {{ $t("Srecette") }} </small></v-stepper-step
        >
        <v-stepper-step editable :complete="e6 > 6" step="6">
          {{ $t("s6") }} <small> {{ $t("Splat") }} </small>
        </v-stepper-step>
        <v-stepper-step editable :complete="e6 > 7" step="7">
          {{ $t("s7") }} <small> {{ $t("Seventcheff") }} </small>
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="mx-auto">
            <v-row>
              <v-col cols="12" xl="8">
                <v-img
                  class="white--text align-end"
                  :src="require('@/assets/stepper/_1.png')"
                >
                  <v-card-title
                    >Meilleurs Restaurants à Béni Mellal-Khénifra</v-card-title
                  >
                </v-img>
              </v-col>
              <v-col cols="12" xl="4">
                <v-card-subtitle class="pb-0">
                  saisir les informations
                </v-card-subtitle>

                <v-card-text class="text--primary">
                  <strong> choisir ses fournisseurs de restaurant</strong>

                  <div>
                    Un fournisseur professionnel en restauration se reconnaît à
                    la qualité des produits qu'il vend, mais pas uniquement. Ce
                    qui fait la différence, ...
                  </div>
                </v-card-text>
                <v-card-actions>
                  <steppers-menu :controller_show="['restaurant']" />
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>

          <v-btn color="primary" @click="e6 = e6 + 1">
            <v-icon>mdi-skip-next</v-icon>
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card class="mx-auto">
            <v-row>
              <v-col cols="12" xl="8">
                <v-img
                  class="white--text align-end"
                  :src="require('@/assets/stepper/_2.png')"
                >
                  <v-card-title>les etapes de preparation </v-card-title>
                </v-img>
              </v-col>
              <v-col cols="12" xl="4">
                <v-card-subtitle class="pb-0">
                  Les meilleures techniques
                </v-card-subtitle>

                <v-card-text class="text--primary">
                  <div>
                    Analysez vos rapports de ventes. Catégorisez les éléments du
                    menu. Déterminez les plats que vous voulez inclure dans
                    votre menu. Soyez stratégique dans la conception de votre
                    menu. Créez une carte de boissons attrayante. Votre menu est
                    prêt !
                  </div>

                  <div>
                    Mettre de la hauteur dans l'assiette. Découper la viande en
                    belles tranches. Jouer avec les textures. Utiliser des
                    couleurs contrastées. Faire correspondre présentation et
                    thème de votre restaurant. Choisir les bonnes assiettes.
                    Servir de plus petites portions.
                  </div>
                </v-card-text>
                <v-card-actions> </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
          <v-btn color="primary" @click="e6 = e6 - 1">
            <v-icon>mdi-skip-previous</v-icon>
          </v-btn>
          <v-btn color="primary" @click="e6 = e6 + 1">
            <v-icon>mdi-skip-next</v-icon>
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card class="mx-auto">
            <v-row>
              <v-col cols="12" xl="8">
                <v-img
                  class="white--text align-end"
                  :src="require('@/assets/stepper/_3.png')"
                >
                  <v-card-title>Ingrédients indispensable,</v-card-title>
                </v-img>
              </v-col>
              <v-col cols="12" xl="4">
                <v-card-subtitle class="pb-0">
                  Ingrédients indispensable, qui sont-ils ?
                </v-card-subtitle>

                <v-card-text class="text--primary">
                  <div>
                    La plupart des aliments de base dérivent soit de céréales,
                    telles que le blé, le maïs ou le riz, ou de légumes-racines
                    riches en amidon comme la pomme de terre, le taro, le manioc
                    et la patate douce.
                  </div>

                  <div>les produits de cuisine et ingrédients</div>
                </v-card-text>
                <v-card-actions>
                  <steppers-menu
                    :controller_show="['produits', 'bonsachats']"
                  />
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
          <v-btn color="primary" @click="e6 = e6 - 1">
            <v-icon>mdi-skip-previous</v-icon>
          </v-btn>
          <v-btn color="primary" @click="e6 = e6 + 1">
            <v-icon>mdi-skip-next</v-icon>
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-card class="mx-auto">
            <v-row>
              <v-col cols="12" xl="8">
                <v-img
                  class="white--text align-end"
                  :src="require('@/assets/stepper/_4.png')"
                >
                  <v-card-title>Le chef de cuisine </v-card-title>
                </v-img>
              </v-col>
              <v-col cols="12" xl="4">
                <v-card-subtitle class="pb-0">
                  Le chef de cuisine dirige et organise son équipe de cuisiniers
                  dont l'effectif peut aller du simple au double. Il est
                  responsable de recruter et former sa propre brigade. Il
                  apporte sa touche personnelle aux plats, et créé lui-même les
                  menus en proposant ses plats à mettre à la
                  carte.</v-card-subtitle
                >

                <v-card-text class="text--primary">
                  <div>
                    Chef cuisinier (« chef de cuisine » ou plus rarement « chef
                    des cuisines ») est un métier du monde de la restauration.
                    Le maître queux désignait le chef des ...
                  </div>

                  <div>
                    Le Sous-Chef élabore, sous les ordres du chef, les plats de
                    la carte. Il participe également avec lui à l'élaboration
                    des menus, aux choix des ...
                  </div>
                </v-card-text>
                <v-card-actions>
                  <steppers-menu :controller_show="['user']" />
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>

          <v-btn color="primary" @click="e6 = e6 - 1">
            <v-icon>mdi-skip-previous</v-icon>
          </v-btn>
          <v-btn color="primary" @click="e6 = e6 + 1">
            <v-icon>mdi-skip-next</v-icon>
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="5">
          <v-card class="mx-auto">
            <v-row>
              <v-col cols="12" xl="8">
                <v-img
                  class="white--text align-end"
                  :src="require('@/assets/stepper/_5.png')"
                >
                  <v-card-title>recette de cuisine</v-card-title>
                </v-img>
              </v-col>
              <v-col cols="12" xl="4">
                <v-card-subtitle class="pb-0"> </v-card-subtitle>

                <v-card-text class="text--primary">
                  <div>Recette de cuisine</div>

                  <div>
                    Recette de cuisine est un procédé qui indique l'ensemble des
                    ingrédients et des opérations nécessaire pour effectuer une
                    préparation alimentaire en cuisine à l’aide d’ustensiles.
                    Elle précise notamment, pour un nombre donné de personnes,
                    les quantités de chaque ingrédient, les temps de préparation
                    et de cuisson et, le cas échéant, le mode de cuisson. Les
                    différentes opérations à effectuer sont souvent décrites en
                    utilisant des termes spécialisés propres aux techniques
                    culinaires. Les recettes sont souvent une variante de
                    l'alimentation de base d'une culture en particulier.
                  </div>
                </v-card-text>
                <v-card-actions>
                  <steppers-menu :controller_show="['categories', 'recette']" />
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>

          <v-btn color="primary" @click="e6 = e6 - 1">
            <v-icon>mdi-skip-previous</v-icon>
          </v-btn>
          <v-btn color="primary" @click="e6 = e6 + 1">
            <v-icon>mdi-skip-next</v-icon>
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="6">
          <v-card class="mx-auto">
            <v-row>
              <v-col cols="12" xl="8">
                <v-img
                  class="white--text align-end"
                  :src="require('@/assets/stepper/_6.png')"
                >
                  <v-card-title>plat de restaurant</v-card-title>
                </v-img>
              </v-col>
              <v-col cols="12" xl="4">
                <v-card-subtitle class="pb-0">
                  plat de restaurant
                </v-card-subtitle>

                <v-card-text class="text--primary">
                  <h1>
                    c'est l'ensemble des mets qui peuvent être servis pour un
                    prix déterminé.
                  </h1>
                </v-card-text>
                <v-card-actions>
                  <steppers-menu
                    :controller_show="['optiongroup', 'categorieplat', 'plat']"
                  />
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
          <v-btn color="primary" @click="e6 = e6 - 1">
            <v-icon>mdi-skip-previous</v-icon>
          </v-btn>
          <v-btn color="primary" @click="e6 = e6 + 1">
            <v-icon>mdi-skip-next</v-icon>
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="7">
          <v-card class="mx-auto">
            <v-row>
              <v-col cols="12" xl="8">
                <v-img
                  class="white--text align-end"
                  :src="require('@/assets/stepper/_7.png')"
                >
                  <v-card-title>cuisine rapide </v-card-title>
                </v-img>
              </v-col>
              <v-col cols="12" xl="4">
                <v-card-subtitle class="pb-0"> </v-card-subtitle>

                <v-card-text class="text--primary">
                  <div>
                    Le chef de cuisine dirige et organise son équipe de
                    cuisiniers dont l'effectif peut aller du simple au double.
                    Il est responsable de recruter et former sa propre brigade.
                    Il apporte sa touche personnelle aux plats, et créé lui-même
                    les menus en proposant ses plats à mettre à la carte.
                  </div>
                </v-card-text>
                <v-card-actions>
                  <steppers-menu
                    :controller_show="[
                      'eventcheff',
                      'commandes',
                      'mouvementsstock',
                    ]"
                  />
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>

          <v-btn color="primary" @click="e6 = e6 - 1">
            <v-icon>mdi-skip-previous</v-icon>
          </v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
export default {
  computed: {
   info_organisation() {
      return this.$store.getters["theme/organisation"];
    },
  },
  data() {
    return {
      e6: 1,
    };
  },
};
</script>