<template>
  <v-app id="inspire" >
    <theme-drawer-left />
    <theme-drawer-right />
    <theme-drawer-internationalization />

    <theme-toolbar app />

    <!-- Sizes your content based upon application components -->
    <v-main style="margin-bottom: 44px">
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view />
      </v-container>
    </v-main>
    <theme-btn-palette />

    <theme-flash />
    <theme-footer app />
  </v-app>
</template>





<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      rightDrawer: false,
      isMobile: false,
      snackbar: {
        show: false,
        text: "",
        color: "",
      },
    };
  },
  methods: {



  },
  beforeDestroy() {},
  mounted() {
    if (typeof window !== undefined && window._VMA === undefined) {
      window._VMA = this;
    }

    this.$store.dispatch("run_get_metaData");
   

      this.$vuetify.theme.dark = true;
  },

 
 
};
</script>
<style lang="css" scoped>
.page-wrapper {
  min-height: calc(100vh - 112px - 48px);
}
</style>
