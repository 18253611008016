<template>
  <v-list-item>
    <v-list-item-icon>
      <v-icon color="indigo">mdi-text</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title><u>{{ label }}  :</u></v-list-item-title>
      <h3>{{values_show}}</h3>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
import InputMixin from "./InputMixin";
export default {
  mixins: [InputMixin],

  computed: {
    values_show() {
      let data = this.$store.getters["structure/get_data_Select_load"]({
        controller_root: this.controller_root,
        nameManyToOne: this.from,
      });

      for (const key in data) {
        let option = {};

        const element = data[key];
        if (element["@id"] == this.values_binds) {

          return element[this.to_string];
        }
      }
    },
  },
};
</script>
