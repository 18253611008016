var render = function render(){var _vm=this,_c=_vm._self._c;return _c('card-card',{staticClass:"px-5 py-3 d-inline-block mx-auto",attrs:{"icon":"mdi-wallet-travel","title":"Dettes / Créances ","color":"orange","close":_vm.close},on:{"close":_vm.closeevent}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-tabs',{on:{"change":_vm.handleTabChange},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab',{attrs:{"href":"#tab-1"}},[_vm._v("Graphe")]),_c('v-tab',{attrs:{"href":"#tab-2"}},[_vm._v("Factures clients impayées")]),_c('v-tab',{attrs:{"href":"#tab-3"}},[_vm._v("Factures fournisseurs impayées ")]),_c('v-tabs-items',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab-item',{attrs:{"value":"tab-1"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-responsive',{staticStyle:{"margin-bottom":"38px"},attrs:{"width":"100%"}},[(_vm.showchart)?_c(_vm.chart,{tag:"component",attrs:{"path-option":_vm.option,"height":"600px","width":"100%"}}):_vm._e()],1),_c('v-card-text',{staticClass:"pt-0"},[_c('v-divider',{staticClass:"my-2"}),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-clock")]),_c('span',{staticClass:"caption grey--text font-weight-light"},[_vm._v(_vm._s(new Date().toLocaleString()))])],1)],1)],1)],1)],1),_c('v-tab-item',{attrs:{"value":"tab-2"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('widgets-listFactureImpeyeClient')],1)],1)],1),_c('v-tab-item',{attrs:{"value":"tab-3"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('widgets-listFactureImpeyeFournisseur')],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('card-stats',{attrs:{"color":"orange","icon":"mdi-cash-minus","title":"Dettes TTC","value":_vm.Dettes.toLocaleString(undefined, { minimumFractionDigits: 2 }),"smallValue":"DH","sub-icon":"mdi-tag","sub-text":'les Factures achats: ' +
            _vm.DettesFactures.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            }) +
            ' / les Factures avoirs achats:' +
            _vm.DettesAvoirs.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            }) +
            ' DH',"tooltip":"Les dettes fournisseurs correspondent donc à des achats effectués par l'entreprise auprès de ses fournisseurs, non encore réglés à la date d'établissement du bilan. En effet, il existe différents délais de paiement applicables entre professionnels"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('card-stats',{attrs:{"color":"primary","icon":"mdi-cash-plus","title":"Créances TTC","value":_vm.Creances.toLocaleString(undefined, { minimumFractionDigits: 2 }),"smallValue":"DH","sub-icon":"mdi-tag","sub-text":'factures ventes: ' +
            _vm.CreancesFactures.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            }) +
            ' / factures avoirs ventes:' +
            _vm.CreancesAvoirs.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            }) +
            ' DH',"tooltip":"Les créances clients: calcul et définition Une créance étant une dette, les créances clients sont les sommes facturées par une entreprise, à une personne morale ou physique (pour la vente de marchandises ou de services), mais qui n’ont pas été perçues. En d'autres termes, le montant total des factures impayées constitue les créances clients."}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }