export default {
  connected: true,
  connectionType: '',

  // login info
  Base_url: "",
  username: 'system@system.system',
  password: 'root',
  JWTToken: '',




  Index_url: "/apiinfo",
  

  paths: {},
  lazy: {},
  created: {},
  dataselect: {},
  add: {},
  update: {},
  delete: {},
  attachs: {},
  load: {},
  statistiques: {},
  pdf: {},
  email: {},
  fusionner: {},
  importexcel: {},

  gestion_files: {}
};
