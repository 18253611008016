<template>
  <v-row class="justify-center">
    <v-col cols="8">
      <v-file-input
        ref="filePickerField"
        accept="image/png, image/jpeg"
        prepend-icon="mdi-camera"
        :label="label"
        @change="modelbindfile($event)"
        show-size
        counter
        chips
        :loading="loading"
      ></v-file-input>
    </v-col>
    <v-col cols="12">
      <v-navigation-drawer max-width="100" :value="true" stateless>
        <readfile-img :refgroup="values_binds"></readfile-img>
      </v-navigation-drawer>
    </v-col>

   <v-col cols="12"> <image-cropper-dialog
      ref="cropperDialog"
      :chosenImage="chosenImage"
      @onReset="$refs.filePickerField.value = null"
      @onCrop="
        (croppedImage) => {
          send(croppedImage);
        }
      "
    /></v-col>
  </v-row>
</template>
<script>
import InputMixin from "./InputMixin";
import ImageCropperDialog from "./ImageCropperDialog.vue";
export default {
  components: {
    ImageCropperDialog,
  },
  mixins: [InputMixin],
  data: () => ({ chosenImage: null }),

  methods: {
    async modelbindfile(event) {
      if (event == undefined) {
        this.modelbind("");
      }

      if (!event) return;

      var file = event;
      this.chosenImage = await this.toBase64(file);
      this.$refs.cropperDialog.initCropper(file.type);
    },
    dataURLtoFile(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], "logo", { type: mime });
    },

    async send(toBase64) {
      this.modelbind("");
      let event = this.dataURLtoFile(toBase64);

      /// send files to server and respons refgroup
      this.$store.dispatch("files/send_files", {
        controller_root: this.controller_root,
        files_input: [event],
        name_input: this.name,
        fun_progress: (etat) => {},
        fun_finally: (refGroup) => {
          this.modelbind(refGroup);
        },
      });
    },

    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
};
</script>
