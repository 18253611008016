<template>
  <v-row>
    <v-col cols="6">
      <v-btn class="ma-2" outlined small  color="indigo" v-text="5" @click="setitemsPerPage(5)" v-if="items.length>0"/>
      <v-btn class="ma-2" outlined small  color="indigo" v-text="15" @click="setitemsPerPage(15)"  v-if="items.length>5"/>
      <v-btn class="ma-2" outlined small  color="indigo" v-text="items.length" @click="setitemsPerPage(items.length)"  v-if="items.length>15"/>
    
    </v-col>

    <v-col cols="4">
      <v-pagination @input="setPage($event)" :length="pageCount" :value="page"></v-pagination>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: { items: {}, controller_root: {} },
  computed: {
    page() {
      return this.$store.getters["show_data/g_page"]({
        controller_root: this.controller_root,
      });
    },
    pageCount() {
      let pageCount = this.$store.state.show_data.pageCount;

      return pageCount[this.controller_root];

      /* return this.$store.getters["show_data/g_pageCount"]({
        controller_root: this.controller_root
      }); */
    },
    itemsPerPage() {
      return this.$store.getters["show_data/g_itemsPerPage"]({
        controller_root: this.controller_root,
      });
    },
  },

  data() {
    return {};
  },
  methods: {
    setitemsPerPage(val) {
      let itemsPerPage = parseInt(val, 10);
      if (itemsPerPage > 0) {
        this.$store.commit(
          "show_data/set_itemsPerPage",

          { data: itemsPerPage, controller_root: this.controller_root },
        );
      }
    },
    setPage(val) {
      let page = parseInt(val, 10);

      this.$store.commit("show_data/set_page", {
        data: page,
        controller_root: this.controller_root,
      });
    },
  },
};
</script>
