<template>
  <div class="v-list_add">
    <div class="v-main_table">
      <v-row>
        <v-col cols="4">
          <v-btn-toggle
            shaped
            mandatory
            v-model="valuealllibre"
            v-if="headers.length > 0"
          >
            <v-btn @click="libre" v-show="edit_multiselect">
              <v-icon>mdi-link-box-variant-outline</v-icon>
            </v-btn>

            <v-btn @click="all" v-show="edit_multiselect">
              <v-icon>mdi-all-inclusive</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="8">
          <v-spacer></v-spacer>

          <multiselect-search
            v-show="items.length > 0"
            :title="title"
            :nametableroot="controller_multiselect"
          ></multiselect-search>
        </v-col>
      </v-row>
    </div>
    <v-data-table
      v-show="items.length > 0"
      :loading="loading"
      class="elevation-1"
      no-results-text="no data serch"
      :show-expand="show_expand"
      :show-select="edit_multiselect"
      :search="search"
      :headers="headers"
      :items="items"
      :single-expand="true"
      :expanded.sync="expanded"
      :value="selected"
      @input="select($event)"
      @item-selected="item_selected($event)"
      @toggle-select-all="toggle_select_all($event)"
      :item-key="item_key"
     
    >
      <template v-slot:expanded-item="{ headers, item }">
        <multiselect-expand
          :nametableroot="controller_multiselect"
          :item="item"
          :numbretd="headers.length"
        ></multiselect-expand>
      </template>

      <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->
      <template v-slot:item.files="{ item }">
        <readfile-avatar
          size="30px"
          :refgroup="item.files"
          :hasdowlaod="true"
        ></readfile-avatar>
      </template>
      <template v-slot:item.file="{ item }">
        <readfile-avatar
          size="30px"
          :refgroup="item.file"
          :hasdowlaod="true"
        ></readfile-avatar>
      </template>
      <template v-slot:item.logo="{ item }">
        <readfile-avatar
          size="30px"
          :refgroup="item.logo"
          :hasdowlaod="true"
        ></readfile-avatar>
      </template>
    </v-data-table>

    <!--
          :hide-default-footer="true"
         :page="page"
        :items-per-page="itemsPerPage"
        @page-count="page_count($event)"
        
         <multiselect-pagination

       




        :items="items"
        :nametableroot="controller_multiselect"
      ></multiselect-pagination> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    controller_multiselect: {
      type: String,
      required: true,
    },
    controller_root: {
      type: String,
      required: true,
    },
    edit_multiselect: {
      type: Boolean,
      default: true,
    },
    show_expand: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      expanded: [],
      valuealllibre: "",
    };
  },

  computed: {
    title() {
      return this.$store.getters["structure/multiselect/g_title"]({
        controller_multiselect: this.controller_multiselect,
      });
    },
    notaffiche() {
      return this.$store.getters["structure/multiselect/g_not_affiche"]({
        controller_multiselect: this.controller_multiselect,
      });
    },

    search() {
      return this.$store.getters["structure/multiselect/g_search"]({
        controller_multiselect: this.controller_multiselect,
      });
    },
    page() {
      return this.$store.getters["structure/multiselect/g_page"]({
        controller_multiselect: this.controller_multiselect,
      });
    },
    itemsPerPage() {
      return this.$store.getters["structure/multiselect/g_itemsPerPage"]({
        controller_multiselect: this.controller_multiselect,
      });
    },

   

    items() {
      let edit_multiselect = this.edit_multiselect;
      if (edit_multiselect == false) {
        let select = this.$store.getters["structure/multiselect/g_selected"]({
          controller_multiselect: this.controller_multiselect,
        });
        let itemsdatabas = this.$store.getters["structure/multiselect/g_items"](
          {
            controller_multiselect: this.controller_multiselect,
          }
        );

        console.log(select);
        console.log(itemsdatabas);

        let getIDselect = [];
        select.forEach((element) => {
          getIDselect.push(element["id"]);
        });
        let items = [];

        itemsdatabas.forEach((element) => {
          let id = element["id"];
          if (getIDselect.includes(id)) {
            items.push(element);
          }
        });
        console.log(items);
        return items;
      }

      return this.$store.getters["structure/multiselect/g_items"]({
        controller_multiselect: this.controller_multiselect,
      });
    },
    selected() {
      return this.$store.getters["structure/multiselect/g_selected"]({
        controller_multiselect: this.controller_multiselect,
      });
    },
    item_key() {
      return this.$store.getters["structure/multiselect/g_item_key"]({
        controller_multiselect: this.controller_multiselect,
      });
    },
    namesexpanded() {
      let expanded = this.$store.getters["structure/multiselect/g_expanded"]({
        controller_multiselect: this.controller_multiselect,
      });
      let namesexpanded = [];
      for (const key in expanded) {
        namesexpanded.push(expanded[key]["controller_multiselect"]);
      }
      return namesexpanded;
    },



    headers() {


     /*  return this.$store.getters["structure/multiselect/g_headers"]({
        controller_multiselect: this.controller_multiselect,
        namesexpanded: this.namesexpanded,
        notaffiche: this.notaffiche,
      }); */

 let expanded = this.$store.getters["structure/multiselect/g_expanded"]({
        controller_multiselect: this.controller_multiselect,
      });
        
   return this.$store.getters["structure/multiselect/g_headers"]({
        controller_multiselect: this.controller_multiselect,
        namesexpanded: [expanded["controller_expanded"]],
        notaffiche: this.notaffiche,
      });

      
    },

    vuex_expanded() {
      return this.$store.getters["structure/multiselect/g_expanded"]({
        controller_multiselect: this.controller_multiselect,
      });
    },
    loading() {
      return this.$store.getters["structure/multiselect/loading"];
    },
    write() {
      return this.$store.getters["structure/multiselect/write"];
    },
  },
  methods: {
    all() {
      this.$store.commit("structure/multiselect/set_jointure", {
        controller_multiselect: this.controller_multiselect,
        data: "FULL",
      });

      this.$store.dispatch(
        "structure/multiselect/ajax_initialize_struct_Multiselect"
      );
    },

    libre() {
      this.$store.commit("structure/multiselect/set_jointure", {
        controller_multiselect: this.controller_multiselect,
        data: "INNER",
      });

      this.$store.dispatch(
        "structure/multiselect/ajax_initialize_struct_Multiselect"
      );
    },

    select(selected) {
      let controller_multiselect = this.controller_multiselect;

      this.$store.commit("structure/multiselect/set_selected", {
        controller_multiselect,
        data: selected,
      });
    },
    item_selected({ item, value }) {
      console.log(item, value);
      let controller_multiselect = this.controller_multiselect;

      let controller_listsAdd = this.$store.getters[
        "structure/multiselect/g_controller_listsAdd_attach"
      ]({ controller_multiselect });

      let namepiece = this.$store.getters["structure/multiselect/g_pieceID"]({
        controller_multiselect,
      });
      let name_expanded = this.vuex_expanded["controller_expanded"];
      let pieceID = item.id + namepiece;

      this.$store.commit(
        "structure/addlist/remove_rows_by_idpieace_chargetrage",
        {
          controller_listsAdd,
          pieceID: item.id,
        }
      );

      if (value) {
        this.$store.dispatch("structure/addlist/set_data_par_multiselect", {
          controller_listsAdd,
          pieceID,
          data: item[name_expanded].dataSet,
        });
      }
    },

    toggle_select_all($event) {
      /// pour attend fonction select()
      setTimeout(() => {
        let controller_multiselect = this.controller_multiselect;
        let controller_listsAdd = this.$store.getters[
          "structure/multiselect/g_controller_listsAdd_attach"
        ]({ controller_multiselect });
        let namepiece = this.$store.getters["structure/multiselect/g_pieceID"]({
          controller_multiselect,
        });
        let name_expanded = this.vuex_expanded["controller_expanded"];

        this.$store.commit("structure/addlist/remove_rows", {
          controller_listsAdd,
        });

        for (const item of this.selected) {
          let pieceID = item.id + namepiece;
          this.$store.dispatch("structure/addlist/set_data_par_multiselect", {
            controller_listsAdd,
            pieceID,
            data: item[name_expanded].dataSet,
          });
        }
      }, 400);
    },
    page_count(val) {
      this.$store.commit("structure/multiselect/set_pageCount", val);
    },
  },
};
</script>

<style scoped></style>
