<template>
  <div class="v-list_add">
    <!--  <div class="v-main_table" v-if="headers.length > 0"> -->
    <div class="v-main_table">
      <!--  plugine-->
      <table-headers
        :title="title"
        :controller_root="controller_root"
        :slot_show="slot_show"
        :action_traitement="action_traitement"
        :selectajax="selectajax"
      ></table-headers>
      <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->
      <v-data-table
        :loading="loading"
        class="elevation-1"
        no-results-text="Aucun élément trouvé"
        no-data-text="Aucun élément trouvé"
        :show-expand="expanded.length > 0"
        :single-expand="true"
        :headers="headers"
        :items="items"
        :show-select="true"
        :value="selected"
        @input="select($event)"
        :item-key="item_key"
      >
        <!--  :search="search" -->
        <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->

        <template v-slot:expanded-item="{ headers, item }">
          <table-expand
            :controller_root="controller_root"
            :item="item"
            :numbretd="headers.length"
          ></table-expand>
        </template>

        <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->
        <template v-slot:item.action="{ item }">
          <table-action
            :item="item"
            :controller_root="controller_root"
            :slot_show="slot_show"
            :action_traitement="action_traitement"
          ></table-action>
        </template>

        <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->
        <template v-slot:item.id="{ item }">
          <v-chip color="primary" dark
            ><strong>{{ item.id }}</strong></v-chip
          >
        </template>
        <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->
        <template v-slot:item.date="{ item }">{{
          convertDate(item.date)
        }}</template>
        <template v-slot:item.createdAt="{ item }">{{
          convertDate(item.createdAt)
        }}</template>
        <template v-slot:item.updatedAt="{ item }">{{
          convertDate(item.updatedAt)
        }}</template>
        <template v-slot:item.dateUpload="{ item }">{{
          convertDate(item.dateUpload)
        }}</template>

        <template v-slot:item.dateCommande="{ item }">{{
          convertDate(item.dateCommande)
        }}</template>
        <template v-slot:item.dateLivraison="{ item }">{{
          convertDate(item.dateLivraison)
        }}</template>

        <template v-slot:item.dateoperation="{ item }">{{
          convertDate(item.dateoperation)
        }}</template>

        <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->
        <template v-slot:item.files="{ item }">
          <!-- <table-setfiles v-if="!item.files || item.files==''" name="files" :controller_root="controller_root" :id="item.id"  ></table-setfiles>
            -->
          <table-avatar
            :controller_root="controller_root"
            name="files"
            :id="item.id"
            size="30px"
            :refgroup="item.files"
            :hasdowlaod="true"
          ></table-avatar>
        </template>
        <template v-slot:item.file="{ item }">
          <!-- <table-setfiles v-if="!item.file || item.file==''" name="file" :controller_root="controller_root" :id="item.id"  ></table-setfiles>
        -->
          <table-avatar
            :controller_root="controller_root"
            name="file"
            :id="item.id"
            size="30px"
            :refgroup="item.file"
            :hasdowlaod="true"
          ></table-avatar>
        </template>
        <template v-slot:item.logo="{ item }">
          <!-- <table-setfiles v-if="!item.logo || item.logo==''" name="logo" :controller_root="controller_root" :id="item.id"  ></table-setfiles>
        -->
          <table-avatar
            :controller_root="controller_root"
            name="logo"
            :id="item.id"
            size="30px"
            :refgroup="item.logo"
            :hasdowlaod="true"
          ></table-avatar>
        </template>

        <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->
        <template v-slot:item.actionTraiter="{ item }">
          <table-actiontraiter
            :action="item.actionTraiter"
          ></table-actiontraiter>
        </template>

        <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->
        <template v-slot:item.avoir="{ item }">
          <table-actiontavoirbonvente
            :avoir="item.avoir"
            :item="item"
            :controller_root="controller_root"
            :slot_show="slot_show"
            :action_traitement="action_traitement"
          ></table-actiontavoirbonvente>
        </template>

        <template
          v-slot:body.append="{ headers, items, isSelected, isMobile }"
          v-if="calculemontantitem.length > 0 && items.length > 0"
        >
          <tr v-if="!isMobile">
            <td :colspan="headers.length" v-if="selected.length == 0">
              <v-alert
                border="left"
                color="green"
                dense
                icon="mdi-cash-multiple"
                colored-border
                type="success"
              >
                les élements afficher:{{ items.length }}<br />
                <span
                  v-for="(montant, indexmontant) in calculemontantitem"
                  :key="indexmontant"
                >
                  {{ $t("TOTAL " )+ montant }} :
                  {{ calculemontant(items, montant) }} DH<br />
                </span>
              </v-alert>
            </td>

            <td :colspan="headers.length" v-else>
              <v-alert
                border="left"
                colored-border
                color="orange"
                dense
                icon="mdi-cash-multiple"
                type="success"
              >
                les élements selecter :{{ selected.length }}<br />
                <span
                  v-for="(montant, indexmontantt) in calculemontantitem"
                  :key="indexmontantt"
                >
                  {{ $t("TOTAL ") + montant }} :
                  {{ calculemontant(selected, montant) }}
                  DH<br />
                </span>
              </v-alert>
            </td>
          </tr>
        </template>
      </v-data-table>

      <table-dialog :controller_root="controller_root" v-if="slot_show">
        <template v-slot:show>
          <slot name="show"></slot>
        </template>

        <template v-slot:add>
          <slot name="add"></slot>
        </template>

        <template v-slot:update>
          <slot name="update"></slot>
        </template>
      </table-dialog>
    </div>
  </div>
</template>

<script>
import util from "@/util/index";
import i18n from "@/plugins/i18n";

export default {
  props: {
    controller_root: {},

    slot_show: { default: false },
    action_traitement: { default: true },

    selectajax: {
      type: Array,
      default: () => {
        return [];
      },
    },
    calculemontantitem: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      isMobile: false,
      optionsDataTable: {},
    };
  },
  watch: {
    optionsDataTable(options) {
      this.get_date_http();
    },
    search(search) {
      this.get_date_http();
    },
  },

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  computed: {
    title() {
      return this.$store.getters["show_data/g_title"]({
        controller_root: this.controller_root,
      });
    },

    notaffiche() {
      return this.$store.getters["show_data/g_not_affiche"]({
        controller_root: this.controller_root,
      });
    },

    search() {
      return this.$store.getters["show_data/g_search"]({
        controller_root: this.controller_root,
      });
    },
    page() {
      return this.$store.getters["show_data/g_page"]({
        controller_root: this.controller_root,
      });
    },
    itemsPerPage() {
      return this.$store.getters["show_data/g_itemsPerPage"]({
        controller_root: this.controller_root,
      });
    },
    TotalItemCount() {
      return this.$store.getters["show_data/g_TotalItemCount"]({
        controller_root: this.controller_root,
      });
    },

    items() {
      let items = this.$store.getters["show_data/g_items"]({
        controller_root: this.controller_root,
      });
      // let newItems = [];

      items.forEach((item, index) => {
        let newItem = {};
        for (const key in item) {
          const value = item[key];
          newItem[key] = value;

          if (typeof value == "boolean") {
            if (value) {
              newItem[key] = 1;
            } else {
              newItem[key] = 0;
            }
          }
        }
        items[index] = newItem;
        // newItems.push(newItem);
      });

      return items;
    },
    selected() {
      return this.$store.getters["show_data/g_selected"]({
        controller_root: this.controller_root,
      });
    },
    item_key() {
      return this.$store.getters["show_data/g_item_key"]({
        controller_root: this.controller_root,
      });
    },
    expanded() {
      return this.$store.getters["show_data/g_expanded"]({
        controller_root: this.controller_root,
      });
    },
    namesexpanded() {
      let expanded = this.expanded;
      let namesexpanded = [];
      for (const key in expanded) {
        namesexpanded.push(expanded[key]["controller_attach"]);
      }
      return namesexpanded;
    },
    headers() {
      //************************************************************ */
      let name_input_files = [];
      /*    let shema_inputs = this.$store.getters["g_SCHEMA_GlobalHTML"][this.controller_root];
    

      if (shema_inputs != undefined) {
        for (const shema_input of shema_inputs["html"]) {
          if (shema_input.type == "file" || shema_input.type == "files" || shema_input.type == "logo") {
            name_input_files.push(shema_input.name);
          }
        }
      } */

      //*********************************************************************** */

      let headers = this.$store.getters["show_data/g_headers"]({
        controller_root: this.controller_root,
        namesexpanded: this.namesexpanded,
        notaffiche: this.notaffiche,
        name_input_files,
      });
      let textaction = "";
      if (this.isMobile) {
        textaction = "";
      } else {
        textaction = "Les Actions des traitement ";
      }

      if (this.action_traitement) {
        headers.push({
          text: textaction,
          value: "action",
          sortable: false,
          align: "end",
        });
      }

      return headers;
    },

    loading() {
      return this.$store.state["show_data"].loading;
    },
  },
  methods: {
    customsearch: util.customsearchTable,

    get_date_http() {
      /* this.optionsDataTable["itemsPerPage"]=-1
      this.optionsDataTable["sortBy"]=[]
      this.optionsDataTable["sortDesc"]=[] */

      console.log(this.search);
      setTimeout(() => {
        const params = {
          [this.controller_root]: {
            optionsDataTable: JSON.stringify(this.optionsDataTable),
            searchDataTable: JSON.stringify(this.search),
            AttributesTableHide: JSON.stringify(this.notaffiche),
          },
        };
        this.$store.dispatch("run_show", {
          names_controllers: undefined,
          params,
        });
        console.log(this.controller_root);
        console.log(this.optionsDataTable);
        console.log(this.search);
      }, 300);
    },

    calculemontant(items, pour) {
      let a = 0;
      for (let index = 0; index < items.length; index++) {
        const element = items[index];
        a = a + element[pour] * 1;
      }
      return a.toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },
    calculemontantSelected(items, pour, isSelected) {
      let a = 0;
      for (let index = 0; index < items.length; index++) {
        const element = items[index];
        if (isSelected(element)) {
          a = a + element[pour] * 1;
        }
      }
      return a.toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },

    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
    convertDate(inputFormat) {
      function pad(s) {
        return s < 10 ? "0" + s : s;
      }
      var d = new Date(inputFormat);
      return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(
        "/"
      );
    },
    page_count(val) {
      this.$store.commit(
        "show_data/set_pageCount",

        {
          data: val,
          controller_root: this.controller_root,
        }
      );
    },
    select(selected) {
      this.$store.commit(
        "show_data/set_selected",

        {
          data: selected,
          controller_root: this.controller_root,
        }
      );
    },

    isGranted(attribute) {
      return this.$store.getters["security/isGranted"](
        attribute,
        this.controller_root
      );
    },
  },
};
</script>
