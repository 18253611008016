<template>
  <div>
    <v-autocomplete
      :label="label"
      :value="values_binds"
      @change="modelbind"
      :items="items"
      @focus="init_validation()"
      :rules="rules"
      item-text="text"
      item-value="value"
    >
      <template v-slot:append-outer>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-btn
              text
              icon
              color="success"
              @click="dialog = true"
              v-show="!readonly && isGranted('add', name.toLowerCase())"
              v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>ajouter élément</span>
        </v-tooltip>

        <div class="text-center">
          <v-dialog v-model="dialog" width="500">
            <v-card>
              <v-form ref="form" v-model="valid">
                <v-card-title class="headline grey lighten-2">
                  Ajouter
                </v-card-title>

                <v-card-text>
                  <box-defaultajax :controller_root="from" />
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <btn-sendajax
                    @click="dialog = false"
                    :valid="valid"
                    :controller_root="from"
                    :controller_main="controller_root"
                  />
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </div>
      </template>
    </v-autocomplete>
  </div>

  <!--   <bootstrap-select :options="items" searchable @input="modelbind" > </bootstrap-select>
  -->
</template>
<script>
import InputMixin from "./InputMixin";

export default {
  mixins: [InputMixin],
  data() {
    return {
      dialog: false,
      valid: false,
    };
  },

  computed: {
    values_binds() {
      return this.$store.getters["structure/get_input_binds"]({
        controller_root: this.controller_root,
        name: this.name,
      });
    },

    items() {
      let data = this.$store.getters["structure/get_data_Select_load"]({
        controller_root: this.controller_root,
        nameManyToOne: this.from,
      });

      let items = [];

      for (const key in data) {
        let option = {};

        const element = data[key];

        if (typeof this.to_string == "string") {
          option.text = element[this.to_string];
        } else {
          option.text =
            element[this.to_string[0]] + " / " + element[this.to_string[1]];
        }
        option.value = element["@id"];

        items.push(option);
      }

      return items;
    },
  },
  watch: {
    val_root(val) {
      if (this.input_root != "" && val != undefined) {
        let val_defaut_par_root = this.info_root()[this.name + "_id"];

        this.$store.commit("structure/set_input_binds", {
          name: this.name,
          value: val_defaut_par_root,
          controller_root: this.controller_root,
        });
      }
    },
  },
  methods: {
    init_validation() {
      this.rules = [this.validation.required];
    },
    isGranted(attribute, controller) {
      return this.$store.getters["security/isGranted"](attribute, controller);
    },
  },
  mounted() {
    this.$store.dispatch("initialize_struct_from", {
      names_controllers: [this.from],
    });
  },
};
</script>
