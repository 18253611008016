<template>
  <div class="v-list_add">

  <v-fab-transition>
              <v-btn
              @click="simplevue=!simplevue"
               :loading="loading"
                color="orange"
                dark
                absolute
                button
                right
                fab
              >
                <v-icon>mdi-palette-swatch</v-icon>
              </v-btn>
            </v-fab-transition>

    <div v-if="simplevue">
      

         <table-run
      :controller_root="controller_root"
      :slot_show="slot_show"
      :action_traitement="action_traitement"
      :calculemontantitem="calculemontantitem"
    />
    </div>
     <div v-else>
  <!--  plugine-->
    <table-headers
      :title="title"
      :controller_root="controller_root"
      :slot_show="slot_show"
      :action_traitement="action_traitement"
    ></table-headers>
    <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->

    <v-row align="center" justify="center">
      <v-col cols="12" md="6" lg="4" xl="3" v-for="(item, indexrow) in items" :key="indexrow" align="center" justify="center">
        <component :is="card" :item="item"  :controller_root="controller_root"></component>
      </v-col>
    </v-row>
    <v-row class="align-end">
      <v-col>
        <v-pagination
          v-model="page_set"
          :length="Math.ceil(TotalItemCount / itemsPerPage)"
          total-visible="7"
          circle
        ></v-pagination
      ></v-col>
      <v-col style="max-width: 120px">
        <v-select
          v-model="select_itemsPerPage"
          :items="[
            { text: 3, value: 3 },
            { text: 6, value: 6 },
            { text: 10, value: 10 },
            { text: 'ALL', value: -1 },
          ]"
          label=""
        ></v-select
      ></v-col>
    </v-row>

    <table-dialog :controller_root="controller_root" v-if="slot_show">
      <template v-slot:show>
        <slot name="show"></slot>
      </template>

      <template v-slot:add>
        <slot name="add"></slot>
      </template>

      <template v-slot:update>
        <slot name="update"></slot>
      </template>
    </table-dialog>


     </div>




 

  
  </div>
</template>

<script>
export default {
  props: {
    card: {},
    controller_root: {},

    slot_show: { default: false },
    action_traitement: { default: true },
    calculemontantitem: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      simplevue:false,
      page_set: 1,
      select_itemsPerPage: 10,
      optionsDataTable: {
        groupBy: [],
        groupDesc: [],

        multiSort: false,
        mustSort: false,

        sortBy: [],
        sortDesc: [],
        page: 0,
        itemsPerPage: 0,
      },
    };
  },
  watch: {
    optionsDataTable(options) {
      this.get_date_http();
    },
    search(search) {
      this.get_date_http();
    },
    page_set(val) {
      this.setPage(val);
      this.get_date_http();
    },
    select_itemsPerPage(val) {
      this.setPage(1);
      this.setitemsPerPage(val);
      this.get_date_http();
    },
  },

  computed: {
    title() {
      return this.$store.getters["show_data/g_title"]({
        controller_root: this.controller_root,
      });
    },loading() {
      return this.$store.state["show_data"].loading;
    },

    notaffiche() {
      return this.$store.getters["show_data/g_not_affiche"]({
        controller_root: this.controller_root,
      });
    },

    search() {
      return this.$store.getters["show_data/g_search"]({
        controller_root: this.controller_root,
      });
    },
    page() {
      return this.$store.getters["show_data/g_page"]({
        controller_root: this.controller_root,
      });
    },
    itemsPerPage() {
      return this.$store.getters["show_data/g_itemsPerPage"]({
        controller_root: this.controller_root,
      });
    },
    TotalItemCount() {
      return this.$store.getters["show_data/g_TotalItemCount"]({
        controller_root: this.controller_root,
      });
    },

    items() {
      return this.$store.getters["show_data/g_items"]({
        controller_root: this.controller_root,
      });
    },
  },
  methods: {
    get_date_http() {
      setTimeout(() => {
        let itemsPerPage = this.itemsPerPage;

        if (itemsPerPage == -1) {
          itemsPerPage = this.TotalItemCount;
        }

        let op = {
          page: this.page,
          itemsPerPage: itemsPerPage,
        };

        const params = {
          [this.controller_root]: op,
        };

        this.$store.dispatch("run_show", {
          names_controllers: undefined,
          params,
        });
      }, 300);
    },

    setitemsPerPage(val) {
      this.$store.commit(
        "show_data/set_itemsPerPage",

        { data: val, controller_root: this.controller_root }
      );
    },
    setPage(val) {
      let page = parseInt(val, 10);

      this.$store.commit("show_data/set_page", {
        data: page,
        controller_root: this.controller_root,
      });
    },
  },
  mounted() {
    this.get_date_http();
  },
};
</script>
