<template>
  <v-row class="">
    <v-col :cols="1">
    
        <v-btn icon @click="refresh">
          <v-icon>mdi-home</v-icon>
        </v-btn>

      
    </v-col>



    <v-col :cols="11" :lg="11">
      <v-spacer></v-spacer>
      <v-breadcrumbs :items="breadcrumbs"  >
        <template v-slot:divider>
          <v-icon>mdi-star-box</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    
  </v-row>
</template>
<script>
export default {
  props: ["breadcrumbs"],
  methods: {
    refresh() {
      window.location.href = "/";
    },
  },
};
</script>