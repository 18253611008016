<template>
  <v-dialog v-model="showCropper" persistent max-height="650" >
    <v-row class="justify-center align-center">
      <v-col cols="12" md="6" lg="4" xl="3">
        <div class="crop-image-dialog">
          <v-card class="pt-6 pb-3 ma-0">
            <v-card-text class="pb-3">
              <vue-cropper
                ref="cropper"
                class="image-container"
                :aspect-ratio="0"
                :guides="true"
                :background="false"
                :view-mode="3"
                drag-mode="move"
                :src="chosenImage"
                alt="Image not available"
              >
              </vue-cropper>
            </v-card-text>
            <v-card-actions class="py-0 mx-10">
              <v-btn @click="resetCropper" text color="red">
                <v-icon large>mdi-close</v-icon></v-btn
              >
              <v-spacer></v-spacer>
              <v-btn @click="cropChosenImage" text color="blue">
                <v-icon large>mdi-hand-okay</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "ImageCropperDialog",
  components: {
    VueCropper,
  },
  props: {
    chosenImage: {
      default: null,
    },
  },
  data() {
    return {
      showCropper: false,
      imageFileType: null,
    };
  },
  methods: {
    async initCropper(imageFileType) {
      this.showCropper = true;
      this.imageFileType = imageFileType;
      await new Promise((resolve) => setTimeout(resolve, 50));
      this.$refs.cropper.replace(this.chosenImage);
    },

    async resetCropper() {
      this.$emit("onReset");
      this.showCropper = false;
    },

    async cropChosenImage() {
      this.$emit(
        "onCrop",
        this.$refs.cropper.getCroppedCanvas().toDataURL(this.imageFileType)
      );

      this.resetCropper();
    },
  },
};
</script>

<style>
.image-container {
  max-width: 100%;
}

.image-container img {
  /* This is important */
  width: 100%;
}
</style>