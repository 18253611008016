<template>
  <div id="v-widget">
    

    <v-card  elevation=0>
      <v-card-text :class="contentBg">
        <slot name="widget-content"></slot>
      </v-card-text>
    </v-card>
    
  </div>
</template>

<script>
export default {
  name: "v-widget",
  props: {
    title: {
      type: String,
    },
    enableHeader: {
      type: Boolean,
      default: true,
    },
    contentBg: {
      type: String,
      default: "white",
    },
  },

  data() {
    return {};
  },
  computed: {},
};
</script>
