var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('btn-action',{attrs:{"controller_root":_vm.controller_root,"item":{ id: _vm.id }}})],1)],1),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":12,"lg":5}},[_c('card-box',{attrs:{"id":"groupa1","color":"orange","title":_vm.$t('show_commandes_infoplat_1'),"text":_vm.$t('show_commandes_infoplat_2')},scopedSlots:_vm._u([{key:"chart",fn:function(){return undefined},proxy:true}])},[_c('show-group',{attrs:{"names":[
            'code',
            'client',
            'validateur',
            'serveur',
            'valide',
            'dureepreparation',
          ],"controller_root":_vm.controller_root}})],1)],1),_c('v-col',{attrs:{"cols":12,"lg":5}},[_c('card-box',{attrs:{"id":"groupa1","color":"orange","title":_vm.$t('show_commandes_infoplat_3'),"text":_vm.$t('show_commandes_infoplat_4')},scopedSlots:_vm._u([{key:"chart",fn:function(){return undefined},proxy:true}])},[_c('show-group',{attrs:{"names":['modepaiement', 'total', 'especes', 'rendu'],"controller_root":_vm.controller_root}})],1)],1),_c('v-col',{attrs:{"cols":12,"lg":"7"}},[_c('card-box',{attrs:{"id":"addlist2","icon":"mdi-file-star-outline","title":_vm.$t('show_commandes_infoplat_5'),"color":"success"}},[_c('addlist-table',{attrs:{"edit_listsAdd":false,"controller_listsAdd":"commandeplats"}})],1)],1),_c('v-col',{attrs:{"cols":12,"lg":3}},[_c('card-box',{attrs:{"id":"groupa1","color":"orange","title":_vm.$t('show_commandes_infoplat_6'),"text":_vm.$t('show_commandes_infoplat_7')},scopedSlots:_vm._u([{key:"chart",fn:function(){return undefined},proxy:true}])},[_c('show-group',{attrs:{"names":['montantHt', 'montantTva', 'montantTtc'],"controller_root":_vm.controller_root}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }