<template>
  <v-form ref="form" v-model="valid">
     <v-row class="justify-center">
      <v-col cols="12"
        ><btn-action
          :controller_root="controller_root"
          :item="{ id: id }"
        ></btn-action
      ></v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="12" lg="5">
        <card-box
          id="a1"
          icon="mdi-file-star-outline"
          :title="$t('show_bonsachats_infoplat_1')"
          color="success"
        >
          <box-root
            :edit_root="false"
            :input_root="input_root"
            :controller_root="controller_root"
          />
          <show-group
            :names="['titre', 'reffournisseur', 'note']"
            :controller_root="controller_root"
          />
        </card-box>
      </v-col>
      <v-col cols="12" lg="5">
        <card-box
          id="a2"
          icon="mdi-file-star-outline"
          :title="$t('show_bonsachats_infoplat_2')"
          color="success"
        >
          <show-group
            :names="['dateLivraison', 'dateCommande', 'files']"
            :controller_root="controller_root"
          />
        </card-box>
      </v-col>

      <v-col cols="12" lg="7">
        <card-box
          id="addlist2"
          icon="mdi-file-star-outline"
          :title="$t('show_bonsachats_infoplat_3')"
          color="success"
        >
          <addlist-table
            :edit_listsAdd="false"
            controller_listsAdd="produitsbonsachats"
          />
        </card-box>

         <card-box
          id="addlist22"
          icon="mdi-file-star-outline"
          :title="$t('show_bonsachats_infoplat_4')"
          color="success"
        >
          <addlist-table :edit_listsAdd="false" controller_listsAdd="recettebonachats" />
          
        </card-box>
      </v-col>

      <v-col cols="12" lg="3">
        <v-row class="justify-center">
          <v-col :cols="12" :lg="12">
            <card-box
              id="groupa1"
              color="orange"
              :title="$t('show_bonsachats_infoplat_5')"
              :text="$t('show_bonsachats_box_text_5')"
            >
              <template v-slot:chart> </template>

              <show-group
                :names="[
                  'montantHt',
                  'montantTva',
                  'montantTtc',
                  'modepaiement',
                ]"
                :controller_root="controller_root"
              />
            </card-box>
          </v-col>
        </v-row>
      </v-col>

     
    </v-row>
  </v-form>
</template>


<script>
import i18n from "@/plugins/i18n";
import { mapGetters } from "vuex";
import ViewMixin from "@/views/show_item";

export default {
  mixins: [ViewMixin],

  props: {},
  data() {
    return {
      controller_root: "bonsachats",
      input_root: "fournisseur",
      namemodule: "Achat",
      lists_Add: [
        {
          controller_listsAdd: "produitsbonsachats",
        
        },{
          controller_listsAdd: "recettebonachats",
         
        },
      ],
    };
  },
  computed: {
   

    id() {
      return this.$route.params.id;
    },
  },
  created() {
    document.title = i18n.t("show_item_" + this.controller_root);
    this.initia();
    this.run();
  },

  methods: {
    isGranted(controller) {
      return this.$store.getters["security/isGranted"]("view", controller);
    },
  },
};
</script>
