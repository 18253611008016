import axios from 'axios'
import router from '@/router'
import api_config from '@/#_CONFIG/Entity'
//let colorconsole="color: red"
//let colorconsole="color: green"
//let colorconsole="color: yellow"
let colorconsole = 'color: blue'
//let colorconsole="color: magenta"
//let colorconsole="color: cyan"
//let colorconsole="color: gray"
//let colorconsole="color: grey"
let colorconsoleyellow = 'color: magenta'

export default {
  //ok
  ajax_data(
    { commit, dispatch, getters, rootGetters, rootState, state },
    { names_controllers, params },
  ) {
    let namescontrollers
    if (names_controllers === undefined) {
      namescontrollers = state.namescontrollers
    } else {
      namescontrollers = names_controllers
    }

    state.loading = true
    namescontrollers.forEach((controller_root) => {
      if (rootGetters['get_path'](controller_root) !== false) {
        const urlPage = rootGetters['get_path'](controller_root)

        let param = {
          
          optionsDataTable: { page: 1, itemsPerPage: 10 },
        }
        if (params != undefined && params[controller_root] != undefined) {
          param = params[controller_root]
        } else {
          param = params
        }






        let Base_url = rootState.Base_url
        let JWTToken = rootState.JWTToken
        let search = getters.g_search({
          controller_root: controller_root,
        });




        // create a data object to store the information below.
    let searchObj   = {};

       
    // this will now loop through the search and pull out key value pairs seperated 
    // by the & character as a combined search, in addition it passes up the ? mark
        let pairs = search.substring(search.indexOf('?')+1).split('&');
        for(let key in pairs)
        {
            let value = pairs[key].split("=");
            searchObj[value[0]] = value[1];
        }


        axios

          .get(Base_url + urlPage, {
            params: {...param, ...searchObj},
            headers: {
              'Content-Type': 'application/ld+json',
              'Accept': 'application/ld+json',
              'Authorization':JWTToken
            },
          })

          .then(function (response) {
            commit('set_schemas', {
              controller_root: controller_root,
              data: api_config[controller_root].schema,
            })

            commit('set_headers', {
              controller_root: controller_root,
              data: api_config[controller_root].titles,
            })

            commit('set_items', {
              controller_root: controller_root,
              data: response.data['hydra:member'],
            })

            commit('set_options', {
              controller_root: controller_root,
              data: response.data.options,
            })
            commit('set_CurrentPageNumber', {
              controller_root: controller_root,
              data: response.data.CurrentPageNumber,
            })
            commit('set_ItemNumberPerPage', {
              controller_root: controller_root,
              data: response.data.ItemNumberPerPage,
            })
            commit('set_TotalItemCount', {
              controller_root: controller_root,
              data: response.data['hydra:totalItems'],
            })

            state.loading = false
          })
          .catch(function (error) {
            // router.push("/login")
          })
      } else {
        //  router.push("/")
      }
    })
  },
}
