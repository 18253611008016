<template>
  <card-card
    :close="close"
    @close="closeevent"
    icon="mdi-calendar-check-outline"
    color="orange"
    :title="$t('comptesbancairesbonBonsventes')"
    class="px-5 py-3"
  >
    <v-row class="fill-height">
      <v-col>
        <v-sheet>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
        </v-sheet>
        <v-sheet height="80">
          <v-toolbar flat>
            <v-btn
              :loading="loading"
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              {{ $t("aujourd'hui") }}
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>

            <v-spacer></v-spacer>

            <v-autocomplete
              :loading="loading"
              multiple
              :clearable="true"
              label="comptesbancairesbon"
              @change="comptesbancairesbonChange"
              :items="comptesbancairesbonItems"
              item-text="text"
              item-value="value"
              :messages="'  Total_TTC :  ' + Total_TTC + ' DH'"
            >
            </v-autocomplete>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title> {{ $t("Jour") }} </v-list-item-title>
                </v-list-item>

                <v-list-item @click="type = 'month'">
                  <v-list-item-title> {{ $t("Mois") }} </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>

        <v-sheet height="600">
          <v-calendar
            :locale="$t('locale')"
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="updateRange"
          ></v-calendar>

          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card color="grey lighten-4" min-width="350px" flat>
              <v-toolbar :color="selectedEvent.color" dark>
                <v-btn
                  v-if="selectedEvent._id_ > 0"
                  icon
                  @click="
                    showItem(selectedEvent._id_, selectedEvent.controller_root_)
                  "
                  v-show="isGranted('view', selectedEvent.controller_root_)"
                >
                  <v-icon>mdi-view-carousel</v-icon>
                </v-btn>

                <v-toolbar-title v-html="selectedEvent.name"> </v-toolbar-title>
                <v-spacer></v-spacer>

                <v-btn icon @click="selectedOpen = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-toolbar flat>
                  <v-toolbar-title>
                    {{ selectedEvent.client_ }}
                  </v-toolbar-title>

                  <v-spacer></v-spacer>
                  <v-chip class="ma-2" :color="selectedEvent.color" dark>
                    {{ selectedEvent.comptesbancairesbon_ }}
                  </v-chip>
                </v-toolbar>

                <v-toolbar flat>

                  <v-chip class="ma-2" :color="selectedEvent.color" dark>
                    {{ selectedEvent.recettesORdepenses_ }}
                  </v-chip>

                  <v-spacer></v-spacer>
                  <v-chip class="ma-2" :color="selectedEvent.color" dark>
                    {{ selectedEvent.categoriesrecettesORdepenses_ }}
                  </v-chip>

                  <v-spacer></v-spacer>
                  <v-chip class="ma-2" :color="selectedEvent.color" dark>
                    {{ selectedEvent.modePaiement_ }}
                  </v-chip>
                </v-toolbar>

                <v-simple-table dense color="grey lighten-4">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          {{ $t(selectedEvent.tr_table_[0]) }}
                        </th>
                        <th class="text-left">
                          {{ $t(selectedEvent.tr_table_[1]) }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in selectedEvent.td_table_"
                        :key="item.key"
                      >
                        <td>{{ item.a }}</td>
                        <td>{{ item.b }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <h4 right>Montant TTC : {{ selectedEvent.montantTtc_ }} DH</h4>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
  </card-card>
</template>

<script>
import axios from "axios";


import widgetsMixin from "../widgetsMixin";

export default {
  mixins: [widgetsMixin],
  data: () => ({
    comptesbancairesbonItems: [],
    controller_root_1: "recettesbon",
    controller_root_2: "depensesbon",
    focus: "",
    type: "month",

    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    eventsAll: [],
    events: [],
    eventsTTC: {},
    Total_TTC: 0,
    typeToLabel: {
      month: "Mois",

      day: "Jour",
    },
    min: 0,
    max: 0,
    start_date: 0,
    end_date: 0,
    loading: false,
  }),
  mounted() {
    this.$refs.calendar.checkChange();
    // this.start();
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      console.log(start);
      console.log(end);

      this.min = new Date(`${start.date}T00:00:00`).getTime();
      this.max = new Date(`${end.date}T23:59:59`).getTime();

      this.start_date = start.date;
      this.end_date = end.date;
      this.start();
    },
    comptesbancairesbonChange(val) {
      console.log(this.eventsAll);

      let eventsDB = this.eventsAll.filter((item) => {
        return val.includes(item.comptesbancairesbon_id_);
      });

      let eventsTTC = {};
      eventsDB.forEach((element) => {
        if (eventsTTC[element.start] == undefined) {
          eventsTTC[element.start] = {
            name: "TOTAL TTC",
            color: "indigo",
            start: element.start,
            end: element.start,
            tr_table_: ["bonsVentes", "montantReglement"],
            td_table_: [],
            montantTtc_: 0,
            client_: "",
            comptesbancairesbon_: "",
          };
        }

        let ttc =
          eventsTTC[element.start]["montantTtc_"] * 1 +
          element["montantTtc_"] * 1;

        eventsTTC[element.start]["montantTtc_"] = ttc.toFixed(2);
        eventsTTC[element.start]["comptesbancairesbon_"] =
          ttc.toLocaleString(undefined, {
            minimumFractionDigits: 2,
          }) + " DH";
        eventsTTC[element.start]["name"] =
          "TOTAL TTC : " +
          ttc.toLocaleString(undefined, {
            minimumFractionDigits: 2,
          }) +
          " DH ";
        eventsTTC[element.start]["td_table_"].push({
          a: element.name,
          b: element.montantTtc_,
        });
      });

      let events = [];
      this.eventsTTC = eventsTTC;
      for (let key in eventsTTC) {
        events.push(eventsTTC[key]);
      }

      this.events = events.concat(eventsDB);

      console.log(this.events);
      this.calcul_total_ttc_();
    },
    calcul_total_ttc_() {
      let min = this.min;
      let max = this.max;

      console.log(min);
      console.log(max);
      let $Total_TTC = 0;
      for (const key in this.eventsTTC) {
        const Time_TTC = new Date(`${key}T00:00:00`).getTime();

        if (Time_TTC >= min && Time_TTC <= max) {
          $Total_TTC = $Total_TTC + this.eventsTTC[key]["montantTtc_"] * 1;
        }
      }
      console.log($Total_TTC);
      this.Total_TTC = $Total_TTC.toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },

    /////////////////////////
    count(paths) {
      if (this.start_date == this.end_date) {
        this.calcul_total_ttc_();
        return;
      }

      let search = ">date===" + this.start_date + "~" + this.end_date;

      const params = {
        optionsDataTable: JSON.stringify({
          groupBy: [],
          groupDesc: [],
          itemsPerPage: -1,
          multiSort: false,
          mustSort: false,
          page: 1,
          sortBy: [],
          sortDesc: [],
        }),
        searchDataTable: JSON.stringify(search),
        AttributesTableHide: "",
      };
      this.loading = true;
      this.comptesbancairesbonItems = [];
      this.eventsAll = [];
      this.events = [];
      this.eventsTTC = {};
      this.Total_TTC = 0;
      let path1 = paths[this.controller_root_1];
      if (path1 != undefined) {
        axios
          .get(this.Base_url_web + path1, { params: params })
          .then((response) => {
            this.eventsAll = [];

            let data = response.data["dataSet"];
            console.log(data);
            data.forEach((element) => {
              let event = {};

              let releves = element["releveRecettesbons"]["dataSet"];
              let inforeleves = [];
              let i = 0;
              releves.forEach((releve) => {
                i++;
                inforeleves.push({
                  key: i,
                  a: releve["bonsVentes"],
                  b: releve["montantReglement"],
                });
              });

              event["td_table_"] = inforeleves;
              event["tr_table_"] = ["bonsVentes", "montantReglement"];

              event["_id_"] = element.id;
              event["start"] = element.date;
              event["end"] = element.date;

              event["comptesbancairesbon_"] = element.comptesbancairesbon;
              event["comptesbancairesbon_id_"] = element.comptesbancairesbon_id;

              event["color"] ="teal darken-3"
               // util["color"]["valueColor"][element.comptesbancairesbon_id];

              event["controller_root_"] = this.controller_root_1;
              event["client_"] = element["client"];
              event["recettesORdepenses_"] = "recettes";
              event["modePaiement_"] = element["modePaiement"];
              event["categoriesrecettesORdepenses_"] =
                element["categoriesrecettes"];

              event["montantTtc_"] = element["montant"].toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 2,
                }
              );
              event["name"] = element["user"] + " " + element["toString"];

              this.eventsAll.push(event);

              this.comptesbancairesbonItems.push({
                text: element.comptesbancairesbon,
                value: element.comptesbancairesbon_id,
              });
              // clone array
            });

            ////////////////////////////////////////////

            let path2 = paths[this.controller_root_2];
            axios
              .get(this.Base_url_web + path2, { params: params })
              .then((response) => {
                this.loading = false;

                let data = response.data["dataSet"];
                console.log(data);

                data.forEach((element) => {
                  let event = {};

                  let releves = element["releveDepensesbons"]["dataSet"];
                  let inforeleves = [];
                  let i = 0;
                  releves.forEach((releve) => {
                    i++;
                    inforeleves.push({
                      key: i,
                      a: releve["BonsAchats"],
                      b: releve["montantReglement"],
                    });
                  });

                  event["td_table_"] = inforeleves;
                  event["tr_table_"] = ["BonsAchats", "montantReglement"];

                  event["_id_"] = element.id;
                  event["start"] = element.date;
                  event["end"] = element.date;

                  event["comptesbancairesbon_"] = element.comptesbancairesbon;
                  event["comptesbancairesbon_id_"] =
                    element.comptesbancairesbon_id;

                  event["color"] ="deep-orange"
                    //util["color"]["valueColor"][element.comptesbancairesbon_id];

                  event["controller_root_"] = this.controller_root_2;
                  event["client_"] = element["fournisseur"];
                  event["recettesORdepenses_"] = "depenses";
                  event["modePaiement_"] = element["modePaiement"];
                  event["categoriesrecettesORdepenses_"] =
                    element["categoriesdepenses"];

                  event["montantTtc_"] = -1 * element["montant"];
                  event["name"] = element["user"] + " " + element["toString"];

                  this.eventsAll.push(event);

                  this.comptesbancairesbonItems.push({
                    text: element.comptesbancairesbon,
                    value: element.comptesbancairesbon_id,
                  });
                  // clone array
                });

                this.events = [...this.eventsAll];

                this.calcul_total_ttc_();
              })
              .catch((error) => {
                console.error(error);
              });

            ///////////////////////////////////////////////
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },

    showItem(id, controller_root) {
      this.$router.push({
        name: controller_root + "_show_item",
        params: { id: id },
      });
    },
    isGranted(attribute, controller_root) {
      return this.$store.getters["security/isGranted"](
        attribute,
        controller_root
      );
    },
    start() {
      this.count(this.paths_statistiques);
    },
  },

  watch: {
    paths_statistiques() {
      this.start();
    },
  },
  computed: {
    Base_url_web() {
      return this.$store.getters["get_Base_url"];
    },
    paths_statistiques() {
      return this.$store.getters["get_paths"];
    },
  },
};
</script>
<style >
.v-calendar-daily__scroll-area {
  display: none;
}
</style>