import Vue from "vue";
import VueRouter from "vue-router";
//import routes from "./routes"
Vue.use(VueRouter);




//import notfound from "../views/404.vue";



import dashboard from "../views/dashboard.vue";



import bonsachats from "../views/Achats/bonsachats/routes";



import categorieclient from "../views/CRM/categorieclient/routes";
import client from "../views/CRM/client/routes";
import contactsfournisseur from "../views/CRM/contactsfournisseur/routes";
import fournisseur from "../views/CRM/fournisseur/routes";


import eventcheff from "../views/Event/eventcheff/routes";


import categories from "../views/Menu/categories/routes";
import extras from "../views/Menu/extras/routes";
import personnalisez from "../views/Menu/personnalisez/routes";

import recette from "../views/Menu/recette/routes";
import categorieplat from "../views/Menu/categorieplat/routes";
import categoriesproduit from "../views/Menu/categoriesproduit/routes";
import plat from "../views/Menu/plat/routes";


import modepaiement from "../views/Reglages/modepaiement/routes";
import pays from "../views/Reglages/pays/routes";
import regions from "../views/Reglages/regions/routes";
import tags from "../views/Reglages/tags/routes";
import optiongroup from "../views/Reglages/optiongroup/routes";
import ville from "../views/Reglages/ville/routes";


import restaurant from "../views/Restaurant/restaurant/routes";


import user from "../views/Security/user/routes";
import gestion_files from "../views/Security/gestion_files/routes";

import commandes from "../views/Ventes/commandes/routes";
import facturesventes from "../views/Ventes/facturesventes/routes";



//const notfound = () => import("../views/404.vue");
import produits from "../views/stocks/produits/routes";
import mouvementsstock from "../views/stocks/mouvementsstock/routes";
import perte from "../views/stocks/perte/routes";
import inventaire from "../views/stocks/inventaire/routes";


import Files from "../views/Files/routes";
import BackupDB from "../views/BackupDB/routes";


import notfound from "../views/404.vue";
//import dashboard from "../views/dashboard.vue";
import infopage from "../views/infopage.vue";
import pdf from "../views/pdf.vue";
import email from "../views/email.vue";
import print from "../views/print.vue";
import i18n from "../views/i18n.vue";
import importdata from "../views/importdata.vue";
import login from "../views/login.vue";
import access_denied from "../views/access_denied.vue";
import oauth from "../views/oauth.vue";





let mainA = [
  {
    path: "/importdata/:controller([0-9a-z]+)",
    component: importdata,
    name: "importdata",
  },

  {
    path: "/pdf/:controller([0-9a-z]+)",
    component: pdf,
    name: "pdf",
  },
  {
    path: "/email/:controller([0-9a-z]+)/:id([0-9]+)",
    component: email,
    name: "email",
  },
  {
    path: "/print/:controller([0-9a-z]+)/:id([0-9]+)",
    component: print,
    name: "print",
  },
  {
    path: "/login",
    component: login,
    name: "login",
  },
  {
    path: "/accessdenied",
    component: login,
    name: "accessdenied",
  },
  {
    path: "/infopage",
    component: infopage,
    name: "_infopage",
  },
  {
    path: "/i18n",
    component: i18n,
    name: "_i18n",
  },

  {
    path: "/oauth",
    component: oauth,
    name: "_oauth",
  },


  {
    path: "/*",
    component: notfound,
    name: "notfound",
  },
];





let main = [
 

  {
    path: "/",
    component: dashboard,
    name: "_dashboard"
  },
  {
    path: "/",
    component: dashboard,
    name: "_dashboard"
  },
 
 
  
];

export default new VueRouter({
  mode: "history",
  routes: []
    .concat(bonsachats)
    .concat(categorieclient, client, contactsfournisseur, fournisseur)
    .concat(eventcheff)
    .concat(categories,extras,personnalisez, produits,mouvementsstock,perte,inventaire, recette, categorieplat,categoriesproduit, plat)

    .concat(modepaiement, pays, regions, tags,optiongroup, ville)
    .concat(restaurant)
    .concat(user, gestion_files)
    .concat(commandes, facturesventes)
    
    .concat(main)
    .concat(Files, BackupDB, mainA)
});
