<template>
  <div>
    <v-list-group
      no-action
      v-for="(
        controller_multiselect, index_multiselect
      ) in controllers_multiselect"
      :key="'B' + index_multiselect"
      v-show="connecte && $route.name != '_dashboard'"
    >
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>
            <h5 color="primary">{{ $t(controller_multiselect) }}</h5>
          </v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item
        v-for="(header_multiselect, key_multiselect) in headers_multiselect(
          controller_multiselect
        )"
        :key="'A' + key_multiselect"
      >
        <v-checkbox
          v-if="header_multiselect.text !== 'ID'"
          :value="header_multiselect.value"
          :label="header_multiselect.text"
          @change="change_multiselect($event, controller_multiselect)"
          :input-value="not_affiches_multiselect(controller_multiselect)"
          off-icon="mdi-eye"
          on-icon="mdi-eye-off"
          color="red darken-3"
        >
          <template v-slot:label>
            <span class="awacolor">{{ header_multiselect.text }}</span>
          </template>
        </v-checkbox>
        <span v-else> <v-icon>mdi-crosshairs-gps</v-icon>ID </span>
      </v-list-item>
    </v-list-group>
  </div>
</template>


<script>
import router from "@/router";

export default {
  methods: {
    not_affiches_multiselect(controller) {
      let not_affiches = [];
      not_affiches = this.$store.getters["structure/multiselect/g_not_affiche"](
        {
          controller_multiselect: controller,
        }
      );

      return not_affiches;
    },

    headers_multiselect(controller) {
      // let name_input_files = [];
      // let shema_inputs = [];

      // let expanded = this.$store.getters["structure/multiselect/g_expanded"]({
      //   controller_root: controller,
      // });

      // let namesexpanded = [];
      // for (const key in expanded) {
      //   namesexpanded.push(expanded[key]["controller_attach"]);
      // }

      return this.$store.getters["structure/multiselect/g_headers"]({
        controller_multiselect: controller,
        namesexpanded: [],
        notaffiche: [],
        name_input_files: [],
      });
    },

    change_multiselect(selected, controller) {
      this.$store.commit("structure/multiselect/set_not_affiches", {
        data: selected,
        controller_multiselect: controller,
      });
    },
  },

  computed: {
    connecte() {
      return this.$store.getters["security/connecte"];
    },

    controllers_multiselect() {
      let names = [];
      return this.$store.state.structure.multiselect
        .namescontroller_multiselect;

      for (const key in controllers) {
        const element = controllers[key];
        names.push(element);
      }
      return names;
    },
  },
};
</script>




<style>
</style>