<template>
  <v-col cols="12" sm="6" md="4">
    <v-combobox
      :label="label"
       v-model="value"
      @change="modelbind($event)"
      :items="items"
      item-text="text"
      item-value="value"
      :single-line="singleline"
      :disabled="disabled"
      :filter="filter"
      hide-selected
    >
    </v-combobox>
  </v-col>
</template>
<script>
import InputMixin from "./InputMixin";
export default {
  mixins: [InputMixin],
    data() {
    return {
      value: undefined,

     
    };
  },

  methods: {
    modelbind($event) {
      let value;

      if ($event != undefined) {
        value = $event["value"];
      }
      this.$store.dispatch("structure/addlist/input_change", {
        name: this.name,
        value: value,
        input_schema: this.schema,
        controller_listsAdd: this.controller_listsAdd,
        indexrows: this.indexrows,
        indexrow: this.indexrow,
      });
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      let res =
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1;

      return res;
    },
  },

  computed: {
    items() {
      let data = this.$store.getters["structure/addlist/data_Select_load"][
        this.controller_listsAdd
      ][this.from];

      let items = [];

      for (const key in data) {
        let option = {};
        const element = data[key];
      if (typeof this.to_string == "string") {
   option.text = element[this.to_string];
  
} else {




      let txt=
                
                
                  element[this.to_string[0]] +
                  ' (' +element[this.to_string[1]] +')'

                  if (element[this.to_string[2]]!=undefined&&element[this.to_string[2]]!=""&&element[this.to_string[2]]!=null) {
                    txt=txt+' / '+ element[this.to_string[2]]+"dh"
                  }


option.text=txt

  
}
        option.value = element["@id"];
           if (option.value == this.values_binds) {
          this.value = option;
        }
        items.push(option);
      }

      return items;
    },
  },
};
</script>