<template>
  <div id="pageDashboard">
    <v-container grid-list-xl fluid>
      <v-row class="justify-start">
      
       <v-col cols="11">
          <div v-html="info_organisation.pageInfo"></div>
       </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Material from "vuetify/es5/util/colors";
import interact from "interactjs";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  layout: "info page",

  data: () => ({
    editor: ClassicEditor,
    editorData: "<p>Content of the editor.</p>",
    editorConfig: {
      // The configuration of the editor.
    },
  }),
  computed: {
    info_organisation() {
      return this.$store.getters["theme/organisation"];
    },
  },
};
</script>

