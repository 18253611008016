import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const widgets = '@/widgets/'
const listcompontesGroup = [
  'Achats',
  'CRM',
  'Produit',
  'Reglages',
  'Transactions',
  'Ventes',
]
/*
listcompontesGroup.forEach((Group) => {
  const requireComponent = require.context(widgets + Group, true, /\.vue$/);
  const componentsNames = [];

  requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName);

    const componentName = upperFirst(
      camelCase(fileName.replace(/^\.\//, "").replace(/\.\w+$/, ""))
    );
    componentsNames.push(componentName);

    Vue.component(componentName, componentConfig.default || componentConfig);
  });
});*/

const requireComponent = require.context('@/widgets', true, /\.vue$/)
const componentsNames = []

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName)

  const componentName = upperFirst(
    camelCase(
      fileName
        .split('/')[2]
        .replace(/^\.\//, '')
        .replace(/\.\w+$/, ''),
    ),
  )
  componentsNames.push(componentName)

  Vue.component(componentName, componentConfig.default || componentConfig)
})

export default componentsNames
