//let colorconsole="color: red"
let colorconsole="color: gray"
let colorconsoleyellow="color: magenta"
//let colorconsole="color: blue"
//let colorconsole="color: magenta"
//let colorconsole="color: cyan"
//let colorconsole="color: gray"
//let colorconsole="color: grey"



export default {
  get_data_Select_load: (state) => () => {
    return state.data_Select_load;
  },
  get_Data_Info_Lot: (state) => () => {
    return state.Data_Info_Lot;
  },
};
