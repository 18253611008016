<template>
  <v-form ref="form" v-model="valid">
     <v-row class="justify-center">
      <v-col cols="12"
        ><btn-action
          :controller_root="controller_root"
          :item="{ id: id }"
        ></btn-action
      ></v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col :cols="12" :lg="6">
        <card-box
          id="groupa1"
          color="orange"
          :title="$t('show_tags_infoplat_1')"
          :text="$t('show_tags_infoplat_2')"
        >
          <template v-slot:chart> </template>

          <show-default :controller_root="controller_root" />
        </card-box>
      </v-col>
     
    </v-row>
  </v-form>
</template>

<script>
import i18n from "@/plugins/i18n";
import { mapGetters } from "vuex";
import ViewMixin from "@/views/show_item";

export default {
  mixins: [ViewMixin],

  props: {},
  data() {
    return {
      controller_root: "tags",
      namemodule: "CRM",
      lists_Add: [
        {
          controller_listsAdd: "tagsrecettes",
        },
      ],
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  created() {
    document.title = i18n.t("show_item_" + this.controller_root);
    this.initia();
    this.run();
  },

  methods: {
    isGranted(controller) {
      return this.$store.getters["security/isGranted"]("view", controller);
    },
  },
};
</script>
