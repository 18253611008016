<template>
  <v-fade-transition>
    <v-hover transition="fade-transition" v-slot:default="{ hover }" :id="id">
      <v-card
        v-bind="$attrs"
        :style="styles"
        v-on="$listeners"
        :elevation="hover ? 5 : 2"
      >
        <helper-offset
          v-if="hasOffset"
          :full-width="fullWidth"
          :offset="offset"
        >
          <v-card
            v-if="!$slots.offset"
            :color="v_color"
            :class="`elevation-${elevation}  v-card--material__header `"
            dark
          >
            <slot name="header" v-if="!title && !text" />

            <template v-else>
              <v-row class="ma-0 pa-0">
                <v-col cols="10">
                  <v-tooltip top color="blue" v-if="text" max-width="460">
                    <template v-slot:activator="{ on }">
                      <strong
                        class="headline font-weight-medium mb-2"
                        v-on="on"
                        >{{ title }}</strong
                      >
                    </template>
                    <span class="subtitle-2 font-weight-thin">{{ text }}</span>
                  </v-tooltip>

                  <strong class="headline font-weight-medium mb-2" v-else>{{
                    title
                  }}</strong>
                </v-col>

                <v-spacer />

                <v-col cols="1" class="pa-0" align-end justify-end>
                  <v-btn icon class="pa-0" style="margin-left: -10px">
                    <v-icon @click="open()" color>mdi-view-carousel</v-icon>
                  </v-btn>

                  <v-btn icon class="pa-0" style="margin-left: -10px">
                    <v-icon @click="open_color()" color>mdi-palette</v-icon>
                  </v-btn>

                  <v-btn
                    icon
                    class="pa-0"
                    style="margin-left: -10px"
                    v-if="$slots.chart"
                  >
                    <v-icon @click="open_chart()" color
                      >mdi-chart-line-variant</v-icon
                    >
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-card>
        </helper-offset>

        <div :id="'card_show-' + id">
          <v-card-text :id="'slot-' + id">
            <slot />
          </v-card-text>
        </div>

        <v-divider v-if="$slots.actions" class="mx-3" />

        <v-card-actions v-if="$slots.actions">
          <slot name="actions" />
        </v-card-actions>

        <!-- dialog -->

        <div class="justify-center">
          <v-dialog
            v-model="dialog_slot_default"
            :eager="true"
            :attach="'#' + id"
          >
            <v-card>
              <v-card-title class="headline " primary-title >
                {{ title }}
                <v-spacer></v-spacer>
                <v-btn
                  color="orange"
                  text
                  @click="dialog_slot_default = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text :id="'dialog_show-' + id"> </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn
                  color="orange"
                  text
                  @click="dialog_slot_default = false"
                >
                  <v-icon>mdi-hand-okay</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialog_slot_color" :eager="true" max-width="450">
            <v-card>
              <v-row justify="space-around" align="center">
                <v-color-picker
                  class="ma-2"
                  show-swatches
                  dot-size="30"
                  v-model="v_color"
                ></v-color-picker>
              </v-row>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialog_slot_chart" :eager="true">
            <v-card>
              <v-card-title primary-title>
                <v-spacer></v-spacer>
                <v-btn  @click="dialog_slot_chart = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>

              <v-spacer></v-spacer>

              <v-row>
                <v-col cols="12" v-if="dialog_slot_chart">
                  <slot name="chart" />
                </v-col>
              </v-row>
            </v-card>
          </v-dialog>
        </div>
      </v-card>
    </v-hover>
  </v-fade-transition>
</template>

<script>
export default {
  inheritAttrs: false,
  watch: {
    dialog_slot_default(val) {
      let slot = "slot-" + this.id;
      let card_show = "card_show-" + this.id;
      let dialog_show = "dialog_show-" + this.id;

      if (!val) {
        let s = document.getElementById(slot);
        let show = document.getElementById(card_show);

        show.appendChild(s);
      } else {
        let s = document.getElementById(slot);
        let show = document.getElementById(dialog_show);

        show.appendChild(s);
      }
    },
    v_color(val) {
      localStorage.setItem(this.idcolor, this.v_color);
    },
  },
  data() {
    return {
      dialog_slot_default: false,
      dialog_slot_chart: false,
      dialog_slot_color: false,
      v_color: "",
    };
  },
  methods: {
    open() {
      this.dialog_slot_default = !this.dialog_slot_default;
    },

    open_color() {
      this.dialog_slot_color = !this.dialog_slot_color;
    },
    open_chart() {
      this.dialog_slot_chart = !this.dialog_slot_chart;
    },
  },

  props: {
    id: { type: String, required: true },
    color: {
      type: String,
      default: "orange",
    },
    elevation: {
      type: [Number, String],
      default: 4,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    offset: {
      type: [Number, String], // la marge
      default: 24,
    },
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: undefined,
    },
  },

  mounted() {
    let c = localStorage.getItem(this.idcolor, "#FF8F00FF");
    if (c == null) {
      c = "#FF8F00FF";
    }
    this.v_color = c;
  },
  computed: {
    idcolor() {
      return (this.title + this.id + this.id_user)
        .split("")
        .filter((e) => e.trim().length)
        .join("A");
    },

    id_user() {
      return this.$store.getters["security/user"]["id"];
    },

    hasOffset() {
      return (
        this.$slots.header || this.$slots.offset || this.title || this.text
      );
    },
    styles() {
      if (!this.hasOffset) return null;

      return {
        marginBottom: `${this.offset}px`,
        marginTop: `${this.offset * 2}px`,
      };
    },
  },
};
</script>




<style lang="scss">
.v-card--theme {
  &__header {
    &.v-card {
      border-radius: 4px;
    }
  }
}
</style>
