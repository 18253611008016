<template>
  <v-form ref="form" v-model="valid">
    <v-row class="justify-center">
      <v-col cols="12" lg="5">
        <card-box
          id="a1"
          icon="mdi-file-star-outline"
          :title="$t('update_plat_infoplat_1')"
          color="success"
        >
          <box-root
            :input_root="input_root"
            :controller_root="controller_root"
          />
          <box-inputs
            :names="['name', 'mindescription', 'description']"
            :controller_root="controller_root"
          />

          <v-row class="justify-center">
            <v-col cols="12" lg="6">
              <box-inputs
                :names="['rating', 'position', 'recommander']"
                :controller_root="controller_root"
              />
            </v-col>
            <v-col :cols="12" :lg="6">
              <box-inputs
                :names="['logo']"
                :controller_root="controller_root"
              />
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="3">
              <box-inputs :names="['cal']" :controller_root="controller_root" />
            </v-col>
            <v-col cols="3">
              <box-inputs
                :names="['protein']"
                :controller_root="controller_root"
              />
            </v-col>
            <v-col cols="3">
              <box-inputs :names="['fat']" :controller_root="controller_root" />
            </v-col>
            <v-col cols="3">
              <box-inputs
                :names="['carbs']"
                :controller_root="controller_root"
              />
            </v-col>
          </v-row>
        </card-box>
      </v-col>
      <v-col cols="12" lg="7" v-show="val_root() != ''">
        <card-box
          id="addlist2"
          icon="mdi-file-star-outline"
          :title="$t('update_plat_infoplat_3')"
          color="success"
        >
          <addlist-table controller_listsAdd="platrecettes" />
         
        </card-box>
         
        <card-box
          id="groupa1"
          color="orange"
          :title="$t('update_plat_infoplat_4')"
          :text="$t('update_plat_box_text_4')"
        >
          <template v-slot:chart> </template>

          <v-row class="justify-center">
            <v-col cols="12" lg="4" v-show="val_root() != ''">
              <box-inputs
                :names="['prix', 'remise', 'active']"
                :controller_root="controller_root"
              />
            </v-col>
            <v-col :cols="12" :lg="8">
             <addlist-table controller_listsAdd="platoptiongroups" />
            </v-col>
          </v-row>
        </card-box>
      </v-col>

    

   

      <v-col :cols="12" :lg="4" v-show="val_root() != ''">
        <card-box id="default">
          <btn-send :controller_root="controller_root" :valid="valid" />
        </card-box>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import i18n from "@/plugins/i18n";
import { mapGetters } from "vuex";
import ViewMixin from "@/views/update";

export default {
  mixins: [ViewMixin],

  props: {},
  data() {
    return {
      controller_root: "plat",
      input_root: "categorieplat",
      namemodule: "CRM",
      lists_Add: [
        {
          controller_listsAdd: "platrecettes",
          filter: { isracine: 0 },
        },
        {
          controller_listsAdd: "platoptiongroups",
        },
      ],
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  created() {
    document.title = i18n.t("update_" + this.controller_root);
    this.initia();
    this.run();
  },
};
</script>
