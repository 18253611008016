<template>
  <card-card
    icon="mdi-wallet-travel"
    title="Dettes / Créances "
    class="px-5 py-3 d-inline-block mx-auto"
    color="orange"
     :close="close"
    @close="closeevent"
  >
    <v-card-text>
      <v-row>

        <v-col cols="12" lg="12">

          <v-tabs v-model="selectedTab" @change="handleTabChange">
            <v-tab href="#tab-1">Graphe</v-tab>
            <v-tab href="#tab-2">Factures clients impayées</v-tab>
            <v-tab href="#tab-3">Factures fournisseurs impayées </v-tab>
            <v-tabs-items v-model="selectedTab">
              <v-tab-item value="tab-1">
                <v-row>
                  <v-col cols="12">
                    <v-card>
                      <v-responsive width="100%" style="margin-bottom: 38px">
                        <component
                          v-if="showchart"
                          :is="chart"
                          :path-option="option"
                          height="600px"
                          width="100%"
                        ></component>

                        <!-- <plugins-chart
              :path-option="option"
              height="600px"
              width="100%"
            ></plugins-chart> -->
                      </v-responsive>

                      <v-card-text class="pt-0">
                        <v-divider class="my-2"></v-divider>
                        <v-icon class="mr-2" small>mdi-clock</v-icon>
                        <span class="caption grey--text font-weight-light">{{
                          new Date().toLocaleString()
                        }}</span>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item value="tab-2">
                <v-row>
                  <v-col cols="12">
                    <widgets-listFactureImpeyeClient />
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item value="tab-3">
                <v-row>
                  <v-col cols="12">
                    <widgets-listFactureImpeyeFournisseur />
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>

        </v-col>

        <v-col cols="12" lg="6">
          <card-stats
            color="orange"
            icon="mdi-cash-minus"
            title="Dettes TTC"
            :value="
              Dettes.toLocaleString(undefined, { minimumFractionDigits: 2 })
            "
            smallValue="DH"
            sub-icon="mdi-tag"
            :sub-text="
              'les Factures achats: ' +
              DettesFactures.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              }) +
              ' / les Factures avoirs achats:' +
              DettesAvoirs.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              }) +
              ' DH'
            "
            tooltip="Les dettes fournisseurs correspondent donc à des achats effectués par l'entreprise auprès de ses fournisseurs, non encore réglés à la date d'établissement du bilan. En effet, il existe différents délais de paiement applicables entre professionnels"
          />
        </v-col>

        <v-col cols="12" lg="6">
          <card-stats
            color="primary"
            icon="mdi-cash-plus"
            title="Créances TTC"
            :value="
              Creances.toLocaleString(undefined, { minimumFractionDigits: 2 })
            "
            smallValue="DH"
            sub-icon="mdi-tag"
            :sub-text="
              'factures ventes: ' +
              CreancesFactures.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              }) +
              ' / factures avoirs ventes:' +
              CreancesAvoirs.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              }) +
              ' DH'
            "
            tooltip="Les créances clients: calcul et définition Une créance étant une dette, les créances clients sont les sommes facturées par une entreprise, à une personne morale ou physique (pour la vente de marchandises ou de services), mais qui n’ont pas été perçues. En d'autres termes, le montant total des factures impayées constitue les créances clients."
          />
        </v-col>
      </v-row>
    </v-card-text>
  </card-card>
</template>


<script>
import colors from "vuetify/es5/util/colors";
import axios from "axios";


import widgetsMixin from "./../widgetsMixin";
export default {
  mixins: [widgetsMixin],
  data: () => ({
    chart: "",
    isMobile: false,

    showchart: false,
    selectedTab: "tab-1",
    Dettes: 0,
    DettesFactures: 0,
    DettesAvoirs: 0,

    Creances: 0,
    CreancesFactures: 0,
    CreancesAvoirs: 0,

    NBDettesFactures: 0,
    NBDettesAvoirs: 0,

    NBCreances: 0,
    NBCreancesFactures: 0,

    clients_impeye: [],
    fournisseur_impeye: [],
  }),
    computed: {

Base_url_web() {
      return this.$store.getters["get_Base_url"];
    },
    yAxisdata1() {
      let data = [];
      this.clients_impeye.forEach((element) => {
        data.push(element["client"].toLowerCase());
      });

      return data;
    },
    yAxisdata2() {
      let data = [];
      this.fournisseur_impeye.forEach((element) => {
        data.push(element["fournisseur"].toLowerCase());
      });

      return data;
    },

    seriesdata1() {
      let data = [];
      this.clients_impeye.forEach((element) => {
        data.push(element["totalTtc"] - element["dejaregleTTC"]);
      });
      return data;
    },

    seriesdata2() {
      let data = [];
      this.fournisseur_impeye.forEach((element) => {
        data.push(element["totalTtc"] - element["dejaregleTTC"]);
      });
      return data;
    },

    Maxseriesdata1() {
      let dataTTC = 0;
      let datadejaregle = 0;

      this.clients_impeye.forEach((element) => {
        if (element["totalTtc"] > dataTTC) {
          dataTTC = element["totalTtc"] * 1;
        }
        if (element["dejaregleTTC"] > datadejaregle) {
          datadejaregle = element["dejaregleTTC"] * 1;
        }
      });

      return dataTTC;
    },
    Maxseriesdata2() {
      let dataTTC = 0;
      let datadejaregle = 0;

      this.fournisseur_impeye.forEach((element) => {
        if (element["totalTtc"] > dataTTC) {
          dataTTC = element["totalTtc"] * 1;
        }
        if (element["dejaregleTTC"] > datadejaregle) {
          datadejaregle = element["dejaregleTTC"] * 1;
        }
      });

      return dataTTC;
    },

    nombreFacture() {
      let NFclient = 0;
      this.clients_impeye.forEach((element) => {
        NFclient = NFclient + element["countfacture"];
      });
      let NFfournisseur = 0;
      this.fournisseur_impeye.forEach((element) => {
        NFfournisseur = NFfournisseur + element["countfacture"];
      });

      return " : Ventes (" + NFclient + ") / Achats (" + NFfournisseur + ")";
    },

    option() {
      let option = {
        toolbox: {
          show: true,
          feature: {
            mark: { show: false },
            dataView: { show: false, readOnly: false },
            restore: { title: "Save", show: false },
            magicType: {
              title: "line/bare",
              show: true,
              type: ["line", "bar"],
            },

            saveAsImage: { title: "Save", show: true },
          },
        },
        calculable: true,
        dataZoom: [
          {
            id: "dataZoomY0",
            type: "slider",
            yAxisIndex: [0],
            backgroundColor: "#eee",
            left: this.isMobile ? "95%" : "51%",
            startValue: 0,
            endValue: 9,
            filterMode: "empty",
          },

          {
            id: "dataZoomY1",
            type: "slider",
            yAxisIndex: [1],
            backgroundColor: "#eee",
            left: this.isMobile ? "95%" : "51%",
            startValue: 0,
            endValue: 9,
            filterMode: "empty",
          },
        ],
        color: [
          colors.amber.base,
          colors.indigo.base,
          colors.pink.base,
          colors.green.base,
          colors.teal.base,
          colors.purple.base,
        ],

        tooltip: {
          axisPointer: {
            type: "shadow",
          },

          trigger: "item",
          formatter: (params) => {
            if (params["seriesType"] == "pie") {
              return (
                params.name +
                "<br>" +
                params.value.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                }) +
                " DH<br>" +
                params.percent +
                " %"
              );
            } else {
              let dataIndex = params["dataIndex"];
              switch (params["seriesIndex"]) {
                case 0:
                  // data: this.seriesdata1
                  //this.clients_impeye

                  return this.tooltipClient(dataIndex);

                  break;
                case 1:
                  // data: this.seriesdata2
                  return this.tooltipClient(dataIndex);
                  break;

                case 2:
                  // data: this.seriesdata3
                  return this.tooltipFournisseur(dataIndex);
                  break;
                case 3:
                  // data: this.seriesdata4
                  return this.tooltipFournisseur(dataIndex);
                  break;

                default:
                  break;
              }

              console.log(params);
            }

            // let o={seriesIndex,dataIndex,name,data,value,seriesType}
          },
        },

        title: [
          {
            text: "les Factures impayées",
            subtext: "Nomber factures " + this.nombreFacture,
            left: "25%",
            textAlign: "center",
          },
          {
            text: "Créances TTC",
            subtext:
              "Somme " +
              this.Creances.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              }) +
              " DH",

            left: "75%",
            textAlign: "center",
            show: !this.isMobile,
          },
          {
            text: "Dettes TTC",
            subtext:
              "Somme " +
              this.Dettes.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              }) +
              " DH",
            left: "75%",
            top: "50%",
            textAlign: "center",
            show: !this.isMobile,
          },
        ],
        grid: [
          {
            top: 50,
            width: this.isMobile ? "100%" : "50%",
            bottom: "45%",
            left: 10,
            containLabel: true,
          },
          {
            top: "57%",
            width: this.isMobile ? "100%" : "50%",
            bottom: 0,
            left: 10,
            containLabel: true,
          },
        ],
        xAxis: [
          {
            type: "value",
            max: this.Maxseriesdata1,
            splitLine: {
              show: false,
            },
            axisLabel: {
              formatter: function (a) {},
            },
          },
          {
            type: "value",
            max: this.Maxseriesdata2,
            gridIndex: 1,
            splitLine: {
              show: false,
            },
            axisLabel: {
              formatter: function (a) {},
            },
          },
        ],

        yAxis: [
          {
            type: "category",
            data: this.yAxisdata1,
            axisLabel: {
              interval: 0,
              rotate: 30,
              formatter: function (a) {
                if (a.length > 10) {
                  return a.substr(0, 10) + "...";
                }

                return a;
              },
            },
            splitLine: {
              show: false,
            },
          },
          {
            gridIndex: 1,
            type: "category",
            data: this.yAxisdata2,
            axisLabel: {
              interval: 0,
              rotate: 30,
              formatter: function (a) {
                if (a.length > 10) {
                  return a.substr(0, 10) + "...";
                }

                return a;
              },
            },
            splitLine: {
              show: false,
            },
          },
        ],

        series: this.isMobile
          ? [
              {
                type: "bar",
                stack: "chart",
                z: 5,
                itemStyle: {
                  color: colors.indigo.base,
                },
                label: {
                  position: "right",
                  show: false,
                },
                data: this.seriesdata1,
              },

              {
                type: "bar",
                stack: "chart",
                silent: false,
                itemStyle: {
                  color: "#eee",
                },

                data: Object.keys(this.seriesdata1).map((key) => {
                  return this.Maxseriesdata1 - this.seriesdata1[key];
                }),
              },

              {
                type: "bar",
                stack: "component",
                xAxisIndex: 1,
                yAxisIndex: 1,
                z: 3,
                itemStyle: {
                  color: colors.orange.base,
                },
                label: {
                  position: "right",
                  show: false,
                },
                data: this.seriesdata2,
              },

              {
                type: "bar",
                stack: "component",
                silent: false,
                xAxisIndex: 1,
                yAxisIndex: 1,
                itemStyle: {
                  color: "#eee",
                },
                data: Object.keys(this.seriesdata2).map((key) => {
                  return this.Maxseriesdata2 - this.seriesdata2[key];
                }),
              },
            ]
          : [
              {
                type: "bar",
                stack: "chart",
                z: 5,
                itemStyle: {
                  color: colors.indigo.base,
                },
                label: {
                  position: "right",
                  show: false,
                },
                data: this.seriesdata1,
              },

              {
                type: "bar",
                stack: "chart",
                silent: false,
                itemStyle: {
                  color: "#eee",
                },

                data: Object.keys(this.seriesdata1).map((key) => {
                  return this.Maxseriesdata1 - this.seriesdata1[key];
                }),
              },

              {
                type: "bar",
                stack: "component",
                xAxisIndex: 1,
                yAxisIndex: 1,
                z: 3,
                itemStyle: {
                  color: colors.orange.base,
                },
                label: {
                  position: "right",
                  show: false,
                },
                data: this.seriesdata2,
              },

              {
                type: "bar",
                stack: "component",
                silent: false,
                xAxisIndex: 1,
                yAxisIndex: 1,
                itemStyle: {
                  color: "#eee",
                },
                data: Object.keys(this.seriesdata2).map((key) => {
                  return this.Maxseriesdata2 - this.seriesdata2[key];
                }),
              },

              {
                type: "pie",
                radius: ["22%", "30%"],
                center: ["80%", "30%"],
                itemStyle: {
                  borderRadius: 10,
                  borderColor: "#fff",
                  borderWidth: 2,
                },
                label: {
                  show: true,
                  position: "left",
                },

                labelLine: {
                  show: true,
                },
                data: [
                  {
                    value: this.CreancesFactures,
                    name: "factures ventes",
                    itemStyle: {
                      color: colors.blue.base,
                    },
                  },
                  {
                    value: this.CreancesAvoirs * -1,
                    name: "factures avoirs ventes",
                    itemStyle: {
                      color: colors.indigo.base,
                    },
                  },
                ],
                show: !this.isMobile,
              },

              {
                type: "pie",
                radius: ["22%", "30%"],
                center: ["80%", "81%"],
                itemStyle: {
                  borderRadius: 10,
                  borderColor: "#fff",
                  borderWidth: 2,
                },
                label: {
                  show: true,
                  position: "left",
                },

                labelLine: {
                  show: true,
                },
                data: [
                  {
                    value: this.DettesFactures,
                    name: "Factures achats",
                    itemStyle: {
                      color: colors.pink.base,
                    },
                  },
                  {
                    value: this.DettesAvoirs * -1,
                    name: "Factures avoirs achats",
                    itemStyle: {
                      color: colors.orange.base,
                    },
                  },
                ],
                show: !this.isMobile,
              },
            ],
      };

      return option;
    },
  },
  methods: {

    start() {
      let path = "/apidashboardcreancesdettes";

      axios
        .get(this.Base_url_web+path, { params: {} })
        .then((response) => {
          let data = response.data;
          this.Dettes = data.Dettes;
          this.DettesFactures = data.DettesFactures;
          this.DettesAvoirs = data.DettesAvoirs;

          this.NBDettesFactures = data.NBDettesFactures;
          this.NBDettesAvoirs = data.NBDettesAvoirs;

          this.Creances = data.Creances;
          this.CreancesFactures = data.CreancesFactures;
          this.CreancesAvoirs = data.CreancesAvoirs;

          this.NBCreancesFactures = data.NBCreancesFactures;
          this.NBCreancesAvoirs = data.NBCreancesAvoirs;
          
          this.clients_impeye = response.data["clientsimpeye"];
          this.fournisseur_impeye = response.data["fournisseurimpeye"];

          this.showchart = true;
          this.chart = "plugins-chart";
          window.dispatchEvent(new Event("optimizedResize"));
        })
        .catch((error) => {
          console.error("path");
        });

      window.dispatchEvent(new Event("optimizedResize"));
    },
    handleTabChange() {
      window.dispatchEvent(new Event("optimizedResize"));
    },
    tooltipClient(dataIndex) {
      let item = this.clients_impeye[dataIndex];

      let val = item["totalTtc"] - item["dejaregleTTC"];
      return (
        item["client"].toUpperCase() +
        "<br> reste  :<strong>" +
        val.toLocaleString(undefined, {
          minimumFractionDigits: 2,
        }) +
        " DH </strong><br> Totat TTC : " +
        (item["totalTtc"] * 1).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        }) +
        " DH<br> déjà réglé : " +
        (item["dejaregleTTC"] * 1).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        }) +
        " DH<br> nombre factures: <strong>" +
        item["countfacture"] +
        "</strong> <br>année : " +
        item["annee"] +
        " <br> réglé en " +
        ((item["dejaregleTTC"] / item["totalTtc"]) * 100).toLocaleString(
          undefined,
          {
            minimumFractionDigits: 2,
          }
        ) +
        " %"
      );
    },
    tooltipFournisseur(dataIndex) {
      let item = this.fournisseur_impeye[dataIndex];

      let val = item["totalTtc"] - item["dejaregleTTC"];
      return (
        item["fournisseur"].toUpperCase() +
        "<br> reste  :<strong>" +
        val.toLocaleString(undefined, {
          minimumFractionDigits: 2,
        }) +
        " DH </strong><br> Totat TTC : " +
        (item["totalTtc"] * 1).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        }) +
        " DH<br> déjà réglé : " +
        (item["dejaregleTTC"] * 1).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        }) +
        " DH<br> nombre factures: <strong>" +
        item["countfacture"] +
        "</strong> <br>année : " +
        item["annee"] +
        " <br> réglé en " +
        ((item["dejaregleTTC"] / item["totalTtc"]) * 100).toLocaleString(
          undefined,
          {
            minimumFractionDigits: 2,
          }
        ) +
        " %"
      );
    },
  },

  mounted() {
    this.start();
    this.isMobile = window.innerWidth < 600;
  },
  watch: {},
};
</script>