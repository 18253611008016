<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="DateFormatted + ss"
        :label="label"
        prepend-icon="mdi-calendar-range"
        readonly
        v-on="on"
         @focus="init_validation()"
    :rules="rules"
      ></v-text-field>
    </template>

    <v-date-picker
      :value="formatDateEN(values_binds)"
      @input="modelbindlocal($event)"
      :readonly="readonly"
      color="green lighten-1"
      header-color="primary"
      no-title
      scrollable
    >
      <v-btn text color="primary" @click="menu = false" v-show="!readonly"
        >ok</v-btn
      >
    </v-date-picker>
  </v-menu>
</template>
<script>
import InputMixin from "./InputMixin";
export default {
  mixins: [InputMixin],
  data: (vm) => ({
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    ss: "",
  }),

  computed: {
    DateFormatted() {
      return this.formatDateFR(this.values_binds);
    },
  },

  methods: {
    modelbindlocal(val) {
      this.modelbind(val);
      if (this.DateFormatted == "") {
        this.ss = this.formatDateFR(val);
      }
    },

    formatDateFR(inputFormat) {
      if (
        inputFormat == "" ||
        inputFormat == null ||
        inputFormat == undefined
      ) {
        return "";
      }
      function pad(s) {
        return s < 10 ? "0" + s : s;
      }
      var d = new Date(inputFormat);
      return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(
        "/"
      );
    },
    formatDateEN(inputFormat) {
      if (
        inputFormat == "" ||
        inputFormat == null ||
        inputFormat == undefined
      ) {
        return "";
      }
      function pad(s) {
        return s < 10 ? "0" + s : s;
      }
      var d = new Date(inputFormat);
      return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join(
        "-"
      );
    },
     init_validation() {
      this.rules = [this.validation.required, ];
    },
  },
};
</script>
