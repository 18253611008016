var render = function render(){var _vm=this,_c=_vm._self._c;return _c('card-card',{staticClass:"px-5 py-3",attrs:{"close":_vm.close,"icon":"mdi-chart-bar","color":"primary","title":_vm.$t('CA par client')},on:{"close":_vm.closeevent}},[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"justify-space-around align-center"},[_c('v-col',{attrs:{"cols":"5","sm":"5","md":"5"}},[_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('date'),"placeholder":"Placeholder","append-icon":"mdi-refresh"},on:{"click:append":_vm.get_List_client_CA},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('>_CA_clients'),"placeholder":"Placeholder","append-icon":"mdi-refresh"},on:{"click:append":_vm.get_List_client_CA},model:{value:(_vm.CA),callback:function ($$v) {_vm.CA=$$v},expression:"CA"}})],1),_c('v-col',{attrs:{"cols":"11","sm":"11","md":"11"}},[_c('v-autocomplete',{attrs:{"disabled":false,"multiple":true,"outlined":"","dense":"","filled":"","chips":"","label":"client","items":_vm.list_clientsCA,"item-text":"client","item-value":"id"},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected,"close":""},on:{"click":data.select,"click:close":function($event){return _vm.removeItemOnSelect(data.item)}}},'v-chip',data.attrs,false),[_c('v-avatar',{attrs:{"left":""}},[_c('readfile-img',{attrs:{"refgroup":data.item.logo}})],1),_vm._v(" "+_vm._s(data.item.client)+" ")],1)]}},{key:"item",fn:function(data){return [_c('v-list-item-avatar',[_c('readfile-img',{attrs:{"refgroup":data.item.logo}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s('<strong>' + data.item.client + '</strong>')}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(
                      '<strong>' +
                      data.item.totalTtc +
                      '</strong> DH / nombre facture ' +
                      data.item.countfacture
                    )}}),(
                      data.item.cin != null &&
                      data.item.cin != 'null' &&
                      data.item.cin != ''
                    )?_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(
                      '<strong>REF : ' +
                      data.item.ref +
                      '</strong> /  ' +
                      'CIN: ' +
                      data.item.cin
                    )}}):(
                      data.item.ice != null &&
                      data.item.ice != 'null' &&
                      data.item.ice != ''
                    )?_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(
                      '<strong>REF : ' +
                      data.item.ref +
                      '</strong> /  ' +
                      'ICE: ' +
                      data.item.ice
                    )}}):_vm._e()],1)]}}]),model:{value:(_vm.clientsCA_selected_id),callback:function ($$v) {_vm.clientsCA_selected_id=$$v},expression:"clientsCA_selected_id"}})],1)],1),_c('v-row',{staticClass:"justify-center align-center"},[_c('v-responsive',{staticStyle:{"margin-bottom":"38px"},attrs:{"width":"100%"}},[(_vm.showchart)?_c(_vm.chart,{tag:"component",attrs:{"path-option":_vm.option,"height":"450px","width":"100%"}}):_vm._e()],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }