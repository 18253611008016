import { render, staticRenderFns } from "./sendajax.vue?vue&type=template&id=2e06a4d0&"
import script from "./sendajax.vue?vue&type=script&lang=js&"
export * from "./sendajax.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports