function schemafor(controller) {
  let schemas = [];

  for (const name in controller) {
    let schema = {
      typeValidate: ["number", "integer", "string", "boolean", "null"],
      patternValidate: false,
      group_Choice: "",
      items_Group_Choice: "",
      nullable: true,
      unique: false,
      uniqueby: false,
      typeColumn: "Column",
      defaultValue: "",
      value: "",
      from: "",
      to_string: "@id",
    };

    let element = controller[name];
    schema["name"] = name;
    schema["typeHtml"] = element["typeHtml"];
    if (element["typeValidate"] != undefined) {
      schema["typeValidate"] = element["typeValidate"];
    }
    if (element["patternValidate"] != undefined) {
      schema["patternValidate"] = element["patternValidate"];
    }
    if (element["group_Choice"] != undefined) {
      schema["group_Choice"] = element["group_Choice"];
    }
    if (element["items_Group_Choice"] != undefined) {
      schema["items_Group_Choice"] = element["items_Group_Choice"];
    }
    if (element["nullable"] != undefined) {
      schema["nullable"] = element["nullable"];
    }
    if (element["unique"] != undefined) {
      schema["unique"] = element["unique"];
    }
    if (element["uniqueby"] != undefined) {
      schema["uniqueby"] = element["uniqueby"];
    }
    if (element["typeColumn"] != undefined) {
      schema["typeColumn"] = element["typeColumn"];
    }
    if (element["defaultValue"] != undefined) {
      schema["defaultValue"] = element["defaultValue"];
    }

    if (element["value"] != undefined) {
      schema["value"] = element["value"];
    }

    if (element["from"] != undefined) {
      schema["from"] = element["from"];
    } else {
      schema["from"] = name;
    }
    if (element["to_string"] != undefined) {

      schema["to_string"] = element["to_string"];

      
    }

    schemas.push(schema);
  }
  return schemas;
}
function titlesfor(controller) {
  let titles = [];
  for (const name in controller) {
    let title = {};
    title["text"] = name;
    title["value"] = name;
    titles.push(title);
  }

  return titles;
}

function config_for(controller) {
  return {
    schema: schemafor(controller),
    titles: titlesfor(controller),
  };
}

export default {
  bonsachats: config_for(require("./bonsachats")),
  categorieclient: config_for(require("./categorieclient")),
  categories: config_for(require("./categories")),
  commandes: config_for(require("./commandes")),
/*   client: config_for(require("./client")), */
  contactsfournisseur: config_for(require("./contactsfournisseur")),
  eventcheff: config_for(require("./eventcheff")),
  facturesventes: config_for(require("./facturesventes")),
  fournisseur: config_for(require("./fournisseur")),
  modepaiement: config_for(require("./modepaiement")),
  paiement: config_for(require("./paiement")),
/*   pays: config_for(require("./pays")), */
  produits: config_for(require("./produits")),
  recette: config_for(require("./recette")),
/*   regions: config_for(require("./regions")), */
  restaurant: config_for(require("./restaurant")),
  tags: config_for(require("./tags")),
  user: config_for(require("./user")),
  /* ville: config_for(require("./ville")), */
  produitsrecettes: config_for(require("./produitsrecettes")),
  tagsrecettes: config_for(require("./tagsrecettes")),
  plat: config_for(require("./plat")),
  platrecettes: config_for(require("./platrecettes")),
  commandeplats: config_for(require("./commandeplats")),
  categorieplat: config_for(require("./categorieplat")),
  produitsbonsachats: config_for(require("./produitsbonsachats")),
  recettebonachats: config_for(require("./recettebonachats")),
  gestion_files: config_for(require("./gestion_files")),
  mouvementsstock: config_for(require("./mouvementsstock")),
  perte: config_for(require("./perte")),

  produitspertes: config_for(require("./produitspertes")),
  recettepertes: config_for(require("./recettepertes")),



  optiongroup: config_for(require("./optiongroup")),
  optiongrouprecettes: config_for(require("./optiongrouprecettes")),
  platoptiongroups: config_for(require("./platoptiongroups")),
  categoriesproduit: config_for(require("./categoriesproduit")),
  personnalisez: config_for(require("./personnalisez")),
  extras: config_for(require("./extras")),
  recettepersonnalisezs: config_for(require("./recettepersonnalisezs")),
  recetteextras: config_for(require("./recetteextras")),
  usercategories: config_for(require("./usercategories")),
  recetteracine: config_for(require("./recetteracine")),

  recetteinventaires: config_for(require("./recetteinventaires")),
  produitsinventaires: config_for(require("./produitsinventaires")),
  inventaire: config_for(require("./inventaire")),
 
  recetteforrecettes: config_for(require("./recetteforrecettes")),
  
};
