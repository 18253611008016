import router from '@/router'
import axios from 'axios'

export default {
  count_notification({ state, commit, dispatch, rootState }) {

    let Base_url = rootState.Base_url;
    let JWTToken = rootState.JWTToken
let param = {
      headers: {
        Accept: 'application/ld+json',
      },
    }
   
    param['headers']['Authorization']=JWTToken


let urlPageInterval = state.Index_url_interval




    axios.get(Base_url + urlPageInterval,param).then(function (response) {
      commit('set_count_notification', response.data.count_notification)
    })
  },
  notification({ state, commit, dispatch, rootState }) {
    let Base_url = rootState.Base_url
    let Index_url_notification = state.Index_url_notification

    let JWTToken = rootState.JWTToken
    let param = {
          headers: {
            Accept: 'application/ld+json',
          },
        }
       
        param['headers']['Authorization']=JWTToken

    axios
      .get(Base_url + Index_url_notification,param)
      .then((response) => {
        let notification = response.data['notification']
        console.log(notification)
        let items = []
        for (let index = 0; index < notification.length; index++) {
          const element = notification[index]

          let icon = 'mdi-plus'
          let color = 'blue'
          switch (element['levelName']) {
            case 'add':
              icon = 'mdi-plus'
              color = 'blue'
              break
            case 'update':
              icon = 'mdi-update'
              color = 'green'
              break
            case 'delete':
              icon = 'mdi-delete'
              color = 'red'
              break

            default:
              icon = 'mdi-information'
              color = 'blue'
              break
          }

          items.push({
            title: element['controller'],
            action: element['levelName'],
            color: color,
            icon: icon,

            avatar: element['user_log'],
            to: { name: '_dashboard' },
            user: element['user_dentifiant'],
            email: element['user'],
            text: element['message'],
            date: element['createdAt'],
            time: element['createdAt_time'],
          })
          items.push({ divider: true, inset: true })
        }

        commit('set_notification', items)
        dispatch('count_notification')
      })
      .catch((error) => {})
  },
}
