<template>
  <v-card
    class="v-card--material--has-heading v-card--material pa-3 mb-16 mt-12"
    elevation="8"
    width="100%"
  >
    <v-row>
      <v-col cols="6">
        <div class="d-flex grow flex-wrap">
          <v-avatar
            rounded
            :size="150"
            class="mx-auto v-card--material __avatar elevation-6"
            color="grey"
          >
            <readfile-img :refgroup="item.logo" :size="150" />
          </v-avatar>
        </div>
      </v-col>
      <v-col cols="6">
        <div class="text-overline mb-4">
          <span
            class="text-decoration-line-through text-h7"
            v-if="item.remise > 0"
            >{{


              ((item.prix * 100) / (100-item.remise ) ).toLocaleString(
                undefined,
                { minimumFractionDigits: 2 }
              )
            }}
            MAD</span
          >
          <strong
            class="red--text text--lighten-1 text-h7"
            v-if="item.remise > 0"
          >
            -{{
              (item.remise * 1).toLocaleString(undefined, {
                minimumFractionDigits: 0,
              })
            }}%</strong
          ><br />

          <v-chip class="ma-2" label color="orange lighten-2 text-h6">
            {{
              (item.prix * 1).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })
            }}
            MAD
          </v-chip>
        </div>
         <div>
          <v-rating
            small
            :value="item.rating"
            background-color="orange lighten-3"
            color="orange"
            readonly
          ></v-rating>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div class="ml-4">
          <div
            class="card-title font-weight-light text-h5"
            v-text="item.name"
          />
          <v-card-subtitle> {{ item.mindescription }} </v-card-subtitle>
        </div>
      </v-col>
    </v-row>

    <v-card-actions>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
          class="mr-2"
            fab
            dark
            small
            color="orange"
            @click="showItem(item)"
            v-show="isGranted('view', item)"
            v-on="on"
          >
            <v-icon>mdi-view-carousel</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Consulter") }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
          class="mr-2"
            fab
            dark
            small
            color="orange"
            @click="editItem(item)"
            v-show="isGranted('edit', item)"
            v-on="on"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Modifier") }}</span>
      </v-tooltip>

      <v-spacer></v-spacer>

      <v-spacer></v-spacer>

      <v-btn icon @click="show = !show">
        <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <v-card-subtitle>
          <div class="text-center">
            <v-chip class="ma-2" v-if="item.carbs" outlined>
              <v-icon left> mdi-fire </v-icon>
              CARBS {{ item.carbs }} g
            </v-chip>

            <v-chip v-if="item.protein" class="ma-2" outlined>
              <v-icon left> mdi-fire </v-icon>
              Protein {{ item.protein }} g
            </v-chip>
            <v-chip class="ma-2" v-if="item.fat" outlined>
              <v-icon left> mdi-fire </v-icon>
              FAT {{ item.fat }} g
            </v-chip>
            <v-chip v-if="item.cal" class="ma-2" outlined>
              <v-icon left> mdi-fire </v-icon>
              Cal {{ item.cal }}
            </v-chip>
          </div>
        </v-card-subtitle>

        <v-card-text>
          {{ item.description }}
        </v-card-text>

 <v-virtual-scroll
        
        :items="item.platrecettes"
        :height="64*item.platrecettes.length"
        item-height="64"
      >
        <template v-slot:default="{ item }">

          <v-list-item :key="item.id">
           <v-list-item-avatar>
             <readfile-img :refgroup="item.recette.logo" :size="150" />
          </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.recette.name }} <strong> X </strong>  {{ item.qte }}
              </v-list-item-title>
            </v-list-item-content>

           
          </v-list-item>

          <v-divider></v-divider>
        </template>
      </v-virtual-scroll>




      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
   props: {
    item: { type: Object },
    controller_root: {},
  },
  data: () => ({
    show: false,
  }),  methods: {
    isGranted(attribute, item) {
      return this.$store.getters["security/isGranted"](
        attribute,
        this.controller_root,
        item
      );
    },
    showItem() {
      let item = this.item;
      if (this.slot_show) {
        this.$store.dispatch("custom_fromById", {
          names_controllers: [this.controller_root],
          id: item.id,
        });

        this.$store.commit("dialog/set_dialog", {
          data: item,
          flag: true,
          type: "show",
          controller: this.controller_root,
        });
      } else {
        this.$router.push({
          name: this.controller_root + "_show_item",
          params: { id: item.id },
        });
      }
    },
    editItem() {
      let item = this.item;
      if (this.slot_show) {
        this.$store.dispatch("custom_fromById", {
          names_controllers: [this.controller_root],
          id: item.id,
        });

        this.$store.commit("dialog/set_dialog", {
          data: item,
          flag: true,
          type: "update",
          controller: this.controller_root,
        });
      } else {
        this.$router.push({
          name: this.controller_root + "_update",
          params: { id: item.id },
        });
      }
    },
  },

 
 
};
</script>

<style scoped>
.v-card--material .__avatar {
  position: relative;
  top: -64px;
  margin-bottom: -32px;
}

.v-card--material .__heading {
  position: relative;
  top: -40px;
  transition: 0.3s ease;
  z-index: 1;
}
</style>
