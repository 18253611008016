import files from "./files";



export default [
  {
    path: "/" + "files",
    component: files,

    name: "files_show",
  },
];
