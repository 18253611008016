<template>
  
   <div></div>
 
</template>
<script>
import InputMixin from "./InputMixin";
export default {
     mixins: [InputMixin],
};
</script>