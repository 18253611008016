import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";

const requireComponent = require.context("./", true, /\.vue$/);

const componentsNames = [];

requireComponent.keys().forEach((fileName) => {

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\//, "").replace(/\.\w+$/, ""))
  );
  componentsNames.push(componentName);
});

export default componentsNames.sort();
