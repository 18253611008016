<template>
  <div>
    <!-- form -->
    <v-row>
      <box-input-renderajax
        v-for="(schema_input, index) in schemas_inputs"

        :key="index"
        :typeHtml="schema_input.typeHtml"
        :name="schema_input.name"
        :schema="schema_input"
        :controller_root="controller_root"
        
      />
    </v-row>

    <!-- /.form -->
  </div>
</template>
<script >

export default {
  props: {
    controller_root: {
      type: String,
      required:true
    },
  },
  computed: {
    schemas_inputs() {

      
      return this.$store.getters["structure/get_schemas"]({
        controller_root: this.controller_root
      });
    }
  }
};
</script>

<style >
</style>