import i18n from '@/plugins/i18n'
import validation from './validation'

export default {
  props: {
    controller_root: String,
    input_root: String,
    schema: Object,
  },

  data() {
    return {
      validation: validation(this.schema['name'], this.schema['nullable']),
      rules: [],
      val: '',
    }
  },

  computed: {
    name() {
      return this.schema['name']
    },
    unique() {
      return this.schema['unique']
    },
    uniqueby() {
      return this.schema['uniqueby']
    },

    from() {
      return this.schema['from']
    },
    to_string() {
      return this.schema['to_string']
    },

    label() {
      let icon = ''
      if (!this.schema['nullable']) {
        icon = '* '
      }

      return icon + i18n.t(this.name)
    },

    values_binds() {
      return this.$store.getters['structure/get_input_binds']({
        controller_root: this.controller_root,
        name: this.name,
      })
    },

    loading() {
      return false
    },
    readonly() {
      return false
    },
    val_root() {
      if (this.input_root != '') {
        let value = this.$store.getters['structure/get_input_binds']({
          controller_root: this.controller_root,
          name: this.input_root,
        })
        if (value == undefined) {
          return ''
        }
        return value
      }
    },
  },

  methods: {
    modelbind(val) {
      this.val = val

      this.$store.commit('structure/set_input_binds', {
        name: this.name,
        value: val,
        controller_root: this.controller_root,
      })
    },
    labelUpperCase(label) {
      return label.toLocaleUpperCase()
    },
    info_root() {
      if (this.input_root != '') {
        const val_selected = this.val_root
        let data_root_load = this.$store.getters[
          'structure/get_data_Select_load'
        ]({
          controller_root: this.controller_root,
          nameManyToOne: this.input_root,
        })
        for (const key in data_root_load) {
          const element = data_root_load[key]
          if (element['id'] == val_selected) {
            return element
          }
        }
        return {}
      }
      return {}
    },
    uniqueajax_validation() {
      if (this.unique == false || this.val == '' || this.val == null) {
        return
      }

      let valueniqueby = this.$store.getters['structure/get_input_binds']({
        controller_root: this.controller_root,
        name: this.uniqueby,
      })

      this.$store.dispatch('structure/unique_ajax_validation', {
        name: this.name,

        controller_root: this.controller_root,
        uniqueby: this.uniqueby,
        valueniqueby: valueniqueby,

        values_binds: this.val,
        fun: (ajax) => {
          this.init_validation()
          this.rules.push(ajax)
        },
      })
    },
    init_validation() {
      this.rules = []
    },
  },
  watch: {
    val_root(val) {
      if (this.input_root != '' && val != undefined) {
        let val_defaut_par_root = this.info_root()[this.name]

        this.$store.commit('structure/set_input_binds', {
          name: this.name,
          value: val_defaut_par_root,
          controller_root: this.controller_root,
        })
      }
    },
  },
  mounted() {
    this.init_validation()
  },
}
