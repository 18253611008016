export default [
  

  {
    IconModule: 'mdi-food-drumstick',
    NameModule: 'Produits',
    Controllers: {
    

      produits: {
        definition: {
          show: true,
          title: 'Produits',
          IconController: 'mdi-food-drumstick',
          message: 'text',
        },
      },
    
     
    },
  },


  {
    IconModule: 'mdi-cart-outline',
    NameModule: 'Achats',
    Controllers: {
      fournisseur: {
        definition: {
          show: true,
          title: 'fournisseur',
          IconController: 'mdi-human-dolly',
          message: 'text',
        },
      },

      contactsfournisseur: {
        definition: {
          show: true,
          title: 'Contactsfournisseur',
          IconController: 'mdi-face-agent',
          message: 'text',
        },
      },
      bonsachats: {
        definition: {
          show: true,
          title: 'bonsachats',
          IconController: 'mdi-cart-arrow-down',
          message: 'text',
        },
      },
    },
  },

  {
    IconModule: 'mdi-hamburger',
    NameModule: 'Recette',
    Controllers: {
      categories: {
        definition: {
          show: true,
          title: 'Categories',
          IconController: 'mdi-shape-outline',
          message: 'text',
        },
      },
      recette: {
        definition: {
          show: true,
          title: 'Recette',
          IconController: 'mdi-hamburger',
          message: 'text',
        },
      },
    },
  },
  {
    IconModule: 'mdi-food',
    NameModule: 'plat',
    Controllers: {
      optiongroup: {
        definition: {
          show: true,
          title: 'optiongroup',
          IconController: 'mdi-food-fork-drink',
          message: 'text',
        },
      },

      categorieplat: {
        definition: {
          show: true,
          title: 'categorieplat',
          IconController: 'mdi-shape-outline',
          message: 'text',
        },
      },
      plat: {
        definition: {
          show: true,
          title: 'plat',
          IconController: 'mdi-food',
          message: 'text',
        },
      },
    },
  },

  {
    IconModule: 'mdi-currency-usd',
    NameModule: 'Ventes',
    Controllers: {
      commandes: {
        definition: {
          show: true,
          title: 'Commandes',
          IconController: 'mdi-cash-register',
          message: 'text',
        },
      },

     /*  facturesventes: {
        definition: {
          show: true,
          title: 'Facturesventes',
          IconController: 'mdi-certificate',
          message: 'text',
        },
      }, */
    },
  },
  {
    IconModule: 'mdi-chef-hat',
    NameModule: 'Event',
    Controllers: {
      eventcheff: {
        definition: {
          show: true,
          title: 'Eventcheff',
          IconController: 'mdi-car',
          message: 'text',
        },
      },
    },
  },
  {
    IconModule: 'mdi-package-variant',
    NameModule: 'Stocks',
    Controllers: {
      mouvementsstock: {
        definition: {
          show: true,
          title: 'mouvementsstock',
          IconController: 'mdi-unfold-more-vertical',
          message: 'text',
        },
      },

      perte: {
        definition: {
          show: true,
          title: 'perte',
          IconController: 'mdi-delete',
          message: 'text',
        },
      },

      inventaire: {
        definition: {
          show: true,
          title: 'inventaire',
          IconController: 'mdi-store-edit-outline',
          message: 'text',
        },
      },
    },
  },
  {
    IconModule: 'mdi-shield-lock',
    NameModule: 'Security',
    Controllers: {
      user: {
        definition: {
          show: true,
          title: 'user',
          IconController: 'mdi-account',
          message: 'text',
        },
      },
      gestion_files: {
        definition: {
          show: true,
          title: 'gestion_files',
          IconController: 'mdi-account',
          message: 'text',
        },
      },

      backup: {
        definition: {
          show: true,
          title: 'backup',
          IconController: 'mdi-account',
          message: 'text',
        },
      },
    },
  },{
    IconModule: 'mdi-tune',
    NameModule: 'Reglages',
    Controllers: {
      restaurant: {
        definition: {
          show: true,
          title: 'restaurant',
          IconController: 'mdi-domain',
          message: 'text',
        },
      },

   
      modepaiement: {
        definition: {
          show: true,
          title: 'Modepaiement',
          IconController: 'mdi-treasure-chest',
          message: 'text',
        },
      },
      personnalisez: {
        definition: {
          show: true,
          title: 'personnalisez',
          IconController: 'mdi-food-drumstick-off-outline',
          message: 'text',
        },
      },
      extras: {
        definition: {
          show: true,
          title: 'extras',
          IconController: 'mdi-shaker',
          message: 'text',
        },
      },
      tags: {
        definition: {
          show: true,
          title: 'Tags',
          IconController: 'mdi-tag-plus',
          message: 'text',
        },
      },
    }
  },
]
