<template>
  <div class="text-center justify-center">
    <v-row>
      <v-file-input
        v-if="!values_binds"
        @change="modelbindfile($event)"
        hide-input
        :multiple="true"
      >
      </v-file-input>

      <readfile-avatar v-else :refgroup="values_binds"></readfile-avatar>
    </v-row>
    <v-row v-if="valueProgress > 0">
      <v-progress-linear
        :value="valueProgress"
        v-show="valueProgress > 0"
      ></v-progress-linear>
    </v-row>
    <v-row> </v-row>
  </div>
</template>
<script>
import i18n from "@/plugins/i18n";

export default {
  props: {
    id: Number,
    controller_root: String,
    name: String,
  },
  data: () => ({
    valueProgress: 0,
    values_binds: "",
    val: "",
  }),

  computed: {
    label() {
      return i18n.t(this.name);
    },
  },
  methods: {
    modelbind(val) {
      this.val = val;
      this.values_binds = val;
      console.log(this.values_binds, this.id, this.controller_root, this.name);

      this.$store.dispatch("send/send_refGroup_files", {
        controller_root: this.controller_root,
        controller_id: this.id,
        name: this.name,
        value: val,
      });
      this.$store.commit("dialog/set_dialog_btn_send", { flag: true });
    },

    modelbindfile(event) {
      if (event == undefined) {
        this.modelbind("");
      }
      if (Array.isArray(event)) {
        this.valueProgress = 0;
        /// send files to server and respons refgroup
        this.$store.dispatch("files/send_files", {
          controller_root: this.controller_root,
          files_input: event,
          name_input: this.name,

          fun_progress: (etat) => {
            this.valueProgress = etat;
          },
          fun_finally: (refGroup) => {
            this.modelbind(refGroup);
            this.valueProgress = 0;
          },
        });
      }
    },
  },
};
</script>
