export default {
  toggledrawer_right(state) {
    //toggle
    state.drawer_right = !state.drawer_right;
  },
  set_drawer_right(state, drawer_right) {
    //toggle
    state.drawer_right = drawer_right;
    
  },
  
  toggledrawer_i18n(state) {
    //toggle
    state.drawer_i18n = !state.drawer_i18n;
    if ( state.drawer_i18n) {
      localStorage.setItem('drawer_i18n', "ok")

      
    }else{
      localStorage.setItem('drawer_i18n', "")
    }


  },
  
  set_drawer_i18n(state, drawer_i18n) {
    //toggle
    state.drawer_i18n = drawer_i18n;

    if ( state.drawer_i18n) {
      localStorage.setItem('drawer_i18n', "ok")
    }else{
      localStorage.setItem('drawer_i18n', "")
    }
  },

  toggledrawer_left(state) {
    //toggle
    state.drawer_left = !state.drawer_left;
  },
  set_drawer_left(state, drawer_left) {
    //toggle
    state.drawer_left = drawer_left;
  },
  toggledark(state) {
    //toggle
    state.dark = !state.dark;
  },
  set_menu(state, menu) {
    let modules = {};

    for (const m of menu) {
      let name = m.NameModule;
      modules[name] = m;
    }
    state.menu = menu;

    state.modules = modules;
  },
  // set_user(state, user) {
  //   state.user = user;
  // },
  // set_rools(state, rools) {
  //   state.rools = rools;
  // },
  set_organisation(state, organisation) {
    state.organisation = organisation;
  },
  set_notification(state, notification) {
    state.notification = notification;
  },
  set_count_notification(state, count_notification) {
    state.count_notification = count_notification;
  },
  set_widgets(state, widgets) {
    // Enregistrer des données dans localStorage
    localStorage.setItem("widgets", widgets.join());

    state.widgets = widgets;
  },
};
