<template>
  <v-card-title>
    <v-row>
      <v-col :cols="12" :lg="3" class="d-none d-lg-block">
     {{$t('Total élements')}}  {{ items.length }}
        <div class="flex-grow-1"></div>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
      </v-col>

      <v-col :cols="12" :lg="6">
        <!--     vvvvvvvvvvvvvvvvvvvvv -->
        <v-btn
          color="pink"
          class="mx-2"
          outlined
          dark
          @click="alertshow()"
          v-show="
            selected.length > 0 && isGranted('delete') && action_traitement
          "
        >
          <v-badge color="red">
            <span slot="badge">{{ selected.length }}</span>
            <v-icon>mdi-delete</v-icon>
          </v-badge>
        </v-btn>
        <!--     vvvvvvvvvvvvvvvvvvvvv -->
        <v-btn
          color="primary"
          class="mx-2"
          outlined
          dark
          v-show="selected.length > 0"
          :to="{ name: 'pdf', params: { controller: controller_root } }"
        >
          <v-badge color="primary">
            <span slot="badge">{{ selected.length }}</span>
            <v-icon>mdi-download</v-icon>
          </v-badge>
        </v-btn>

     
      
      </v-col>

      <v-col :cols="12" :lg="3">
        <div class="flex-grow-1"></div>

        <!--     vvvvvvvvvvvvvvvvvvvvv -->
        <div>
          <p
            class="text-decoration-underline font-weight-thin text--secondary caption d-none d-lg-block"
            style="padding: 0; margin: 0; font-size: 14px"
          >
            {{ title }}
          </p>
          <v-text-field
            style="margin-top: 0; padding-top: 0"
            :value="search"
            @input="find($event)"
            append-icon="mdi-file-find"
            label="recherche "
            single-line
            hide-details
          ></v-text-field>
        </div>
      </v-col>
    </v-row>

    <v-snackbar v-model="alert" centered vertical color="error" :top="true">
      <v-icon>mdi-home-alert</v-icon>{{$t('Êtes-vous sûr de vouloir supprimer des lignes')}}
      <br />
      <strong>{{ text }}</strong>
      <div style="display-2">
        <v-btn dark text @click="alert = false">{{$t('Fermer')}}</v-btn>
        <v-btn dark text @click="delete_group()">{{$t('oui')}}</v-btn>
      </div>
    </v-snackbar>
  </v-card-title>
</template>


<script>
import { mapGetters } from "vuex";

export default {
  props: {
    title: {},
    controller_root: {},
    action_traitement: { default: true },
    slot_show: {},
    // pdf:{ default: false },
  },
  data() {
    return { dialog: false, alert: false, delete: false, text: "" };
  },
  methods: {
    find(val) {
      this.$store.commit("show_data/set_search", {
        controller_root: this.controller_root,
        data: val,
      });
    },
   
    delete_group() {
      this.alert = false;
      this.$store.dispatch("send/delete_group", this.controller_root);
    },
    alertshow() {
      this.alert = true;
      this.text = " ";
      this.selected.forEach((element) => {
        this.text = this.text + "    ,     " + element["toString"];
      });
    },
    isGranted(attribute) {
      return this.$store.getters["security/isGranted"](
        attribute,
        this.controller_root
      );
    },
  },

  computed: {
    
    isadmin() {
      return this.$store.getters["security/isAdmin"];
    },
    search() {
      return this.$store.getters["show_data/g_search"]({
        controller_root: this.controller_root,
      });
    },
    selected() {
      return this.$store.getters["show_data/g_selected"]({
        controller_root: this.controller_root,
      });
    },
    items() {
      return this.$store.getters["show_data/g_items"]({
        controller_root: this.controller_root,
      });
    },
  },
};
</script>

