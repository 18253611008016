<template>
     <v-list-item class="mx-auto my-12">
      <v-list-item-avatar tile>
    
      
<readfile-imagecoollightbox
 v-if="['image/avif', 'image/jpg','image/gif', 'image/jpeg', 'image/png', 'image/tiff', 'image/vnd.microsoft.icon', 'image/vnd.djvu'].includes(type)"
      :images="[minifile]"
 
    ></readfile-imagecoollightbox>



        <v-icon color="primary" size="50px" v-else-if="type == 'application/pdf'"> mdi-pdf-box </v-icon>
        <v-icon color="primary" size="50px" v-else > mdi-file-document-outline </v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
             <v-alert type="error" v-if="status=='remove'">
    supprimés
    </v-alert>
        <v-list-item-title v-html="name" ></v-list-item-title>
        <v-list-item-content>
          <v-rating :value="size * 5" color="amber" dense half-increments readonly size="14"></v-rating>

          tail fichier: {{ size }} MB date: {{ date_save }} time: {{ time_save }} <br />

          {{ type }}
     <v-chip>{{ status }}</v-chip>
          <v-chip>
            <router-link
              :to="{
                name: this.controller + '_show_item',
                params: { id: id },
              }"
              >{{ controller }}/{{ id }}</router-link
            >
          </v-chip>
            <v-chip>
              <v-btn color="deep-purple accent-4" text @click="dowlaod(pathDownload)">
        telecharger
      </v-btn>
       
          </v-chip>
          <v-chip v-if="status=='remove'">
            <v-btn  color="red accent-4" text @click="remove(idfile)">
        vide corbeille
      </v-btn>
          </v-chip>
        </v-list-item-content>
      </v-list-item-content>

    </v-list-item>
</template>
<script>
export default {
  props: {
    file: {},
  },
  data() {
    return {
      loading: false,
      selection: 0,
    };
  },
  methods: {
    reserve() {
      this.loading = true;

      setTimeout(() => (this.loading = false), 2000);
    },  remove(idfile) {
      this.$store.dispatch("files/remove_file_by_id", {
        idfile: idfile,

        fun_finally: (info) => {
          if (info["success"]) {
            this.show = false;
          }

          this.show = false;
          console.log(info);
        },
      });
    },

    dowlaod(src_image) {
      var a = document.createElement("a");
      a.href = src_image;
      a.download = "" + this.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
  computed: {
    name() {
      return this.file.originalName;
    },
    date_save() {
      let date = this.file.dateTimeUpload.split("-");

      let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

      let day = date[2];
      let monthIndex = date[1] - 1;
      let year = date[0];

      return day + " " + monthNames[monthIndex] + " " + year;
    },
    time_save() {
      return this.file.dateTimeUpload_time;
    },
    controller() {
      return this.file.controller;
    },
    id() {
      return this.file.idController;
    },
    size() {
      return ((this.file.size * 1) / 1024 / 1024).toFixed(2); //octe => ko =>mb
    },
    type() {
      return this.file.type;
    }, 
    pathDownload() {
      return this.file.pathDownload;
    },minifile() {
      return this.file.minifile;
    },status(){
      return this.file.status;
    },




  },
};
</script>
