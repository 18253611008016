<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      :bottom="y === 'bottom'"
      :color="color"
      :left="x === 'left'"
      :multi-line="multi_line"
      :right="x === 'right'"
      :timeout="timeout"
      :top="y === 'top'"
    >
      {{ text }}
      <v-btn dark text @click="snackbar = false"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-snackbar>

    <div class="text-center">
      <v-dialog :value="dialog" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
           {{$t('Veuillez patienter')}}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      // attacher `this.doneCount` à `this.$store.getters.doneTodosCount`

      color: "flash/color",
      multi_line: "flash/multi_line",
      // snackbar: "flash/show",
      text: "flash/message",
      timeout: "flash/timeout",
      x: "flash/coordonneX",
      y: "flash/coordonneY",
    }),
    snackbar: {
      get() {
        return this.$store.getters["flash/show"];
      },
      set(val) {
        this.$store.commit("flash/set_show", val);
      },
    },
    dialog() {
      return this.$store.getters["dialog/get_dialog_btn_send"];
    },
  },
  methods: {
    close() {
      this.$store.commit("flash/set_show", false);
    },
  },
};
</script>

