<template>
  <v-list-item>
    <v-checkbox
    
      label="dark"
      @change="toggledark()"
      off-icon="mdi-brightness-3"
      on-icon="mdi-brightness-5"
     
    ></v-checkbox>
  </v-list-item>
  
</template>

<script>
export default {
  computed: {
    dark() {
      return this.$store.getters["theme/dark"];
    },
  },

  methods: {
    toggledark() {
      this.$store.commit("theme/toggledark");

      this.$vuetify.theme.dark = this.dark;
    },
  },
};
</script>

<style>
</style>