"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  Index_url_interval: "/apiinterval",
  Index_url_notification: "/notification/api",
  dark: true,
  drawer_right: false,
  drawer_left: false,
  drawer_i18n: false,
  title: "",
  organisation: [],
  menu: [],
  // user: [],
  // rools: [],
  modules: {},
  widgets: [],
  count_notification: 0,
  notification: []
};
exports["default"] = _default;