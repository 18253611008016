<template>
  <card-card
    :close="close"
    @close="closeevent"
    icon="mdi-chart-bar"
    color="orange"
    :title="$t('CA par fournisseur')"
    class="px-5 py-3"
  >
    <v-col cols="12" lg="12">
      <v-row>
        <v-col cols="12">
          <v-row class="justify-space-around align-center">
            <v-col cols="5" sm="5" md="5">
              <v-text-field
                type="number"
                v-model="date"
                :label="$t('date')"
                placeholder="Placeholder"
                append-icon="mdi-refresh"
                @click:append="get_List_fournisseur_CA"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                type="number"
                v-model="CA"
                :label="$t('>_CA_fournisseurs')"
                placeholder="Placeholder"
                append-icon="mdi-refresh"
                @click:append="get_List_fournisseur_CA"
              ></v-text-field>
            </v-col>
            <v-col cols="11" sm="11" md="11">
              <v-autocomplete
                :disabled="false"
                :multiple="true"
                outlined
                dense
                filled
                chips
                label="fournisseur"
                v-model="fournisseursCA_selected_id"
                :items="list_fournisseursCA"
                item-text="fournisseur"
                item-value="id"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="removeItemOnSelect(data.item)"
                  >
                    <v-avatar left>
                      <readfile-img :refgroup="data.item.logo"></readfile-img>
                    </v-avatar>
                    {{ data.item.fournisseur }}
                  </v-chip>
                </template>

                <template v-slot:item="data">
                  <v-list-item-avatar>
                    <readfile-img :refgroup="data.item.logo"></readfile-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="'<strong>' + data.item.fournisseur + '</strong>'"
                    ></v-list-item-title>

                    <v-list-item-subtitle
                      v-html="
                        '<strong>' +
                        data.item.totalTtc +
                        '</strong> DH / nombre facture ' +
                        data.item.countfacture
                      "
                    ></v-list-item-subtitle>
                    <v-list-item-subtitle
                      v-if="
                        data.item.cin != null &&
                        data.item.cin != 'null' &&
                        data.item.cin != ''
                      "
                      v-html="
                        '<strong>REF : ' +
                        data.item.ref +
                        '</strong> /  ' +
                        'CIN: ' +
                        data.item.cin
                      "
                    ></v-list-item-subtitle>
                    <v-list-item-subtitle
                      v-else-if="
                        data.item.ice != null &&
                        data.item.ice != 'null' &&
                        data.item.ice != ''
                      "
                      v-html="
                        '<strong>REF : ' +
                        data.item.ref +
                        '</strong> /  ' +
                        'ICE: ' +
                        data.item.ice
                      "
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="justify-center align-center">
            <v-responsive width="100%" style="margin-bottom: 38px">
              <component
                v-if="showchart"
                :is="chart"
                :path-option="option"
                height="450px"
                width="100%"
              ></component>
            </v-responsive>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </card-card>
</template>
<script>
import i18n from "@/plugins/i18n";
import colors from "vuetify/es5/util/colors";
import axios from "axios";

import widgetsMixin from "../widgetsMixin";
export default {
  mixins: [widgetsMixin],
  data() {
    return {
      fournisseursCA_selected_id: [],

      list_fournisseursCA: [],
      list_fournisseursCA_by_id: [],

      date: new Date().getFullYear() - 4,
      CA: 0,
      statut: "facture",

      chart: "",

      isMobile: false,

      showchart: false,

      Graphiques: {},
    };
  },
  computed: {
    Base_url_web() {
      return this.$store.getters["get_Base_url"];
    },
    listDate() {
      let nowyear = new Date().getFullYear() + 2;
      let item = nowyear - this.date;

      let listyear = [];

      for (let index = 0; index < item; index++) {
        listyear.push(this.date * 1 + index);
      }

      return listyear;
      //return ["2014", "2015", "2016", "2017", "2018", "2019", "2020", "2021"]
    },
    fournisseursCA_selected_name() {
      let $name = [];

      this.list_fournisseursCA_by_id.forEach((element) => {
        $name.push(element[0]["fournisseur"]);
      });
      return $name;
    },
    series() {
      let $series = [];

      this.list_fournisseursCA_by_id.forEach((element) => {
        $series.push({
          name: element[0]["fournisseur"],
          type: "bar",
          tooltip: {
            trigger: "item",

            formatter: this.formatter_bar,
          },
        });
      });
      return $series;
    },

    option() {
      return {
        baseOption: {
          toolbox: {
            show: true,
            feature: {
              mark: { show: false },
              dataView: { show: false, readOnly: false },
              restore: { title: "Save", show: false },
              magicType: {
                title: "line/bare",
                show: true,
                type: ["line", "bar"],
              },

              saveAsImage: { title: "Save", show: true },
            },
          },
          color: [
            colors.amber.base,
            colors.indigo.base,
            colors.pink.base,
            colors.green.base,
            colors.teal.base,
            colors.purple.base,
          ],
          timeline: {
            lineStyle: {
              color: colors.indigo.base,
              shadowColor: "rgba(0, 0, 0, 0.5)",
              shadowBlur: 10,
            },
            itemStyle: {
              color: colors.indigo.base,
              shadowColor: "rgba(0, 0, 0, 0.5)",
              shadowBlur: 10,
            },
            checkpointStyle: {
              color: "#316bf3",
              borderColor: "#fff",
              shadowColor: "rgba(0, 0, 0, 0.5)",
              shadowBlur: 5,
            },
            axisType: "category",
            // realtime: false,
            // loop: false,
            autoPlay: true,
            // currentIndex: 2,
            playInterval: 7000,
            // controlStyle: {
            //     position: 'left'
            // },
            data: this.listDate,
            label: {
              formatter: function (s) {
                return new Date(s).getFullYear();
              },
            },
          },
          title: {
            subtext: " par mois",
          },
          tooltip: {},
          calculable: true,
          grid: {
            top: 80,
            bottom: 100,
            left: "5%",
            right: 90,
          },
          xAxis: [
            {
              data: [
                "",
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ],
              type: "category",
              axisLabel: { interval: 0 },
              splitLine: { show: false },
            },
          ],
          yAxis: [
            {
              type: "value",
              name: "MAD / DH",
            },
          ],

          legend: {
            top: 50,
            right: 10,
            type: "scroll",
            orient: "vertical",
          },
          series: this.series,
        },

        options: this.optionbaylistDate,
      };
    },
    optionbaylistDate() {
      let op = [];
      this.listDate.forEach((date) => {
        let d = {
          title: { text: i18n.t("Montant de Factures: ") + date + " " },
        };
        let series = [];

        this.Graphiques.forEach((Graphique) => {
          if (Graphique[date] != undefined) {
            series.push({ data: Graphique[date] });
          } else {
            series.push({ data: [] });
          }
        });
        d["series"] = series;

        op.push(d);
      });
      return op;
    },
  },
  methods: {
    removeItemOnSelect(item) {
      const index = this.fournisseursCA_selected_id.indexOf(item.id);
      if (index >= 0) this.fournisseursCA_selected_id.splice(index, 1);
    },

    get_List_fournisseur_CA() {
      this.chart = "theme-hiba";
      let path = "/apidashboardfournisseur";

      axios
        .get(this.Base_url_web + path, {
          params: {
            date: this.date,
            CA: this.CA,
            statut: this.statut,
            id: this.fournisseursCA_selected_id.join(),
          },
        })
        .then((response) => {
          this.showchart = false;
          this.list_fournisseursCA = response.data["list_fournisseursCA"];
          this.list_fournisseursCA_by_id =
            response.data["list_fournisseursCA_by_id"];
          this.Graphiques = response.data["Graphiques"];
          if (this.list_fournisseursCA_by_id.length > 0) {
            this.showchart = true;
            this.chart = "plugins-chart";

            window.dispatchEvent(new Event("optimizedResize"));
          }
        })
        .catch((error) => {
          console.error("path");
        });

      window.dispatchEvent(new Event("optimizedResize"));
    },

    formatter_bar: (params) => {
      console.log(params);
      let seriesName = params["seriesName"];
      let color = params["color"];
      let value = params["value"];
      let annee = "";
      let mois = "";
      let list = [];
      let listHTML = "<ul style='list-style-type: square'>";

      if (params["data"] != undefined) {
        annee = params["data"]["annee"];
        mois = params["data"]["mois"];
        list = params["data"]["list"];
        value = params["value"].toLocaleString(undefined, {
          minimumFractionDigits: 2,
        });
      }

      list.forEach((element) => {
        listHTML =
          listHTML +
          `<li>

                    id: ` +
          element.reffournisseur +
          `  ` +
          // element.titre +
          ` / ` +
          (element.montantHt * 1).toLocaleString(undefined, {
            minimumFractionDigits: 2,
          }) +
          ` TTC  dh 
                    
                    </li>`;
      });

      listHTML = listHTML + `</ul>`;

      return (
        `<table>
        <tr>
        <td valign="bottom">` +
        listHTML +
        `<strong> Total :` +
        seriesName +
        `</strong><br>
                    <span style=" 
                       height: 10px;
                       width: 10px;
                       margin-right:6px;
                      background-color: ` +
        color +
        `;
                      border-radius: 50%;
                      display: inline-block;"></span>` +
        mois +
        `/` +
        annee +
        ` : <strong>` +
        value +
        " </strong>DH <br>" +
        `</td>
        <td>` +
        `</td>
        </tr>
        </table>
        
        `
      );
    },
  },

  mounted() {
    // this.start();
    this.get_List_fournisseur_CA();
    this.isMobile = window.innerWidth < 600;
  },
  watch: {
    statut() {
      this.showchart = false;
      this.get_List_fournisseur_CA();
      //  this.start();
    },
    fournisseursCA_selected_id() {
      this.showchart = false;
      this.get_List_fournisseur_CA();
      //  this.start();
    },
  },
};
</script>
