<template>
  <v-navigation-drawer app v-model="drawerValue" temporary right width="320px">
    <v-list>
      <v-list-item @click.stop="toggledrawer_right()">
        <v-list-item-avatar>
         <readfile-img  :refgroup="refGroup" />
        </v-list-item-avatar>

        <v-list-item-title>{{ info_user["email"] }}</v-list-item-title>
      </v-list-item>

      <theme-drawer-toggledark />
      <theme-drawer-lang />
      <theme-drawer-controllers />
      <theme-drawer-multiselect />
      <v-list-item v-if="isadmin && connecte && $route.name == '_dashboard'">
        <v-tabs v-model="tabs" fixed-tabs>
          <v-tabs-slider></v-tabs-slider>

          <!-- <v-tab href="#mobile-tabs-5-1" class="primary--text">
            <v-icon>mdi-filter-menu-outline</v-icon>
          </v-tab> -->

          <v-tab href="#mobile-tabs-5-2" class="primary--text">
            <v-icon>mdi-magnify</v-icon>
          </v-tab>

          <v-tab href="#mobile-tabs-5-3" class="primary--text">
            <v-icon>mdi-file-tree</v-icon>
          </v-tab>
        </v-tabs>
      </v-list-item>
      <v-tabs-items
        v-model="tabs"
        v-if="isadmin && connecte && $route.name == '_dashboard'"
      >
        <!-- <v-tab-item :value="'mobile-tabs-5-1'">
          <theme-drawer-widgets-allwidgets />
        </v-tab-item> -->
        <v-tab-item :value="'mobile-tabs-5-2'">
          <theme-drawer-widgets-alllist />
        </v-tab-item>

        <v-tab-item :value="'mobile-tabs-5-3'">
          <!--  -->
          <!--      <theme-drawer-widgets-crm /> -->

          <!--          <theme-drawer-widgets-transactions /> -->
          <!--  <theme-drawer-widgets-achats /> -->
          <!--      <theme-drawer-widgets-ventes /> -->
          <!-- <theme-drawer-widgets-reglages /> -->
          <!-- -->
          <theme-drawer-widgets-produit />
          <!-- <theme-drawer-widgets-trajets /> -->
        </v-tab-item>
      </v-tabs-items>
    </v-list>
  </v-navigation-drawer>
</template>



<script>
import router from "@/router";

export default {
  data() {
    return {
      tabs: null,
    };
  },
  methods: {
    toggledrawer_right() {
      this.$store.commit("theme/toggledrawer_right");
    },
  },

  computed: {
     refGroup() {
      return this.$store.getters["security/refGroup"];
    },
    connecte() {
      return this.$store.getters["security/connecte"];
    },
    isadmin() {
      return this.$store.getters["security/isAdmin"];
    },
    info_user() {
      return this.$store.getters["security/user"];
    },
    logo() {
      return this.$store.getters["security/logo"];
    },

    drawerValue: {
      get() {
        return this.$store.getters["theme/drawer_right"];
      },
      set(val) {
        this.$store.commit("theme/set_drawer_right", val);
      },
    },
  },
};
</script>







<style  >
div.v-list-group__items div.v-input__slot .mdi-eye {
  color: #0277bd !important ;
}

div.v-navigation-drawer__content div.v-input__slot .mdi-eye-off {
  color: #757575 !important ;
}
</style>
