<template>
  <div>
    
    <readfile-imagecoollightbox
      :images="images"
      :size="size"
    ></readfile-imagecoollightbox>


    <div>
      <v-avatar
        :size="size"
        v-for="(file, indexke) in files_not_viewer"
        :key="indexke"
        @click="dowlaod(file.pathDownload, file.originalName)"
      >
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon color="primary" v-on="on" :size="size"
              >mdi-file-download-outline</v-icon
            >
          </template>
          <span>{{ file.originalName }}</span>
        </v-tooltip>
      </v-avatar>



    </div>
    <div>
      
    </div>
  </div>

 
</template>
<script>

export default {
  props: {
    refgroup: {
      type: String,
      default: "",
    },
    groupfilses: {
      type: Array,
      default:function () {
        return []
      },
    },
    hasdowlaod: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "29px",
    },
  },
  data: () => ({
    files_valueshow: [],

    files_not_viewer: [],
    images: [],

    index: null,
  }),
  methods: {
    setIndex(index) {
      this.index = index;
    },
    getfile(val) {
      this.files_valueshow = [];

      if (val != "" && val != undefined) {
        this.$store.dispatch("files/get_files_save", {
          refGroup: val,
          fun_finally: (info) => {
            this.gestionfiles(info);
          },
        });
      }
    },

    gestionfiles(info) {
      this.files_valueshow = info;
      //     this.images=[];
      for (const key in info) {
        let file = info[key];
let Base_url=this.Base_url
        if (
          [
            "image/avif",
             "image/jpg",
            "image/gif",
            "image/jpeg",
           
            "image/png",
            "image/tiff",
            "image/vnd.microsoft.icon",
            "image/vnd.djvu",
          ].includes(file.type)
        ) {
          this.images.push({
            title: file["originalName"],
            description: file["type"],

            src: Base_url+file["pathDownload"],
            mediaType: "image",
            backgroundImage: Base_url+file["minifile"],
          });
        } else if (["application/pdf"].includes(file.type)) {
          this.images.push({
            title: file["originalName"],
            description: file["type"],

            src: Base_url+file["pathDownload"],

            mediaType: "iframe",
            thumb: Base_url+"/src/img/video.png",
            backgroundImage:Base_url+ "/src/img/pdf.png",
          });
        } else if (
          ["video/mp4", "video/webm", "video/ogg"].includes(file.type)
        ) {
          this.images.push({
            title: file["originalName"],
            description: file["type"],

            src:Base_url+ file["pathDownload"],
            thumb:Base_url+ "/src/img/video.png",
            backgroundImage:Base_url+ "/src/img/video.png",
          });
        } else {
          this.files_not_viewer.push(file);
        }
      }
    },
    dowlaod(src_file, name) {
      let Base_url=this.Base_url
      if (this.hasdowlaod) {
        var a = document.createElement("a");
        a.href =Base_url+ src_file;
        a.download = "" + name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    },
  },

  watch: {
    refgroup(val) {
      this.getfile(val);
    },
  },

  mounted() {
    if (this.refgroup !="") {
     this.getfile(this.refgroup);  
    } else {
     
       this.gestionfiles( this.groupfilses);
    }
   
  },
    computed: {
    Base_url() {
      return this.$store.getters["get_Base_url"];
    },
  },


};
</script>
<style scoped>
</style>