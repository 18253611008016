<template>

 <v-row class="justify-center align-center">



    <v-col v-for="(controller, key) in listmenu" :key="key" 
    :md="11" 
      :cols="6" 
    v-show="controller['definition']['show'] && controller_show.includes(key)"
      >
        <v-btn  elevation="15"
       class="animate__animated  animate__rubberBand animate__infinite"
        color="orange"
  
  rounded
     :to="{ name: key + '_show' }" v-if="controller['definition']['show'] && controller_show.includes(key)"> 

             <v-icon >mdi-arrow-right</v-icon> 
         {{ $t(controller["definition"]["title"]) }}
          <v-icon> {{ controller["definition"]["IconController"] }}</v-icon>
          
          </v-btn>

    </v-col>


 </v-row>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  props: {
    controller_show: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    ...mapGetters({
      // attacher `this.doneCount` à `this.$store.getters.doneTodosCount`
      menu: "theme/menu",
    }),

    listmenu() {
      let me = {};
      this.menu.forEach(m => {
       
 me = {...me, ...m['Controllers']};
       
      });


      return me;
    },
  },
};
</script>

<style>
</style>