<template>

  <v-col cols="12" sm="6" md="4">
    <input type="text"  :value="values_binds" :disabled="true"  @input="modelbind($event)" />
  </v-col>
</template>
<script>
import InputMixin from "./InputMixin";
export default {
     mixins: [InputMixin],
};
</script>