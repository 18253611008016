import router from '@/router'

import axios from 'axios'
export default {
  send_files(
    { commit, dispatch, getters, rootGetters, rootState, state },
    {
      controller_root,
      files_input,
      name_input,
      fun_progress,
      fun_finally,
      refGroup,
    },
  ) {
    let urlPage = rootGetters['get_gestion_files']['upload']
    let Base_url = rootGetters['get_Base_url']

    let formdata = new FormData()
    formdata.append('controller', controller_root)
    formdata.append('name_input', name_input)
    if (refGroup != undefined && refGroup != '' && refGroup != null) {
      formdata.append('refGroup', refGroup)
    }

    let i = 0
    for (const file_set of files_input) {
      formdata.append(name_input + '[' + i + ']', file_set)
      i++
    }
   
    let JWTToken = rootState.JWTToken

    let param = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        let etat = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        )
        fun_progress(etat)
      },
    }

  
   
    param['headers']['Authorization']=JWTToken


    axios.post(Base_url + urlPage, formdata, param).then((response) => {
      fun_finally(response.data)
    })
  },

  /* get_files_save(
    { commit, dispatch, getters, rootGetters, rootState, state },
    { refGroup, fun_finally },
  ) {
    let urlPage = '/api/gestion_files' + '?page=1&refGroup=' + refGroup

    let Base_url = rootGetters['get_Base_url']

  
    let JWTToken = rootState.JWTToken

    let param = {
      headers: {
       
      },
    }
   
    param['headers']['Authorization']=JWTToken

    axios
      .get(Base_url + urlPage, param)
      .then((response) => {
        fun_finally(response.data['hydra:member'])
      })
      .catch((response) => {
        console.error(response)
      })
  }, */


   get_files_save(
    { commit, dispatch, getters, rootGetters, rootState, state },
    { refGroup, fun_finally },
  ) {
    let Base_url = rootGetters['get_Base_url']
    if (RegExp('\\b' + '#path' + '\\b').test(refGroup)) {
      let path = refGroup.split('#path')[1]

      fun_finally([{


        name: path,
        originalName: path,
        pathDownload: path,
        minifile:path,
      }])
    } else {
      let urlPage = '/api/gestion_files' + '?page=1&refGroup=' + refGroup

      let JWTToken=rootState.JWTToken


      axios
        .get(Base_url + urlPage,  {headers: {
          Authorization: JWTToken,
        },})
        .then((response) => {
          fun_finally(response.data['hydra:member'])
        })
        .catch((response) => {
          console.error(response)
        })
    }
  },
}
