//import { mapGetters } from "vuex";

export default {
  data: function () {
    return {
      valid: true,
      controller_root: "",
      namemodule: "",

      input_root: "",
      options_showData: { title: "", not_affiche: [], expanded: [] },
      lists_Add: [/*{controller_listsAdd: "",field_attach_to: {},field_event_to: {},calcules: {}}*/],

    };
  },
  computed: {},

  methods: {
    initia() {
      this.$store.dispatch("initialize_view", {
        controllers: [
          {
            controller_root: this.controller_root,
            namemodule: this.namemodule,
            input_root: this.input_root,


            lists_Add: this.lists_Add,
            options_showData: this.options_showData


          },
        ],
      });
    },
    run() {
      this.$store.dispatch("run_show", { names_controllers: undefined });
    },
  },
};
