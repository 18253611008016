<template>

  <card-card
    :close="close"
    @close="closeevent"
   icon="mdi-calendar-check-outline"
    color="orange"
    :title="$t('baseBonsventes')"
    class="px-5 py-3"

  >

  <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            aujourd'hui
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title> {{ $t("Jour") }} </v-list-item-title>
              </v-list-item>

              <v-list-item @click="type = 'month'">
                <v-list-item-title> {{ $t("Mois") }} </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          :locale="$t('locale')"
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-btn
                icon
                @click="showItem(selectedEvent._id_)"
                v-show="isGranted('view')"
              >
                <v-icon>mdi-view-carousel</v-icon>
              </v-btn>

              <v-toolbar-title v-html="selectedEvent.name"> </v-toolbar-title>
              <v-spacer></v-spacer>

              <v-btn icon @click="selectedOpen = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ selectedEvent.client_ }}
                </v-toolbar-title>

                <v-spacer></v-spacer>
                <v-chip class="ma-2" :color="selectedEvent.color" dark>
                  {{ selectedEvent.livraison }}
                </v-chip>
              </v-toolbar>

              <v-simple-table dense color="grey lighten-4">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Produits</th>
                      <th class="text-left">Quantite</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in selectedEvent.produits"
                      :key="item.key"
                    >
                      <td>{{ item.produits }}</td>
                      <td>{{ item.quantite }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <h4 right>Montant TTC : {{ selectedEvent.montantTtc_ }} DH</h4>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>

 </card-card>
</template>

<script>
import axios from "axios";


import widgetsMixin from "./../widgetsMixin";
export default {
  mixins: [widgetsMixin],
  data: () => ({
    controller_root: "bonsventes",
    focus: "",
    type: "month",

    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    typeToLabel: {
      month: "Mois",

      day: "Jour",
    },
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],
  }),
  mounted() {
    this.$refs.calendar.checkChange();
    this.start();
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      console.log(start);
      console.log(end);
      let events = [];

      events.push({
        // name: this.names[this.rnd(0, this.names.length - 1)],
        // color: this.colors[this.rnd(0, this.colors.length - 1)],
        // start: first,
        // end: second,

        name: "tarik/BV77:899",
        color: "indigo",
        start: "2021-02-19",
        end: "2021-02-19",
        text_awa: "awawawawaw",
        //timed: !allDay,
      });

      // this.events = events;
    },

    /////////////////////////
    count(paths, controller) {
      let path = paths[controller];
      if (path != undefined) {
        axios
          .get(this.Base_url_web+path, { params: {} })
          .then((response) => {
            let events = [];
            let data = response.data["dataSet"];

            data.forEach((element) => {
              let event = {};

              switch (element["livraison"]) {
                case "attente":
                  event["color"] = "orange";
                  break;
                case "partiellement":
                  event["color"] = "deep-purple";
                  break;
                case "encours":
                  event["color"] = "blue";
                  break;
                case "livree":
                  event["color"] = "green";
                  break;

                default:
                  break;
              }
              let produits = element["produitsBonsVentes"]["dataSet"];
              let infoproduit = [];
              let i=0;
              produits.forEach((produit) => {
                i++;
                infoproduit.push({
                   key:i,
                  produits: produit["produit"],
                  quantite: produit["quantite"],
                });
              });

              event["_id_"] = element.id;
              event["end"] = element.date;
              event["start"] = element.date;
              event["livraison"] = element.livraison;
              event["produits"] = infoproduit;

              (event["client_"] = element["client"]),
                (event["montantTtc_"] = element["montantTtc"]),
                (event["name"] = element["user"] + " " + element["toString"]);

              events.push(event);
            });

            this.events = events;
          })
          .catch((error) => {
            console.error("path");
          });
      }
    },

    showItem(id) {
      this.$router.push({
        name: this.controller_root + "_show_item",
        params: { id: id },
      });
    },
    isGranted(attribute) {
      return this.$store.getters["security/isGranted"](
        attribute,
        this.controller_root
      );
    },
    start() {
      this.count(this.paths_statistiques, this.controller_root);
    },
  },

  watch: {
    paths_statistiques() {
      this.start();
    },
  },
    computed: {

Base_url_web() {
      return this.$store.getters["get_Base_url"];
    },
    paths_statistiques() {
      return this.$store.getters["get_paths"];
    },
  },
};
</script>