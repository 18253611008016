export default {
  controller_root_attach: {},
  namesmodules: {},
  where_attach: {},
  where_attach_get_value: {},
  jointure: {},

  namescontroller_multiselect: [],

  // controller: {},
  titles: {},
  not_affiches: {},

  controller_listsAdd_attach: {},
  pieceID: {},

  expandeds: {},
  //////////////////////////

  search: {},
  //////////////////////////
  headers: {},
  items: {},
  //////////////////////////
  selected: {},
  item_key: {},

  //pagination
  page: {},
  pageCount: {},
  itemsPerPage: {},
  //////////////////////////
  loading: false,

  write: true,
};
