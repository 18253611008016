module.exports = {
  id: { typeHtml: "hidden" },
  categories: { typeHtml: "select", to_string: "categorie" , nullable: false},
  isracine: { typeHtml: "checkbox",defaultValue:true},
  name: { typeHtml: "text", unique: true, nullable: false },
  
 
  unite: { typeHtml: "text", defaultValue: "g", nullable: false },
  stock: { typeHtml: "hidden" },
  qtereturn: { typeHtml: "money", defaultValue: "1" , nullable: false},
  coutrecette: { typeHtml: "hidden" },
  stockalerte: { typeHtml: "money", defaultValue: "0" },

  description: { typeHtml: "Textarea" },
  logo: { typeHtml: "logo" },
  color: { typeHtml: "color", defaultValue: "#FF8F00FF" , nullable: false},

  updatedAt: { typeHtml: "hidden" },
  createdAt: { typeHtml: "hidden" },
  user: { typeHtml: "hidden" , to_string: "identifiant"},
};
