<template>
  <div>
    <!-- select -->
    <v-autocomplete
      :clearable="true"
      :label="label"
      @change="modelbind"
      :items="items"
      item-text="text"
      item-value="value"
      :value="value"
    >
    </v-autocomplete>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    label: { default: "" },
    from: { default: "" },
    controller_root: { default: "" },
    value: { default: null },
  },

  data: (vm) => ({
    valuesbind: "",
    divers_information: "",
    items_selected_info: {},

    data_load: [],
  }),

  computed: {
    items() {
      let data = this.data_load;

      let items = [];

      for (const key in data) {
        let option = {};

        const element = data[key];

        option.text = element["toString"];
        option.value = element["id"];
        items.push(option);
      }

      return items;
    },
  },

  methods: {
    modelbind(val) {
      this.$emit("change", { value: val, from: this.from });
    },
  },

  mounted() {
    const url = this.$store.getters["get_dataselect"](this.from);
    axios.get(url).then((response) => {
      this.data_load = response["data"]["dataSet"];
    });
  },
};
</script>
