<template>
  <v-row class="justify-center align-center" style="margin-top: 10px">
    <v-col cols="12" class="justify-center align-center">
      <v-card class="elevation-3 pa-0" style="margin: 0 auto">
        <v-card-text>
          <div class="layout column align-center">
            <h1 class="primary--text display-1 page-login_title">
              {{ check_url ? "START" : "STOP error " }}
            </h1>
            <!-- <img
              :src="protocol + uri_connected() + '/src/img/logo.jpg'"

              alt="logouser"
              width="100"
              height="100"
            /> -->
            <img
              v-if="check_url"
              :src="require('@/assets/conect.png')"
              alt="logouser"
              width="100"
              height="100"
            />
            <img
              v-else
              :src="require('@/assets/notconect.png')"
              alt="logouser"
              width="100"
              height="100"
            />
          </div>
          <v-form class="my-3">
            <v-radio-group v-model="mode_conected" row>
              <v-radio label="local" value="local"></v-radio>
              <v-radio label="web" value="web"></v-radio>
            </v-radio-group>

            <v-text-field
              v-show="mode_conected == 'local'"
              append-icon="mdi-lan"
              name="Base_url_local"
              :label="'Adresse serveur SVP'"
              type="text"
              v-model="Base_url_local"
            ></v-text-field>

            <v-text-field
              v-show="mode_conected == 'local'"
              append-icon="mdi-ip"
              name="Base_url_ip"
              :label="'Adresse ip internet SVP'"
              type="text"
              v-model="Base_url_ip"
            ></v-text-field>

            <v-text-field
              append-icon="mdi-web"
              name="Base_url_web"
              :label="'Adresse serveur web SVP'"
              type="text"
              v-model="Base_url_web"
            ></v-text-field>

            <v-text-field
              :disabled="!check_url"
              append-icon="mdi-account"
              name="login"
              :label="'Email'"
              type="text"
              v-model="name"
            ></v-text-field>

            <v-text-field
              :disabled="!check_url"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              counter
              @click:append="show1 = !show1"
              name="password"
              :label="'Password'"
              id="password"
              v-model="password"
              v-on:keyup.enter="submit"
            ></v-text-field>
          </v-form>

          <v-alert v-show="alert" outlined type="error" prominent border="left">
            Impossible de se connecter au serveur
            <strong> Email:ERROR OR Password :ERROR</strong>
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="!check_url"
            color="primary"
            @click="submit"
            :loading="loading"
            right
            >{{ "Login" }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { ajax_JWTToken } from "../util/ajax_JWTToken";
import axios from "axios";
export default {
  data: () => ({
    alert: false,
    protocol: "http://",
    mode_conected: "local",
    check_url: false,
    Base_url_local: "",
    Base_url_ip: "",
    Base_url_web: "fastfood.appcomptable.com/",
    name: "",
    password: "",

    loading: false,
    show1: false,
  }),

  mounted() {
    let Base = this.$store.getters["get_Base_url"];
    var b = Base.split("//");
    this.protocol = b[0] + "//";
    this.Base_url_local = b[1];
    this.name = this.$store.getters["get_username"];
    this.password = this.$store.getters["get_password"];

    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => (this.Base_url_ip = response.data.ip))
      .catch((response) => (this.check_url = false));
  },

  methods: {
    async submit() {
      this.loading = true;
      this.alert = false;

      const JWTToken = await ajax_JWTToken(
        this.protocol + this.uri_connected(),
        this.name.trim(),
        this.password,
        false
      );

      if (JWTToken != "") {
        this.$store.commit(
          "set_Base_url",
          this.protocol + this.uri_connected()
        );
        this.$store.commit("set_username", this.name.trim());
        this.$store.commit("set_password", this.password);
        this.$store.commit("set_JWTToken", JWTToken);

        if (this.mode_conected == "local") {
          this.set_ip_DB_TO_SERVER();
        }

        this.$store.dispatch("run_get_metaData");
      } else {
        this.alert = true;
      }

      this.loading = false;

      //
    },
    clear() {
      this.name = "";
      this.password = "";
    },
    check_URL() {
      this.check_url = false;
      axios
        .get(this.protocol + this.uri_connected())
        .then((response) => (this.check_url = true))
        .catch((response) => (this.check_url = false));
    },
    uri_connected() {
      let Base_url = "";

      if (this.mode_conected == "local") {
        Base_url = this.Base_url_local;

        if (Base_url==""||Base_url==null||Base_url==undefined||Base_url.trim()=="") {
         Base_url= "localhost"
        }

      } else {
        Base_url = this.Base_url_web;
      }

      let uri = Base_url.replace("https://", "")
        .replace("http://", "")
        .replace("www.", "")
        .replace("/", "")

        .trim();
      if (this.protocol == "https://") {
        uri = uri.replace(".appcomptable.com", "") + ".appcomptable.com";
      }
      return uri;
    },
    set_ip_DB_TO_SERVER() {


        let uri = this.Base_url_web.replace("https://", "")
        .replace("http://", "")
        .replace("www.", "")
        .replace("/", "")

        .trim();
    
let ip=this.Base_url_ip;
   axios
        .get("https://" + uri+'/setNameServerDB.php',{
          params: {
          host: ip,
         
        },
        })
        .then((response) => ( this.check_url = true))
        .catch((response) => (this.check_url = false));

     

    
      
    },
  },
  watch: {
    Base_url_local() {
      this.check_URL();
    },
    Base_url_web() {
      this.check_URL();
    },

    mode_conected(newValue) {
      if (newValue == "local") {
        this.protocol = "http://";
        this.check_URL();
      } else {
        this.protocol = "https://";
        this.check_URL();
      }
    },
  },
};
</script>
<style scoped lang="css">
#login {
  margin: 0;
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
</style>