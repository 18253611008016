// import Vue from 'vue'
// import VueI18n from 'vue-i18n'

import Vue from 'vue'
import VueI18n from '@/util/vue-i18n'
import { loadLocaleMessages, loadServerMessages } from '@/util/system_i18n_lang'

Vue.use(VueI18n)

// export default new VueI18n({
//   locale:  'fr',
//   fallbackLocale: 'fr',
//   silentTranslationWarn: false,
//   messages: loadLocaleMessages()
// })

//console.log(loadServerMessages());

export default new VueI18n({
  locale: 'fr-fr',
  fallbackLocale: 'fr-fr',
  silentTranslationWarn: false,
  messages: loadServerMessages(),
})
