module.exports = {
  id: { typeHtml: "hidden" },
  fournisseur: { typeHtml: "selectajax", to_string: "fournisseur" ,nullable: false},
  prenom: { typeHtml: "text",nullable: false },
  nom: { typeHtml: "text" ,nullable: false},

  telephone: { typeHtml: "tel" },
  gsm: { typeHtml: "tel" },
  fonction: { typeHtml: "text" },
  email: { typeHtml: "email" },
  note: { typeHtml: "Textarea" },
/*   ville: { typeHtml: "selectajax", to_string: "ville" },
  pays: { typeHtml: "selectajax", to_string: "pays" },
  regions: { typeHtml: "selectajax", to_string: "region" }, */
  adresse: { typeHtml: "Textarea" },

  updatedAt: { typeHtml: "hidden" },
  createdAt: { typeHtml: "hidden" },
  user: { typeHtml: "hidden" , to_string: "identifiant"},
};
