<template>
  <div class="text-center justify-center">
    <v-chip v-if="action == 'Traiter'" color="success" class="mx-2" dark small>
      <v-icon dark>mdi-hand-okay</v-icon>
    </v-chip>

    <v-btn
      v-else
      v-show="isGranted('add')"
      class="mx-2"
      dark
      small
      color="orange"
      @click="runTraiter()"
    >
      <v-icon dark>mdi-alarm-light-outline</v-icon>
    </v-btn>
  </div>
</template>
<script>
import i18n from "@/plugins/i18n";

export default {
  props: {
    action: String,
  },
  data: () => ({}),

  computed: {
    controller_root() {
      let a = this.action.split("|");
      return a[1].trim().toLowerCase();
    },
    id_input_root() {
      let a = this.action.split("|");
      return a[0];
    },
  },

  methods: {
    runTraiter() {
      console.log(this.id_input_root + "  " + this.controller_root);
      this.$store.commit("structure/set_actionTraiter", {
        actionTraiter: this.id_input_root,
      });
      this.$store.commit("dialog/set_dialog_btn_send", { flag: true });

      this.$store.dispatch("initialize_view", {
        controllers: [
          {
            controller_root: this.controller_root,
            namemodule: "",
            input_root: "",

            lists_Add: [],
            multiselect: [],
            options_showData: [],
          },
        ],
      });
      this.$router.push({ name: this.controller_root + "_add" });
    },
    isGranted(attribute) {
      return this.$store.getters["security/isGranted"](
        attribute,
        this.controller_root
      );
    },
  },
};
</script>
