



export default {
  set_data_Select_load(state, { data_Select_load }) {
    state["data_Select_load"] = data_Select_load;
  },
  set_Data_Info_Lot(state, { Data_Info_Lot }) {
    state["Data_Info_Lot"] = Data_Info_Lot;
  },
};
