<template>
 <v-list-item>
          <v-list-item-icon>
          <v-icon color="indigo">mdi-web</v-icon>
        </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title><u>{{ label }}  :</u> </v-list-item-title>
      <a :href="values_binds">{{ values_binds }}</a>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
import InputMixin from "./InputMixin";

export default {
  mixins: [InputMixin],
  


};
</script>
