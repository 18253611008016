<template>
  <v-form ref="form" v-model="valid">
     <v-row class="justify-center">
      <v-col cols="12"
        ><btn-action
          :controller_root="controller_root"
          :item="{ id: id }"
        ></btn-action
      ></v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="4">
        <card-box
          id="groupt1"
          color="orange"
          :title="$t('show_recettes_box_title_')"
          :text="$t('show_recettes_box_text_')"
        >
          <show-default :controller_root="controller_root" />
        </card-box>
      </v-col>

      <v-col cols="8">
        <card-box
          id="addlist2"
          icon="mdi-file-star-outline"
          :title="$t('show_recettes_box_2')"
          color="success"
        >
          <addlist-table
            :edit_listsAdd="false"
            controller_listsAdd="produitsrecettes"
          />
        </card-box>

        <card-box
          v-show="val_isracine()==false"
          id="addlist22"
          icon="mdi-file-star-outline"
          :title="$t('show_recettes_box_4')"
          color="success"
        >
          <addlist-table
            :edit_listsAdd="false"
            controller_listsAdd="recetteforrecettes"
          />
        </card-box>
      </v-col>
      <v-col cols="12" lg="11">
        <card-box
          v-show="val_isracine()==false"
          id="addlist2x"
          icon="mdi-file-star-outline"
          :title="$t('show_recettes_box_5')"
          color="success"
        >
          <v-row>
            <v-col cols="12" lg="4">
              <addlist-foradd
                :edit_listsAdd="false"
                controller_listsAdd="recettepersonnalisezs"
                item_listsAdd="personnalisez"
              />
            </v-col>

            <v-col cols="12" lg="4">
              <addlist-foradd
                item_listsAdd="extras"
                :edit_listsAdd="false"
                controller_listsAdd="recetteextras"
              />
            </v-col>
            <v-col cols="12" lg="4">
              <addlist-foradd
                :edit_listsAdd="false"
                item_listsAdd="tags"
                controller_listsAdd="tagsrecettes"
              />
            </v-col>
          </v-row>
        </card-box>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import i18n from "@/plugins/i18n";
import { mapGetters } from "vuex";
import ViewMixin from "@/views/show_item";

export default {
  mixins: [ViewMixin],

  props: {},
  data() {
    return {
      controller_root: "recette",
      namemodule: "CRM",
      lists_Add: [
        {
          controller_listsAdd: "produitsrecettes",
        },{
          controller_listsAdd: "recetteforrecettes",
        },
        { controller_listsAdd: "recetteextras" },
        { controller_listsAdd: "tagsrecettes" },
        ,
        { controller_listsAdd: "recettepersonnalisezs" },
      ],
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  created() {
    document.title = i18n.t("show_item_" + this.controller_root);
    this.initia();
    this.run();
  },

  methods: {
    isGranted(controller) {
      return this.$store.getters["security/isGranted"]("view", controller);
    }, val_isracine() {
      
      let value = this.$store.getters["structure/get_input_binds"]
      ({ controller_root: this.controller_root, name:"isracine", });
      
      if (value == undefined) {
        return false;
      }
     
      return value;
    },
  }, 
};
</script>
