

// Lib imports
import Vue from 'vue'
import Vuex from 'vuex'

// Store functionality
import actions from './MAIN/actions'
import getters from './MAIN/getters'
import mutations from './MAIN/mutations'
import state from './MAIN/state'
// Store modules
import modules from './modules'

Vue.use(Vuex)

// Create a new store


const store = new Vuex.Store({
  modules,


  actions,
  state,
  getters,
 mutations,

})
export default store
