<template>
  <!-- https://github.com/wassimhazime/vue-cool-lightbox
https://vue-cool-lightbox.lucaspulliese.com/#basic-usage



 images: [
        'https://cosmos-images2.imgix.net/file/spina/photo/20565/191010_nature.jpg?ixlib=rails-2.1.4&auto=format&ch=Width%2CDPR&fit=max&w=835',
        'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/nature-quotes-1557340276.jpg?crop=0.666xw:1.00xh;0.168xw,0&resize=640:*',
      ],

      or


       images: [
        {
          title: 'In nature, nothing is perfect and everything is perfect',
          description: "Photo by Lucas",
          thumb: 'https://cosmos-images2.imgix.net/file/spina/photo/20565/191010_nature.jpg?ixlib=rails-2.1.4&auto=format&ch=Width%2CDPR&fit=max&w=835',
          src: 'https://www.youtube.com/watch?v=d0tU18Ybcvk',
        },
        {
          title: 'A beautiful mountain view',
          description: "Photo by Facundo",
          src: 'https://vimeo.com/43338103',
          thumb: 'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/nature-quotes-1557340276.jpg?crop=0.666xw:1.00xh;0.168xw,0&resize=640:*',
        },
        {
          title: 'In nature, nothing is perfect and everything is perfect',
          description: "Photo by Lucas",
          thumb: 'https://scx1.b-cdn.net/csz/news/800/2019/1-nature.jpg',
          src: '/video.mp4',
        },
         title: file["originalName"],
                  description: file["type"],

                  src: file["pathDownload"],
                  thumb: "/src/img/video.png",
                  backgroundImage: "/src/img/video.png",
      ],

 -->
  <div   style="display:inline-block ;" >

   

   
      <div
       style="display: inline-block;" 
        :width="size"
        :height="size"
        class="iconshow images-wrapper"
        v-for="(image, imageIndex) in images"
        :key="imageIndex"
        @click="setIndex(imageIndex)"
        
      >
        <v-img
          :src="image.backgroundImage ? image.backgroundImage : image"
          :lazy-src= " Base_url+'/src/img/load.jpg'"
          aspect-ratio="1"
          class="grey lighten-2"
          :width="size"
          :height="size"
          v-bind:style="styleObject">
          
      
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div>

       <readfile-coollightbox
      :useZoomBar="true"
      :fullScreen="true"
      :items="images"
      :index="index"
      @close="index = null"
    >
    
    </readfile-coollightbox>
    </div>
  
</template>


<script>
export default {
  props: {
    images: {
      type: Array,
      default: [],
    },

    size: {
      type: String,
      default: "29px",
    },
  },
  data: () => ({
    files_valueshow: [],

    files_not_viewer: [],

    index: null,
  }),
  methods: {
    setIndex(index) {
      this.index = index;
    },
  },computed:{
    styleObject(){
      let size=this.size
      return{
         margin: "1px "
          ,"max-width":size,
          "max-height": size,
      }
    },  Base_url() {
      return this.$store.getters["get_Base_url"];
    },
  }
};
</script>
<style scoped>
.images-wrapper {
  display: flex;
}
.iconshow {
  display: block;
  /* background-color: red; */
}
</style>