import axios from 'axios'
import router from '@/router'
//let colorconsole="color: red"
let colorconsole = 'color: green'
let colorconsoleyellow = 'color: magenta'
//let colorconsole="color: blue"
//let colorconsole="color: magenta"
//let colorconsole="color: cyan"
//let colorconsole="color: gray"
//let colorconsole="color: grey"

export default {
  //ok
  ajax_initialize_struct_Multiselect({
    commit,
    dispatch,
    getters,
    rootGetters,
    rootState,
    state,
  }) {
    console.groupCollapsed(
      '%caction => structure/%cajax_initialize_struct_Multiselect',
      colorconsole,
      colorconsoleyellow,
    )
    console.log('commit exec => set_headers ')
    //console.log("event data root event name + value", event)
    state['namescontroller_multiselect'].forEach((controller_multiselect) => {
      const where_attach = state['where_attach'][controller_multiselect]

      const jointure = state['jointure'][controller_multiselect]

      const controller_root_attach =
        state['controller_root_attach'][controller_multiselect]

      const where_attach_get_value =
        state['where_attach_get_value'][controller_multiselect]
      let value_name
      if (where_attach_get_value == undefined) {
        value_name = where_attach
      } else {
        value_name = where_attach_get_value
      }

      // value root atache
      const value = rootGetters['structure/get_input_binds']({
        controller_root: controller_root_attach,
        name: value_name,
      })

      const urlPage = rootGetters['get_path'](controller_multiselect)

      let Base_url = rootGetters ['get_Base_url'];
      let JWTToken = rootGetters['get_JWTToken'];
      axios
        .get(Base_url+urlPage, {
          params: {
            where: where_attach,
            value: value,
            jointure: jointure,
            controller_root_attach: controller_root_attach,
          },headers:{Authorization:JWTToken}
        })
        .then(function (response) {
          // let schema = response.data.schema;
          let titles = response.data['titles']
          let dataSet = response.data['dataSet']

          commit('set_headers', {
            controller_multiselect: controller_multiselect,
            data: titles,
          })
          commit('set_items', {
            controller_multiselect: controller_multiselect,
            data: dataSet,
          })
        })
        .catch(function (error) {
          console.error('error get info', error)
        })
    })

    console.log(state)

    console.groupEnd()
  },

  //ok
  ajax_custom_Multiselect_ByDataSave(
    { commit, dispatch, getters, rootGetters, rootState, state },
    { datasave },
  ) {
    console.groupCollapsed(
      '%caction => structure/%cajax_custom_Multiselect_ByDataSave',
      colorconsole,
      colorconsoleyellow,
    )

    console.log('commit exec => set_jointure ')
    console.log('dispatch exec => ajax_initialize_struct_Multiselect')
    console.log('commit exec => set_selected')

    state.namescontroller_multiselect.forEach((controller_multiselect) => {
      commit('set_jointure', { controller_multiselect, data: 'FULL' })

      if (datasave[controller_multiselect] != undefined) {
        commit('set_selected', {
          controller_multiselect,
          data: datasave[controller_multiselect].dataSet,
        })
      } else if (datasave[controller_multiselect + 's'] != undefined) {
        commit('set_selected', {
          controller_multiselect,
          data: datasave[controller_multiselect + 's'].dataSet,
        })
      }
    })

    dispatch('ajax_initialize_struct_Multiselect')
    console.log(state)

    console.groupEnd()
  },

  /*   remove({ commit, dispatch, getters, rootGetters, rootState, state }) {
    for (const controller_multiselect in state.headers) {
      commit("remove_all", { controller_multiselect: controller_multiselect });
    }
  }, */
}
