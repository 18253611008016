<template>
  <v-card  elevation="8"
    width="100%" dark>
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title>
          <strong class="red--text text-h3">{{ item.qte }}</strong>

          <span class="text-h6"> {{ "x   " + item.recette.name }}</span
          ><br />
        </v-card-title>

        <v-card-subtitle>
          <v-alert border="left" colored-border :color="getcolor()">
            <ul>
              <li v-if="item.isvalidecommande">
                valide :
                <v-icon color="orange" large v-if="item.isvalidecommande">
                  mdi-account-cash-outline
                </v-icon>
              </li>
              <li>
                ID: <strong>{{ item.id }}</strong>
              </li>
              <li>
                COMMANDE:
                <strong >{{
                  item.commandes.code
                }}</strong>
              </li>

              <li v-if="item.plat != null">
                PLAT: <strong>{{ item.plat.name }}</strong>
              </li>
              <li>
                categorie:
                <strong>{{ item.recette.categories.categorie }}</strong
                >
              </li>
            </ul>
          </v-alert>
        </v-card-subtitle>
      </div>
      <v-spacer />
      <div>
        <div>
          <v-avatar
            right
            v-if="item.recette.logo"
            rounded
            :size="80"
            class="v-card--material __avatar elevation-6 mr-6 mt-6 text-right"
            :color="item.recette.color"
          >
            <readfile-img :refgroup="item.recette.logo" />
          </v-avatar>

          <v-icon
            v-else
            :color="item.recette.color"
            right
            x-large
            class="mr-6 mt-6 text-right"
          >
            mdi-silverware
          </v-icon>
          <br />
          <span v-if="item.user != null" class="orange--text">{{
            item.user.identifiant
          }}</span>
        </div>
        <v-btn class="ma-2" label icon x-large>
                  <v-icon x-large :color="item.recette.color">
                    {{ item.recette.categories.icon }}</v-icon
                  >
                </v-btn>
      </div>
    </div>

    <v-divider class="mx-4"></v-divider>

    <v-card-actions class="">
      <v-btn v-if="item.note != null" :color="getcolor()" class="mr-4">
        {{ item.note }}
          <v-chip
        class="ml-1"
        :color="getcolor()"
        label
        v-if="getTimeline(item.datestart, item.datefin) !== ''"
      >
        <strong class="text-h4 font-weight-light">
          {{ getTimeline(item.datestart, item.datefin) }}</strong
        >
        min
      </v-chip>
      </v-btn>

    
      <v-spacer></v-spacer>

      <v-btn icon @click="show = !show" color="orange">
        <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <div v-show="show">
        <div class="text-overline mb-2" v-if="item.datestart != null">
          <v-chip class="mr-1" label>
            <v-icon left color="success"> mdi-timer-sand </v-icon>
            {{ convertDate(item.datestart) }}
          </v-chip>
        </div>
        <div class="text-overline mb-2" v-if="item.datefin != null">
          <v-chip class="mr-1" label>
            <v-icon left color="red"> mdi-timer-sand-complete </v-icon>
            {{ convertDate(item.datefin) }}
          </v-chip>
        </div>

        <v-divider></v-divider>

        <v-card-text>
          {{ item.recette_description }}
        </v-card-text>

        <v-card-actions>
          <!--     <v-card-title v-if="personnalise.length > 0"
      >Personnalisez Recette</v-card-title
    > -->

          <v-card-text v-if="personnalise.length > 0">
            <v-chip-group
              multiple
              v-model="selectionpersonnalise"
              active-class="deep-purple accent-4 white--text"
              column
            >
              <v-chip v-for="(personna, iff) in personnalise" :key="iff + 'pr'">
                <v-icon left color="red" dark> mdi-minus </v-icon>
                {{ personna[0] }} // {{ personna[1] }}
              </v-chip>
            </v-chip-group>
          </v-card-text>

          <v-card-text v-if="extras.length > 0">
            <v-chip-group
              multiple
              v-model="selectionextra"
              active-class="deep-purple accent-4 white--text"
              column
            >
              <v-chip v-for="(extra, iff) in extras" :key="iff + 'ex'">
                <v-icon left color="success" dark>mdi-plus-thick</v-icon>

                {{ extra[0] }}//{{ extra[1] }}
              </v-chip>
            </v-chip-group>
          </v-card-text>
        </v-card-actions>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  props: {
    item: { type: Object },
    controller_root: {},
    selectionextra: [],
    selectionpersonnalise: [],
  },
  data: () => ({
    show: false,
  }),
  methods: {
    getcolor() {
    

      if (this.item.note === 'start' ) {
        return "success"
      }
       if (this.item.note === 'fin' ) {
        return "primary"
      }
       if (this.item.note === 'annule' ) {
        return "orange"
      }
       if (this.item.note === 'reload' ) {
        return "purple"
      }
       return ""
    
    },
    convertDate(inputFormat) {
      if (
        inputFormat == "" ||
        inputFormat == null ||
        inputFormat == undefined
      ) {
        return "";
      }
      function pad(s) {
        return s < 10 ? "0" + s : s;
      }
      var d = new Date(inputFormat);

      return (
        [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/") +
        " " +
        [pad(d.getHours()), pad(d.getMinutes())].join(":")
      );
    },

    getTimeline(datestart, datefin) {
      if (datestart == "" || datestart == null || datestart == undefined) {
        return "";
      }
      if (datefin == "" || datefin == null || datefin == undefined) {
        return "";
      }

      let d1 = new Date(datestart).getTime();
      let d2 = new Date(datefin).getTime();
      return Math.floor((d2 - d1) / (60 * 1000));
    },

    isGranted(attribute, item) {
      return this.$store.getters["security/isGranted"](
        attribute,
        this.controller_root,
        item
      );
    },
    showItem() {
      let item = this.item;
      if (this.slot_show) {
        this.$store.dispatch("custom_fromById", {
          names_controllers: [this.controller_root],
          id: item.id,
        });

        this.$store.commit("dialog/set_dialog", {
          data: item,
          flag: true,
          type: "show",
          controller: this.controller_root,
        });
      } else {
        this.$router.push({
          name: this.controller_root + "_show_item",
          params: { id: item.id },
        });
      }
    },
    editItem() {
      let item = this.item;
      if (this.slot_show) {
        this.$store.dispatch("custom_fromById", {
          names_controllers: [this.controller_root],
          id: item.id,
        });

        this.$store.commit("dialog/set_dialog", {
          data: item,
          flag: true,
          type: "update",
          controller: this.controller_root,
        });
      } else {
        this.$router.push({
          name: this.controller_root + "_update",
          params: { id: item.id },
        });
      }
    },
  },
  computed: {
    personnalise() {
      let info_chef = [];
      if (typeof this.item.personnaliserecette == "string") {
        let arrayitem = this.item.personnaliserecette.split(" | ");
        arrayitem.forEach((element) => {
          let info = element.split(" // ");
          if (info[0] != "") {
            info_chef.push(info);
          }
        });
      }
      return info_chef;
    },
    extras() {
      let info_chef = [];
      if (typeof this.item.extrasrecette == "string") {
        let arrayitem = this.item.extrasrecette.split(" | ");
        arrayitem.forEach((element) => {
          let info = element.split(" // ");
          if (info[0] != "") {
            info_chef.push(info);
          }
        });
      }
      return info_chef;
    },
  },
};
</script>

<style scoped>
.v-card--material .__avatar {
  position: relative;
  top: -64px;
  margin-bottom: -32px;
}

.v-card--material .__heading {
  position: relative;
  top: -40px;
  transition: 0.3s ease;
  z-index: 1;
}
</style>
