<template>
  <v-row class="justify-center">
    <v-card>
      <v-data-table
        v-show="isnotlotbasique() && identrepot != ''"
        hide-default-footer
        :headers="lotheaders"
        :items="bindlot"
        class="elevation-1"
        item-key="index"
        white="90%"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title> {{ lotstypeproduit() }} . </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            . QTE lots {{ quantite_lot_total }} / QTE BL {{ quantite_item }}

            <v-spacer></v-spacer>
            <v-divider class="mx-4" inset vertical></v-divider>
            <addlist-inputlots-viewaddlot
              v-show="show_btn_add && identrepot != ''"
              :controller_root="controller_root"
            />
          </v-toolbar>
        </template>

        <template v-slot:item.lot="props">
          <v-select
            dense
            readonly
            v-model="props.item.lot"
            :items="data_Select_load_lot"
            item-text="text"
            item-value="value"
          >
          </v-select>
        </template>

        <template v-slot:item.quantite="props">
          <v-text-field
            :value="props.item.quantite"
            @input="change_quantite($event, props.item.index)"
            type="number"
          >
            <template v-slot:append-outer>
              <v-btn
                v-if="show_btn_add"
                class="mx-2"
                fab
                dark
                small
                color="success"
                @click="regler_add_qte(props.item)"
              >
                <v-icon dark>mdi-subdirectory-arrow-left</v-icon>
              </v-btn>

              <v-btn
                v-else
                class="mx-2"
                fab
                dark
                small
                color="orange"
                @click="regler_moin_qte(props.item)"
              >
                <v-icon dark>mdi-subdirectory-arrow-right</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </template>
        <template v-slot:no-data>
          <addlist-inputlots-viewaddlot
            :controller_root="controller_root"
            v-show="show_btn_add && identrepot != ''"
          />
        </template>
      </v-data-table>
    </v-card>
  </v-row>
</template>
<script>
import InputMixin from "./InputMixin";
export default {
  mixins: [InputMixin],
  props: {
    show_btn_add: {
      type: Boolean,
      default: true,
    },
    qte_not_zero: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    lotheaders: [],
    identrepot: "",
  }),
  computed: {
    bindlot: {
      get() {
        return this.$store.getters["structure/addlist/rows"][
          this.controller_listsAdd
        ][this.indexrows][this.indexrow]["value"];
      },
      set(value) {
        this.$store.dispatch("structure/addlist/input_change", {
          name: this.name,
          value: value,
          input_schema: this.schema,
          controller_listsAdd: this.controller_listsAdd,
          indexrows: this.indexrows,
          indexrow: this.indexrow,
        });
      },
    },

    idproduit() {
      let indexitemproduit = this.getIndexItemByName("produit");
      return this.$store.getters["structure/addlist/rows"][
        this.controller_listsAdd
      ][this.indexrows][indexitemproduit]["value"];
    },
    quantite_item() {
      let indexitemquantite = this.getIndexItemByName("quantite");

      return (
        this.$store.getters["structure/addlist/rows"][this.controller_listsAdd][
          this.indexrows
        ][indexitemquantite]["value"] * 1
      );
    },

    quantite_lot_total() {
      let lot = this.bindlot;
      let qte = 0;

      for (let index = 0; index < lot.length; index++) {
        let q = lot[index]["quantite"];
        qte = q * 1 + qte;
      }
      return qte;
    },

    data_Select_load_lot() {
      //  this.remove_all_lot();

      let data = this.$store.getters["lots/get_data_Select_load"]();

      let items = [];

      for (const key in data) {
        let option = {};

        const element = data[key];

        option.text = element["toString"];
        option.value = element["id"];

        items.push(option);
      }

      return items;
    },
  },
  methods: {
    getIndexItemByName(name) {
      const schemehtml = this.$store.getters["structure/addlist/schemehtml"][
        this.controller_listsAdd
      ];
      let i = -1;

      for (let index = 0; index < schemehtml.length; index++) {
        const element = schemehtml[index];
        if (element["from"] == name || element["name"] == name) {
          i = index;
          return i;
        }
      }
      return i;
    },

    addlot() {
      let lots = this.values_binds; //      input_lot["value"];

      const lotnew = this.$store.getters[
        "structure/addlist/lotproduits/lotbindmodel"
      ](this.from);

      lots.push(lotnew);

      let value = lots.map((lots, index) => ({
        ...lots,
        index: index,
      }));

      this.modelbind(value);
    },
    change_quantite(val, index) {
      this.bindlot[index]["quantite"] = val;
      this.refreshvalue();
    },
    refreshvalue() {
      let lots = this.values_binds;
      let v = JSON.parse(JSON.stringify(lots));
      this.modelbind(v);
    },
    charge_lot() {
      let idproduit = this.idproduit;
      if (idproduit != "") {
        this.$store.commit("structure/set_input_binds", {
          name: "produits",
          value: idproduit,
          controller_root: "lot",
        });

        let identrepot = this.$store.getters["structure/get_input_binds"]({
          controller_root: this.controller_root,
          name: "entrepot",
        });
        this.identrepot = identrepot;

        this.$store.commit("structure/set_input_binds", {
          name: "entrepot",
          value: identrepot,
          controller_root: "lot",
        });

        //////////////////////////////
        let controller_root = this.controller_root;

        this.$store.dispatch("lots/ajax_chargeDataSelect", {
          idproduit,
          identrepot,
          controller_root,
          qte_not_zero: this.qte_not_zero,
        });

        this.$store.dispatch("lots/ajax_add_chargeDataInfoLot", {
          idproduit,
          identrepot: "all",
          controller_root: "all",
          qte_not_zero: "all",
        });
      }
    },


    
    isnotlotbasique() {
      return (
        this.lotstypeproduit() != "basique" && this.lotstypeproduit() != ""
      );
    },

    lotstypeproduit() {
      let value = this.idproduit;

      let data = this.$store.getters["structure/addlist/data_Select_load"][
        this.controller_listsAdd
      ]["produit"];

      let item = "";

      for (const key in data) {
        const element = data[key];

        if (element["id"] == value) {
          item = element["lotstype"];
        }
      }

      return item;
    },



    regler_add_qte(item) {
      for (let i = 0; i < this.bindlot.length; i++) {
        this.bindlot[i]["quantite"] = 0;
      }

      let index = item["index"];
      this.bindlot[index]["quantite"] = this.quantite_item;

      this.refreshvalue();

      console.log(item);
    },
    regler_moin_qte(item) {
      let rest = this.quantite_item - this.quantite_lot_total;
      let lotid = item["lot"];
      let data = this.$store.getters["lots/get_data_Select_load"]();
      for (const key in data) {
        const element = data[key];
        if (lotid == element["id"]) {
          if (rest > element["quantite"]) {
            item["quantite"] = element["quantite"];
          } else {
            item["quantite"] = rest;
          }
        }
      }
      this.refreshvalue();
    },
  },
  mounted() {
    this.lotheaders = this.$store.getters[
      "structure/addlist/lotproduits/titles"
    ](this.from);

    this.charge_lot();
  },
  watch: {
    idproduit(val) {
      //remove all
      this.modelbind([]);

      this.charge_lot();
    },
    data_Select_load_lot(items) {
      if (items && items.length > 0) {
        for (let index = 0; index < items.length; index++) {
          const element = items[index];

          if (this.bindlot[index] == undefined) {
            this.addlot();
            this.bindlot[index]["lot"] = element["value"];
          } else {
            this.bindlot[index]["lot"] = element["value"];
          }
        }
        this.refreshvalue();
      }
    },
  },
};
</script>