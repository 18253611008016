<template>
  <div>
    <v-list two-line subheader>
      <show-item-render
        v-for="(schema_input, index) in schemas_inputs"
        :key="index"
        :typeHtml="schema_input.typeHtml"
        :name="schema_input.name"
        :schema="schema_input"
        :controller_root="controller_root"
      />
    </v-list>
  </div>
</template>
<script >
import { mapGetters } from "vuex";
export default {
  props: {
    controller_root: {
      type: String,
      required: true
    }
  },
  computed: {
    schemas_inputs() {
      return this.$store.getters["structure/get_schemas"]({
        controller_root: this.controller_root
      });
    }
  }
};
</script>

<style >
</style>