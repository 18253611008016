<template>
 <v-list-item>
      <v-list-item-action></v-list-item-action>
    <v-list-item-content>
      <v-list-item-title><u>{{ label }}  :</u> </v-list-item-title>
      <h3>{{ values_binds }}</h3>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
import InputMixin from "./InputMixin";

export default {
  mixins: [InputMixin],
  


};
</script>
