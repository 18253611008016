<template>
  <component
    :is="typeVuetify"
    :controller_listsAdd="controller_listsAdd"
    :indexrows="indexrows"
    :indexrow="indexrow"
    :schema="schema"
    :disabled="disabled"
    :controller_root="controller_root"
    :show_btn_add="show_btn_add"
    :qte_not_zero="qte_not_zero"
  >
    <slot></slot>
  </component>
</template>
<script>
export default {
  props: {
    controller_root: {  /// pour lot
      type: String,
      default: "",
    },
    show_btn_add: {  /// pour lot
      type: Boolean,
      default: true,
    },
    qte_not_zero: { /// pour lot
      type: Boolean,
      default: false,
    },
    controller_listsAdd: String,
    schema: Object,
    indexrows: Number,
    indexrow: Number,
    disabled: Boolean,
  },

  data() {
    return {};
  },
  computed: {
    typeVuetify() {
      const type = this.schema.typeHtml;

      switch (type) {
        case "Textarea":
          return "addlist-input-textarea";
          break;
        case "select":
          return "addlist-input-select";
          break;
           case "selectajax":
          return "addlist-input-selectajax";
          break;

        case "file":
          return "addlist-input-file";
          break;
        case "files":
          return "addlist-input-files";
          break;
        case "logo":
          return "addlist-input-logo";
          break;

        case "checkbox":
          return "addlist-input-checkbox";
          break;
        case "radio":
          return "addlist-input-radio";
          break;
        case "decimal":
          return "addlist-input-decimal";
          break;
        case "number":
          return "addlist-input-number";
          break;
        case "money":
          return "addlist-input-money";
          break;
        case "hidden":
          return "addlist-input-hidden";
          break;
        case "selecthidden":
          return "addlist-input-hidden";
          break;
        case "date":
          return "addlist-input-date";
          break;
        case "tel":
          return "addlist-input-tel";
          break;
        case "fax":
          return "addlist-input-fax";
          break;
        case "email":
          return "addlist-input-email";
          break;
        case "url":
          return "addlist-input-url";
          break;

        case "ref":
          return "addlist-input-ref";
          break;
        case "reflot":
          return "addlist-input-reflot";
          break;

        default:
          return "addlist-input-text";
          break;
      }
    },
  },
};
</script>