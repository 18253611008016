<template>

    <v-text-field :readonly="readonly" 
   @blur="uniqueajax_validation()"
    @focus="init_validation()"
    :rules="rules"
    
    :label="label" 
    :value="values_binds" 
     @input="modelbind($event)" type="number" :loading="loading" ></v-text-field>

</template>
<script>
import InputMixin from "./InputMixin";
export default {
   mixins: [InputMixin],
     methods: {
    init_validation() {
      this.rules =[this.validation.decimal];
    },
  },
};
</script>