<template>
  <v-form ref="form" v-model="valid">
    <v-row class="justify-center">
      <v-col cols="12" lg="5">
        <card-box
          id="a1"
          icon="mdi-file-star-outline"
          :title="$t('update_bonsachats_infoplat_1')"
          color="success"
        >
          <box-root
            :input_root="input_root"
            :controller_root="controller_root"
          />
          <box-inputs
            :names="['titre', 'reffournisseur', 'note']"
            :controller_root="controller_root"
          />
        </card-box>
      </v-col>
      <v-col cols="12" lg="5">
        <card-box
          id="a2"
          icon="mdi-file-star-outline"
          :title="$t('update_bonsachats_infoplat_2')"
          color="success"
        >
          <box-inputs
            :names="['dateLivraison', 'dateCommande', 'files']"
            :controller_root="controller_root"
          />
        </card-box>
      </v-col>

      <v-col cols="12" lg="7">
        <card-box
          id="addlist2"
          icon="mdi-file-star-outline"
          :title="$t('update_bonsachats_infoplat_3')"
          color="success"
        >
          <addlist-table controller_listsAdd="produitsbonsachats" />
        </card-box>
          <card-box
          id="addlist22"
          icon="mdi-file-star-outline"
          :title="$t('update_bonsachats_infoplat_4')"
          color="success"
        >
          <addlist-table  controller_listsAdd="recettebonachats" />
          
        </card-box>
      </v-col>

      <v-col cols="12" lg="3">
        <v-row class="justify-center">
          <v-col :cols="12" :lg="12">
            <card-box
              id="groupa1"
              color="orange"
              :title="$t('update_bonsachats_infoplat_5')"
              :text="$t('update_bonsachats_box_text_5')"
            >
              <template v-slot:chart> </template>

              <box-inputs
                :names="[
                  'montantHt',
                  'montantTva',
                  'montantTtc',
                  'modepaiement',
                ]"
                :controller_root="controller_root"
              />
            </card-box>
          </v-col>
        </v-row>
      </v-col>

      <v-col :cols="12" :lg="4">
        <card-box id="default">
          <p v-show="false">
            {{ totallist }}
          </p>
          <btn-send :controller_root="controller_root" :valid="valid" />
        </card-box>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import i18n from "@/plugins/i18n";
import { mapGetters } from "vuex";
import ViewMixin from "@/views/update";

export default {
  mixins: [ViewMixin],

  props: {},
  data() {
    return {
      controller_root: "bonsachats",
      input_root: "fournisseur",
      namemodule: "Achat",
      lists_Add: [
        {
          controller_listsAdd: "produitsbonsachats",
          calcules: {
            total({ prix, qte }) {
              let total = qte * prix;

              return total.toFixed(3);
            },
          },
        },{
          controller_listsAdd: "recettebonachats",
          calcules: {
            total({ prix, qte }) {
              let total = qte * prix;

              return total.toFixed(3);
            },
          },
        },
      ],
    };
  },
  computed: {
    totallist() {
      let columnsproduit = this.$store.getters["structure/addlist/columns"](
        "produitsbonsachats",
        "total"
      );

  let columnsrecette = this.$store.getters["structure/addlist/columns"](
        "recettebonachats",
        "total"
      );

      let sum = this.sum_value_array(columnsproduit)*1 + this.sum_value_array(columnsrecette)*1;




      if (sum > 0) {
        this.$store.commit("structure/set_input_binds", {
          name: "montantHt",
          value: (sum / 1.2).toFixed(2),
          controller_root: this.controller_root,
        });
        this.$store.commit("structure/set_input_binds", {
          name: "montantTva",
          value: (sum - sum / 1.2).toFixed(2),
          controller_root: this.controller_root,
        });
        this.$store.commit("structure/set_input_binds", {
          name: "montantTtc",
          value: sum.toFixed(2),
          controller_root: this.controller_root,
        });
      }

      return sum;
    },

    id() {
      return this.$route.params.id;
    },
  },
  created() {
    document.title = i18n.t("update_" + this.controller_root);
    this.initia();
    this.run();
  },
};
</script>
