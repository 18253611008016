<template>
 <!-- btn drawer right -->
  <v-btn
    
    fab
    dark
    fixed
    small
    top="top"
    right="right"
    class="setting-fab"
    color="success"
    @click="openThemeSettings"
  >
    <v-icon small>mdi-palette-swatch</v-icon>
  </v-btn>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  methods: {
    openThemeSettings() {
      this.$store.commit("theme/toggledrawer_right");
    },
  },
};
</script>
<style lang="css" scoped>
.setting-fab {
  top: 50% !important;
  right: 0;
  border-radius: 0;
}

</style>
