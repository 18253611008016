<template>
<v-col cols="12" sm="6" md="4">
  <!-- true-value="oui"
  false-value="non"-->
  <v-checkbox
    :label="label"
    :input-value="values_binds"
    @change="modelbind($event)"
    :loading="loading"
  ></v-checkbox>
</v-col>
</template>
<script>
import InputMixin from "./InputMixin";
export default {
  mixins: [InputMixin]
};
</script>