"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  toggledrawer_right: function toggledrawer_right(state) {
    //toggle
    state.drawer_right = !state.drawer_right;
  },
  set_drawer_right: function set_drawer_right(state, drawer_right) {
    //toggle
    state.drawer_right = drawer_right;
  },
  toggledrawer_i18n: function toggledrawer_i18n(state) {
    //toggle
    state.drawer_i18n = !state.drawer_i18n;

    if (state.drawer_i18n) {
      localStorage.setItem('drawer_i18n', "ok");
    } else {
      localStorage.setItem('drawer_i18n', "");
    }
  },
  set_drawer_i18n: function set_drawer_i18n(state, drawer_i18n) {
    //toggle
    state.drawer_i18n = drawer_i18n;

    if (state.drawer_i18n) {
      localStorage.setItem('drawer_i18n', "ok");
    } else {
      localStorage.setItem('drawer_i18n', "");
    }
  },
  toggledrawer_left: function toggledrawer_left(state) {
    //toggle
    state.drawer_left = !state.drawer_left;
  },
  set_drawer_left: function set_drawer_left(state, drawer_left) {
    //toggle
    state.drawer_left = drawer_left;
  },
  toggledark: function toggledark(state) {
    //toggle
    state.dark = !state.dark;
  },
  set_menu: function set_menu(state, menu) {
    var modules = {};
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = menu[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var m = _step.value;
        var name = m.NameModule;
        modules[name] = m;
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator["return"] != null) {
          _iterator["return"]();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    state.menu = menu;
    state.modules = modules;
  },
  // set_user(state, user) {
  //   state.user = user;
  // },
  // set_rools(state, rools) {
  //   state.rools = rools;
  // },
  set_organisation: function set_organisation(state, organisation) {
    state.organisation = organisation;
  },
  set_notification: function set_notification(state, notification) {
    state.notification = notification;
  },
  set_count_notification: function set_count_notification(state, count_notification) {
    state.count_notification = count_notification;
  },
  set_widgets: function set_widgets(state, widgets) {
    // Enregistrer des données dans localStorage
    localStorage.setItem("widgets", widgets.join());
    state.widgets = widgets;
  }
};
exports["default"] = _default;