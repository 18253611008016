<template>
  <v-container fluid>
    <v-checkbox
      v-model="custom"
      label="calcule par reglement totale"
    ></v-checkbox>
    <v-text-field
      v-show="custom"
      :readonly="readonly"
      :rules="[validation.decimal]"
      :label="' entrée une valeur qui  > ' + values_total + ' DH'"
      v-model="value"
      type="number"
      :loading="loading"
      append-icon="mdi-cash-refund"
      @click:append="modelbind()"
    ></v-text-field>
  </v-container>
</template>
<script>
import i18n from "@/plugins/i18n";
import validation from "./validation";
import InputMixin from "./InputMixin";
export default {
  props: {
    controller_listsAdd: String,
    schema: Object,
    indexrows: Number,
    indexrow: Number,
    disabled: Boolean,
    montantglobale: Number,
  },

  data() {
    return {
      validation: validation(this.schema["name"], this.schema["nullable"]),
      value: 0,
      custom: false,
    };
  },
  computed: {
    singleline() {
      return true;
    },
    loading() {
      return false;
    },
    readonly() {
      return false;
    },
    name() {
      return this.schema.name;
    },

    label() {
      return i18n.t(this.name);
    },

    values_binds() {
      return this.$store.getters["structure/addlist/rows"][
        this.controller_listsAdd
      ][this.indexrows][this.indexrow]["value"];
    },
    values_total() {
      return this.montantglobale - this.values_binds;
    },
  },

  methods: {
    modelbind($event) {
      let val = this.value - this.values_total;

      if (val < 0) {
        alert(
          "erreur valeur   |  entrée une valeur qui  >  " +
            this.values_total +
            " DH"
        );
      } else {
        this.$store.dispatch("structure/addlist/input_change", {
          name: this.name,
          value: val,
          input_schema: this.schema,
          controller_listsAdd: this.controller_listsAdd,
          indexrows: this.indexrows,
          indexrow: this.indexrow,
        });
      }
    },
  },
};
</script>