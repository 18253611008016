<template>
  <div>
    <!-- form -->

    <v-row>
      <v-combobox
        :label="label + '    '"
        @change="selected($event)"
        @click:clear="items_selected_info = ''"
        v-model="value"
        :items="items"
        item-text="text"
        item-value="value"
        :filter="filter"
        hide-selected
        :clearable="!readonly"
        :readonly="readonly"
        :disabled="!edit_root"
      >
        <template v-slot:append-outer>
          <v-btn
            text
            icon
            color="indigo"
            :to="{ name: input_root + '_add' }"
            v-show="!readonly && isGranted('add', input_root.toLowerCase())"
          >
            <v-icon>mdi-account-plus</v-icon>
          </v-btn>
        </template>
      </v-combobox>
    </v-row>

    <v-row>
      <v-card
        class="mx-auto"
        style="margin-bottom: 16px"
        width="100%"
        outlined
        v-if="info"
        v-show="items_selected_info['id']"
      >
        <div class="overline mb-4">
          <h2
            v-if="
              items_selected_info['ice'] && items_selected_info['ice'] != 'null'
            "
          >
            ICE : {{ items_selected_info["ice"] }}
          </h2>
          <h2
            v-if="
              items_selected_info['cin'] && items_selected_info['cin'] != 'null'
            "
          >
            CIN : {{ items_selected_info["cin"] }}
          </h2>
        </div>

        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="headline mb-1">{{
              $t(info_title)
            }}</v-list-item-title>
            <v-list-item-subtitle>{{
              items_selected_info[info_title]
            }}</v-list-item-subtitle>
            <v-list-item-subtitle>
              <strong>{{ $t(info_subtitle) }}</strong>
              {{ items_selected_info[info_subtitle] }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <strong>{{ $t(info_text) }}</strong>
              {{ items_selected_info[info_text] }}
            </v-list-item-subtitle>

            <v-list-item-subtitle v-if="flag_montant_encours_autorise">
              <h3 class="red--text text--lighten-1">
                {{ $t(message_montant_encours_autorise) }}
              </h3>
            </v-list-item-subtitle>

            <v-list-item-subtitle v-if="flag_montant_encours_autorise">
              <u>
                <strong>{{ $t("Montant encours: ") }}</strong>
              </u>
              <h4>
                (
                {{
                  items_selected_info["montantencoursbons"] * 1 +
                  items_selected_info["montantencoursfactures"] * 1
                }}
                DH
                <span v-if="items_selected_info['montantencoursautorise'] > 0">
                  / {{ items_selected_info["montantencoursautorise"] }} DH
                </span>
                )
              </h4>
            </v-list-item-subtitle>

            <v-list-item-subtitle v-if="info_montant_encours_autorise">
              <u>
                <strong>{{ $t("Montant encours: ") }}</strong>
              </u>
              <h4>
                (
                {{
                  items_selected_info["montantencoursbons"] * 1 +
                  items_selected_info["montantencoursfactures"] * 1
                }}
                DH )
              </h4>
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-avatar tile size="80" color="grey">
            <readfile-img
              :refgroup="items_selected_info['logo']"
            ></readfile-img>
            <!-- <img :src="'/' + file_logo.path" :alt="file_logo.name" v-if="file_logo.path" /> -->
          </v-list-item-avatar>
        </v-list-item>

        <v-card-actions class="justify-center">
          <v-spacer></v-spacer>
          <v-btn
            text
            icon
            color="indigo"
            v-if="items_selected_info['id']"
            :to="{
              name: input_root + '_show_item',
              params: { id: items_selected_info['id'] },
            }"
            v-show="!readonly && isGranted('view', input_root.toLowerCase())"
          >
            <v-icon>mdi-view-carousel</v-icon>
          </v-btn>
          <v-btn
            text
            icon
            color="indigo"
            v-if="items_selected_info['id']"
            :to="{
              name: input_root + '_update',
              params: { id: items_selected_info['id'] },
            }"
            v-show="!readonly && isGranted('edit', input_root.toLowerCase())"
          >
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>

    <!-- /.form -->
  </div>
</template>
<script>
import api_config from "@/#_CONFIG/Entity";
import i18n from "@/plugins/i18n";

export default {
  props: {
    controller_root: {
      type: String,
      required: true,
    },

    input_root: {
      type: String,
      required: true,
    },
    input_root_from: {
      type: String,
      default: "",
    },
    to_string: {
      type: String,
      default: "@id",
    },

    info: {
      type: Boolean,
      default: false,
    },

    edit_root: {
      type: Boolean,
      default: true,
    },
    info_title: {
      type: String,
      default: "",
    },
    info_subtitle: {
      type: String,
      default: "",
    },
    info_text: {
      type: String,
      default: "",
    },
    info_image: {
      type: String,
      default: "",
    },
    flag_montant_encours_autorise: {
      type: Boolean,
      default: false,
    },
    info_montant_encours_autorise: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: undefined,
      message_montant_encours_autorise: "",

      items_selected_info: "",
      file_logo: "",
    };
  },
  computed: {
    label() {
      return i18n.t(this.input_root);
    },

    items() {
      //let schemas = api_config[this.controller_root].schema;
      let to_string = "";

      if (this.to_string == "@id") {
        let schema = this.$store.getters["structure/get_schema"]({
          controller_root: this.controller_root,
          name: this.input_root,
        });
        if (schema["to_string"] != undefined) {
          to_string = schema["to_string"];
        }
      } else {
        to_string = this.to_string;
      }

      let data = this.data_load;

      let items = [];

      for (const key in data) {
        let option = {};

        const element = data[key];

        option.text = element[to_string];
        option.value = element["@id"];
        if (option.value == this.values_binds["@id"]) {
          this.value = option;
        }

        items.push(option);
      }

      return items;
    },

    data_load() {
      let from = "";
      if (this.input_root_from != "") {
        from = this.input_root_from;
      } else {
        from = this.input_root;
      }
      return this.$store.getters["structure/get_data_Select_load"]({
        controller_root: this.controller_root,
        nameManyToOne: from,
      });
    },
    values_binds() {
      return this.$store.getters["structure/get_input_binds"]({
        controller_root: this.controller_root,
        name: this.input_root,
      });
    },

    readonly() {
      return false;
    },
  },
  watch: {
    value(val) {
      if (val != undefined) {
        this.selected_info(val.value);
      } else {
        this.items_selected_info = "";
      }
    },
    items(val) {
      console.log(val);

      if (val.length > 0) {
        let actionTraiter =
          this.$store.getters["structure/get_actionTraiter"]();

        if (actionTraiter != "") {
          setTimeout(() => {
            this.selected({ value: actionTraiter });

            console.log(actionTraiter);
            console.log(val);
            this.$store.commit("dialog/set_dialog_btn_send", { flag: false });
            this.$store.commit("structure/set_actionTraiter", {
              actionTraiter: "",
            });
          }, 5000);
        }
      }
    },
  },

  methods: {
    isGranted(attribute, controller) {
      return this.$store.getters["security/isGranted"](attribute, controller);
    },
    calcul_montant_encours_autorise(val_selected) {
      this.message_montant_encours_autorise = "";
      if (this.flag_montant_encours_autorise) {
        this.selected_info(val_selected);
        let montantencoure =
          this.items_selected_info["montantencoursbons"] * 1 +
          this.items_selected_info["montantencoursfactures"] * 1;
        let montantencoursautorise =
          this.items_selected_info["montantencoursautorise"] * 1;

        if (montantencoursautorise == 0) {
          return true;
        }

        if (montantencoursautorise < montantencoure) {
          this.message_montant_encours_autorise =
            "Montant encours autorisé dépassé";
          return false;
        } else {
          return true;
        }
      }

      return true;
    },

    selected(val_selected) {
      let value;

      if (val_selected != undefined) {
        value = val_selected["value"];
      }

      if (!this.calcul_montant_encours_autorise(value)) {
        // return;

        value = null;
      }

      this.$store.commit("structure/set_input_binds", {
        name: this.input_root,
        value: value,
        controller_root: this.controller_root,
      });

      let event = {
        name: this.input_root,
        value: value,
        controller_root: this.controller_root,
      };
      console.log("change root event => ", event);

      this.$store.dispatch("structure/event_root_change", { event });
    },

    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      let res =
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1;

      return res;
    },
    selected_info(val_selected) {
      let data = this.data_load;
      this.file_logo = "";
      for (const key in data) {
        const element = data[key];
        if (element["id"] == val_selected) {
          this.items_selected_info = element;
        }
      }
    },
  },
};
</script>

<style></style>
