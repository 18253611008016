<template>
  <v-row>
    <v-col cols="6">
      <v-subheader class="pl-0">Items par page</v-subheader>

      <v-text-field
        :max="items.length"
        min="1"
        :value="itemsPerPage"
        thumb-label
        @input="setitemsPerPage($event)"
      ></v-text-field>


    </v-col>

    <v-col cols="4">
      <v-pagination @input="setPage($event)" :length="pageCount" :value="page"></v-pagination>
    </v-col>
  </v-row>
</template>


<script>
import { mapGetters } from "vuex";
export default {
  props: { nametableroot: {},items: {} },
  computed: {
    page() {
      return this.$store.getters["structure/multiselect/g_page"]({
        nametable: this.nametableroot
      });
    },
    pageCount() {
      return this.$store.getters["structure/multiselect/g_pageCount"]({
        nametable: this.nametableroot
      });
    },
    itemsPerPage() {
      return this.$store.getters["structure/multiselect/g_itemsPerPage"]({
        nametable: this.nametableroot
      });
    }
  },

  data() {
    return {};
  },
  methods: {
    setitemsPerPage(val) {
      let itemsPerPage = parseInt(val, 10);
      if (itemsPerPage > 0) {
        this.$store.commit(
          "structure/multiselect/set_itemsPerPage",

          { data: itemsPerPage, nametable: this.nametableroot }
        );
      }
    },
    setPage(val) {
      let page = parseInt(val, 10);

      this.$store.commit("structure/multiselect/set_page", {
        data: page,
        nametable: this.nametableroot
      });
    }
  }
};
</script>

