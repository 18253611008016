//let colorconsole="color: red"
let colorconsole="color: gray"
let colorconsoleyellow="color: magenta"
//let colorconsole="color: blue"
//let colorconsole="color: magenta"
//let colorconsole="color: cyan"
//let colorconsole="color: gray"
//let colorconsole="color: grey"



export default {
  g_flag: (state) => (controller_root) => {
    console.groupCollapsed("%cgetters  dialog/g_flag", colorconsole);
    console.log("   get etat flag de => " + controller_root);
    console.table(state.controllers);
    console.log("flag =>" + state.controllers[controller_root].flag);
    console.groupEnd();

    return state.controllers[controller_root].flag;
  },

  g_type: (state) => (controller_root) => {
    console.groupCollapsed("%cgetters  dialog/g_type", colorconsole);
    console.log("   get etat type de => " + controller_root);
    console.table(state.controllers);
    console.log("type =>" + state.controllers[controller_root].type);
    console.groupEnd();

    return state.controllers[controller_root].type;
  },
  g_data: (state) => (controller_root) => {
    console.groupCollapsed("%cgetters  dialog/g_data", colorconsole);
    console.log("   get etat data de => " + controller_root);

    console.table(state.controllers);
    console.table(state.controllers[controller_root].data);
    console.groupEnd();

    return state.controllers[controller_root].data;
  },
  get_dialog_btn_send: (state) => {
    return state.showbtnsend;
  },
};
