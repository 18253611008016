<template>
  <card-card
    icon="mdi-tag-text-outline"
    color="primary"
    title="TVA      Mensuelle / Trimestrielle / Annuelle"
    class="px-5 py-3"
     :close="close"
    @close="closeevent"
  >
    <h3>Montant de TVA</h3>

    <v-row class="justify-space-around align-center">
      <v-col cols="5" sm="5" md="5">
        <v-text-field type="number" v-model="date" :label=" $t('date' )" placeholder="Placeholder" ></v-text-field>
      </v-col>

      <v-col cols="1" sm="1" md="1">
        <v-btn icon slot="widget-header-action">
          <v-icon class="text--secondary" @click="start">mdi-refresh</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="justify-center align-center">
      <v-tabs>
        <v-tab>
          <v-icon left>mdi-calendar-range</v-icon>Mensuelle
        </v-tab>
        <v-tab>
          <v-icon left>mdi-calendar-range</v-icon>Trimestrielle
        </v-tab>

        <v-tab>
          <v-icon left>mdi-calendar-range</v-icon>Annuelle
        </v-tab>

        <v-tab-item>
          <v-col cols="12">
            <v-simple-table>
              <thead>
                <tr>
                  <th>moin</th>
                  <th class="primary--text">TVA déductible</th>
                  <th class="orange--text">TVA collectée</th>

                  <th class="text-right deep-purple--text">TVA due</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(data, index) in datasets" :key="index">
                  <td>{{data.index}} / {{date}}</td>
                  <td
                    class="primary--text"
                  >{{data.relevedepenses.toLocaleString(undefined,{ minimumFractionDigits: 2 } ) }}</td>
                  <td
                    class="orange--text"
                  >{{data.releverecettes.toLocaleString(undefined,{ minimumFractionDigits: 2 } ) }}</td>
                  <td class="text-right subtitle-1 deep-purple--text font-weight-light">
                    <strong>{{(data.relevedepenses + data.releverecettes).toLocaleString(undefined,{ minimumFractionDigits: 2 } )  }}</strong> DH
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-tab-item>
        <v-tab-item>
          <v-col cols="12">
            <v-simple-table>
              <thead>
                <tr>
                  <th>moin</th>
                  <th class="primary--text">TVA déductible</th>
                  <th class="orange--text">TVA collectée</th>

                  <th class="text-right deep-purple--text">TVA due</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(data, index) in datasetsTrimestrielle" :key="index">
                  <td>{{data.index}} / {{date}}</td>
                  <td
                    class="primary--text"
                  >{{data.relevedepenses.toLocaleString(undefined,{ minimumFractionDigits: 2 } ) }} </td>
                  <td
                    class="orange--text"
                  >{{data.releverecettes.toLocaleString(undefined,{ minimumFractionDigits: 2 } ) }}</td>
                  <td class="text-right subtitle-1 deep-purple--text font-weight-light">
                    <strong>{{(data.relevedepenses + data.releverecettes).toLocaleString(undefined,{ minimumFractionDigits: 2 } )}}</strong> DH
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-tab-item>

        <v-tab-item>
          <v-col cols="12" class="justify-center align-center">
            <h3 class="primary--text" >TVA déductible : {{(TVAAnnuelle.tva_d *-1).toLocaleString(undefined,{ minimumFractionDigits: 2 } )}} DH</h3>
            <h3  class="orange--text" >TVA collectée : {{(TVAAnnuelle.tva_r).toLocaleString(undefined,{ minimumFractionDigits: 2 } )}} DH</h3>
            <h2  class=" deep-purple--text ">TVA due : {{(TVAAnnuelle.tva_r - TVAAnnuelle.tva_d).toLocaleString(undefined,{ minimumFractionDigits: 2 } )}} DH</h2>
          </v-col>
        </v-tab-item>
      </v-tabs>
    </v-row>
  </card-card>
</template>
<script>

import axios from "axios";


import widgetsMixin from "./../widgetsMixin";
export default {
  mixins: [widgetsMixin],
  data: () => ({

   date: new Date().getFullYear() ,

    satatistique_mois: {
      //  commandes: {},
      // bonsachats: {},
      relevedepenses: {},
      releverecettes: {},
    },
    satatistique_trimestrielle: {
      //  commandes: {},
      // bonsachats: {},
      relevedepenses: {},
      releverecettes: {},
    },

    satatistiqueAnnee: [],
  }),
    computed: {

Base_url_web() {
      return this.$store.getters["get_Base_url"];
    },
    controllers() {
      let controllers = [];
      for (const key in this.satatistique_mois) {
        controllers.push(key);
      }
      return controllers;
    },

    datasets() {
      let datasets = {};

      for (const keyob in this.satatistique_mois) {
        let object = this.satatistique_mois[keyob];

        for (const key in object) {
          if (!datasets.hasOwnProperty(key)) {
            datasets[key] = {
              relevedepenses :0, releverecettes:0
            };
          }

          if (object.hasOwnProperty(key)) {
            let tvareglemente = object[key]["tvareglemente"]
              ?( object[key]["tvareglemente"] * 1)
              : 0;
            if ("relevedepenses" == keyob) {
              tvareglemente = tvareglemente * -1;
            }

            datasets[key][keyob] = tvareglemente;
            datasets[key]["index"] = key * 1;
          }
        }
      }

      return datasets;
    },

    datasetsTrimestrielle() {
      let datasets = {};

      for (const keyob in this.satatistique_trimestrielle) {
        let object = this.satatistique_trimestrielle[keyob];

        for (const key in object) {
          if (!datasets.hasOwnProperty(key)) {
            datasets[key] = {   relevedepenses :0, releverecettes:0};
          }

          if (object.hasOwnProperty(key)) {
            let tvareglemente = object[key] ? object[key] * 1 : 0;
            if ("relevedepenses" == keyob) {
              tvareglemente = tvareglemente * -1;
            }

            datasets[key][keyob] = tvareglemente;
            datasets[key]["index"] = key;
          }
        }
      }

      return datasets;
    },

    TVAAnnuelle() {
      let data = this.satatistiqueAnnee;

      let tva_r = data[1] ? data[1] : 0;

      let tva_d = data[0] ? data[0] : 0;

      return {tva_r , tva_d};
    },

    paths_statistiques() {
      return this.$store.getters["get_statistiques"];
    },
  },
  methods: {
    count(paths, controller) {
      let path = paths[controller];
      if (path != undefined) {
        axios
          .get(this.Base_url_web+path, { params: { date: this.date } })
          .then((response) => {
            this.satatistique_mois[controller] = response.data["TVAMensuelle"];
            this.satatistique_trimestrielle[controller] = response.data["TVATrimestrielle"];
            this.satatistiqueAnnee.push(response.data["TVAAnnuelle"]["tvareglemente"] );
          })
          .catch((error) => {
            console.error("path");
          });
      }
    },

    start() {
      this.satatistiqueAnnee = [];
      for (const controller of this.controllers) {
        this.count(this.paths_statistiques, controller);
      }
    },
  },

  mounted() {
    this.start();
  },
  watch: {
    paths_statistiques() {
      this.start();
    },

    date(){
       this.start();
    }
  },
};
</script>
