<template>
  <v-img
    :src="Base_url + files_valueshow.minifile"
    :alt="files_valueshow.originalName"
  ><slot></slot></v-img>
</template>
<script>
export default {
  props: ["refgroup"],
  data: () => ({
    files_valueshow: { minifile: "/src/img/logo.jpg", originalName: "" },
  }),
  methods: {
    getfile(val) {
      this.files_valueshow = {
        minifile: "/src/img/logo.jpg",
        originalName: "",
      };

      if (val != "" && val != undefined) {
        this.$store.dispatch("files/get_files_save", {
          refGroup: val,
          fun_finally: (info) => {
            if (info[0] != undefined) {
              this.files_valueshow = info[0];
            } else {
              this.files_valueshow = {
                minifile: "/src/img/logo.jpg",
                originalName: "",
              };
            }
          },
        });
      }
    },
  },

  watch: {
    refgroup(val) {
      this.getfile(val);
    },
  },
  computed: {
    Base_url() {
      return this.$store.getters["get_Base_url"];
    },
  },

  mounted() {
    this.getfile(this.refgroup);
  },
};
</script>
