<template>
  <div style="margin-top: 66px; margin-left: 37px">
    <card-block
      icon="mdi-translate"
      id="grouproot1"
      color="orange"
      :title="$t('box_title_Traduction')"
    >
      <v-card :elevation="2" style="margin-bottom: 35px">
        <v-row>
          <v-col cols="4">
            <v-select
              prepend-icon="mdi-flag-variant-outline"
              :items="langs"
              item-text="state"
              item-value="lang"
              v-model="$i18n.locale"
              :label="$i18n.locale"
              single-line
              hide-details
            >
              <template v-slot:append-outer>
                <span>
                  <v-chip color="primary" dark>
                    {{ $i18n.locale }}
                  </v-chip>
                </span>
              </template>
            </v-select>
            <v-spacer></v-spacer>
                <v-btn color="success" dark class="mb-2" @click="updatelang">
                 update   <v-icon> mdi-cloud-arrow-down</v-icon>
              </v-btn>
          </v-col>

          <v-col cols="5">
            <v-file-input
              :label="'excel de '"
              @change="red($event)"
              show-size
              counter
              chips
            >
              <template v-slot:append-outer>
                <v-btn fab dark small color="primary" @click="exceljs">
                  <v-icon> mdi-file-excel</v-icon>
                </v-btn>
                 
              </template>
            </v-file-input>
            
          </v-col>
        </v-row>
      </v-card>

      <v-card :elevation="2">
        <v-data-table
          :headers="headers"
          :items="messages"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-divider class="mx-4" inset vertical></v-divider>

              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('Search')"
                single-line
                hide-details
              ></v-text-field>

              <v-divider class="mx-4" inset vertical></v-divider>

              <v-dialog v-model="dialog">
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ editedItem.KEY }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-textarea
                            clearable
                            clear-icon="mdi-close-circle"
                            v-model="editedItem.message"
                            label="message"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      {{ $t("Cancel") }}
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      {{ $t("Save") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline"
                    >Are you sure you want to delete this item?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              fab
              dark
              color="primary"
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>

            <!--   <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
          </template>

          <template v-slot:item.message="{ item }">
            <v-chip
              color="orange"
              dark
              v-if="item.KEY == 'locale' || item.KEY == 'lang'"
            >
              <strong>{{ item.message }}</strong>
            </v-chip>
            <span v-else>{{ item.message }}</span>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize"> Reset </v-btn>
          </template>
        </v-data-table>
      </v-card>

      
    </card-block>
  </div>
</template>


<script>
import ExportJsonExcel from "js-export-excel";
import readXlsxFile from "read-excel-file";
import axios from "axios";

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    search: "",
    headers: [],
    messages: [],
    editedIndex: -1,
    editedItem: {
      KEY: "",
      message: "",
    },
    defaultItem: {
      KEY: "",
      message: "",
    },
  }),

  computed: {
    drawerValue: {
      get() {
        return this.$store.getters["theme/drawer_i18n"];
      },
      set(val) {
        this.$store.commit("theme/set_drawer_i18n", val);
      },
    },

    langs() {
      let lang = [];
      for (const key in this.$i18n.messages) {
        lang.push({
          state: this.$i18n.messages[key]["lang"],
          lang: key,
        });
      }
      return lang;

      // let lang = [];
      // for (const key in this.$i18n.messages) {
      //   lang.push({ state: this.$i18n.messages[key]["lang"], lang: key });
      // }
      // return lang;
    },
    langsselect() {
      this.$vuetify.lang.current = this.$i18n.locale
        .split("-")[0]
        .toLowerCase();
      return this.$i18n.locale;
    },

    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    Base_url_web() {
      return this.$store.getters["get_Base_url"];
    },
    JWTToken() {
      return this.$store.getters["get_JWTToken"];
    },
  },

  watch: {
    langsselect(val) {
      this.initialize();
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    setInterval(() => {
      this.initialize();
    }, 2000);
  },

  methods: {
    red(ev) {
      readXlsxFile(ev).then((rows) => {
        let headers = rows[0];
        let data = [];
        for (let index = 1; index < rows.length; index++) {
          let d = {};
          const row = rows[index];
          for (let i = 0; i < headers.length; i++) {
            const key = headers[i];
            const value = row[i];
            d[key] = value;
          }
          data.push(d);
        }

        this.messages = data;

        this.savetoserver();

        // `rows` is an array of rows
        // each row being an array of cells.
      });
    },

    exceljs() {
      let sheetheader = [];
      let sheetheadervalue = [];
      // let row = {};
      let dataselecteds = this.messages;

      for (let index = 0; index < this.headers.length; index++) {
        const element = this.headers[index];

        if (element.value != "actions" && element.value != "ID") {
          sheetheader.push(element.value);
          sheetheadervalue.push(element.value);

          // row[element.value] = element.text;
        }
      }
      // dataselecteds.unshift(row);

      var option = {};
      let namecontroller = "translated";
      option.fileName = namecontroller;

      option.datas = [
        {
          sheetName: namecontroller,
          //   sheetFilter: [ "one","two"],
          // sheetHeader: [ "one","two"],
          sheetHeader: sheetheader,
          sheetFilter: sheetheadervalue,
          columnWidths: [15, 30],
          sheetData: dataselecteds,
        },
      ];

      var toExcel = new ExportJsonExcel(option); //new
      toExcel.saveExcel();
    },

    updatelang(){


      let JWTToken = this.JWTToken;
      axios
        .get(this.Base_url_web + "/i18n/updatebydatabase",  {
          params: {},
          headers: {
            Authorization: JWTToken,
          },
        })
        .then((response) => {
          let msgs = response.data;

          for (const key in msgs.lang) {
            this.$i18n.setLocaleMessage(key, msgs.lang[key]);
          }

          this.$i18n.locale = msgs.locale;
        })
        .catch((error) => {
          console.error("error to POST data =>" + error);
        });

    },
    initialize() {
      let select = this.$i18n.locale;

      let messages = this.$i18n.messages[select];
      let data = [];
      for (const key in messages) {
        data.push({
          KEY: key,
          message: messages[key],
        });
      }


      this.headers = [
        {
          text: "KEY",
          value: "KEY",
        },
        {
          text: "Chaîne de traduction actuelle",
          value: "message",
        },

        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ];
      this.messages = data;
    },

    savetoserver(editedItem) {
      this.messages;
      let objectmessage = {};
      this.messages.forEach((element) => {
        objectmessage[element.KEY] = element.message;
      });

      var json_arr = JSON.stringify(objectmessage);
      const formdata = new FormData();
      formdata.append("i18n", json_arr);

      if (editedItem != undefined) {
        formdata.append("locale", this.$i18n.locale  );
        

        formdata.append("editedItem", JSON.stringify(editedItem) );
      }
      let JWTToken = this.JWTToken;
      axios
        .post(this.Base_url_web + "/i18n/langsave", formdata, {
          params: {},
          headers: {
            Authorization: JWTToken,
          },
        })
        .then((response) => {
          let msgs = response.data;

          for (const key in msgs.lang) {
            this.$i18n.setLocaleMessage(key, msgs.lang[key]);
          }

          this.$i18n.locale = msgs.locale;
        })
        .catch((error) => {
          console.error("error to POST data =>" + error);
        });
    },

    editItem(item) {
      this.editedIndex = this.messages.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.messages.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.messages.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.messages[this.editedIndex], this.editedItem);
      } else {
        this.messages.push(this.editedItem);
      }
      this.close();
      this.savetoserver(this.editedItem);
    },
  },
  mounted() {
    setTimeout(() => {
      window.document.addEventListener(
        "click",
        (e) => {
          e = e || window.event;
          var target = e.target || e.srcElement,
            text = target.textContent || target.innerText;

          let etat = window.localStorage.getItem("drawer_i18n", "");
          let icon = " ✍️";

          if (
            etat == "ok" &&
            text.indexOf(icon) > -1 &&
            text.split(icon).length == 2
          ) {
            let serch_text = text.split(icon)[0].trim();
            if (serch_text.indexOf("*") == 0) {
              //Delete first character of string,=> *
              this.search = serch_text.substring(1);
            } else {
              this.search = serch_text;
            }
          }
        },
        false
      );
    }, 2000);
  },
};
</script>


