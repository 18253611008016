<template>
<v-col cols="12" sm="6" md="4">
  <v-radio-group
    :value="values_binds"
    @change="modelbind($event)"
    :loading="loading"
    row
    :readonly="readonly"
  >


    <v-radio
      v-for="(label_item, i) in labels"
      :key="i"
      :label="labelUpperCase(label_item)"
      :value="label_item"
    ></v-radio>

  </v-radio-group>
</v-col>
</template>
<script>
import InputMixin from "./InputMixin";
export default {
  mixins: [InputMixin],
  computed: {
    labels() {
      return this.group_Choice.split("--");
    }, 
    group_Choice() {

      return this.schema["group_Choice"];

    },
  }
};
</script>
